import { notification } from 'antd';
import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { CASH_ORDER_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import {
    REGISTER_SERVICE_INPUT_CASH_ORDER_IN_CASHDESK,
    REGISTER_SERVICE_OUTPUT_CASH_ORDER_IN_CASHDESK,
    SERVICE_INPUT,
    SERVICE_OUTPUT,
    TRANSFER,
    registerServiceInputCashOrderInCashdesk,
    registerServiceOutputCashOrderInCashdesk,
    selectCashOrderSum,
    selectComment,
    selectCommentSecond,
    selectOnCashOrderCreatedCallback,
    selectSelectedCashboxId,
    selectSelectedCashboxIdSecond
} from 'modals/RefactoredCashOrderModal/redux/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { selectCashOrderDate } from '../duck';

/**
 * Handle creation of cash order of service input
 */
export function* serviceInputSaga() {
    while (true) {
        try {
            yield take(SERVICE_INPUT);
            const serviceInputSum = yield select(selectCashOrderSum);
            const cashboxId = yield select(selectSelectedCashboxId);
            const datetimeSelect = yield select(selectCashOrderDate);
            const comment = yield select(selectComment);
            const callback = yield select(selectOnCashOrderCreatedCallback);

            // Get cashbox to register in cashdesk later
            const cashBoxes = yield call(fetchAPI, 'GET', '/cash_boxes', null, null, {handleErrorInternally: true});
            const cashBox = _.get(
                _.filter(cashBoxes, obj => obj.id == cashboxId),
                '[0]'
            );

            const isCashBoxRst = Boolean(_.get(cashBox, 'rst'));

            const cashOrderPayload = {
                type: CASH_ORDER_TYPES.INCOME,
                cashBoxId: cashboxId,
                increase: serviceInputSum,
                datetime: datetimeSelect,
                otherCounterparty: 'Service input',
                description: comment,
                analyticsUniqueId: 1201
            };

            // Create CashOrder in our system first
            const { id: cashOrderId } = yield call(
                fetchAPI,
                'POST',
                '/cash_orders',
                null,
                cashOrderPayload,
                { handleErrorInternally: true }
            );

            // If cashbox contains rst it must be registred in cashdesk if possible
            if (isCashBoxRst) {
                yield put(registerServiceInputCashOrderInCashdesk({ cashOrderId }));
            }

            // eslint-disable-next-line callback-return
            callback && callback({ cashOrderId });
        } catch (error) {
            yield put(emitError(error));
            notification.error({ message: _.get(error, 'response.message') }); // Print special error message if it exists
        }
    }
}

/**
 * Handle creation of cash order of service output
 */
export function* serviceOutputSaga() {
    while (true) {
        try {
            yield take(SERVICE_OUTPUT);

            const serviceOutputSum = yield select(selectCashOrderSum);
            const cashboxId = yield select(selectSelectedCashboxId);
            const comment = yield select(selectComment);
            const datetimeSelect = yield select(selectCashOrderDate);
            const callback = yield select(selectOnCashOrderCreatedCallback);

            // Get cashbox to register in cashdesk later
            const cashBoxes = yield call(fetchAPI, 'GET', '/cash_boxes', null, null, {handleErrorInternally: true});
            const cashBox = _.get(
                _.filter(cashBoxes, obj => obj.id == cashboxId),
                '[0]'
            );

            const isCashBoxRst = Boolean(_.get(cashBox, 'rst'));

            const cashOrderPayload = {
                type: CASH_ORDER_TYPES.EXPENSE,
                cashBoxId: cashboxId,
                decrease: serviceOutputSum,
                datetime: datetimeSelect,
                otherCounterparty: 'Service output',
                description: comment,
                analyticsUniqueId: 1202
            };

            // Create CashOrder in our system first
            const { id: cashOrderId } = yield call(
                fetchAPI,
                'POST',
                '/cash_orders',
                null,
                cashOrderPayload,
                { handleErrorInternally: true }
            );

            // If cashbox contains rst it must be registred in cashdesk if possible
            if (isCashBoxRst) {
                yield put(registerServiceOutputCashOrderInCashdesk({ cashOrderId }));
            }

            // eslint-disable-next-line callback-return
            callback && callback({ cashOrderId });
        } catch (error) {
            yield put(emitError(error));
            notification.error({ message: _.get(error, 'response.message') }); // Print special error message if it exists
        }
    }
}

export function* serviceTransferSaga() {
    while (true) {
        try {
            yield take(TRANSFER);
            const transitionSumSelect = yield select(selectCashOrderSum);
            const datetimeSelect = yield select(selectCashOrderDate);
            const cashboxId = yield select(selectSelectedCashboxId);
            const cashboxIdSecond = yield select(selectSelectedCashboxIdSecond);
            const comment = yield select(selectComment);
            const commentSec = yield select(selectCommentSecond);
            const callback = yield select(selectOnCashOrderCreatedCallback);

            // Get cashbox to register in cashdesk later
            const cashBoxes = yield call(fetchAPI, 'GET', '/cash_boxes', null, null, {handleErrorInternally: true});
            const cashBox = _.get(
                _.filter(cashBoxes, obj => obj.id == cashboxId),
                '[0]'
            );

            const isCashBoxRst = Boolean(_.get(cashBox, 'rst'));

            const cashOrderPayload = {
                typeInc: CASH_ORDER_TYPES.INCOME,
                typeExp: CASH_ORDER_TYPES.EXPENSE,
                cashBoxIdExp: cashboxId,
                cashBoxIdInc: cashboxIdSecond,
                datetime: datetimeSelect,
                transitionSum: transitionSumSelect,
                otherCounterparty: 'Transfer',
                descriptionInc: comment,
                descriptionExp: comment,
                analyticsUniqueId: 1202
            };

            // Create CashOrder in our system first
            const { id: cashOrderId } = yield call(
                fetchAPI,
                'POST',
                '/cash_orders/transition',
                null,
                cashOrderPayload,
                { handleErrorInternally: true }
            );

            // If cashbox contains rst it must be registred in cashdesk if possible
            // if (isCashBoxRst) {
            //     yield put(registerServiceOutputCashOrderInCashdesk({ cashOrderId }));
            // }

            // eslint-disable-next-line callback-return
            callback && callback({ cashOrderId });
        } catch (error) {
            yield put(emitError(error));
            notification.error({ message: _.get(error, 'response.message') }); // Print special error message if it exists
        }
    }
}

/**
 * For cashboxes with rst we can register them in cashdesk
 */
export function* registerServiceInputSaga() {
    while (true) {
        try {
            const {
                payload: { cashOrderId }
            } = yield take(REGISTER_SERVICE_INPUT_CASH_ORDER_IN_CASHDESK);

            yield call(
                fetchAPI,
                'POST',
                '/cashdesk/service_input_cash_order',
                null,
                { localNumber: cashOrderId },
                { handleErrorInternally: true }
            );

            notification.success();
        } catch (error) {
            yield put(emitError(error));
            notification.error({ message: _.get(error, 'response.message') }); // Print special error message if it exists
        }
    }
}

/**
 * For cashboxes with rst we can register them in cashdesk
 */
export function* registerServiceOutputSaga() {
    while (true) {
        try {
            const {
                payload: { cashOrderId }
            } = yield take(REGISTER_SERVICE_OUTPUT_CASH_ORDER_IN_CASHDESK);

            yield call(
                fetchAPI,
                'POST',
                '/cashdesk/service_output_cash_order',
                null,
                { localNumber: cashOrderId },
                { handleErrorInternally: true }
            );

            notification.success();
        } catch (error) {
            yield put(emitError(error));
            notification.error({ message: _.get(error, 'response.message') }); // Print special error message if it exists
        }
    }
}

export function* saga() {
    yield all([
        call(serviceInputSaga),
        call(serviceOutputSaga),
        call(serviceTransferSaga),
        call(registerServiceInputSaga),
        call(registerServiceOutputSaga)
    ]);
}
