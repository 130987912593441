/* eslint-disable no-underscore-dangle */
/* eslint-disable react/sort-comp */
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Tabs } from 'antd';
import { loadModal, MODALS, resetModal } from 'core/modals/duck';
import _ from 'lodash';
import { ReportAnalyticsModal } from 'modals';
import { setDefaultAnalyticsUniqueId } from 'modals/DebtZeroingModal/redux/duck';
import { SEARCH_TYPES } from 'modals/RefactoredCashOrderModal/components/containers/ClientCounterpartyContainer/constants';
import { selectCashboxes } from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, isForbidden, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { DefaultForm, ServiceInputForm, ServiceOutputForm, ServiceTransferForm } from './components';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES, MODES, SERVICE_OPERATION_TYPES } from './constants';
import {
    clearModalState,
    createCashOrder,
    fetchAnalytics,
    printCashOrder,
    selectAnalyticsStats,
    selectCashOrderSum,
    selectCashOrderType,
    selectClientSearchType,
    selectInitialized,
    selectIsRegisteredRST,
    selectMode,
    selectOtherCounterpartyName,
    selectResponsible,
    selectSelectedCashbox,
    selectSelectedCashboxId,
    selectSelectedClientId,
    selectSelectedCounterpartyType,
    selectSelectedEmployeeId,
    selectSelectedOrderId,
    selectSelectedStoreDocId,
    selectSelectedSupplierId,
    selectSelectedSupplierStoreDocId,
    selectServiceOperationType,
    serviceInput,
    serviceOutput,
    serviceTransfer,
    setCashCollected,
    setCashOrderId,
    setCashOrderSum,
    setCashOrderType,
    setClientSearchType,
    setInitialized,
    setMode,
    setOnCashOrderCreatedCallback,
    setOnCashOrderUpdatedCallback,
    setOrdersFilters,
    setSalary,
    setSalaryDocId,
    setSalaryDocNum,
    setSelectedAnalyticsUniqueId,
    setSelectedCashboxId,
    setSelectedClientId,
    setSelectedCounterpartyType,
    setSelectedEmployeeId,
    setSelectedOrderId,
    setSelectedStoreDocId,
    setSelectedSupplierId,
    setSelectedSupplierStoreDocId,
    setServiceOperationType,
    updateOrder
} from './redux/duck';
import Styles from './styles.m.css';

const { TabPane } = Tabs;
const { confirm } = Modal;

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    user: state.auth,
    modal: state.modals.modal,
    modalProps: state.modals.modalProps,
    initialized: selectInitialized(state),
    cashOrderType: selectCashOrderType(state),
    analyticsStats: selectAnalyticsStats(state),
    selectedCounterpartyType: selectSelectedCounterpartyType(state),
    selectedClientSearchType: selectClientSearchType(state),
    serviceOperationType: selectServiceOperationType(state),

    selectedCashBoxId: selectSelectedCashboxId(state),
    selectedCashbox: selectSelectedCashbox(state),
    cashOrderSum: selectCashOrderSum(state),
    selectedClientId: selectSelectedClientId(state),
    selectedOrderId: selectSelectedOrderId(state),
    selectedStoreDocId: selectSelectedStoreDocId(state),
    selectedEmployeeId: selectSelectedEmployeeId(state),
    selectedSupplierId: selectSelectedSupplierId(state),
    selectedSupplierStoreDocId: selectSelectedSupplierStoreDocId(state),
    otherCounterpartyName: selectOtherCounterpartyName(state),
    responsible: selectResponsible(state),
    mode: selectMode(state),

    cashboxes: selectCashboxes(state),
    isRegisteredWithRst: selectIsRegisteredRST(state)
});

const mapDispatchToProps = {
    resetModal,
    loadModal,
    setCashOrderSum,
    setSelectedAnalyticsUniqueId,
    setDefaultAnalyticsUniqueId,
    setSelectedCashboxId,
    setCashOrderType,
    setSelectedCounterpartyType,
    setOnCashOrderCreatedCallback,
    setOnCashOrderUpdatedCallback,
    setServiceOperationType,
    createCashOrder,
    updateOrder,
    printCashOrder,
    setSelectedClientId,
    setSelectedEmployeeId,
    setOrdersFilters,
    setSelectedOrderId,
    setSelectedSupplierId,
    setSelectedSupplierStoreDocId,
    setSelectedStoreDocId,
    setClientSearchType,
    clearModalState,
    setMode,
    setCashOrderId,
    setInitialized,
    fetchAnalytics,
    serviceInput,
    serviceOutput,
    serviceTransfer,
    setSalaryDocNum,
    setSalary,
    setSalaryDocId,
    setCashCollected
};

/**
 * ### General description
 * #### Main
 * This modal is used to create cash orders(any of DEFAULT, SERVICE_INPUT or SERVICE_OUTPUT).
 *
 * #### RST(Українсьокою буде РРО)
 * This component can handle RST cashboxes and **cashdesk services**. Cash desk services
 * are handled by sending additional request to the server after ordinary cash order(which contains RST cashbox) was created.
 * Those types of cash orders work as standard ones.
 *
 * #### Initializing
 * Initializing is performed **ONLY** after user permissions was checked and modal was not initialized before. Note that modal can be closed
 * without loosing initialized values(redux store was not cleared)m this is used to open other modal without loosing data of previous one.
 *
 * #### Access
 * If user has no rights to access this modal then modal will not mount and notification will appear. Access is checked by special function
 * before any further operations will start or any content will be returned by ``render``.
 *
 * ### For developers(before you create something new)
 * 1. Each component of this modal(tables, selectors, inputs etc.) is autonomous element, they are connected
 * with redux store only and are independent of each other. **Note and obey this if you want to add a new one!**
 * 2. This modal is structured by feature first file structure, so all dependents are stored in the same ``RefactoredCashOrderModal`` folder.
 * 3. There are some validators for input values, they have to be placed inside ``initializer`` or redux action.
 * 4. If you want to check if cash order can be created with current configuration(selected fields, entered values etc.) then edit ``validator`` function.
 * 5. If you need to change behavior of a component then edit the component itself(do not do any external logic from inside лєвий component)
 * 6. If you need to load any data for a component - trigger fetching from ``componentDidMount`` of a component or from redux
 *
 * @callback [ modalProps.onCashOrderCreatedCallback({cashOrderId}) ] - When cash order was created and server responded with its ID
 * @callback [ modalProps.onCashOrderUpdatedCallback({cashOrderId}) ] - When cash order was updated and server responded with its ID
 * @callback [ modalProps.onClose ] - When modal is closing(cancel or submit events occurred)
 *
 * @property [ modalProps.mode = 'ADD' ] - One of: 'EDIT', 'ADD', 'PRINT'.
 * @property [ modalProps.initValues.cashOrderId ] - Initial cash order id(required for edit and print mode)
 * @property [ modalProps.initValues.sum ] - Initial cash order sum, can take positive numbers only. Has built-in validator which will check whatever was supplied.
 * @property [ modalProps.initValues.cashBoxId ] - Initial cashbox id
 * @property [ modalProps.initValues.type ] - Initial cash order type. One of: 'INCOME', 'EXPENSE', 'ADJUSTMENT_INCOME', 'ADJUSTMENT_EXPENSE'.
 * @property [ modalProps.initValues.counterpartyType ] - Initial counterparty type. One of: 'CLIENT', 'EMPLOYEE', 'BUSINESS_SUPPLIER', 'OTHER'.
 * @property [ modalProps.initValues.supplierId ] - Initial business supplier ID
 * @property [ modalProps.initValues.supplierStoreDocId ] - Initial business supplier storage document ID (There are different types of storage documents handled by this modal)
 * @property [ modalProps.initValues.clientId ] - Initial client
 * @property [ modalProps.initValues.orderId ] - Initial order
 * @property [ modalProps.initValues.clientStoreDocId ] - Initial client store doc(there is type of store docs used for clients - OUT-1234-567)
 * @property [ modalProps.initValues.serviceOperationType ] - One of: 'DEFAULT', 'SERVICE_INPUT', 'SERVICE_OUTPUT'.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RefactoredCashOrderModal extends Component {
    constructor(props) {
        super(props);

        this.state = { editable: false };
    }

    componentDidUpdate(prevProps) {
        const prevModal = _.get(prevProps, 'modal');
        const currModal = _.get(this.props, 'modal');
        const currInitialized = _.get(this.props, 'initialized');
        // Initialize this modal when it is opened or reopened after closing(when modal is closed it's state will be cleared)
        if (prevModal != currModal && currModal == MODALS.REFACTORED_CASH_ORDER && !currInitialized) {
            this.initializeCashOrderModal();
        }
    }

    /**
     * Check if user can access content of this modal or not.
     *
     * @returns false if access is forbidden
     */
    checkUserPermissions = () => {
        const { user } = this.props;
        const mode = _.get(this.props, 'modalProps.mode');

        if (
            isForbidden(user, permissions.ACCESS_ACCOUNTING) || // Access for this modal id ADD mode
            (mode === MODES.EDIT && isForbidden(user, permissions.EDIT_CASH_ORDERS)) // Access for editing
        ) {
            return false; // Initialize nothing if no rights
        }

        return true;
    };

    /**
     * Must be called when modal is opened. Should be called only at first start or after closing.
     * **Note that closing is performed by ``this.onClose`` function!**
     * \
     * **If user does not have permission to access this modal, initializer will close it automatically**
     * @param {*} initFields - contains initial values and settings
     */
    initializeCashOrderModal() {
        const {
            setCashOrderSum,
            setSelectedCashboxId,
            setCashOrderType,
            setSelectedCounterpartyType,
            setSelectedClientId,
            setSelectedEmployeeId,
            setSelectedAnalyticsUniqueId,
            setDefaultAnalyticsUniqueId,
            setOrdersFilters,
            setSelectedOrderId,
            setSelectedSupplierId,
            setSelectedSupplierStoreDocId,
            setServiceOperationType,
            setSelectedStoreDocId,
            setClientSearchType,
            setOnCashOrderCreatedCallback,
            setOnCashOrderUpdatedCallback,
            setSalaryDocNum,
            setSalary,
            setSalaryDocId,
            modalProps,
            setMode,
            setCashOrderId,
            setInitialized,
            user,
            salaryDocNum,
            intl: { formatMessage },
            setCashCollected
        } = this.props;

        const { onCashOrderCreatedCallback, onCashOrderUpdatedCallback, mode } = modalProps;

        const {
            sum,
            cashBoxId,
            type,
            counterpartyType,
            clientId,
            orderId,
            supplierId,
            supplierStoreDocId,
            cashOrderId,
            clientStoreDocId,
            serviceOperationType,
            employeeId,
            analyticsUniqueId,
            salary,
            salaryDocId,
            precision,
            cashCollected
        } = _.get(modalProps, 'initValues', {});

        // If user does not have permission we have to close this modal without initializing
        if (!this.checkUserPermissions()) {
            notification.info({
                message: formatMessage({ id: 'refactored_cash_order_modal.info_forbidden' }),
                duration: 3
            });

            this.onClose();

            return;
        }

        // Initialize callbacks
        onCashOrderCreatedCallback && setOnCashOrderCreatedCallback(onCashOrderCreatedCallback);
        onCashOrderUpdatedCallback && setOnCashOrderUpdatedCallback(onCashOrderUpdatedCallback);

        // Initialize default fields, initializing process depends on supplied data.

        if (mode === MODES.EDIT || mode === MODES.PRINT) {
            mode && setMode(mode);
            cashOrderId && setCashOrderId(cashOrderId);
        } else {
            setCashOrderType(type || CASH_ORDER_TYPES.INCOME); // Always set cash order type, this triggers fetching depending data
            setMode(MODES.ADD);
            setServiceOperationType(serviceOperationType || SERVICE_OPERATION_TYPES.DEFAULT);
            if (!cashBoxId && user.cashBoxId) {
                setSelectedCashboxId(user.cashBoxId);
            }
            setSalaryDocNum(salaryDocNum);
            setSalary(salary);
            setSalaryDocId(salaryDocId);
            setCashCollected(cashCollected);

            sum && setCashOrderSum(sum, precision);
            cashBoxId && setSelectedCashboxId(cashBoxId);
            counterpartyType && setSelectedCounterpartyType(counterpartyType);

            clientId && setSelectedClientId(clientId);
            clientId && setOrdersFilters({ client: clientId }); // Fetch orders of this client only
            clientId && !orderId && setClientSearchType(SEARCH_TYPES.FOR_CLIENT);

            orderId && setSelectedOrderId(orderId);
            orderId && setClientSearchType(SEARCH_TYPES.FOR_ORDER);

            clientStoreDocId && setSelectedStoreDocId(clientStoreDocId);
            clientStoreDocId && setClientSearchType(SEARCH_TYPES.FOR_DOCUMENT);

            supplierId && setSelectedSupplierId(supplierId);
            supplierStoreDocId && setSelectedSupplierStoreDocId(supplierStoreDocId);

            employeeId && setSelectedEmployeeId(employeeId);

            analyticsUniqueId && setSelectedAnalyticsUniqueId(analyticsUniqueId);
        }

        setInitialized(true);
    }

    /**
     * Called when modal has to be closed.
     * Handles callbacks
     */
    onClose = () => {
        const { resetModal, modalProps, clearModalState } = this.props;
        const onCloseCB = _.get(modalProps, 'onClose');

        resetModal();
        clearModalState();
        onCloseCB && onCloseCB();
        this.setState({
            editable: false
        });
    };

    /**
     * When cash order has to be created and modal should close.
     */
    onSubmit = () => {
        const { cashOrderType, selectedCashbox, createCashOrder, updateOrder, mode, vehicleId, salaryDocNum, salary } =
            this.props;

        const cashBoxWithRST = Boolean(_.get(selectedCashbox, 'rst'));

        if (mode === MODES.ADD) {
            if (cashBoxWithRST && cashOrderType === CASH_ORDER_TYPES.EXPENSE) {
                setSalaryDocNum(salaryDocNum);
                setSalary(salary);
                confirm({
                    title: this.props.intl.formatMessage({
                        id: 'refactored_cash_order_modal.rts_exense_message'
                    }),
                    onOk: () => {
                        createCashOrder();
                        this.onClose();
                    }
                });
            } else {
                createCashOrder();
                this.onClose();
            }
        } else if (mode === MODES.EDIT) {
            updateOrder();
            this.onClose();
        }
        if (vehicleId) {
            window.location.reload();
        }
    };

    onSpread = async () => {
        const {
            modalProps: {
                initValues: { cashOrderId }
            },
            intl: { formatMessage }
        } = this.props;
        const { res } = await fetchAPI('GET', 'journal_calculations', {
            filterDocumentType: 'CSH',
            filterDocumentNumber: cashOrderId
        });
        if (res.length) {
            const { sum, remaining, sign, id, pairingIds, cashOrderId } = res[0];
            if (sum !== remaining || pairingIds.length) {
                notification.error({
                    message: 'Розпаруйте касовий ордер перед редагуванням.'
                });
            } else {
                this.setState({
                    editable: true
                });
            }
        } else {
            this.setState({
                editable: true
            });
        }
    };

    /**
     * Check if all requirements for creating a new cash order in a current state
     * is valid (whenever all required fields are entered).
     *
     * @returns Object {isValid: true/false [, message]}
     */
    cashOrderValidator = () => {
        const {
            selectedCashBoxId,
            cashOrderSum,
            selectedCounterpartyType,
            selectedClientSearchType,

            selectedClientId,
            selectedOrderId,
            selectedStoreDocId,
            selectedEmployeeId,
            selectedSupplierId,
            selectedSupplierStoreDocId,
            serviceOperationType,
            cashOrderType,

            cashboxes
        } = this.props;

        if (!selectedCashBoxId || !cashOrderSum) {
            return {
                isValid: false,
                message: this.props.intl.formatMessage({
                    id: 'refactored_cash_order_modal.cashbox_sum'
                })
            };
        }

        const cashbox = cashboxes.find(({ id }) => id === selectedCashBoxId);
        if (cashbox && cashbox.rst && cashbox.onlineCashdeskState === 'CLOSED' && cashOrderType === 'INCOME') {
            return {
                isValid: false,
                message: this.props.intl.formatMessage({
                    id: 'cashbox_rst_icon.hint_closed_cash_box_with_rst'
                })
            };
        }

        // Additional options for cash orders of default service operation type
        if (serviceOperationType === SERVICE_OPERATION_TYPES.DEFAULT) {
            switch (selectedCounterpartyType) {
                case COUNTERPARTY_TYPES.CLIENT:
                    if (selectedClientSearchType === SEARCH_TYPES.FOR_CLIENT && !selectedClientId) {
                        return {
                            isValid: false,
                            message: `${this.props.intl.formatMessage({
                                id: 'clients-table.client'
                            })}?`
                        };
                    }
                    if (selectedClientSearchType === SEARCH_TYPES.FOR_ORDER && !selectedOrderId) {
                        return {
                            isValid: false,
                            message: `${this.props.intl.formatMessage({
                                id: 'order-status.order'
                            })}?`
                        };
                    }
                    if (selectedClientSearchType === SEARCH_TYPES.FOR_DOCUMENT && !selectedStoreDocId) {
                        return {
                            isValid: false,
                            message: `${this.props.intl.formatMessage({
                                id: 'employee_task_modal.store_doc'
                            })}?`
                        };
                    }
                    break;
                case COUNTERPARTY_TYPES.EMPLOYEE:
                    if (!selectedEmployeeId) {
                        return {
                            isValid: false,
                            message: `${this.props.intl.formatMessage({
                                id: 'employee-table.employee'
                            })}?`
                        };
                    }
                    break;
                case COUNTERPARTY_TYPES.BUSINESS_SUPPLIER:
                    if (!(selectedSupplierId || selectedSupplierStoreDocId)) {
                        return {
                            isValid: false,
                            message: `${this.props.intl.formatMessage({
                                id: 'brands.supplier_name'
                            })}?`
                        };
                    }
                    break;
                case COUNTERPARTY_TYPES.OTHER:
                    // Allow any
                    break;

                default:
                    break;
            }
        }

        return { isValid: true };
    };

    _renderSubmitButtons() {
        const {
            serviceOperationType,
            mode,
            printCashOrder,
            serviceInput,
            serviceOutput,
            serviceTransfer,
            user,
            selectedCashbox,
            modalProps,
            intl: { formatMessage },
            isRegisteredWithRst
        } = this.props;

        const { editable } = this.state;

        const cashBoxWithRST = Boolean(_.get(selectedCashbox, 'rst')) && isRegisteredWithRst;

        const validationResult = this.cashOrderValidator();

        // If we are in print mode the only button appears is a PRINT button
        if (mode === MODES.PRINT) {
            return (
                <div className={Styles.buttonGroup}>
                    {mode == MODES.PRINT ? (
                        <Button
                            data-qa='btn_add'
                            disabled={!isGrantAccessed(user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_PRINT)}
                            icon={<PrinterOutlined />}
                            onClick={() => {
                                printCashOrder();
                                this.onClose();
                            }}
                        >
                            {formatMessage({ id: 'cash-order-form.print' })}
                        </Button>
                    ) : null}
                </div>
            );
        }

        // If we have default cash order form there are rendered only buttons for that form
        if (serviceOperationType === SERVICE_OPERATION_TYPES.DEFAULT) {
            return (
                <div className={Styles.buttonGroup}>
                    {mode == MODES.ADD ? (
                        <div title={validationResult.message}>
                            <Button
                                data-qa='btn_add'
                                disabled={!validationResult.isValid}
                                onClick={this.onSubmit}
                                type='primary'
                            >
                                {formatMessage({ id: 'add' })}
                            </Button>
                        </div>
                    ) : null}
                    {mode == MODES.EDIT ? (
                        <React.Fragment>
                            {isGrantAccessed(
                                user,
                                grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_CASH_ORDER_UNPOSTING
                            ) && (
                                <Button disabled={editable || cashBoxWithRST} onClick={this.onSpread}>
                                    {formatMessage({ id: 'edit' })}
                                </Button>
                            )}
                            <Button onClick={this.onSubmit} type='primary'>
                                {formatMessage({ id: 'ok' })}
                            </Button>
                        </React.Fragment>
                    ) : null}
                </div>
            );
        }

        // Service input handling
        if (serviceOperationType === SERVICE_OPERATION_TYPES.SERVICE_INPUT) {
            return (
                <div className={Styles.buttonGroup} title={validationResult.message}>
                    <Button
                        data-qa='btn_add'
                        disabled={mode !== MODES.ADD || !validationResult.isValid}
                        onClick={() => {
                            serviceInput();
                            this.onClose();
                        }}
                        type='primary'
                    >
                        {formatMessage({ id: 'add' })}
                    </Button>
                </div>
            );
        }

        // Service output handling
        if (serviceOperationType === SERVICE_OPERATION_TYPES.SERVICE_OUTPUT) {
            return (
                <div className={Styles.buttonGroup} title={validationResult.message}>
                    <Button
                        data-qa='btn_add'
                        disabled={mode !== MODES.ADD || !validationResult.isValid}
                        onClick={() => {
                            serviceOutput();
                            this.onClose();
                        }}
                        type='primary'
                    >
                        {formatMessage({ id: 'add' })}
                    </Button>
                </div>
            );
        }

        if (serviceOperationType === SERVICE_OPERATION_TYPES.TRANSFER) {
            return (
                <div className={Styles.buttonGroup} title={validationResult.message}>
                    <Button
                        data-qa='btn_add'
                        disabled={mode !== MODES.ADD || !validationResult.isValid}
                        onClick={() => {
                            serviceTransfer();
                            this.onClose();
                        }}
                        type='primary'
                    >
                        {formatMessage({ id: 'add' })}
                    </Button>
                </div>
            );
        }

        return null;
    }

    render() {
        const {
            isMobile,
            modal,
            serviceOperationType,
            loadModal,
            fetchAnalytics,
            setServiceOperationType,
            mode,
            modalProps,
            responsible,
            isRegisteredWithRst,
            cashOrderType
        } = this.props;

        const { editable } = this.state;
        // Render nothing if not enough permissions
        if (!this.checkUserPermissions()) {
            return <div />; // Return nothing if user do not have enough permissions to prevent data loading
        }

        const { analyticsUniqueId } = _.get(modalProps, 'initValues', {});

        return (
            <React.Fragment>
                <Modal
                    className={Styles.mainContainer}
                    destroyOnClose
                    footer={this._renderSubmitButtons()}
                    maskClosable={false}
                    onCancel={this.onClose}
                    title={<FormattedMessage id='refactored_cash_order_modal.title' />}
                    visible={modal === MODALS.REFACTORED_CASH_ORDER}
                    width={isMobile ? '90vw' : '70vw'}
                >
                    {mode !== MODES.ADD && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                opacity: '0.7'
                            }}
                        >
                            <FormattedMessage id='orders.responsible' />: {responsible}
                        </div>
                    )}
                    <Tabs activeKey={serviceOperationType} onChange={key => setServiceOperationType(key)}>
                        <TabPane
                            key={SERVICE_OPERATION_TYPES.DEFAULT}
                            tab={<FormattedMessage id='refactored_cash_order_modal.DEFAULT' />}
                        >
                            <DefaultForm
                                analyticsUniqueIdForEmployee={analyticsUniqueId}
                                cashOrderType={cashOrderType}
                                editable={editable}
                                isMobile={isMobile}
                            />
                        </TabPane>
                        <TabPane
                            key={SERVICE_OPERATION_TYPES.SERVICE_INPUT}
                            disabled={mode != MODES.ADD}
                            tab={<FormattedMessage id='refactored_cash_order_modal.SERVICE_INPUT' />}
                        >
                            <ServiceInputForm />
                        </TabPane>
                        <TabPane
                            key={SERVICE_OPERATION_TYPES.SERVICE_OUTPUT}
                            disabled={mode != MODES.ADD}
                            tab={<FormattedMessage id='refactored_cash_order_modal.SERVICE_OUTPUT' />}
                        >
                            <ServiceOutputForm />
                        </TabPane>
                        <TabPane
                            key={SERVICE_OPERATION_TYPES.TRANSFER}
                            disabled={mode != MODES.ADD}
                            tab={<FormattedMessage id='refactored_cash_order_modal.TRANSFER' />}
                        >
                            <ServiceTransferForm />
                        </TabPane>
                    </Tabs>
                </Modal>

                <ReportAnalyticsModal
                    onCancelTrigger={() => loadModal(MODALS.REFACTORED_CASH_ORDER)}
                    onOkTrigger={() => {
                        loadModal(MODALS.REFACTORED_CASH_ORDER);
                        fetchAnalytics();
                    }}
                />
            </React.Fragment>
        );
    }
}
