/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-template-curly-in-string */
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, TimePicker } from 'antd';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.less';

const format = 'HH:mm';

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export class ScheduleSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scheduleData: []
        };
    }

    componentDidMount() {
        this.setState({
            scheduleData: _.get(this.props, 'initialValues.schedule', []).map((el, key) => ({
                ...el
            }))
        });
    }

    deleteBlock = async index => {
        const { scheduleData } = this.state;
        await this.setState({ scheduleData: scheduleData.filter(({ key }) => key !== index) });
        this.save();
    };

    save = () => {
        const { saveData, initialValues } = this.props;
        const { scheduleData } = this.state;

        const values = _.omit(initialValues, ['clientAccountId']);

        saveData({
            schedule: scheduleData,
            name: get(initialValues, 'name')
        });
    };

    render() {
        const { isDisabledCRUD } = this.props;
        const { scheduleData } = this.state;

        return (
            <div>
                <div className='scheduleBlock'>
                    {scheduleData.map((card, key) => (
                        <ScheduleCard
                            key={key}
                            data={card}
                            deleteBlock={this.deleteBlock}
                            isDisabledCRUD={isDisabledCRUD}
                            updateState={data =>
                                this.setState(({ scheduleData }) => {
                                    const schedule = scheduleData;
                                    schedule[key] = { ...scheduleData[key], ...data };

                                    return { scheduleData: schedule };
                                })
                            }
                        />
                    ))}
                    <div className='addIcon'>
                        <Button
                            icon={
                                <PlusOutlined
                                    style={{
                                        fontSize: 25,
                                        opacity: 0.3
                                    }}
                                />
                            }
                            onClick={async () => {
                                await this.setState({
                                    scheduleData: [...scheduleData, { beginHours: '08:00', endHours: '19:00' }]
                                });
                                this.save();
                            }}
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'var(--lightGray)'
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        disabled={isDisabledCRUD}
                        onClick={this.save}
                        style={{
                            float: 'right',
                            padding: '4px 22px'
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='save' />
                    </Button>
                </div>
            </div>
        );
    }
}

const ScheduleCard = ({ data, updateState, deleteBlock, isDisabledCRUD }) => {
    const { beginHours, endHours, h24, key } = data;

    return (
        <div className='scheduleBlock__card'>
            <div className='section'>
                {days.map(day => (
                    <Button
                        key={day}
                        disabled={isDisabledCRUD}
                        onClick={() => updateState({ [day]: !data[day] })}
                        style={{
                            padding: '4px 8px'
                        }}
                        type={data[day] ? 'primary' : 'default'}
                    >
                        <FormattedMessage id={day.substring(0, 3)} />
                    </Button>
                ))}
            </div>
            <div className='section'>
                <TimePicker
                    disabled={h24 || isDisabledCRUD}
                    format={format}
                    onChange={time => updateState({ beginHours: dayjs(time).format(format) })}
                    value={dayjs(beginHours, format)}
                />{' '}
                -{' '}
                <TimePicker
                    disabled={h24 || isDisabledCRUD}
                    format={format}
                    onChange={time => updateState({ endHours: dayjs(time).format(format) })}
                    value={dayjs(endHours, format)}
                />
            </div>
            <div className='checkboxSection'>
                <div>
                    <Checkbox
                        checked={h24}
                        disabled={isDisabledCRUD}
                        onChange={() => updateState({ h24: !h24 })}
                        style={{
                            marginRight: 8
                        }}
                    />
                    <FormattedMessage id='general_settings.around_the_clock' />
                </div>
                <Popconfirm
                    disabled={isDisabledCRUD}
                    onConfirm={() => {
                        deleteBlock(key);
                    }}
                    title={<FormattedMessage id='general_settings_delete_question' />}
                >
                    <Button
                        disabled={isDisabledCRUD}
                        icon={
                            <DeleteOutlined
                                style={{
                                    fontSize: 18
                                }}
                            />
                        }
                    />
                </Popconfirm>
            </div>
        </div>
    );
};
