// Core
import { Button } from 'antd';
import { Layout } from 'commons';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { images } from 'utils';
import Styles from './styles.m.css';

@injectIntl
@withRouter
class Exception extends Component {
    _getErrorImage = statusCode => {
        switch (statusCode) {
            case '400':
                return images.exception400;

            case '403':
                return images.exception400;

            case '404':
                return images.exception404;

            case '500':
                return images.exception500;

            default:
                return images.exception404;
        }
    };

    _getErrorText = statusCode => {
        switch (statusCode) {
            case '400':
                return 'Bad Request';

            case '403':
                return <FormattedMessage id='403_message' />;

            case '404':
                return 'Not Found';

            case '500':
                return 'Internal Server Error';

            default:
                return 'Error!';
        }
    };

    render() {
        console.log(this);
        const { statusCode } = this.props.match.params;
        const grant = get(this.props, 'location.state.grant');

        return (
            <Layout paper={false} title={<FormattedMessage id='exception-page.title' />}>
                <div className={Styles.error}>
                    <div className={Styles.errorImage}>
                        <img alt='error' src={this._getErrorImage(statusCode)} />
                    </div>
                    <div className={Styles.errorData}>
                        <h1 className={Styles.errorCode}>{statusCode}</h1>
                        <span className={Styles.errorDesc}>{this._getErrorText(statusCode)}</span>
                        {grant && <span className={Styles.errorDesc}>{grant}</span>}
                    </div>
                </div>
                <div className={Styles.homeButtonWrapper}>
                    <Link to={book.ordersAppointments}>
                        <Button type='primary'>
                            <FormattedMessage id='exception-page.back_to_main' />
                        </Button>
                    </Link>
                </div>
            </Layout>
        );
    }
}

export default Exception;
