import numeral from 'numeral';
import messages from 'utils/messages';

// Intl
const fallbackLocale = window.navigator.language || window.navigator.userLanguage === 'uk_UA' ? 'uk' : 'ru';

const setIntl = language => {
    let locale = language;
    if (locale === 'ua') {
        locale = 'uk';
    }

    return {
        locale: locale || fallbackLocale,
        messages: messages[locale || fallbackLocale]
    };
};

let persistedLocale = localStorage.getItem('_my.carbook.pro_locale') || fallbackLocale;

if (persistedLocale === 'ua') {
    persistedLocale = 'uk';
}

// const intl = createIntl(setIntl(persistedLocale), cache);
const intl = setIntl(persistedLocale);

// Numeral
// TODO: provide locale dynamic for numeral register
numeral.register('locale', 'ru', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'тыс.',
        million: 'мил.',
        billion: 'бил.',
        trillion: 'трил.'
    },
    ordinal: () => '.',
    currency: {
        symbol: '₴'
    }
});
numeral.locale('ru');

export { intl, messages, setIntl };
