import { DatePicker, Input, InputNumber, Modal, Select, notification } from 'antd';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const operationTypes = {
    DIRECT: 'entries_tab.DIRECT',
    STORNO: 'entries_tab.STORNO'
};

const mapStateToProps = state => ({
    user: state.auth,
    clients: state.clients.clients
});

const mapDispatchToProps = {
    fetchClients,
    setClientsSearchFilter
};

const AddAccDocsModal = ({ open = false, hideModal, intl: { formatMessage }, fetchAccDocs, ...props }) => {
    const [documentNumber, setDocumentNumber] = useState();
    const [counterpartType, setCounterpartyType] = useState();
    const [counterpartId, setCounterpartId] = useState();
    const [warehouseId, setWarehouseId] = useState();
    const [cashBoxId, setCashboxId] = useState();
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState('DIRECT');
    const [documentDatetime, setDocumentDatetime] = useState(dayjs());
    const [cashboxes, setCashboxes] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [employees, setEmployees] = useState([]);

    const fetchCashboxes = useCallback(async query => {
        const response = await fetchAPI('GET', 'cash_boxes', { query }, null, {
            handleErrorInternally: true
        });

        setCashboxes(response);
    }, []);

    const fetchWarehouses = useCallback(async query => {
        const response = await fetchAPI('GET', 'warehouses', { query }, null, {
            handleErrorInternally: true
        });

        setWarehouses(response);
    }, []);

    const fetchSuppliers = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                all: true,
                cut: true,
                query
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setSuppliers(response);
    }, []);

    const fetchEmployees = useCallback(async query => {
        const response = await fetchAPI('GET', '/employees', { query, disabled: false }, null, {
            handleErrorInternally: true
        });

        setEmployees(response);
    }, []);

    const addAccDoc = async () => {
        const response = await fetchAPI(
            'POST',
            '/general_ledger/acc_document',
            undefined,
            {
                documentNumber,
                documentDatetime: documentDatetime ? dayjs(documentDatetime).toISOString() : undefined,
                counterpartId,
                counterpartType: counterpartType || 'DIRECT',
                warehouseId,
                cashBoxId,
                comment,
                operationType
            },
            { handleErrorInternally: true }
        );
        await notification.success({
            message: formatMessage({ id: 'barcode.success' })
        });
        if (response.success && isGrantAccessed(props.user, grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST)) {
            goTo(`${book.accDocs}/${response.success}`);
        }
    };

    const onClose = () => {
        setDocumentNumber(undefined);
        setCounterpartId(undefined);
        setCounterpartyType(undefined);
        setCashboxId(undefined);
        setWarehouseId(undefined);
        setComment(undefined);

        hideModal();
        if (fetchAccDocs) {
            setTimeout(() => fetchAccDocs(), 100);
        }
    };

    useEffect(() => {
        fetchCashboxes();
        fetchWarehouses();
    }, [fetchCashboxes, fetchWarehouses]);

    const onFinish = async () => {
        addAccDoc();

        await onClose();
    };

    const handleSearchSuppliers = useMemo(() => {
        const loadOptions = value => {
            fetchSuppliers(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [fetchSuppliers]);

    const handleSearchEmployees = useMemo(() => {
        const loadOptions = value => {
            fetchEmployees(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [fetchEmployees]);

    const handleSearchClients = useMemo(() => {
        const loadOptions = value => {
            props.setClientsSearchFilter(value);
            props.fetchClients();
        };

        return _.debounce(loadOptions, 1000);
    }, [props]);

    return (
        <Modal
            okButtonProps={{
                disabled: !documentNumber || !documentDatetime || !operationType
            }}
            onCancel={hideModal}
            onOk={() => onFinish()}
            open={open}
            title={<FormattedMessage id='acc_page.add_acc_doc' />}
            width='50%'
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='entry_table.doc_number' />
                        <span className={Styles.rules}>*</span>
                    </div>

                    <InputNumber
                        min={0}
                        onChange={async value => {
                            await setDocumentNumber(value);
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'entry_table.doc_number'
                        })}
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={documentNumber}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='order_from_table_date_doc' />
                        <span className={Styles.rules}>*</span>
                    </div>

                    <DatePicker
                        format='YYYY-MM-DD'
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={async value => {
                            await setDocumentDatetime(value);
                        }}
                        optionFilterProp='children'
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={documentDatetime}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='interactions.counterparty_type' />
                    </div>
                    <Select
                        onChange={value => {
                            setCounterpartyType(value);
                            setCounterpartId(undefined);
                            if (value === 'SUPPLIERS' && !_.get(suppliers, 'length')) {
                                fetchSuppliers();
                            }
                            if (value === 'EMPLOYEES' && !_.get(employees, 'length')) {
                                fetchEmployees();
                            }
                            if (value === 'CLIENTS' && !_.get(props.clients, 'length')) {
                                props.fetchClients();
                            }
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={counterpartType}
                    >
                        <Option key='SUPPLIERS' value='SUPPLIERS'>
                            <FormattedMessage id='entries_tab.SUPPLIER' />
                        </Option>
                        <Option key='EMPLOYEES' value='EMPLOYEES'>
                            <FormattedMessage id='entries_tab.EMPLOYEE' />
                        </Option>
                        <Option key='CLIENTS' value='CLIENTS'>
                            <FormattedMessage id='entries_tab.CLIENT' />
                        </Option>
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='entries_tab.CONTRAGENT' />
                    </div>
                    <Select
                        disabled={!counterpartType}
                        onChange={value => {
                            setCounterpartId(value);
                        }}
                        onSearch={input => {
                            if (counterpartType === 'SUPPLIERS') {
                                handleSearchSuppliers(input);
                            }
                            if (counterpartType === 'EMPLOYEES') {
                                handleSearchEmployees(input);
                            }
                            if (counterpartType === 'CLIENTS') {
                                handleSearchClients(input);
                            }
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'entries_tab.CONTRAGENT'
                        })}
                        showSearch
                        style={{ width: '100%' }}
                        value={counterpartId}
                    >
                        {counterpartType === 'SUPPLIERS'
                            ? suppliers.map(supplier => (
                                  <Option key={supplier.id} value={supplier.id}>
                                      {`${supplier.name}`}
                                  </Option>
                              ))
                            : counterpartType === 'EMPLOYEES'
                            ? employees.map(employee => (
                                  <Option key={employee.id} value={employee.id}>
                                      {`${employee.name} ${employee.surname}`}
                                  </Option>
                              ))
                            : counterpartType === 'CLIENTS'
                            ? props.clients.map(client => (
                                  <Option
                                      key={client.clientId}
                                      phone={_.get(client, 'phones[0]')}
                                      value={client.clientId}
                                      vehicles={_.get(client, 'vehicles')}
                                  >
                                      {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                          client,
                                          'phones[0]',
                                          ''
                                      )}`}
                                  </Option>
                              ))
                            : []}
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='entry_table.operation_type' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Select
                        onChange={value => {
                            setOperationType(value);
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={operationType}
                    >
                        {Object.entries(operationTypes).map(([key, value]) => (
                            <Option key={key} value={key}>
                                <FormattedMessage id={value} />
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='navigation.storage' />
                    </div>
                    <Select
                        onChange={value => {
                            setWarehouseId(value);
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'navigation.storage'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={warehouseId}
                    >
                        {warehouses.map(elem => (
                            <Option key={elem.id} value={elem.id}>
                                {elem.name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='supplier.cashbox_id' />
                    </div>
                    <Select
                        onChange={value => {
                            setCashboxId(value);
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'supplier.cashbox_id'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={cashBoxId}
                    >
                        {cashboxes.map(({ name, id }) => (
                            <Option key={id} value={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='comment' />
                    </div>
                    <Input.TextArea
                        autoFocus
                        onChange={event => {
                            setComment(event.target.value);
                        }}
                        style={{ width: '100%', minHeight: '150px' }}
                        value={comment}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddAccDocsModal));
