import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Popconfirm, Switch, Table } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import DateCalendar from '../DateCalendar';
import DayModal from '../DayModal';
import ScheduleModal from '../ScheduleModal';
import Styles from './styles.m.css';

const red = 'var(--db_reserve)';
const green = '#ddeaf37d';
const currentDay = 'rgb(169, 209, 142)';
const fullDay = 'rgb(180, 200, 230)';
const notFullDay = 'rgb(255, 240, 180)';
const notStarted = 'rgb(255, 0, 0)';
const dayOff = 'var(--db_reserve)';

const WorkingDaysTab = ({ employee, intl: { formatMessage }, user }) => {
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [forwardOne, setForwardOne] = useState(dayjs().add(1, 'M'));
    const [forwardTwo, setForwardTwo] = useState(dayjs().add(2, 'M'));
    const [schedule, setSchedule] = useState([]);
    const [scheduleOpen, setScheduleOpen] = useState(false);
    const [daySchedule, setDaySchedule] = useState(null);
    const [dayObject, setDayObject] = useState(null);
    const [scheduleDS, setScheduleDS] = useState([]);
    const [currentTableDate, setCurrentTableDate] = useState(dayjs());
    const [planned, setPlannedSchedule] = useState(true);
    const [fact, setFactSchedule] = useState(false);

    const isGrantedDisabled = !isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_WORK_SCHEDULE, accesses.ROWO);

    const fetchCalendar = useCallback(async () => {
        const schedule = await fetchAPI('GET', '/employee_schedule', { employeeId: employee[0].id }, null, {
            handleErrorInternally: true
        });
        setSchedule(schedule.map(s => ({ ...s, dayjsDate: dayjs(s.date) })));
        if (!scheduleDS.length) {
            setScheduleDS([{ dates: schedule.map(s => ({ ...s, dayjsDate: dayjs(s.date) })), employee: employee[0] }]);
        }
    }, [employee, scheduleDS]);
    const fetchTableCalendar = useCallback(async () => {
        const schedule = await fetchAPI('GET', '/employee_schedule', { employeeId: employee[0].id }, null, {
            handleErrorInternally: true
        });
        setScheduleDS([{ dates: schedule.map(s => ({ ...s, dayjsDate: dayjs(s.date) })), employee: employee[0] }]);
    }, [employee]);

    useEffect(() => {
        fetchCalendar();
    }, []);

    useEffect(() => {
        if (!schedule.length) {
            fetchTableCalendar();
        }
    }, [schedule, currentTableDate]);

    const columns = useMemo(() => {
        const days = [];
        for (let i = 0; i < currentTableDate.daysInMonth(); i++) {
            days.push(dayjs(currentTableDate).date(i + 1));
        }

        return [
            {
                title: <FormattedMessage id='order_form_table.employee' />,
                render: ({ employee }) => `${employee.name} ${employee.surname}`
            },
            ...days.map(day => ({
                title: day.format('DD dd'),
                render: ({ dates }) => {
                    const schedule = dates.find(({ dayjsDate }) => dayjsDate.isSame(day, 'day'));

                    const plannedWorkingTime = schedule
                        ? dayjs(schedule.plannedEndTime || '00:00:00', 'HH:mm:ss').diff(
                              dayjs(schedule.plannedStartTime || '00:00:00', 'HH:mm:ss'),
                              'hours'
                          )
                        : undefined;

                    const registerWorkingTime = schedule ? schedule.totalWorkingTime || 0 : undefined;

                    const tillCurrentDay = schedule
                        ? dayjs(schedule.dayjsDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
                        : undefined;

                    if (schedule && schedule.plannedDayOff === false) {
                        const [startHours, startMinutes] = schedule.plannedStartTime.split(':');
                        const [endHours, endMinutes] = schedule.plannedEndTime.split(':');
                        const startDate = dayjs().set('h', Number(startHours)).set('m', Number(startMinutes));
                        const endDate = dayjs().set('h', Number(endHours)).set('m', Number(endMinutes));

                        return (
                            <div
                                className={
                                    schedule.dayjsDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') && fact
                                        ? Styles.cellStyleCurrentDay
                                        : registerWorkingTime >= plannedWorkingTime && fact && tillCurrentDay
                                        ? Styles.cellStyleFullDay
                                        : registerWorkingTime !== 0 &&
                                          plannedWorkingTime > registerWorkingTime &&
                                          fact &&
                                          tillCurrentDay
                                        ? Styles.cellStyleNotFullDay
                                        : registerWorkingTime == 0 &&
                                          plannedWorkingTime > registerWorkingTime &&
                                          fact &&
                                          tillCurrentDay
                                        ? Styles.cellStyleNotStarted
                                        : schedule.plannedDayOff && fact && tillCurrentDay
                                        ? Styles.cellStyleDayOff
                                        : Styles.cellStyle
                                }
                            >
                                <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                                    {Math.round(endDate.diff(startDate, 'hours', true))}
                                </p>
                                <p style={{ fontSize: 11, textAlign: 'center' }}>{startDate.format('HH:mm')}</p>
                                <p style={{ fontSize: 11, textAlign: 'center' }}>{endDate.format('HH:mm')}</p>
                            </div>
                        );
                    }

                    return fact && tillCurrentDay ? <div className={Styles.cellStyleDayOff}></div> : null;
                },
                onCell: () => ({ style: { padding: '6px' } })
            }))
        ];
    }, [currentTableDate, scheduleDS, fact]);
    // tableSchedule.filter(({dayjsDate}) => dayjsDate.isSame(currentTableDate, 'day'))

    const renderScheduleCell = (date, reference) => {
        const scheduleDate = schedule.filter(e => e.dayjsDate.isSame(date, 'day'))[0];
        const background = scheduleDate && scheduleDate.plannedDayOff ? red : green;
        if (date.month() !== reference.month()) return <div>{date.format('D')}</div>;

        return <div style={{ borderRadius: '2px', ...(scheduleDate && { background }) }}>{date.format('D')}</div>;
    };
    const disableCell = (date, reference) => date.month() !== reference.month();

    const openScheduleModal = () => {
        if (!isGrantedDisabled) {
            setScheduleOpen(true);
        }
    };

    const deleteWorkSchedule = useCallback(async () => {
        await fetchAPI(
            'DELETE',
            '/employee_work_schedule',
            null,
            { employeeId: employee[0].id },
            {
                handleErrorInternally: true
            }
        );
        await fetchCalendar();
    }, [employee, fetchCalendar]);

    const closeScheduleModal = () => setScheduleOpen(false);

    const openDayModal = (day, scheduleDate) => {
        if (!isGrantedDisabled) {
            setDaySchedule(day);
            setDayObject(scheduleDate);
        }
    };
    const closeDayModal = () => setDaySchedule(null);

    const changeCurrent = date => {
        setCurrentDate(date);
        setForwardOne(date.add(1, 'M'));
        setForwardTwo(date.add(2, 'M'));
    };

    const changeForwardOne = date => {
        setCurrentDate(date.subtract(1, 'M'));
        setForwardOne(date);
        setForwardTwo(date.add(1, 'M'));
    };

    const changeForwardTwo = date => {
        setForwardTwo(date);
        setForwardOne(date.subtract(1, 'M'));
        setCurrentDate(date.subtract(2, 'M'));
    };

    return (
        <React.Fragment>
            <div className={Styles.container}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        disabled={isGrantedDisabled}
                        onClick={openScheduleModal}
                        size='contained'
                        style={{ width: 'fit-content', marginRight: 8 }}
                        type='primary'
                    >
                        <FormattedMessage id='reports_page.set_schedule' />
                    </Button>

                    <Popconfirm
                        disabled={isGrantedDisabled || !get(schedule, 'length')}
                        onConfirm={deleteWorkSchedule}
                        title={`${formatMessage({ id: 'delete' })} ?`}
                    >
                        <Button
                            danger
                            disabled={isGrantedDisabled || !get(schedule, 'length')}
                            size='contained'
                            style={{ width: 'fit-content' }}
                            type='primary'
                        >
                            <FormattedMessage id='reports_page.delete_schedule' />
                        </Button>
                    </Popconfirm>
                </div>
                <div className={Styles.calendarListWrapper}>
                    <div className={Styles.calendarWrapper}>
                        <DateCalendar
                            changeRange={changeCurrent}
                            disableCell={disableCell}
                            disabled={isGrantedDisabled}
                            openDayModal={openDayModal}
                            renderScheduleCell={renderScheduleCell}
                            schedule={schedule}
                            value={currentDate}
                        />
                    </div>
                    <div className={Styles.calendarWrapper}>
                        <DateCalendar
                            changeRange={changeForwardOne}
                            disableCell={disableCell}
                            disabled={isGrantedDisabled}
                            openDayModal={openDayModal}
                            renderScheduleCell={renderScheduleCell}
                            schedule={schedule}
                            value={forwardOne}
                        />
                    </div>
                    <div className={Styles.calendarWrapper}>
                        <DateCalendar
                            changeRange={changeForwardTwo}
                            disableCell={disableCell}
                            disabled={isGrantedDisabled}
                            openDayModal={openDayModal}
                            renderScheduleCell={renderScheduleCell}
                            schedule={schedule}
                            value={forwardTwo}
                        />
                    </div>
                </div>
                <FormattedMessage id='add-employee-page.schedule' />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <Button
                            disabled={isGrantedDisabled}
                            icon={<LeftOutlined />}
                            onClick={() => setCurrentTableDate(currentTableDate.subtract(1, 'M'))}
                            type='text'
                        />

                        <DatePicker
                            disabled={isGrantedDisabled}
                            format='MMMM, YYYY'
                            onChange={date => setCurrentTableDate(date)}
                            picker='month'
                            style={{ width: 160 }}
                            value={currentTableDate}
                        />
                        <Button
                            disabled={isGrantedDisabled}
                            icon={<RightOutlined />}
                            onClick={() => setCurrentTableDate(currentTableDate.add(1, 'M'))}
                            type='text'
                        />
                    </div>
                    <div>
                        <span
                            style={{
                                marginLeft: 6,
                                marginRight: 6
                            }}
                        >
                            <FormattedMessage id='employee-page.plan_fact' />
                        </span>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Switch
                                checked={fact}
                                disabled={isGrantedDisabled}
                                onClick={() => setFactSchedule(!fact)}
                            />
                        </div>
                    </div>
                </div>
                <Table bordered columns={columns} dataSource={scheduleDS} pagination={false} scroll={{ x: true }} />
            </div>
            <ScheduleModal
                employeeId={get(employee, '[0].id')}
                fetchCalendar={fetchCalendar}
                modalProps={{
                    open: scheduleOpen,
                    onClose: closeScheduleModal,
                    onCancel: closeScheduleModal,
                    title: formatMessage({ id: 'reports_page.set_schedule' })
                }}
            />
            <DayModal
                dayObject={dayObject}
                daySchedule={daySchedule}
                employee={employee}
                fetchCalendar={fetchCalendar}
                modalProps={{
                    onClose: closeDayModal,
                    onCancel: closeDayModal,
                    title: daySchedule && daySchedule.format('dddd, DD.MM.YYYY')
                }}
            />
        </React.Fragment>
    );
};

export default injectIntl(WorkingDaysTab);
