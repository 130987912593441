import { DatePicker, Input, Modal, Select } from 'antd';
import { DateRangePicker } from 'components';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    clients: state.clients.clients,
    user: state.auth
});

const mapDispatchToProps = {
    resetModal,
    fetchClients,
    setClientsSearchFilter
};

const orderStatuses = [
    {
        status: 'not_complete',
        key: 'not_complete'
    },
    {
        status: 'processing',
        key: 'processing'
    },
    {
        status: 'approve',
        key: 'approve'
    },
    {
        status: 'progress',
        key: 'progress'
    },
    {
        status: 'success',
        key: 'success'
    },
    {
        status: 'cancel',
        key: 'cancel'
    },
    {
        status: 'required',
        key: 'required'
    },
    {
        status: 'reserve',
        key: 'reserve'
    },
    {
        status: 'call',
        key: 'call'
    },
    {
        status: 'stop',
        key: 'stop'
    }
];

const denyReasons = [
    {
        title: 'Не виконуємо такі роботи',
        id: 1
    },
    {
        title: 'Не займаємося такими авто',
        id: 2
    },
    {
        title: 'Завантаженні, немає можливості прийняти',
        id: 3
    },
    {
        title: 'Немає потрібного спеціаліста',
        id: 4
    },
    {
        title: 'Консультаційний дзвінок',
        id: 5
    },
    {
        title: 'Інша причина',
        id: 6
    },
    {
        title: 'Дзвінок по рекламі',
        id: 7
    },
    {
        title: 'Спам',
        id: 8
    }
];

const callStatuses = [
    {
        status: 'ANSWER',
        key: 'ANSWER'
    },
    {
        status: 'ANSWERED',
        key: 'ANSWERED'
    },
    {
        status: 'TRANSFER',
        key: 'TRANSFER'
    },
    {
        status: 'FORBIDDENDESTINATION',
        key: 'FORBIDDENDESTINATION'
    },
    {
        status: 'NOEXTENSION',
        key: 'NOEXTENSION'
    },
    {
        status: 'FAILED',
        key: 'FAILED'
    },
    {
        status: 'NOANSWER',
        key: 'NOANSWER'
    },
    {
        status: 'CLIENTNOANSWER',
        key: 'CLIENTNOANSWER'
    },
    {
        status: 'REPEATED',
        key: 'REPEATED'
    },
    {
        status: 'NOFORWARD',
        key: 'NOFORWARD'
    },
    {
        status: 'PROPER',
        key: 'PROPER'
    },
    {
        status: 'BUSY',
        key: 'BUSY'
    },
    {
        status: 'VOICEMAIL',
        key: 'VOICEMAIL'
    },
    {
        status: 'CANCEL',
        key: 'CANCEL'
    }
];

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CallsReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allEmployees: [],
            orderList: [],
            doneFrom: dayjs().startOf('month'),
            doneTo: dayjs().endOf('month'),
            detailing: 'dates',
            searchValue: '',
            orderStatus: undefined
        };

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            if (orderStatuses) {
                this.getAllEmployees();
                this.props.fetchClients();
            }
        }
    }

    handleSearchEmployees = _.debounce(value => {
        this.getAllEmployees(value);
    }, 1000);

    handleSearchOrders = _.debounce(value => {
        this.fetchOrders(value);
    }, 1000);

    getAllEmployees = async query => {
        const employees = await fetchAPI('GET', 'employees', { query }, null, { handleErrorInternally: true });
        this.setState({
            allEmployees: employees
        });
    };

    fetchOrders = async query => {
        const { orders } = await fetchAPI('GET', 'orders', { query, pageSize: 50 }, null, {
            handleErrorInternally: true
        });
        this.setState({
            orderList: orders
        });
    };

    handleCancel = () => {
        this.props.resetModal();
        this.setState({
            doneFrom: dayjs().startOf('month'),
            doneTo: dayjs().endOf('month'),
            clientId: undefined,
            orderId: undefined,
            employeeId: undefined,
            callStatus: undefined,
            orderStatus: undefined,
            orderStatusAttached: undefined,
            denyReason: undefined
        });
    };

    handleOk = async () => {
        const { modalProps } = this.props;
        const { type } = modalProps;

        const {
            doneFrom,
            doneTo,
            clientId,
            employeeId,
            callStatus,
            denyReason,
            orderId,
            orderStatus,
            orderStatusAttached,
            operatorPhone
        } = this.state;

        const getResponse = () => {
            return fetchAPI(
                'GET',
                '/report/orders/excel/byCalls',
                {
                    clientId,
                    orderId,
                    employeeId,
                    callStatus,
                    orderStatus: orderStatus || orderStatusAttached,
                    denyReason,
                    operatorPhone,
                    doneTo: dayjs(doneFrom).format('YYYY-MM-DD'),
                    doneFrom: dayjs(doneTo).format('YYYY-MM-DD')
                },
                null,
                { rawResponse: true, handleErrorInternally: true }
            );
        };
        const response = await getResponse();
        const reportFile = await response.blob();
        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        saveAs(reportFile, fileName);
        this.handleCancel();
    };

    render() {
        const {
            modalProps,
            resetModal,
            visible,
            clients,
            intl: { formatMessage }
        } = this.props;

        const {
            employeeId,
            callStatus,
            denyReason,
            orderId,
            orderStatusAttached,
            orderStatus,
            allEmployees,
            doneFrom,
            doneTo,
            clientId,
            orderList,
            detailing,
            searchValue,
            operatorPhone
        } = this.state;

        const { type } = modalProps;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='reports_page.calls_report' />}
                    visible={visible === MODALS.CALLS_REPORT_MODAL}
                    width='fit-content'
                >
                    <Select
                        allowClear
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={(clientId, option) => {
                            if (option) {
                                this.setState({
                                    clientId
                                });
                            } else {
                                this.setState({
                                    clientId: undefined
                                });
                            }
                        }}
                        onSearch={this.handleSearchClients}
                        placeholder={formatMessage({ id: 'add_order_form.client' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={clientId}
                    >
                        {clients.map(client => (
                            <Option key={client.clientId} phone={_.get(client, 'phones[0]')} value={client.clientId}>
                                {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                    client,
                                    'phones[0]',
                                    ''
                                )}`}
                            </Option>
                        ))}
                    </Select>
                    <Input
                        onChange={event => {
                            this.setState({
                                operatorPhone: event.target.value
                            });
                        }}
                        placeholder={formatMessage({ id: 'calls-table.recipient' })}
                        style={{
                            marginBottom: 8
                        }}
                        type='tel'
                        value={operatorPhone}
                    />
                    <Select
                        allowClear
                        filterOption={(input, option) => {
                            const searchValue = String(option.props.children)
                                .toLowerCase()
                                .replace(/[+\-()., ]/g, '');
                            const inputValue = input.toLowerCase();

                            return searchValue.indexOf(inputValue) >= 0;
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        onBlur={() => {
                            this.setState({
                                searchValue: ''
                            });
                        }}
                        onChange={(value, option) => {
                            this.setState({
                                orderId: value
                            });
                        }}
                        onClear={() => {
                            this.setState({
                                orderId: '',
                                orderStatusAttached: ''
                            });
                        }}
                        onSearch={input => {
                            this.setState({
                                searchValue: input
                            });
                            if (input.length > 1) this.handleSearchOrders(input);
                        }}
                        showSearch
                        style={{ marginBottom: 8 }}
                        value={orderId}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'order'
                        })}
                    >
                        {searchValue.length > 2
                            ? orderList.map(({ id, num, status }) => (
                                  <Option key={id} status={status} value={id}>
                                      {<FormattedMessage id={`order_statuses_mapper.${status}`} />} {num}
                                  </Option>
                              ))
                            : []}
                    </Select>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            this.setState({
                                orderStatus: value
                            });
                        }}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'calls-table.status'
                        })}
                        showSearch
                        style={{ marginBottom: 8 }}
                        value={orderStatus}
                    >
                        {orderStatuses.map(({ status, key }) => (
                            <Option key={key} value={status}>
                                <FormattedMessage id={`order_statuses_mapper.${status}`} />
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            this.setState({
                                employeeId: value
                            });
                        }}
                        onSearch={input => this.handleSearchEmployees(input)}
                        showSearch
                        style={{ marginBottom: 8 }}
                        value={employeeId}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'employee'
                        })}
                    >
                        {allEmployees
                            .filter(({ disabled }) => !disabled)
                            .map(({ id, name, surname }) => (
                                <Option key={id} value={id}>
                                    {`${name} ${surname}`}
                                </Option>
                            ))}
                    </Select>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            this.setState({
                                callStatus: value
                            });
                        }}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'call'
                        })}
                        showSearch
                        style={{ marginBottom: 8 }}
                        value={callStatus}
                    >
                        {callStatuses.map(({ status, key }) => (
                            <Option key={key} value={status}>
                                <FormattedMessage id={`reports_page.${status}`} />
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            this.setState({
                                denyReason: value
                            });
                        }}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'universal_filters_form.cancelReason'
                        })}
                        showSearch
                        style={{ marginBottom: 8 }}
                        value={denyReason}
                    >
                        {denyReasons.map(({ title, id }) => (
                            <Option key={id} value={id}>
                                {title}
                            </Option>
                        ))}
                    </Select>
                    <DateRangePicker
                        dateRange={[doneFrom, doneTo]}
                        onDateChange={arr => {
                            this.setState({ doneFrom: arr[0] });
                            this.setState({ doneTo: arr[1] });
                        }}
                    />
                </Modal>
            </div>
        );
    }
}
