/* eslint-disable max-classes-per-file */
import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    MenuOutlined,
    PlusOutlined,
    PlusSquareOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import { Button, Drawer, Image, Menu, Modal, Select, Table, Tooltip, notification } from 'antd';
import { Catcher } from 'commons';
import { HamburgerMenu } from 'components';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import _, { isArray } from 'lodash';
import { AddLaborOrDetailToOrderModal, AddRecommendationsModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PencilIcon } from 'theme';
import { fetchAPI } from 'utils';
import { recommendationsColumns } from './columns';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
export default class RecommendationsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            recommendationsModalVisible: false,
            dataSource: [],
            selectedRowKeys: [],
            selectedRows: [],
            helperDrawerOpen: false,
            allLinks: []
        };
        this.masterLabors = [];
        this.laborsTreeData = [];

        this.columns = () => [
            {
                title: () => {
                    const {
                        intl: { formatMessage }
                    } = this.props;

                    const { selectedRows } = this.state;
                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.SubMenu
                                key='groupRecommendationsMenu.changeStatus'
                                data-qa='sub_menu_group_details_menu_details_table_order_page'
                                disabled={!selectedRows.length}
                                title={
                                    <React.Fragment>
                                        <EditOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.change_status'
                                        })}
                                    </React.Fragment>
                                }
                            >
                                <Menu.Item
                                    key='groupRecommendationsMenu.changeStatus.active'
                                    onClick={() => {
                                        this.updateRecommendation(
                                            selectedRows.map(rcm => ({
                                                ...rcm,
                                                recommendationStatus: true
                                            }))
                                        );
                                    }}
                                >
                                    {this.props.intl.formatMessage({ id: 'active' })}
                                </Menu.Item>
                                <Menu.Item
                                    key='groupRecommendationsMenu.changeStatus.inactive'
                                    onClick={() => {
                                        this.updateRecommendation(
                                            selectedRows.map(rcm => ({
                                                ...rcm,
                                                recommendationStatus: false
                                            }))
                                        );
                                    }}
                                >
                                    {this.props.intl.formatMessage({ id: 'inactive' })}
                                </Menu.Item>
                            </Menu.SubMenu>

                            <Menu.Item
                                key='groupRecommendationsMenu.copy'
                                disabled={!selectedRows.length || this.props.disabled}
                            >
                                <div
                                    onClick={async () => {
                                        const { vehicleId, orderId, aggregateId } = this.props;
                                        await fetchAPI(
                                            'POST',
                                            'recommendations/labors',
                                            null,
                                            selectedRows.map(recommendation => ({
                                                vehicleId,
                                                orderId: orderId || undefined,
                                                aggregateId: aggregateId || undefined,
                                                ..._.pick(recommendation, [
                                                    'recommendationName',
                                                    'recommendationStatus',
                                                    'recommendationComment',
                                                    'recommendationLaborId',
                                                    'recommendationToDate',
                                                    'recommendationToOdometerValue'
                                                ])
                                            })),
                                            { handleErrorInternally: true }
                                        );
                                        this.updateDataSource();
                                    }}
                                >
                                    <CopyOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                key='groupRecommendationsMenu.deleted'
                                disabled={!selectedRows.length || this.props.disabled}
                            >
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                await fetchAPI('DELETE', 'recommendations/labors', {
                                                    recommendationIds: `[${selectedRows.map(
                                                        ({ recommendationId }) => recommendationId
                                                    )}]`
                                                });
                                                notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.deleted'
                                                    })
                                                });
                                                this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                key='groupRecommendationsMenu.addToOrder'
                                disabled={!selectedRows.length}
                                onClick={() => {
                                    const { setModal, vehicleId, orderId } = this.props;
                                    const defaultUse = _.get(
                                        this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                        '[0].id'
                                    );
                                    setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                        labors: selectedRows.map(
                                            ({ recommendationName, laborId, count, servicePrice, laborUnitId }) => ({
                                                laborId,
                                                serviceName: recommendationName,
                                                count: Number(count) || 1,
                                                servicePrice: Number(servicePrice),
                                                laborUnitId: Number(laborUnitId) || defaultUse
                                            })
                                        ),
                                        mode: 'ADD_LABOR',
                                        vehicleId,
                                        orderId,
                                        onConfirm: () => setTimeout(this.updateDataSource, 200)
                                    });
                                }}
                            >
                                <div>
                                    <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'order_form_table.add_to_order_tooltip' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} />;
                },
                key: 'actions',
                align: 'center',
                render: row => {
                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item key='recommendationActionMenu.delete' disabled={this.props.disabled}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                await fetchAPI('DELETE', 'recommendations/labors', {
                                                    recommendationIds: `[${row.recommendationId}]`
                                                });
                                                notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.deleted'
                                                    })
                                                });
                                                this.updateDataSource();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item key='recommendationActionMenu.copy' disabled={this.props.disabled}>
                                <div
                                    onClick={async () => {
                                        await fetchAPI('POST', 'recommendations/labors', null, [
                                            {
                                                vehicleId: this.props.vehicleId,
                                                orderId: this.props.orderId || undefined,
                                                aggregateId: this.props.aggregateId || undefined,
                                                ..._.pick(row, [
                                                    'recommendationName',
                                                    'recommendationStatus',
                                                    'recommendationComment',
                                                    'recommendationLaborId',
                                                    'recommendationToDate',
                                                    'recommendationToOdometerValue'
                                                ])
                                            }
                                        ]);
                                        this.updateDataSource();
                                    }}
                                >
                                    <CopyOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                key='recommendationActionMenu.addToOrder'
                                onClick={() => {
                                    const { setModal, vehicleId, orderId } = this.props;
                                    const defaultUse = _.get(
                                        this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                        '[0].id'
                                    );
                                    setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                        labors: [
                                            {
                                                laborId: row.laborId,
                                                serviceName: row.recommendationName,
                                                count: Number(row.count) || 1,
                                                servicePrice: Number(row.servicePrice),
                                                laborUnitId: Number(row.laborUnitId) || defaultUse
                                            }
                                        ],
                                        mode: 'ADD_LABOR',
                                        vehicleId,
                                        orderId,
                                        onConfirm: () => setTimeout(this.updateDataSource, 200)
                                    });
                                }}
                            >
                                <div>
                                    <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'order_form_table.add_to_order_tooltip' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu}>
                            <Button
                                data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },
            {
                title: () => (
                    <div className={Styles.headerActions}>
                        <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                            <Button
                                data-qa='btn_show_service_product_modal_services_table_order_page'
                                disabled={this.props.disabled}
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    this.showRecommendationsModal(-1);
                                }}
                            />
                        </Tooltip>
                    </div>
                ),
                key: 'buttonGroup',
                align: 'center',
                render: (row, _, key) => {
                    return (
                        <div className={Styles.rowActions}>
                            <Tooltip placement='top' title={<FormattedMessage id='labors_table.add_edit_button' />}>
                                <Button
                                    data-qa='btn_add_edit_button_service_product_modal_services_table_order_page'
                                    disabled={this.props.disabled}
                                    icon={<PencilIcon />}
                                    onClick={() => {
                                        this.showRecommendationsModal(key);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
            ...recommendationsColumns({
                updateRecommendation: rec => this.updateRecommendation(rec),
                user: this.props.user
            }).slice(1)
        ];
    }

    componentDidMount() {
        this.getRecommendations();
        this.fetchLaborsTree();
        this.setState({
            dataSource: this.structRecommendations([...this.props.orderRecommendations.list])
        });
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.activeKey !== 'recommendations' && this.props.activeKey === 'recommendations') ||
            prevProps.orderRecommendations !== this.props.orderRecommendations
        ) {
            this.setState({
                dataSource: this.structRecommendations([...this.props.orderRecommendations.list])
            });
        }
    }

    handleSearchLabors = _.debounce(value => {
        this.props.fetchLabors(value);
    }, 500);

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_recommendation' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    updateDataSource = () => {
        const callback = data => {
            this.setState({
                dataSource: this.structRecommendations(data.orderRecommendations.list),
                selectedRowKeys: [],
                selectedRows: []
            });
        };
        setTimeout(() => this.props.reloadOrderForm(callback, 'labors'), 500);
    };

    structRecommendations = recommendations =>
        recommendations.map(rcm => ({
            ...rcm,
            recommendationLaborId: rcm.laborId,
            recommendationName: rcm.laborName,
            recommendationToOdometerValue: rcm.recommendationToOdometerValue || undefined,
            recommendationComment: rcm.recommendationComment || undefined,
            recommendationToDate: rcm.recommendationToDate ? dayjs(rcm.recommendationToDate) : undefined
        }));

    getRecommendations = async () => {
        const { vehicleId, orderId } = this.props;
        if (vehicleId) {
            const recommendations = await fetchAPI('GET', 'recommendations/labors', { vehicleId, orderId }, null, {
                handleErrorInternally: true
            });
            this.setState({
                dataSource: recommendations.map(rcm => ({
                    ...rcm,
                    recommendationLaborId: rcm.laborId,
                    recommendationName: rcm.laborName,
                    recommendationToOdometerValue: rcm.recommendationToOdometerValue || undefined,
                    recommendationComment: rcm.recommendationComment || undefined,
                    recommendationToDate: rcm.recommendationToDate ? dayjs(rcm.recommendationToDate) : undefined
                }))
            });
        }
    };

    updateRecommendation = async recommendation => {
        const { vehicleId, orderId, aggregateId } = this.props;
        const fields = [
            'recommendationId',
            'recommendationName',
            'recommendationStatus',
            'recommendationComment',
            'recommendationLaborId',
            'recommendationToDate',
            'recommendationToOdometerValue'
        ];
        await fetchAPI(
            'PUT',
            'recommendations/labors',
            null,
            (isArray(recommendation) ? recommendation : [recommendation]).map(rcmd => ({
                vehicleId: vehicleId || undefined,
                aggregateId: aggregateId || undefined,
                orderId,
                ..._.pick(rcmd, fields)
            })),
            { handleErrorInternally: true }
        );
        this.updateDataSource();
    };

    fetchLaborsTree() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/labors/master?makeTree=true`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.masterLabors = data.masterLabors;
                that.buildLaborsTree();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    buildLaborsTree() {
        const treeData = [];
        for (let i = 0; i < this.masterLabors.length; i++) {
            const parentGroup = this.masterLabors[i];
            treeData.push({
                title: `${parentGroup.defaultMasterLaborName} (#${parentGroup.masterLaborId})`,
                name: parentGroup.defaultMasterLaborName,
                value: parentGroup.masterLaborId,
                className: Styles.groupTreeOption,
                selectable: false,
                children: []
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.defaultMasterLaborName} (#${childGroup.masterLaborId})`,
                    name: childGroup.defaultMasterLaborName,
                    value: childGroup.masterLaborId,
                    className: Styles.groupTreeOption,
                    selectable: false,
                    children: []
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.defaultMasterLaborName} (#${lastNode.masterLaborId})`,
                        name: lastNode.defaultMasterLaborName,
                        value: lastNode.masterLaborId,
                        className: Styles.groupTreeOption
                    });
                }
            }
        }
        this.laborsTreeData = treeData;
        this.setState({});
    }

    showRecommendationsModal(key) {
        this.setState({
            recommendationsModalVisible: true,
            recomModalKey: key
        });
    }

    render() {
        const { isMobile, labors, detailsTreeData, vehicleId, orderId, orderOdometrValue, aggregateId } = this.props;
        const {
            loading,
            dataSource,
            recommendationsModalVisible,
            recomModalKey,
            selectedRowKeys,
            helperDrawerOpen,
            allLinks
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        return (
            <Catcher>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginBottom: 8
                    }}
                >
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            this.setState({
                                helperDrawerOpen: true
                            });
                            await this.fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22
                        }}
                        type='text'
                    />
                </div>
                <Table
                    bordered
                    columns={this.columns()}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={false}
                    rowClassName={record => {
                        const { recommendationStatus } = record;

                        return !recommendationStatus && Styles.disabledRow;
                    }}
                    rowKey='recommendationId'
                    rowSelection={!isMobile && rowSelection}
                    size='small'
                    style={isMobile ? {} : { overflowX: 'scroll' }}
                />
                <AddRecommendationsModal
                    aggregateId={aggregateId}
                    detailsTreeData={detailsTreeData}
                    getRecommendations={this.updateDataSource}
                    handleSearchLabors={this.handleSearchLabors}
                    hideModal={() => {
                        this.setState({
                            recommendationsModalVisible: false
                        });
                    }}
                    labors={labors}
                    laborsTreeData={this.laborsTreeData}
                    orderId={orderId}
                    orderOdometrValue={orderOdometrValue}
                    recommendation={dataSource[recomModalKey]}
                    updateRecommendation={this.updateRecommendation}
                    vehicleId={vehicleId}
                    visible={recommendationsModalVisible}
                />
                <AddLaborOrDetailToOrderModal />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}
