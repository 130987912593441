/* eslint-disable id-length */
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Radio, Rate, Space, notification } from 'antd';
import { Flex } from 'antd/lib';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const { TextArea } = Input;

// http://carbook.pro/s?rw=${orderData.ord_short_id}&b=${orderData.ord_bsn_id_fk}

const UserFeedbackPage = ({ intl: { formatMessage } }) => {
    const [data, setData] = useState({});

    const [postData, setPostData] = useState({});

    const updPostData = field => value => {
        console.log(field, value);
        setPostData(prev => ({ ...prev, [field]: value }));
    };

    const fetchData = useCallback(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const shortOrderId = urlParams.get('rw');
        const businessId = urlParams.get('b');

        if (!shortOrderId || !businessId) return;

        const { data } = await fetchAPI('GET', '/review_from_client/data', { shortOrderId, businessId }, null, {
            handleErrorInternally: true
        });
        setData(data);
        setPostData({
            code: shortOrderId,
            businessId,
            // nps: 10,
            repairDuration: 5,
            repairQuality: 5,
            serviceQuality: 5,
            comfort: 5,
            recommended: true,
            anonymous: false
        });
    }, []);

    const postFeedback = useCallback(async () => {
        await fetchAPI(
            'POST',
            '/review_from_client',
            null,
            {
                ...postData,
                repairDuration: postData.repairDuration * 2,
                repairQuality: postData.repairQuality * 2,
                serviceQuality: postData.serviceQuality * 2,
                comfort: postData.comfort * 2
            },
            { handleErrorInternally: true }
        );
        notification.success({ message: 'Відправлено!' });

        setTimeout(() => window.close(), 1000);
    }, [postData]);

    const ratingArray = useMemo(() => Array.from({ length: 10 }, (_, i) => i + 1), []);

    const rateData = [
        { name: 'Час ремонту', field: 'repairDuration' },
        { name: 'Якість ремонту', field: 'repairQuality' },
        { name: 'Якість сервісу', field: 'serviceQuality' },
        { name: 'Комфорт', field: 'comfort' }
    ];

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Flex
            align='center'
            gap={42}
            style={{ padding: 32, height: '100vh', background: '#fdfdfd', color: 'var(--text)' }}
            vertical
        >
            <p style={{ fontSize: 20, textAlign: 'center' }}>
                Відгук про виконане замовлення{' '}
                <span
                    style={{
                        textDecoration: 'underline',
                        color: 'var(--green)',
                        fontWeight: 700,
                        whiteSpace: 'nowrap'
                    }}
                >
                    {get(data, 'num')}
                </span>{' '}
                від{' '}
                <span
                    style={{
                        textDecoration: 'underline',
                        color: 'var(--green)',
                        fontWeight: 700,
                        whiteSpace: 'nowrap'
                    }}
                >
                    {get(data, 'begindatetime')}
                </span>
            </p>
            <Radio.Group
                buttonStyle='solid'
                onChange={e => updPostData('nps')(e.target.value)}
                size='small'
                style={{ transform: 'scale(1.3)' }}
                value={postData.nps}
            >
                {ratingArray.map(i => (
                    <Radio.Button key={i} value={i}>
                        {i}
                    </Radio.Button>
                ))}
            </Radio.Group>
            <Flex style={{ color: 'var(--text2)', alignSelf: 'normal' }} vertical>
                {rateData.map(({ name, field }) => (
                    <Flex key={field} justify='space-between'>
                        <p style={{ fontSize: 15 }}>
                            <span style={{ color: 'red' }}>* </span>
                            {name}
                        </p>
                        <Rate allowClear={false} onChange={updPostData(field)} value={postData[field]} />
                    </Flex>
                ))}
            </Flex>
            <Space>
                <Button
                    icon={<LikeOutlined />}
                    onClick={() => updPostData('recommended')(true)}
                    type={postData.recommended ? 'primary' : 'default'}
                >
                    Рекомендую
                </Button>
                <Button
                    icon={<DislikeOutlined />}
                    onClick={() => updPostData('recommended')(false)}
                    type={typeof postData.recommended === 'boolean' && !postData.recommended ? 'primary' : 'default'}
                >
                    Не рекомендую
                </Button>
            </Space>
            <TextArea
                autoSize={{
                    minRows: 3,
                    maxRows: 8
                }}
                onChange={e => updPostData('comment')(e.target.value)}
                placeholder='Ваш відгук'
                value={postData.comment}
            />
            <Space direction='vertical' size='small'>
                <Checkbox checked={postData.anonymous} onChange={e => updPostData('anonymous')(e.target.checked)}>
                    Залишити відгук анонімно
                </Checkbox>
                <p style={{ fontSize: 12, color: 'var(--text2)' }}>
                    В цьому разі для партнера CARBOOK буде невідомо хто залишив відгук. Гарантовано CARBOOK
                </p>
            </Space>
            <Button
                disabled={!postData.code || !postData.businessId}
                onClick={postFeedback}
                size='large'
                style={{ alignSelf: 'normal' }}
                type='primary'
            >
                Відправити відгук
            </Button>
        </Flex>
    );
};

export default injectIntl(UserFeedbackPage);
