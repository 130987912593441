import { replace } from 'connected-react-router';
import { updateUser } from 'core/auth/duck';
import { intlActions } from 'core/intl/actions';
import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, take } from 'redux-saga/effects';
import book from 'routes/book';
import { setIntl } from 'store/intl';
import { fetchAPI, setBusinessTypes } from 'utils';
import {
    SUBMIT_PROFILE_FORM,
    SUBMIT_PROFILE_FORM_OTHER,
    submitProfileFormOtherSuccess,
    submitProfileFormSuccess
} from './duck';

export function* submitProfileFormSaga() {
    while (true) {
        try {
            const { payload: user } = yield take(SUBMIT_PROFILE_FORM);
            // yield put(setProfileUpdatingState(true));

            if (user.businessTypes) {
                yield call(
                    fetchAPI,
                    'POST',
                    '/business_types',
                    null,
                    {
                        types:
                            user.businessTypes === 'DEFAULT'
                                ? []
                                : _.isArray(user.businessTypes)
                                ? user.businessTypes
                                : [user.businessTypes]
                    },
                    { handleErrorInternally: true }
                );
                yield setBusinessTypes(user.businessTypes);
            }
            yield delete user.businessTypes;

            if (
                user.packageStatuses ||
                user.incomeOnlyWithCell ||
                !user.incomeOnlyWithCell ||
                user.disableFifoCalcForWarehouses
            ) {
                yield call(
                    fetchAPI,
                    'PUT',
                    '/businesses',
                    null,
                    {
                        packageListDetailStatuses: user.packageStatuses ? [user.packageStatuses] : undefined,
                        incomeOnlyWithCell: Boolean(user.incomeOnlyWithCell)
                    },
                    { handleErrorInternally: true }
                );
            }
            yield delete user.packageStatuses;
            yield delete user.incomeOnlyWithCell;

            yield call(fetchAPI, 'PUT', '/managers', null, user);

            yield put(updateUser(user));

            if (user.language) {
                const intl = yield setIntl(user.language);
                yield put(intlActions.updateIntl(intl));
            }
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(submitProfileFormSuccess());
            yield put(replace(book.profile));
            window.location.reload(true);
            // yield put(setProfileUpdatingState(false));
        }
    }
}

export function* submitProfileFormOtherSaga() {
    while (true) {
        try {
            const { payload: user } = yield take(SUBMIT_PROFILE_FORM_OTHER);
            // yield put(setProfileUpdatingState(true));

            if (user.businessTypes) {
                yield call(
                    fetchAPI,
                    'POST',
                    '/business_types',
                    null,
                    {
                        types:
                            user.businessTypes === 'DEFAULT'
                                ? []
                                : _.isArray(user.businessTypes)
                                ? user.businessTypes
                                : [user.businessTypes]
                    },
                    { handleErrorInternally: true }
                );
                yield setBusinessTypes(user.businessTypes);
            }
            yield delete user.businessTypes;

            if (
                user.packageStatuses ||
                user.incomeOnlyWithCell ||
                !user.incomeOnlyWithCell ||
                user.orderStatusNewVersion ||
                !user.orderStatusNewVersion ||
                user.callOrderAutoCreate ||
                !user.callOrderAutoCreate ||
                user.disableFifoCalcForWarehouses ||
                !user.disableFifoCalcForWarehouses
            ) {
                yield call(
                    fetchAPI,
                    'PUT',
                    '/businesses',
                    null,
                    {
                        packageListDetailStatuses: user.packageStatuses ? [user.packageStatuses] : undefined,
                        incomeOnlyWithCell: Boolean(user.incomeOnlyWithCell),
                        orderStatusNewVersion: Boolean(user.orderStatusNewVersion),
                        callOrderAutoCreate: Boolean(user.callOrderAutoCreate),
                        disableFifoCalcForWarehouses: Boolean(user.disableFifoCalcForWarehouses),
                        generalLedgerWarehouse: Boolean(user.generalLedgerWarehouse),
                        generalLedgerDebt: Boolean(user.generalLedgerDebt),
                        generalLedgerEntries: Boolean(user.generalLedgerEntries),
                        generalLedgerVat: Boolean(user.generalLedgerVat),
                        generalLedgerSalaries: Boolean(user.generalLedgerSalaries)
                    },
                    { handleErrorInternally: true }
                );
            }
            yield delete user.packageStatuses;
            yield delete user.incomeOnlyWithCell;

            yield put(updateUser(user));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(submitProfileFormOtherSuccess());
            yield put(replace(book.settings));
            window.location.reload(true);
            // yield put(setProfileUpdatingState(false));
        }
    }
}

export function* saga() {
    yield all([call(submitProfileFormSaga), call(submitProfileFormOtherSaga)]);
}
