import { Flex, InputNumber, Modal, Radio, Space } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency } from 'utils';
import Styles from './styles.m.css';

const initialData = {
    byCoefficient: 1,
    fixedPrice: 1,
    bySellCoefficient: 1,
    bySellFixedPrice: 1,
    fixedDiscount: 0,
    minMarkup: 10
};

const UpdateLaborsPriceModal = ({ open, intl: { formatMessage }, updateDataSource, orderId, hideModal }) => {
    const [inputs, setInputs] = useState(initialData);
    const [labor, setLaborPrice] = useState();
    const [primeCost, setPrimeCost] = useState('BY_COEFFICIENT');
    const [sell, setSell] = useState('BY_COEFFICIENT');
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    const onChangePrime = e => {
        setPrimeCost(e.target.value);
    };

    const onChangeSell = e => {
        setSell(e.target.value);
    };

    const handleInputsChange = key => value => {
        setInputs({ ...inputs, [key]: value });
    };

    const getLabor = useCallback(async labor => {
        const { labors } = await fetchAPI('GET', '/labors', { page: 1, pageSize: 25, id: labor.laborId }, null, {
            handleErrorInternally: true
        });
        setLaborPrice(labors[0]);
    }, []);

    useEffect(() => {
        if (open) {
            if (open.length === 1) {
                getLabor(open[0]);
            }
            setInputs(inputs);
            const timeoutId = setTimeout(() => {
                inputRef.current.focus();
            }, 100);

            return () => clearTimeout(timeoutId);
        }
    }, [open]);

    const handleClose = useCallback(() => {
        setInputs(initialData);

        setSell('BY_COEFFICIENT');
        setPrimeCost('BY_COEFFICIENT');
        hideModal();
    }, [hideModal]);

    const handleOk = useCallback(async () => {
        const selectedPrimeCost = {
            BY_COEFFICIENT: inputs.byCoefficient,
            FIXED: inputs.fixedPrice
        };

        const selectedPrice = {
            BY_COEFFICIENT: inputs.bySellCoefficient,
            FIXED: inputs.bySellFixedPrice,
            FIXED_DISCOUNT: inputs.fixedDiscount,
            MIN_MARKUP: inputs.minMarkup
        };

        const data = {
            updateMode: true,
            services: open.map(elem => ({
                id: elem.id,
                serviceId: elem.laborId,
                laborUnitId: elem.laborUnitId || 1,
                serviceName: elem.serviceName,
                counterparty: elem.counterparty,
                employeeId: elem.employeeId || null,
                businessSupplierId: elem.businessSupplierId || null,
                serviceHours: elem.hours,
                purchasePrice:
                    primeCost === 'BY_COEFFICIENT'
                        ? elem.purchasePrice * selectedPrimeCost[primeCost]
                        : selectedPrimeCost[primeCost],
                count: elem.count,
                servicePrice:
                    sell === 'LABOR_CARD'
                        ? get(labor, 'laborPrice.price', undefined)
                        : sell === 'BY_COEFFICIENT'
                        ? elem.price * selectedPrice[sell]
                        : sell === 'FIXED_DISCOUNT'
                        ? elem.price
                        : sell === 'MIN_MARKUP'
                        ? elem.price + (elem.price * selectedPrice[sell]) / 100
                        : selectedPrice[sell],
                serviceRowDiscount: sell === 'FIXED_DISCOUNT' ? selectedPrice[sell] : elem.serviceRowDiscount || 0,
                comment: elem.comment || {
                    comment: undefined,
                    positions: [],
                    problems: []
                },
                startTime: elem.startTime ? String(dayjs(elem.startTime).format('YYYY-MM-DD HH:mm')) : null,
                endTime: elem.endTime ? String(dayjs(elem.endTime).format('YYYY-MM-DD HH:mm')) : null
            }))
        };

        setLoading(true);

        const { status } = await fetchAPI('GET', 'orders/status', { orderId }, null);
        if (status === 'success') {
            window.location.reload();

            return;
        }
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${orderId}`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            updateDataSource();
        } catch (error) {
            console.error('ERROR:', error);
            updateDataSource();
        }

        handleClose();
        setLoading(false);
    }, [handleClose, inputs, labor, open, orderId, primeCost, sell, updateDataSource]);

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{
                loading
            }}
            onCancel={() => handleClose()}
            onOk={() => handleOk()}
            open={open}
            title={
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {formatMessage({ id: 'update_price_labors' })}
                </div>
            }
            width='fit-content'
        >
            <Flex justify='space-between'>
                <Space direction='vertical'>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>
                        <FormattedMessage id='order_form_table.prime_cost' />
                    </p>
                    <Radio.Group onChange={onChangePrime} value={primeCost}>
                        <Space direction='vertical'>
                            <Radio value='BY_COEFFICIENT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.by_coefficient' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            // formatter={value => numeralFormatter(value)}
                                            decimalSeparator=','
                                            min={0}
                                            onClick={() => setPrimeCost('BY_COEFFICIENT')}
                                            step={0.002}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.byCoefficient}
                                            onChange={handleInputsChange('byCoefficient')}
                                            // parser={value => numeralParser(value)}
                                            precision={4}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='FIXED'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.fixed_price' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            decimalSeparator=','
                                            min={0}
                                            onClick={() => setPrimeCost('FIXED')}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.fixedPrice}
                                            addonAfter={getCurrency()}
                                            // formatter={value => numeralFormatter(value)}
                                            onChange={handleInputsChange('fixedPrice')}
                                            precision={2}
                                            // parser={value => numeralParser(value)}
                                            step={5}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Space>
                <Space direction='vertical'>
                    <p style={{ fontSize: 16, fontWeight: 700 }}>
                        <FormattedMessage id='order_form_table.purchase_price' />
                    </p>
                    <Radio.Group onChange={onChangeSell} value={sell}>
                        <Space direction='vertical'>
                            <Radio value='BY_COEFFICIENT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.by_coefficient' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            ref={inputRef}
                                            autoFocus
                                            min={0}
                                            onClick={() => setSell('BY_COEFFICIENT')}
                                            precision={4}
                                            showAction={['focus', 'click']}
                                            step={0.002}
                                            decimalSeparator=','
                                            // formatter={value => numeralFormatter(value)}
                                            onChange={handleInputsChange('bySellCoefficient')}
                                            style={{ width: 160, marginLeft: 10 }}
                                            // parser={value => numeralParser(value)}

                                            value={inputs.bySellCoefficient}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='FIXED'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.fixed_price' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            min={0}
                                            onClick={() => setSell('FIXED')}
                                            step={5}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.bySellFixedPrice}
                                            addonAfter={getCurrency()}
                                            // formatter={value => numeralFormatter(value)}
                                            onChange={handleInputsChange('bySellFixedPrice')}
                                            decimalSeparator=','
                                            // parser={value => numeralParser(value)}
                                            precision={2}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='FIXED_DISCOUNT'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.fixed_discount' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter='%'
                                            decimalSeparator=','
                                            max={100}
                                            min={0}
                                            onChange={handleInputsChange('fixedDiscount')}
                                            onClick={() => setSell('FIXED_DISCOUNT')}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.fixedDiscount}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                            <Radio value='LABOR_CARD'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.from_labor_card' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter={getCurrency()}
                                            decimalSeparator=','
                                            onClick={() => setSell('LABOR_CARD')}
                                            // formatter={value => numeralFormatter(value)}
                                            // parser={value => numeralParser(value)}
                                            disabled
                                            precision={2}
                                            step={0.1}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={get(labor, 'laborPrice.price', undefined)}
                                        />
                                    </div>
                                </Flex>
                            </Radio>

                            <Radio value='MIN_MARKUP'>
                                <Flex align='center' className={Styles.radioComponent} justify='space-between'>
                                    <FormattedMessage id='order_form_table.minimal_markup' />{' '}
                                    <div className={Styles.detailNumberInput}>
                                        <InputNumber
                                            addonAfter='%'
                                            decimalSeparator=','
                                            max={100}
                                            min={0}
                                            onChange={handleInputsChange('minMarkup')}
                                            onClick={() => setSell('MIN_MARKUP')}
                                            style={{ width: 160, marginLeft: 10 }}
                                            value={inputs.minMarkup}
                                        />
                                    </div>
                                </Flex>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Space>
            </Flex>
        </Modal>
    );
};

export default injectIntl(UpdateLaborsPriceModal);
