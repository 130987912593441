import { Button, Input, Select, Switch, Table } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const CAPITAL_LETTERS = 'CAPITAL_LETTERS';
const NUMBERS = 'NUMBERS';
const LOWER_CASE_LETTERS = 'LOWER_CASE_LETTERS';
const WAREHOUSE = 'WAREHOUSE';
const ZONE = 'ZONE';
const PLOT = 'PLOT';
const ROW = 'ROW';
const SECTION = 'SECTION';
const SHELF = 'SHELF';
const CELL = 'CELL';
const TYPES = [WAREHOUSE, ZONE, PLOT, ROW, SECTION, SHELF, CELL];

export default class WMSGenerateCells extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generateSettings: [],
            warehouseId: undefined
        };

        const { isDsabledCRUD } = this.props;

        this.columns = () => [
            {
                key: 'type',
                dataIndex: 'type',
                width: '20%',
                render: (data, row) => {
                    return <FormattedMessage id={`wms.${data}`} />;
                }
            },
            {
                title: <FormattedMessage id='wms.active' />,
                key: 'active',
                dataIndex: 'active',
                width: '10%',
                render: (data, row) => {
                    return (
                        <Switch
                            checked={data}
                            disabled={isDsabledCRUD}
                            onChange={value => {
                                row.active = value;
                                this.setState({});
                            }}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='wms.alias' />,
                key: 'aliasType',
                dataIndex: 'aliasType',
                width: '20%',
                render: (data, row) => {
                    return (
                        row.active && (
                            <Select
                                disabled={isDsabledCRUD}
                                onChange={(value, { props }) => {
                                    row.aliasType = value;
                                    row.minValue = props.min;
                                    row.maxValue = props.max;
                                    this.setState({});
                                }}
                                value={data}
                            >
                                <Option max='C' min='A' value={CAPITAL_LETTERS}>
                                    A, B, C
                                </Option>
                                <Option max='3' min='1' value={NUMBERS}>
                                    1, 2, 3
                                </Option>
                                <Option max='c' min='a' value={LOWER_CASE_LETTERS}>
                                    a, b, c
                                </Option>
                            </Select>
                        )
                    );
                }
            },
            {
                title: <FormattedMessage id='wms.add_zero' />,
                key: 'addZeros',
                dataIndex: 'addZeros',
                width: '12%',
                render: (data, row) => {
                    return (
                        row.active && (
                            <Switch
                                checked={data}
                                disabled={isDsabledCRUD}
                                onChange={value => {
                                    row.addZeros = value;
                                    this.setState({});
                                }}
                            />
                        )
                    );
                }
            },
            {
                title: <FormattedMessage id='wms.min' />,
                key: 'minValue',
                dataIndex: 'minValue',
                width: '19%',
                render: (data, row) => {
                    return (
                        row.active && (
                            <Input
                                disabled={isDsabledCRUD}
                                maxLength={2}
                                onChange={event => {
                                    let { value } = event.target;
                                    switch (row.aliasType) {
                                        case CAPITAL_LETTERS:
                                            value = value.replace(/[^A-Za-z]/g, '').toUpperCase();
                                            break;
                                        case LOWER_CASE_LETTERS:
                                            value = value.replace(/[^A-Za-z]/g, '').toLowerCase();
                                            break;
                                        case NUMBERS:
                                            value = value.replace(/[^0-9]/g, '');
                                            break;
                                    }
                                    if (value && value.length == 2) {
                                        value = value[1];
                                        row.minValue = value;
                                        this.setState({});
                                    }
                                }}
                                value={data}
                            />
                        )
                    );
                }
            },
            {
                title: <FormattedMessage id='wms.max' />,
                key: 'maxValue',
                dataIndex: 'maxValue',
                width: '19%',
                render: (data, row) => {
                    return (
                        row.active && (
                            <Input
                                disabled={isDsabledCRUD}
                                onChange={event => {
                                    let { value } = event.target;
                                    switch (row.aliasType) {
                                        case CAPITAL_LETTERS:
                                            value = value.replace(/[^A-Za-z]/g, '').toUpperCase();
                                            break;
                                        case LOWER_CASE_LETTERS:
                                            value = value.replace(/[^A-Za-z]/g, '').toLowerCase();
                                            break;
                                        case NUMBERS:
                                            value = value.replace(/[^0-9]/g, '');
                                            break;
                                    }
                                    row.maxValue = value;
                                    this.setState({});
                                }}
                                value={data}
                            />
                        )
                    );
                }
            }
        ];
    }

    _generateAddresses = async () => {
        const { warehouseId, fetchCells } = this.props;
        const { generateSettings } = this.state;
        const payload = generateSettings.filter(row => row.active);
        payload.map(elem => {
            delete elem.active;
        });
        await fetchAPI('POST', 'wms/generate_cells', { warehouseId }, payload);
        fetchCells();
    };

    componentDidMount() {
        const { generateSettings: propsSettings } = this.props;
        const generateSettings = TYPES.map(type => ({
            type,
            active: false,
            aliasType: CAPITAL_LETTERS,
            addZeros: false,
            minValue: 'A',
            maxValue: 'C'
        }));
        propsSettings.map(propSetting => {
            generateSettings.map((setting, key) => {
                if (propSetting.type == setting.type) {
                    generateSettings[key] = {
                        type: propSetting.type,
                        active: true,
                        aliasType: propSetting.aliasType,
                        addZeros: propSetting.addZeros,
                        minValue: propSetting.minValue,
                        maxValue: propSetting.maxValue
                    };
                }
            });
        });
        this.setState({ generateSettings });
    }

    componentDidUpdate(prevProps) {}

    render() {
        const { warehouseId, isDsabledCRUD } = this.props;
        const { generateSettings } = this.state;
        let isActive = false;
        generateSettings.map(({ active }) => {
            isActive = isActive || active;
        });

        return (
            <div>
                {warehouseId ? (
                    <React.Fragment>
                        <div className={Styles.tabTitle}>
                            <FormattedMessage id='wms.address_system_settings' />
                        </div>
                        <Table
                            bordered
                            columns={this.columns()}
                            dataSource={generateSettings}
                            pagination={false}
                            rowKey='type'
                            size='small'
                        />
                        <div className={Styles.tabFooter}>
                            <Button
                                disabled={!isActive || isDsabledCRUD}
                                onClick={this._generateAddresses}
                                type='primary'
                            >
                                <FormattedMessage id='wms.generate_cells' />
                            </Button>
                        </div>
                    </React.Fragment>
                ) : (
                    <div className={`${Styles.tabTitle} ${Styles.selectWarehouse}`}>
                        <FormattedMessage id='wms.select_warehose' />
                    </div>
                )}
            </div>
        );
    }
}
