import { Button, Popover } from 'antd';
import { Spinner } from 'commons';
import { createOrderForClient, fetchClient, selectClientFetching } from 'core/client/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { fetchVehicleTypes } from 'core/vehicleTypes/duck';
import { VehicleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ClientContainer, Layout } from 'tireFitting';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    clientEntity: state.client.clientEntity,
    clientFetching: selectClientFetching(state),
    vehicleTypes: state.vehicleTypes.vehicleTypes
});

const mapDispatchToProps = {
    fetchClient,
    createOrderForClient,
    setModal,
    fetchVehicleTypes
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientPage extends Component {
    componentDidMount() {
        this.props.fetchClient(this.props.match.params.id);
        this.props.fetchVehicleTypes();
    }

    /**
     * When we want to create a new order for this client
     */
    onCreateOrderForClient = () => {
        const { createOrderForClient, clientEntity, user } = this.props;

        createOrderForClient({
            clientId: clientEntity.clientId,
            managerId: user.id
        });
    };

    /**
     * Open modal to add a new vehicle for current client
     */
    onAddVehicle = () => {
        const { clientEntity } = this.props;

        this.props.setModal(MODALS.VEHICLE, {
            mode: 'ADD',
            clientId: clientEntity.clientId,
            autoRedirect: false,
            onSubmit: () => window.location.reload()
        });
    };

    render() {
        const {
            clientFetching,
            clientEntity,
            match,
            location,
            fetchClient,
            user,
            vehicleTypes,
            isMobile
        } = this.props;
        const specificTab = location && location.state ? location.state.specificTab : undefined;

        return clientFetching ? (
            <Spinner spin={clientFetching} />
        ) : (
            <Layout
                controls={
                    <div>
                        <span className={Styles.controlButton}>
                            <Popover
                                content={
                                    <FormattedMessage id='client_page.hint_create_order_with_client' />
                                }
                            >
                                <Button
                                    disabled={
                                        clientFetching ||
                                        isForbidden(user, permissions.CREATE_ORDER)
                                    }
                                    onClick={this.onCreateOrderForClient}
                                >
                                    <FormattedMessage id='client_page.create_order' />
                                </Button>
                            </Popover>
                        </span>
                        {!isMobile && (
                            <span className={Styles.controlButton}>
                                <Popover
                                    content={
                                        <FormattedMessage id='vehicle_page.hint_add_vehicle_modal' />
                                    }
                                >
                                    <Button
                                        disabled={
                                            clientFetching ||
                                            isForbidden(
                                                user,
                                                permissions.CREATE_EDIT_DELETE_CLIENTS
                                            )
                                        }
                                        onClick={this.onAddVehicle}
                                        type='primary'
                                    >
                                        <FormattedMessage id='vehicle_page.hint_add_vehicle_modal' />
                                    </Button>
                                </Popover>
                            </span>
                        )}
                    </div>
                }
                title={<FormattedMessage id='client_page.title' />}
            >
                <ClientContainer
                    clientEntity={clientEntity}
                    clientId={match.params.id}
                    fetchClient={fetchClient}
                    onAddVehicle={this.onAddVehicle}
                    specificTab={specificTab}
                    vehicleTypes={vehicleTypes}
                />

                <VehicleModal />
            </Layout>
        );
    }
}
