import { InfoCircleOutlined, RedoOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, InputNumber, Select, Skeleton, Table, Tabs, notification } from 'antd';
import { Catcher, Layout, Spinner } from 'commons';
import { CrossesTable, DateRangePicker, TrackingTable, WarehouseSelect } from 'components';
import { setModal } from 'core/modals/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import { fetchProduct } from 'core/storage/products';
import { selectTrackingFilters, selectTrackingLoading, setTrackingFilters } from 'core/storage/tracking';
import { fetchSuppliers } from 'core/suppliers/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import { StoreProductForm } from 'forms';
import _, { omit } from 'lodash';
import { StoreProductModal, WMSCellsModal } from 'modals';
import { CatalogTab, ComplexesTab, MediaTab, OrderTab, SuppliersTab } from 'pages/ProductPage/components/Tabs';
import TechInfoTab from 'pages/ProductPage/components/Tabs/TechInfoTab';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import history from 'store/history';
import styled from 'styled-components';
import { fetchAPI, getCurrency, numeralFormatter } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import RecalculatePriceModal from './components/RecalculatePriceModal';
import Styles from './styles.m.css';

const { TabPane } = Tabs;
const { Option } = Select;

const mapStateToProps = state => ({
    suppliers: state.suppliers.suppliers,
    warehouses: state.warehouses.warehouses,
    priceGroups: selectPriceGroups(state),
    filters: selectTrackingFilters(state),
    loading: selectTrackingLoading(state),
    user: state.auth
});

const mapDispatchToProps = {
    fetchSuppliers,
    fetchWarehouses,
    fetchPriceGroups,
    setModal,
    setTrackingFilters,
    fetchProduct
};

const DataRow = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 12px;
`;

const DataWrapper = styled.div`
    display: flex;
    font-size: 20px;
    &:hover {
        cursor: pointer;
    }
`;

const Highlighted = styled.span`
    color: var(--secondary);
    font-weight: 700;
    font-size: 20px;
`;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: undefined,
            movementData: [],
            activeKey: 'general',
            warehouseId: undefined,
            startDate: undefined,
            endDate: undefined,
            cells: [],
            productCells: [],
            totalCells: undefined,
            selectedCell: undefined,
            total: undefined,
            movementPage: 1,
            movementPageSize: 25,
            pageSize: 5000,
            page: 1,
            isRound: false,
            markups: [],
            recalculateModalOpen: false,
            currencyList: []
        };

        this.debounceSavePrice = _.debounce(() => {
            this._updateProduct();
        }, 1000);

        this.cellTabColumns = [
            {
                title: <FormattedMessage id='navigation.storage' />,
                key: 'warehouse',
                dataIndex: 'warehouse',
                render: (data, row) => {
                    return row.name;
                }
            },
            {
                title: <FormattedMessage id='wms.cell' />,
                key: 'address',
                dataIndex: 'wmsCellOptions',
                render: (data, row) => {
                    return row.address;
                }
            },
            {
                title: <FormattedMessage id='count' />,
                key: 'sum',
                dataIndex: 'sum',
                render: (data, row) => {
                    return row.count;
                }
            },
            {
                title: <FormattedMessage id='wms.fullness' />,
                key: 'fullness',
                dataIndex: 'fullness',
                render: (data, row) => {
                    return row.fullness;
                }
            },
            {
                key: 'action',
                dataIndex: 'address',
                render: (data, row) => {
                    return (
                        <Button
                            disabled={
                                _.get(row, 'attribute') === 'RESERVE' ||
                                _.get(row, 'attribute') === 'STORAGE' ||
                                _.get(row, 'directSales') === false ||
                                !isGrantAccessed(
                                    this.props.user,
                                    grants.DIRECTORIES_PRODUCTS_LIST_AVAILABILITY,
                                    accesses.ROWO
                                )
                            }
                            onClick={() => {
                                this.setState({
                                    selectedCell: row
                                });
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='wms.transfer' />
                        </Button>
                    );
                }
            }
        ];
    }

    _fetchProductMovement = async () => {
        const { id } = this.props;
        const { product, warehouseId, startDate, endDate, movementPage, movementPageSize, movementStats } = this.state;

        const productMovement = await fetchAPI(
            'GET',
            'store_doc_products',
            {
                productId: id,
                warehouseId,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
                showOnlyOrders: false,
                showOnlyReserves: false,
                page: movementPage,
                pageSize: movementPageSize
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        this.setState({
            movementData: productMovement.list,
            movementStats: productMovement.stats
        });
    };

    _fetchProductCells = async () => {
        let warehousesData = [];

        const warehouses = await fetchAPI('GET', 'warehouses');
        warehouses.map(({ id, name, attribute }) => {
            warehousesData.push({
                id,
                name,
                count: 0,
                attribute,
                childs: [
                    {
                        count: 0
                    }
                ]
            });
        });

        const productWarehouses = await fetchAPI('GET', `store_products/${this.props.id}/warehouses`, {
            keeping: true
        });

        for (const [key, value] of Object.entries(productWarehouses)) {
            console.log(key, value, 'val');
            const index = warehousesData.findIndex(({ id }) => id == key);
            warehousesData[index].count = Number(value ? value.availableQuantity : 0).toFixed(2);
            warehousesData[index].childs[0].count = Number(value ? value.availableQuantity : 0).toFixed(2);
        }

        const payload = await fetchAPI('GET', 'wms/cells/statuses', {
            storeProductId: this.props.id
        });

        payload.list.map(elem => {
            if (elem.warehouse.id) {
                const index = warehousesData.findIndex(({ id }) => id == elem.warehouse.id);
                warehousesData[index].count = Number(warehousesData[index].count) + Number(elem.sum);
                warehousesData[index].childs.push({
                    cellAddress: elem.wmsCellOptions.address,
                    count: Number(elem.sum),
                    fullness: elem.fullness,
                    attribute: elem.attribute
                });
            }
        });

        warehousesData = warehousesData.filter(({ count, attribute }) => Number(count) > 0 || attribute == 'RESERVE');

        let productCells = [];

        _.map(warehousesData, data => {
            if (_.get(data, 'childs')) {
                _.map(data.childs, (child, index) => {
                    productCells.push({
                        warehouseId: _.get(data, 'id'),
                        attribute: _.get(data, 'attribute'),
                        name: _.get(data, 'name'),
                        noCell: index == 0,
                        count: child.count,
                        address: child.cellAddress,
                        fullness: child.fullness
                    });
                });
            }
        });

        productCells = productCells.filter(cell => cell.count > 0 || cell.attribute == 'RESERVE');

        const totalCountWithoutReserved = _.sumBy(productCells, cell => {
            return cell.attribute == 'RESERVE' ? 0 : cell.count;
        });

        const totalCount = _.sumBy(productCells, cell => cell.count);

        // if has reserved then show reserved as 0 but if total count equal to zero do not show anything
        if (!totalCount) {
            productCells = [];
        }

        this.setState({
            productCells,
            totalCells: Number(totalCount).toFixed(2),
            totalReservedCells: Number(totalCountWithoutReserved).toFixed(2)
        });
    };

    _fetchProduct = async cb => {
        const { id } = this.props;
        const product = await fetchAPI('GET', `store_products/${id}?showCountsAvailable=true`);
        product.saveOnStock = Boolean(product.saveOnStock);
        product.multiplicity = product.multiplicity || 1;
        product.min = product.min || 1;
        product.max = product.max || 1;
        product.isRound = Boolean(product.isRound);
        product.decimalPlaces = product.decimalPlaces || 0;
        this.setState({
            product
        });
        if (cb) cb();
    };

    _fetchProductStats = async () => {
        const { id } = this.props;
        const productStats = await fetchAPI('GET', `store_doc_products/balance?productId=${id}`, null, {
            handleErrorInternally: true
        });

        this.setState({ total: productStats.total });
    };

    _updateProduct = async () => {
        const { product } = this.state;

        const isAccessedSource = isGrantAccessed(
            this.props.user,
            grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD_SOURCE,
            accesses.ROWO
        );

        const isAccessedCurrency = isGrantAccessed(
            this.props.user,
            grants.DIRECTORIES_PRODUCTS_LIST_PRICES_CURRENCY_PRICES,
            accesses.ROWO
        );

        const omittedProduct =
            !isAccessedSource && !isAccessedCurrency
                ? omit(product, ['source', 'purchasePriceInCurrency', 'sellingCurrencyId', 'sellingPriceInCurrency'])
                : !isAccessedSource
                ? omit(product, ['source'])
                : !isAccessedCurrency
                ? omit(product, ['purchasePriceInCurrency', 'sellingCurrencyId', 'sellingPriceInCurrency'])
                : product;

        await fetchAPI(
            'PUT',
            `store_products/${product.id}`,
            null,
            _.pick(omittedProduct, [
                'productUnitId',
                'source',
                'multiplicity',
                'min',
                'max',
                'saveOnStock',
                'defaultBusinessSupplierId',
                'defaultWarehouseId',
                'fixedSellingPrice',
                'minSellingPrice',
                'fixedPurchasePrice',
                'getSellingPriceFrom',
                'getPurchasePriceFrom',
                'priceGroupNumber',
                'width',
                'height',
                'depth',
                'weight',
                'cellAddresses',
                'isRound',
                'decimalPlaces',
                'markupGroupId',
                'purchaseCurrencyId',
                'sellingCurrencyId',
                'purchasePriceInCurrency',
                'sellingPriceInCurrency'
            ]),
            {
                handleErrorInternally: true
            }
        );

        notification.success({
            message: this.props.intl.formatMessage({
                id: 'barcode.success'
            }),
            duration: 1
        });

        await this._fetchProduct();
    };

    _fetchWMSCells = async () => {
        const { product, page, pageSize, filterQuery } = this.state;
        if (product.defaultWarehouseId) {
            const cells = await fetchAPI('GET', 'wms/cells', {
                page,
                pageSize,
                filterQuery,
                warehouseId: product.defaultWarehouseId
            });

            this.setState({
                cells: cells.list
            });
        }
    };

    fetchUnits = async () => {
        const units = await fetchAPI('GET', 'business/measurement/units', undefined, undefined);
        this.setState({ units });
    };

    fetchMarkups = async () => {
        const response = await fetchAPI('GET', '/markups', null, null, {
            handleErrorInternally: true
        });

        this.setState({
            markups: response
        });
    };

    fetchCurrencyList = async () => {
        const response = await fetchAPI('GET', '/business/currency/list', null, null, {
            handleErrorInternally: true
        });

        this.setState({
            currencyList: response
        });
    };

    componentDidMount = async () => {
        const cb = () => {
            this._fetchProductStats();
            this._fetchProductMovement();
            this._fetchProductCells();
            this._fetchWMSCells();
        };
        this.props.fetchWarehouses();
        this.props.fetchSuppliers();
        this.props.fetchPriceGroups();
        this.props.setTrackingFilters({
            productId: this.props.id,
            showOnlyReserves: true,
            page: 1
        });
        this._fetchProduct(cb);
        this.fetchUnits();
        this.fetchMarkups();
        this.fetchCurrencyList();
    };

    setDaterange = daterange => {
        const [startDate, endDate] = daterange;

        this.props.setTrackingFilters({ startDate, endDate });
    };

    handleRecalculateClose = () => {
        this.setState({ recalculateModalOpen: false });
    };

    handleRecalculateOpen = () => {
        this.setState({ recalculateModalOpen: true });
    };

    render() {
        const SkeletonLoader = <Skeleton active paragraph={{ rows: 1, width: 100 }} title={false} />;

        const renderTotalData = (label, data) => (
            <div>
                <FormattedMessage id={`storage.${label}`} />
                :&nbsp;<Highlighted>{numeralFormatter(data)}</Highlighted>
            </div>
        );

        const {
            intl: { formatMessage },
            user,
            id,
            warehouses,
            suppliers,
            location
        } = this.props;
        const {
            product,
            activeKey,
            movementData,
            startDate,
            endDate,
            cells,
            productCells,
            selectedCell,
            movementPage,
            movementPageSize,
            movementStats,
            units,
            markups,
            recalculateModalOpen,
            currencyList
        } = this.state;

        const pagination = {
            pageSize: movementPageSize,
            size: 'small',
            total: Math.ceil(_.get(movementStats, 'count') / movementPageSize) * movementPageSize,
            current: movementPage,
            onChange: async movementPage => {
                await this.setState({
                    movementPage,
                    movementPageSize
                });
                this._fetchProductMovement();
            }
        };

        const pricesDisabled = !isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_PRICES, accesses.ROWO);

        const specificTab = location && location.state ? location.state.specificTab : undefined;

        const markupForGroup = _.get(product, 'group.markupGroupId');

        const defaultMarkupForProducts = markups
            ? markups.find(({ defaultByProducts }) => defaultByProducts)
            : undefined;

        console.log(productCells, 'productCells');

        return !product ? (
            <Spinner spin />
        ) : (
            <Layout
                controls={
                    <div>
                        <Button
                            onClick={() => {
                                history.push({ pathname: `${book.products}` });
                            }}
                        >
                            <RollbackOutlined />
                        </Button>
                    </div>
                }
                description={product.name}
                title={`${formatMessage({ id: 'navigation.product' })} ${product.code}`}
            >
                <Catcher>
                    <Tabs
                        activeKey={activeKey}
                        defaultActiveKey={specificTab || 'general'}
                        onChange={key => {
                            this.setState({
                                activeKey: key
                            });
                        }}
                    >
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD) && (
                            <TabPane
                                key='general'
                                disabled={!product.id}
                                tab={<FormattedMessage id='client_container.general_info' />}
                            >
                                <div className={Styles.generalInfoTab}>
                                    <StoreProductForm
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        editing
                                        markups={markups}
                                        modalProps={{
                                            id: product.id,
                                            onSubmit: this._fetchProduct,
                                            reload: true
                                        }}
                                        user={user}
                                    />
                                </div>
                            </TabPane>
                        )}

                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_PRICES) && (
                            <TabPane key='price' disabled={!product.id} tab={<FormattedMessage id='prices' />}>
                                <div className={Styles.pricesTab}>
                                    <div className={Styles.pricesBlock}>
                                        <div className={Styles.leftBlock}>
                                            <div className={Styles.pricesBlockRow}>
                                                <div className={Styles.pricesFirstRow}>
                                                    <Form.Item
                                                        disabled={pricesDisabled}
                                                        label={<FormattedMessage id='product_price.is_round' />}
                                                        style={{ marginRight: 8 }}
                                                        tooltip={{
                                                            title: this.props.intl.formatMessage({
                                                                id: 'product_price.save_requried'
                                                            }),
                                                            icon: <InfoCircleOutlined />
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={product.isRound}
                                                            disabled={pricesDisabled}
                                                            onChange={() => {
                                                                product.isRound = !product.isRound;

                                                                product.decimalPlaces = 0;

                                                                this.setState({});
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className={Styles.pricesFirstRow}>
                                                    <Form.Item
                                                        disabled
                                                        label={
                                                            <FormattedMessage id='product_price.number_characters' />
                                                        }
                                                    >
                                                        <InputNumber
                                                            className={Styles.pricesFieldElement}
                                                            decimalSeparator=','
                                                            disabled={!product.isRound || pricesDisabled}
                                                            min={0}
                                                            onChange={value => {
                                                                product.decimalPlaces = value;

                                                                this.setState({});
                                                            }}
                                                            precision={2}
                                                            style={{
                                                                color: '#000',
                                                                font: '900 1.2em "Arial", sans-serif'
                                                            }}
                                                            value={product.decimalPlaces || 0}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={Styles.rightBlock}></div>
                                    </div>
                                    <div className={Styles.pricesBlock}>
                                        <div className={Styles.leftBlock}>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.salePriceField}>
                                                    <FormattedMessage id='product.sale_price' />
                                                </span>
                                                <div className={Styles.pricesFieldDoubleRow}>
                                                    <div className={Styles.pricesFirstRow}>
                                                        <InputNumber
                                                            addonAfter={
                                                                <span className={Styles.pricesFieldCur}>
                                                                    {getCurrency()}
                                                                </span>
                                                            }
                                                            className={Styles.pricesFieldElement}
                                                            decimalSeparator=','
                                                            disabled
                                                            precision={2}
                                                            style={{
                                                                color: '#000',
                                                                font: '900 1.2em "Arial", sans-serif'
                                                            }}
                                                            value={product.sellingPrice || 0}
                                                        />
                                                    </div>
                                                    <div className={Styles.pricesMarkupMargeWrap}>
                                                        <div
                                                            className={Styles.pricesMarkupMarge}
                                                            style={{ marginRight: 8 }}
                                                        >
                                                            <span className={Styles.pricesFieldLabel}>
                                                                <FormattedMessage id='product.markup' />
                                                            </span>
                                                            <InputNumber
                                                                decimalSeparator=','
                                                                disabled
                                                                formatter={value => {
                                                                    return `${(value * 100).toFixed()}%`;
                                                                }}
                                                                precision={2}
                                                                style={{ color: 'var(--text)' }}
                                                                value={product.markup || 0}
                                                            />
                                                        </div>
                                                        <div className={Styles.pricesMarkupMarge}>
                                                            <span className={Styles.pricesFieldLabel}>
                                                                <FormattedMessage id='product.marge' />
                                                            </span>
                                                            <InputNumber
                                                                decimalSeparator=','
                                                                disabled
                                                                formatter={value => {
                                                                    return `${(value * 100).toFixed()}%`;
                                                                }}
                                                                precision={2}
                                                                style={{ color: 'var(--text)' }}
                                                                value={product.margin || 0}
                                                            />
                                                            <span className={Styles.pricesFieldCurHidden}>
                                                                {getCurrency()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.calculate_from' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <Select
                                                        className={Styles.pricesFieldElement}
                                                        disabled={pricesDisabled}
                                                        onChange={value => {
                                                            product.getSellingPriceFrom = value;
                                                            this.setState({});

                                                            if (value === 'STORE_GROUP') {
                                                                product.markupGroupId = markupForGroup;
                                                                this.setState({});
                                                            } else if (value === 'FIXED' || value === 'CLIENT') {
                                                                product.markupGroupId = null;
                                                                this.setState({});
                                                            } else {
                                                                product.markupGroupId = _.get(
                                                                    defaultMarkupForProducts,
                                                                    'id',
                                                                    1
                                                                );
                                                            }
                                                        }}
                                                        value={product.getSellingPriceFrom}
                                                    >
                                                        <Option key='FIXED' value='FIXED'>
                                                            <FormattedMessage id='storage.markup.PRICE_GROUP' />
                                                        </Option>
                                                        <Option key='PRICE_GROUP' value='PRICE_GROUP'>
                                                            <FormattedMessage id='storage.markup.FIXED_MARKUP' />
                                                        </Option>
                                                        <Option key='STORE_GROUP' value='STORE_GROUP'>
                                                            <FormattedMessage id='storage.markup.STORE_GROUP' />
                                                        </Option>
                                                        <Option key='CLIENT' value='CLIENT'>
                                                            <FormattedMessage id='storage.markup.CLIENT' />
                                                        </Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='new-document-page.item.markup-group' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <Select
                                                        className={Styles.pricesFieldElement}
                                                        disabled={
                                                            product.getSellingPriceFrom !== 'PRICE_GROUP' ||
                                                            pricesDisabled
                                                        }
                                                        onChange={value => {
                                                            product.markupGroupId = value;
                                                            this.setState({});
                                                        }}
                                                        onSelect={() => this._updateProduct()}
                                                        optionFilterProp='children'
                                                        showSearch
                                                        value={product.markupGroupId}
                                                    >
                                                        {markups
                                                            .filter(
                                                                ({ enabled, using }) =>
                                                                    enabled && using !== 'CUSTOMER_GROUP'
                                                            )
                                                            .map(markup => {
                                                                return (
                                                                    <Option key={markup.using} value={markup.id}>
                                                                        {markup.name}{' '}
                                                                        {
                                                                            <FormattedMessage
                                                                                id={`product_markups.${markup.using}`}
                                                                            />
                                                                        }{' '}
                                                                        (
                                                                        {markup.markups[0]
                                                                            ? markup.markups[0].lowerLimit
                                                                            : undefined}{' '}
                                                                        -{' '}
                                                                        {markup.markups[0]
                                                                            ? markup.markups[0].markup
                                                                            : undefined}
                                                                        ;{' '}
                                                                        {markup.markups[1]
                                                                            ? markup.markups[1].lowerLimit
                                                                            : undefined}{' '}
                                                                        -{' '}
                                                                        {markup.markups[1]
                                                                            ? markup.markups[1].markup
                                                                            : undefined}
                                                                        ;
                                                                        {markup.markups[2]
                                                                            ? markup.markups[2].lowerLimit
                                                                            : undefined}{' '}
                                                                        -{' '}
                                                                        {markup.markups[2]
                                                                            ? markup.markups[2].markup
                                                                            : undefined}
                                                                        ;{markup.markups[3] ? '...' : ''})
                                                                    </Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.fixed_sale_price' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <InputNumber
                                                        addonAfter={
                                                            <span className={Styles.pricesFieldCur}>
                                                                {getCurrency()}
                                                            </span>
                                                        }
                                                        className={Styles.pricesFieldElement}
                                                        decimalSeparator=','
                                                        disabled={
                                                            product.getSellingPriceFrom !== 'FIXED' || pricesDisabled
                                                        }
                                                        min={0}
                                                        min={0}
                                                        onChange={value => {
                                                            product.fixedSellingPrice = value;
                                                            this.setState({});

                                                            this.debounceSavePrice();
                                                        }}
                                                        precision={2}
                                                        value={product.fixedSellingPrice || 0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={Styles.rightBlock}>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.currency_sale_price' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <InputNumber
                                                        // addonAfter={<span className={Styles.pricesFieldCur}>{getCurrency()}</span>}
                                                        className={Styles.pricesFieldElement}
                                                        decimalSeparator=','
                                                        disabled={
                                                            pricesDisabled ||
                                                            product.getSellingPriceFrom !== 'FIXED' ||
                                                            !isGrantAccessed(
                                                                user,
                                                                grants.DIRECTORIES_PRODUCTS_LIST_PRICES_CURRENCY_PRICES,
                                                                accesses.ROWO
                                                            )
                                                        }
                                                        min={0}
                                                        onChange={value => {
                                                            product.sellingPriceInCurrency = value;
                                                            this.setState({});
                                                        }}
                                                        precision={2}
                                                        value={product.sellingPriceInCurrency || 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.currency_sale_id' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <Select
                                                        className={Styles.pricesFieldElement}
                                                        disabled={
                                                            product.getSellingPriceFrom !== 'FIXED' || pricesDisabled
                                                        }
                                                        fieldNames={{ label: 'currencyCode', value: 'id' }}
                                                        onChange={value => {
                                                            product.sellingCurrencyId = value;
                                                            this.setState({});
                                                        }}
                                                        optionFilterProp='currencyCode'
                                                        options={currencyList}
                                                        showSearch
                                                        value={product.sellingCurrencyId}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Styles.pricesBlock}>
                                        <div className={Styles.leftBlock}>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.salePriceField}>
                                                    <FormattedMessage id='product.purchase_price' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <InputNumber
                                                        addonAfter={
                                                            <span className={Styles.pricesFieldCur}>
                                                                {getCurrency()}
                                                            </span>
                                                        }
                                                        className={Styles.pricesFieldElement}
                                                        decimalSeparator=','
                                                        disabled
                                                        precision={2}
                                                        style={{
                                                            color: '#000',
                                                            font: '900 1.2em "Arial", sans-serif'
                                                        }}
                                                        value={product.purchasePrice || 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.calculate_from' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <Select
                                                        className={Styles.pricesFieldElement}
                                                        disabled={pricesDisabled}
                                                        onChange={value => {
                                                            product.getPurchasePriceFrom = value;
                                                            this.setState({});
                                                        }}
                                                        value={product.getPurchasePriceFrom}
                                                    >
                                                        <Option key='LAST_INCOME' value='LAST_INCOME'>
                                                            <FormattedMessage id='product.last_purchase_price' />
                                                        </Option>
                                                        <Option key='FIXED' value='FIXED'>
                                                            <FormattedMessage id='product.fixed_purchase_price' />
                                                        </Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.last_purchase_price' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <InputNumber
                                                        addonAfter={
                                                            <span className={Styles.pricesFieldCur}>
                                                                {getCurrency()}
                                                            </span>
                                                        }
                                                        className={Styles.pricesFieldElement}
                                                        decimalSeparator=','
                                                        disabled
                                                        precision={2}
                                                        style={{ color: 'var(--text)' }}
                                                        value={product.lastPurchasePrice || 0}
                                                    />

                                                    {/* <Radio
                                                    checked={product.getPurchasePriceFrom == 'LAST_INCOME'}
                                                    className={Styles.pricesRadio}
                                                    onChange={() => {
                                                        product.getPurchasePriceFrom = 'LAST_INCOME';
                                                        this.setState({});
                                                    }}
                                                /> */}
                                                </div>
                                            </div>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.fixed_purchase_price' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <InputNumber
                                                        addonAfter={
                                                            <span className={Styles.pricesFieldCur}>
                                                                {getCurrency()}
                                                            </span>
                                                        }
                                                        className={Styles.pricesFieldElement}
                                                        decimalSeparator=','
                                                        disabled={pricesDisabled}
                                                        min={0}
                                                        onChange={value => {
                                                            product.fixedPurchasePrice = value;
                                                            this.setState({});
                                                        }}
                                                        precision={2}
                                                        value={product.fixedPurchasePrice || 0}
                                                    />

                                                    {/* <Radio
                                                    checked={product.getPurchasePriceFrom == 'FIXED'}
                                                    className={Styles.pricesRadio}
                                                    onChange={() => {
                                                        product.getPurchasePriceFrom = 'FIXED';
                                                        this.setState({});
                                                    }}
                                                /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={Styles.rightBlock}>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.currency_purchase_price' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <InputNumber
                                                        // addonAfter={<span className={Styles.pricesFieldCur}>{getCurrency()}</span>}
                                                        className={Styles.pricesFieldElement}
                                                        decimalSeparator=','
                                                        disabled={
                                                            pricesDisabled ||
                                                            product.getPurchasePriceFrom !== 'FIXED' ||
                                                            !isGrantAccessed(
                                                                user,
                                                                grants.DIRECTORIES_PRODUCTS_LIST_PRICES_CURRENCY_PRICES,
                                                                accesses.ROWO
                                                            )
                                                        }
                                                        min={0}
                                                        onChange={value => {
                                                            product.purchasePriceInCurrency = value;
                                                            this.setState({});
                                                        }}
                                                        precision={2}
                                                        value={product.purchasePriceInCurrency || 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className={Styles.pricesBlockRow}>
                                                <span className={Styles.pricesFieldLabel}>
                                                    <FormattedMessage id='product.currency_purchase_id' />
                                                </span>
                                                <div className={Styles.pricesField}>
                                                    <Select
                                                        className={Styles.pricesFieldElement}
                                                        disabled={
                                                            pricesDisabled ||
                                                            product.getPurchasePriceFrom !== 'FIXED' ||
                                                            !isGrantAccessed(
                                                                user,
                                                                grants.DIRECTORIES_CURRENCIES,
                                                                accesses.ROWO
                                                            )
                                                        }
                                                        fieldNames={{ label: 'currencyCode', value: 'id' }}
                                                        onChange={value => {
                                                            product.purchaseCurrencyId = value;
                                                            this.setState({});
                                                        }}
                                                        optionFilterProp='currencyCode'
                                                        options={currencyList}
                                                        showSearch
                                                        value={product.purchaseCurrencyId}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className={Styles.updateButtonContainer}>
                                            <Button
                                                disabled={
                                                    !(
                                                        product.getPurchasePriceFrom === 'FIXED' ||
                                                        product.getSellingPriceFrom === 'FIXED'
                                                    )
                                                }
                                                onClick={this.handleRecalculateOpen}
                                                type='primary'
                                            >
                                                <FormattedMessage id='update' />
                                            </Button>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className={Styles.saveButtonWrap}>
                                        <Button
                                            disabled={pricesDisabled}
                                            onClick={async () => {
                                                await this._updateProduct();
                                            }}
                                            type='primary'
                                        >
                                            <FormattedMessage id='save' />
                                        </Button>
                                        <span className={Styles.pricesFieldCurHidden}>{getCurrency()}</span>
                                    </div>
                                </div>
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_SUPPLIERS) && (
                            <TabPane
                                key='suppliers'
                                disabled={!product.id}
                                tab={<FormattedMessage id='navigation.suppliers' />}
                            >
                                <SuppliersTab
                                    supplierTabDisabled={
                                        !isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_PRODUCTS_LIST_SUPPLIERS,
                                            accesses.ROWO
                                        )
                                    }
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_PRODUCTION_DISASSEMBLY) && (
                            <TabPane
                                key='complexes'
                                disabled={
                                    _.get(product, 'source') === 'OWN' ||
                                    _.get(product, 'source') === null ||
                                    !product.id
                                }
                                tab={<FormattedMessage id='navigation.production_disassembly' />}
                            >
                                <ComplexesTab
                                    code={_.get(product, 'code')}
                                    id={_.get(product, 'id')}
                                    source={_.get(product, 'source')}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_AVAILABILITY) && (
                            <TabPane
                                key='cell'
                                disabled={!product.id}
                                tab={<FormattedMessage id='pricelists.availabilities' />}
                            >
                                <div className={Styles.cellsTab}>
                                    <div style={{ fontSize: '1.2em' }}>
                                        <div>
                                            <DataRow
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end'
                                                }}
                                            >
                                                <DataWrapper
                                                    onClick={() => {
                                                        this.setState({
                                                            activeKey: 'movement'
                                                        });
                                                    }}
                                                >
                                                    {!_.isEmpty(this.state.total)
                                                        ? renderTotalData(
                                                              'in_stock',
                                                              Number(this.state.total.countOnWarehouse).toFixed(2)
                                                          )
                                                        : SkeletonLoader}
                                                </DataWrapper>
                                                <DataWrapper
                                                    onClick={() => {
                                                        this.setState({
                                                            activeKey: 'reserve'
                                                        });
                                                    }}
                                                >
                                                    {!_.isEmpty(this.state.total)
                                                        ? renderTotalData(
                                                              'reserve',
                                                              Number(this.state.total.reserved).toFixed(2)
                                                          )
                                                        : SkeletonLoader}
                                                </DataWrapper>
                                                <DataWrapper
                                                    onClick={() => {
                                                        this.setState({
                                                            activeKey: 'movement'
                                                        });
                                                    }}
                                                >
                                                    {!_.isEmpty(this.state.total)
                                                        ? renderTotalData(
                                                              'keeping',
                                                              Number(this.state.total.keeping).toFixed(2)
                                                          )
                                                        : SkeletonLoader}
                                                </DataWrapper>
                                                <DataWrapper
                                                    onClick={() => {
                                                        this.setState({
                                                            activeKey: 'movement'
                                                        });
                                                    }}
                                                >
                                                    {!_.isEmpty(this.state.total)
                                                        ? renderTotalData(
                                                              'available',
                                                              Math.abs(
                                                                  this.state.total.remaining - this.state.total.reserved
                                                              ).toFixed(2)
                                                          )
                                                        : SkeletonLoader}
                                                </DataWrapper>
                                                <DataWrapper
                                                    onClick={() => {
                                                        this.setState({
                                                            activeKey: 'orders'
                                                        });
                                                    }}
                                                >
                                                    {!_.isEmpty(this.state.total)
                                                        ? renderTotalData(
                                                              'ordered',
                                                              Math.abs(this.state.total.countInStoreOrders).toFixed(2)
                                                          )
                                                        : SkeletonLoader}
                                                </DataWrapper>
                                                <Button
                                                    disabled={
                                                        !isGrantAccessed(
                                                            user,
                                                            grants.DIRECTORIES_PRODUCTS_LIST_AVAILABILITY,
                                                            accesses.ROWO
                                                        )
                                                    }
                                                    icon={<RedoOutlined />}
                                                    title={this.props.intl.formatMessage({
                                                        id: 'product.reset_cell'
                                                    })}
                                                    type='primary'
                                                ></Button>
                                            </DataRow>
                                        </div>
                                    </div>

                                    <Table
                                        bordered
                                        columns={this.cellTabColumns}
                                        dataSource={productCells}
                                        size='small'
                                    />
                                    <WMSCellsModal
                                        confirmAction={async (address, modalWarehouseId, count) => {
                                            await fetchAPI('POST', 'wms/cells/products', null, {
                                                fromWarehouseId: selectedCell.warehouseId,
                                                productId: product.id,
                                                fromCell: selectedCell.address,
                                                toCell: address,
                                                count,
                                                toWarehouseId: modalWarehouseId
                                            });
                                            await this._fetchProductCells();
                                        }}
                                        hideModal={() => {
                                            this.setState({ selectedCell: undefined });
                                        }}
                                        visible={Boolean(selectedCell)}
                                        warehouseId={product.defaultWarehouseId}
                                    />
                                </div>
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_WAREHOUSE_MOVEMENT) && (
                            <TabPane
                                key='movement'
                                disabled={!product.id}
                                tab={<FormattedMessage id='storage.movement' />}
                            >
                                <div className={Styles.movementFilters}>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_PRODUCTS_LIST_WAREHOUSE_MOVEMENT,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={async () => {
                                            await fetchAPI(
                                                'PUT',
                                                'store_docs/recalc_fifo',
                                                null,
                                                { productId: product.id },
                                                { handleErrorInternally: true }
                                            );
                                            notification.success({
                                                message: this.props.intl.formatMessage({
                                                    id: 'barcode.success'
                                                })
                                            });
                                            this._fetchProductMovement();
                                            this._fetchProduct();
                                            this.setState({
                                                activeKey: 'movement'
                                            });
                                        }}
                                        style={{ margin: '0 8px 0 0' }}
                                    >
                                        <FormattedMessage id='product.recalculate_fifo' />
                                    </Button>
                                    <WarehouseSelect
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_PRODUCTS_LIST_WAREHOUSE_MOVEMENT,
                                                accesses.ROWO
                                            )
                                        }
                                        onChange={async warehouseId => {
                                            await this.setState({ warehouseId });
                                            this._fetchProductMovement();
                                        }}
                                        style={{ margin: '0 8px 0 8px' }}
                                    />
                                    <DateRangePicker
                                        allowClear
                                        dateRange={[startDate, endDate]}
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_PRODUCTS_LIST_WAREHOUSE_MOVEMENT,
                                                accesses.ROWO
                                            )
                                        }
                                        minimize
                                        onDateChange={async daterange => {
                                            const [startDate, endDate] = daterange;
                                            await this.setState({
                                                startDate,
                                                endDate
                                            });
                                            this._fetchProductMovement();
                                        }}
                                        style={{ margin: '0 8px 0 0' }}
                                    />
                                </div>
                                <TrackingTable dataSource={movementData} hideCode pagination={pagination} rawData />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_RESERVED) && (
                            <TabPane
                                key='reserve'
                                disabled={!product.id}
                                tab={<FormattedMessage id='storage.reserve' />}
                            >
                                <div className={Styles.movementFilters}>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_PRODUCTS_LIST_RESERVED_REMOVE_ALL,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={async () => {
                                            const ids = await fetchAPI(
                                                'GET',
                                                `orders/details/ids?orderStatuses=[]&productIds=[${id}]&excludeOrderStatuses=["success", "cancel"]`
                                            );
                                            await fetchAPI('POST', 'store_docs/reserve_all_possible', null, {
                                                ordersAppurtenanciesIds: ids
                                            });
                                            this._fetchProduct();
                                            notification.success(formatMessage({ id: 'barcode.success' }));
                                            this.setState({
                                                activeKey: 'reserve'
                                            });
                                        }}
                                        style={{ margin: '0 8px 0 0' }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='details_table.reserve' />
                                    </Button>

                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_PRODUCTS_LIST_RESERVED_REMOVE_ALL,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={async () => {
                                            await fetchAPI('DELETE', `store_products/reserves?productIds=[${id}]`);
                                            this._fetchProduct();
                                            notification.success(formatMessage({ id: 'barcode.success' }));
                                            this.setState({
                                                activeKey: 'reserve'
                                            });
                                        }}
                                        style={{ margin: '0 8px 0 0' }}
                                        type='danger'
                                    >
                                        <FormattedMessage id='product_page.drop_reserves' />
                                    </Button>

                                    <WarehouseSelect
                                        onChange={warehouseId => this.props.setTrackingFilters({ warehouseId })}
                                        style={{ margin: '0 8px 0 8px' }}
                                    />
                                    <DateRangePicker
                                        allowClear
                                        dateRange={[this.props.filters.startDate, this.props.filters.endDate]}
                                        minimize
                                        onDateChange={this.setDaterange}
                                        style={{ margin: '0 8px 0 0' }}
                                    />
                                </div>
                                <TrackingTable
                                    filters={this.props.filters}
                                    loading={this.props.loading}
                                    tab='RESERVE'
                                    type='reserves'
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_ORDERS) && (
                            <TabPane key='orders' disabled={!product.id} tab={<FormattedMessage id='product.orders' />}>
                                <OrderTab
                                    fetchProductMovement={() => {
                                        this._fetchProductMovement();
                                        this._fetchProduct();
                                        this.setState({
                                            activeKey: 'orders'
                                        });
                                    }}
                                    isAccessed={isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_PRODUCTS_LIST_ORDERS,
                                        accesses.ROWO
                                    )}
                                    productId={product.id}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_STORAGE) && (
                            <TabPane
                                key='storage'
                                disabled={!product.id}
                                tab={<FormattedMessage id='navigation.storage_product' />}
                            >
                                <div className={Styles.storeTab}>
                                    <div className={Styles.storeTabRow}>
                                        <span className={Styles.storeFieldLabel}>
                                            <FormattedMessage id='storage.default_supplier' />
                                        </span>
                                        <Select
                                            allowClear
                                            className={Styles.storeField}
                                            onChange={async value => {
                                                product.defaultBusinessSupplierId = value || null;
                                                this.setState({});
                                            }}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({
                                                id: 'storage.default_supplier'
                                            })}
                                            showSearch
                                            value={product.defaultBusinessSupplierId || undefined}
                                        >
                                            {suppliers.map((elem, i) => {
                                                if (!elem.hide) {
                                                    return (
                                                        <Option key={i} value={elem.id}>
                                                            {elem.name}
                                                        </Option>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </div>
                                    <div className={Styles.storeTabRow}>
                                        <span className={Styles.storeFieldLabel}>
                                            <FormattedMessage id='storage.default_warehouse' />
                                        </span>
                                        <Select
                                            className={Styles.storeField}
                                            onChange={async value => {
                                                product.defaultWarehouseId = value;
                                                product.cellAddresses = undefined;
                                                await this.setState({});
                                                this._fetchWMSCells();
                                            }}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({
                                                id: 'storage.default_warehouse'
                                            })}
                                            showSearch
                                            value={product.defaultWarehouseId || undefined}
                                        >
                                            {warehouses.map((elem, i) => (
                                                <Option key={i} value={elem.id}>
                                                    {elem.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className={Styles.storeTabRow}>
                                        <span className={Styles.storeFieldLabel}>
                                            <FormattedMessage id='product.default_cell' />
                                        </span>
                                        <Select
                                            allowClear
                                            className={Styles.storeField}
                                            disabled={!product.defaultWarehouseId}
                                            onChange={value => {
                                                product.cellAddresses = [value] || null;
                                                this.setState({});
                                            }}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({ id: 'product.default_cell' })}
                                            showSearch
                                            value={product.cellAddresses}
                                        >
                                            {cells.map((elem, i) => {
                                                if (elem.enabled) {
                                                    return (
                                                        <Option key={i} value={elem.address}>
                                                            {elem.address}
                                                        </Option>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </div>
                                    <div className={Styles.storeTabRow}>
                                        <span className={Styles.storeFieldLabel}>
                                            <FormattedMessage id='storage_document.store_in_warehouse' />
                                        </span>
                                        <Checkbox
                                            checked={product.saveOnStock}
                                            onChange={() => {
                                                product.saveOnStock = !product.saveOnStock;
                                                this.setState({});
                                            }}
                                            style={{ marginLeft: 5 }}
                                        />
                                    </div>
                                    {product.saveOnStock && (
                                        <div className={Styles.storeTabRow}>
                                            <div>
                                                <span className={Styles.storeFieldLabel}>
                                                    <FormattedMessage id='storage_document.multiplicity' />
                                                </span>
                                                <InputNumber
                                                    decimalSeparator=','
                                                    min={1}
                                                    onChange={value => {
                                                        product.multiplicity = value;
                                                        this.setState({});
                                                    }}
                                                    step={1}
                                                    value={product.multiplicity}
                                                />
                                            </div>
                                            <div>
                                                <span className={Styles.storeFieldLabel}>
                                                    <FormattedMessage id='storage.min' />
                                                </span>
                                                <InputNumber
                                                    decimalSeparator=','
                                                    min={0}
                                                    onChange={value => {
                                                        product.min = Math.floor(value / product.multiplicity);
                                                        this.setState({});
                                                    }}
                                                    precision={0}
                                                    step={product.multiplicity}
                                                    value={product.min * product.multiplicity}
                                                />
                                            </div>
                                            <div>
                                                <span className={Styles.storeFieldLabel}>
                                                    <FormattedMessage id='storage.max' />
                                                </span>
                                                <InputNumber
                                                    decimalSeparator=','
                                                    min={product.min * product.multiplicity}
                                                    onChange={value => {
                                                        product.max = Math.floor(value / product.multiplicity);
                                                        this.setState({});
                                                    }}
                                                    precision={0}
                                                    step={product.multiplicity}
                                                    value={product.max * product.multiplicity}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className={Styles.storeTabRow}>
                                        <div>
                                            <div>
                                                <FormattedMessage id='wms.width' />
                                            </div>
                                            <InputNumber
                                                decimalSeparator=','
                                                min={0}
                                                onChange={value => {
                                                    product.width = value;
                                                    this.setState({});
                                                }}
                                                value={product.width}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <FormattedMessage id='wms.height' />
                                            </div>
                                            <InputNumber
                                                decimalSeparator=','
                                                min={0}
                                                onChange={value => {
                                                    product.height = value;
                                                    this.setState({});
                                                }}
                                                value={product.height}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <FormattedMessage id='wms.depth' />
                                            </div>
                                            <InputNumber
                                                decimalSeparator=','
                                                min={0}
                                                onChange={value => {
                                                    product.depth = value;
                                                    this.setState({});
                                                }}
                                                value={product.depth}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <FormattedMessage id='wms.weight' />
                                            </div>
                                            <InputNumber
                                                decimalSeparator=','
                                                min={0}
                                                onChange={value => {
                                                    product.weight = value;
                                                    this.setState({});
                                                }}
                                                value={product.weight}
                                            />
                                        </div>
                                    </div>
                                    <div className={Styles.saveButtonWrap}>
                                        <Button onClick={this._updateProduct} type='primary'>
                                            <FormattedMessage id='save' />
                                        </Button>
                                    </div>
                                </div>
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_TECHNICAL_INFORMATION) && (
                            <TabPane
                                key='tech_info'
                                disabled={!product.id}
                                tab={<FormattedMessage id='navigation.tech_info' />}
                            >
                                <TechInfoTab
                                    attributes={product.attributes || []}
                                    id={product.id}
                                    images={product.images || []}
                                    product={product}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_FILE_STORAGE) && (
                            <TabPane
                                key='media'
                                disabled={!product.id}
                                tab={<FormattedMessage id='settings_page.media' />}
                            >
                                <MediaTab
                                    attributes={product.attributes || []}
                                    id={product.id}
                                    images={product.images || []}
                                    product={product}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_TECHDOC_ANALOGS) && (
                            <TabPane
                                key='catalog'
                                disabled={!product.id}
                                tab={<FormattedMessage id='product.analogs' />}
                            >
                                <CatalogTab />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST_OWN_CROSSES) && (
                            <TabPane
                                key='crosses'
                                disabled={!product.id}
                                tab={<FormattedMessage id='product.crosses' />}
                            >
                                <CrossesTable brand={_.get(product.brand, 'id')} productCode={product.code} />
                            </TabPane>
                        )}
                    </Tabs>
                </Catcher>
                <StoreProductModal units={units} />
                <RecalculatePriceModal onCancel={this.handleRecalculateClose} open={recalculateModalOpen} />
            </Layout>
        );
    }
}
