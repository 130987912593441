import { Table } from 'antd';
import { closeShift, deleteCashbox, fetchCashboxes, fetchXReport, openShift } from 'core/cash/duck';
import { fetchBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { columnsConfig } from './config';

const mapStateToProps = state => ({
    cashboxes: state.cash.cashboxes,
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchCashboxes,
    deleteCashbox,
    openShift,
    closeShift,
    fetchXReport,
    fetchBusinessRequisites
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
/**
 * Table of cashboxes
 * @param props.cashboxesFetching - is fetching
 * @param props.cashboxes - array of cashboxes to show
 */
export class CashboxesTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchCashboxes();
        this.props.fetchBusinessRequisites();
    }

    render() {
        const {
            cashboxesFetching,
            cashboxes,
            deleteCashbox,
            isMobile,
            openEdit,
            user,
            intl: { formatMessage },
            setTableRow
        } = this.props;

        const columns = columnsConfig({
            fetchCashboxes: this.props.fetchCashboxes,
            deleteCashbox,
            formatMessage,
            isCRUDForbidden: !isGrantAccessed(user, grants.SETTINGS_CASH_REGISTERS, accesses.ROWO),
            isMobile,
            openEdit,
            setTableRow
        });

        return (
            <Table
                bordered
                columns={columns}
                dataSource={cashboxes}
                loading={cashboxesFetching}
                locale={{
                    emptyText: <FormattedMessage id='no_data' />
                }}
                pagination={false}
                rowKey='id'
                size='small'
            />
        );
    }
}
