import { QuestionCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Input, Select, Table, Tabs, Tooltip } from 'antd';
import { Catcher, Layout, Spinner } from 'commons';
import { DateRangePicker } from 'components';
import { fetchSuppliers } from 'core/suppliers/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import WMSAddressSettings from './WMSAddressSettings';
import WMSGenerateCells from './WMSGenerateCells';
import WMSStoragePlan from './WMSStoragePlan';
import Styles from './styles.m.css';

const { TabPane } = Tabs;
const { Option } = Select;

const mapStateToProps = state => ({
    suppliers: state.suppliers.suppliers,
    warehouses: state.warehouses.warehouses,
    user: state.auth
});

const mapDispatchToProps = {
    fetchSuppliers,
    fetchWarehouses
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class WMSPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cells: [],
            generateSettings: [],
            activeKey: 'plan',
            warehouseId: undefined,
            startDate: dayjs().startOf('year'),
            endDate: dayjs(),
            movementFilter: undefined,
            allLinks: [],
            helperDrawerOpen: false
        };

        this.columns = [
            {
                title: <FormattedMessage id='date' />,
                key: 'datetime',
                dataIndex: 'datetime',
                sorter: (a, b) =>
                    dayjs(a.datetime).format('DD/MM/YYYY').localeCompare(dayjs(b.datetime).format('DD/MM/YYYY')),
                render: (data, row) => {
                    return dayjs(data).format('DD/MM/YYYY');
                }
            },
            {
                title: <FormattedMessage id='wms.cell' />,
                key: 'address',
                dataIndex: 'address',
                sorter: (a, b) => String(a.address).localeCompare(String(b.address))
            },
            {
                title: <FormattedMessage id='order_form_table.product_code' />,
                key: 'code',
                dataIndex: 'code',
                sorter: (a, b) => String(a.code).localeCompare(String(b.code))
            },
            {
                title: <FormattedMessage id='brand' />,
                key: 'brandName',
                dataIndex: 'brandName',
                sorter: (a, b) => String(a.brandName).localeCompare(String(b.brandName))
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => String(a.name).localeCompare(String(b.name))
            },
            {
                title: <FormattedMessage id='count' />,
                key: 'count',
                dataIndex: 'count',
                sorter: (a, b) => a.count - b.count
            },
            {
                title: <FormattedMessage id='storage.document_number' />,
                key: 'storeDocId',
                dataIndex: 'storeDocId',
                render: (data, row) => {
                    return isGrantAccessed(this.props.user, grants.WAREHOUSE_DOCUMENT) ? (
                        <Link to={`${book.storageDocument}/${data}`}>{data}</Link>
                    ) : (
                        data
                    );
                }
            }
        ];
    }

    _fetchCells = async () => {
        const { warehouseId } = this.state;
        await this.setState({
            cells: undefined
        });
        const cells = await fetchAPI('GET', 'wms/cells', { warehouseId });
        const generateSettings = await fetchAPI('GET', 'wms/address_options', { warehouseId });
        if (cells && cells.stats.count == 0) {
            this.state.activeKey = 'generate';
        }
        await this.setState({
            cells: cells.list,
            generateSettings
        });
    };

    _fetchMovement = async (propAddress, propStoreProductId) => {
        await this.setState({
            address: propAddress,
            storeProductId: propStoreProductId
        });
        const { warehouseId, startDate, endDate, address, storeProductId } = this.state;
        const movement = await fetchAPI('GET', 'wms/cells/movements', {
            warehouseId,
            address,
            storeProductId,
            fromDatetime: startDate.format('YYYY-MM-DD'),
            toDatetime: endDate.format('YYYY-MM-DD')
        });
        this.setState({
            activeKey: 'movement',
            movement: movement.list
        });
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_wms' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    componentDidMount = async () => {
        await this.props.fetchWarehouses();
    };

    componentDidUpdate = async prevProps => {
        if (this.props.warehouses.length && !prevProps.warehouses.length) {
            await this.setState({
                warehouseId: this.props.warehouses[0].id
            });
            if (this.props.location && this.props.location.state) {
                await this.setState({
                    activeKey: 'plan',
                    warehouseId: Number(this.props.location.state.warehouseId),
                    tableFilter: this.props.location.state.address
                });
            }
            await this._fetchCells();
            await this.setState({
                tableFilter: undefined
            });
        }
    };

    render() {
        const {
            user,
            warehouses,
            intl: { formatMessage }
        } = this.props;
        const {
            cells,
            activeKey,
            warehouseId,
            generateSettings,
            movement,
            startDate,
            endDate,
            address,
            storeProductId,
            movementFilter,
            tableFilter,
            helperDrawerOpen,
            allLinks
        } = this.state;

        const isDsabledCRUD = !isGrantAccessed(user, grants.WAREHOUSE_CELLS_WMS, accesses.RAWO);

        return !cells ? (
            <Spinner spin />
        ) : (
            <Layout
                controls={
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Tooltip title={<FormattedMessage id='storage.clear_all_cells' />}>
                            <Button
                                icon={<UndoOutlined />}
                                onClick={async () => {
                                    await fetchAPI(
                                        'PUT',
                                        'wms/delete',
                                        null,
                                        {
                                            warehouseId
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    this._fetchCells();
                                }}
                                style={{
                                    marginRight: 8
                                }}
                            />
                        </Tooltip>
                        <Select
                            allowClear
                            autoFocus
                            onChange={async warehouseId => {
                                await this.setState({ warehouseId });
                                if (warehouseId) {
                                    this._fetchCells();
                                } else {
                                    this.setState({
                                        cells: [],
                                        activeKey: 'generate'
                                    });
                                }
                            }}
                            placeholder={formatMessage({ id: 'storage' })}
                            showSearch
                            style={{
                                width: 240
                            }}
                            value={warehouseId}
                        >
                            {warehouses.map(elem => {
                                return (
                                    <Option key={elem.id} disabled={elem.attribute === 'RESERVE'} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                );
                            })}
                        </Select>
                        <div
                            style={{
                                marginLeft: 8,
                                display: 'flex'
                            }}
                        >
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    this.setState({
                                        helperDrawerOpen: true
                                    });
                                    await this.fetchHelperLinks();
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </div>
                    </div>
                }
                title={<FormattedMessage id='navigation.wms' />}
            >
                <Catcher>
                    <Tabs
                        activeKey={activeKey}
                        onChange={activeKey => {
                            this.setState({
                                activeKey,
                                movement: undefined,
                                address: undefined,
                                storeProductId: undefined
                            });
                        }}
                    >
                        <TabPane
                            key='plan'
                            disabled={
                                !cells.length && !isGrantAccessed(user, grants.WAREHOUSE_CELLS_WMS_WAREHOUSE_PLAN)
                            }
                            tab={<FormattedMessage id='wms.storage_plan' />}
                        >
                            {warehouseId && (
                                <WMSStoragePlan
                                    cells={cells}
                                    fetchCells={this._fetchCells}
                                    fetchMovement={this._fetchMovement}
                                    isDsabledCRUD={isDsabledCRUD}
                                    tableFilter={tableFilter}
                                    warehouseId={warehouseId}
                                />
                            )}
                        </TabPane>
                        <TabPane
                            key='settings'
                            disabled={
                                !cells.length && !isGrantAccessed(user, grants.WAREHOUSE_CELLS_WMS_CELLS_SETTINGS)
                            }
                            tab={<FormattedMessage id='wms.cell_settings' />}
                        >
                            <WMSAddressSettings
                                cells={cells}
                                fetchCells={this._fetchCells}
                                isDsabledCRUD={isDsabledCRUD}
                                user={user}
                                warehouseId={warehouseId}
                            />
                        </TabPane>
                        <TabPane
                            key='generate'
                            disabled={!isGrantAccessed(user, grants.WAREHOUSE_CELLS_WMS_GENERATE_WMS)}
                            tab={<FormattedMessage id='wms.generate_wms' />}
                        >
                            <WMSGenerateCells
                                fetchCells={this._fetchCells}
                                generateSettings={generateSettings}
                                isDsabledCRUD={isDsabledCRUD}
                                warehouseId={warehouseId}
                            />
                        </TabPane>
                        {movement && (
                            <TabPane key='movement' tab={<FormattedMessage id='wms.movement' />}>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8
                                    }}
                                >
                                    <Input
                                        allowClear
                                        onChange={({ target }) => {
                                            this.setState({
                                                movementFilter: target.value
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'barcode.search'
                                        })}
                                        value={movementFilter}
                                    />
                                    <DateRangePicker
                                        dateRange={[startDate, endDate]}
                                        minimize
                                        onDateChange={async ([startDate, endDate]) => {
                                            await this.setState({
                                                startDate,
                                                endDate
                                            });
                                            this._fetchMovement(address, storeProductId);
                                        }} // prevent default space
                                        style={{ margin: '0 0 0 8px' }}
                                    />
                                </div>
                                <Table
                                    bordered
                                    columns={this.columns}
                                    dataSource={
                                        !movementFilter
                                            ? movement
                                            : movement.filter(
                                                  elem =>
                                                      String(elem.address)
                                                          .toLocaleLowerCase()
                                                          .includes(String(movementFilter).toLocaleLowerCase()) ||
                                                      String(elem.code)
                                                          .toLocaleLowerCase()
                                                          .includes(String(movementFilter).toLocaleLowerCase()) ||
                                                      String(elem.brandName)
                                                          .toLocaleLowerCase()
                                                          .includes(String(movementFilter).toLocaleLowerCase()) ||
                                                      String(elem.name)
                                                          .toLocaleLowerCase()
                                                          .includes(String(movementFilter).toLocaleLowerCase())
                                              )
                                    }
                                    size='small'
                                />
                            </TabPane>
                        )}
                    </Tabs>
                    <Drawer
                        onClose={() => {
                            this.setState({
                                helperDrawerOpen: false
                            });
                        }}
                        open={helperDrawerOpen}
                        title={<FormattedMessage id='navigation.helper' />}
                        width={420}
                    >
                        <div>
                            {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                                <div className={Styles.linkBlock}>
                                    <div className={Styles.ogTitle}>
                                        {index + 1}. {ogTitle}
                                    </div>
                                    <div className={Styles.ogDesc}>{ogDescription}</div>
                                    <div className={Styles.ogImg}>
                                        <Image
                                            src={
                                                _.isArray(ogImage)
                                                    ? _.get(ogImage, '[0].url', [])
                                                    : _.get(ogImage, 'url', [])
                                            }
                                        />
                                    </div>
                                    <a href={ogUrl} rel='noreferrer' target='_blank'>
                                        <Button
                                            style={{
                                                width: '100%'
                                            }}
                                            type='primary'
                                        >
                                            <FormattedMessage id='repair_map_table.goto' />
                                        </Button>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </Drawer>
                </Catcher>
            </Layout>
        );
    }
}
