import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Table } from 'antd';
import { DateRangePicker } from 'components';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
    fetchTasks,
    resetTasksFilters,
    selectFilters,
    selectTasks,
    selectTasksStats,
    setTasksFilters
} from 'pages/TasksPage/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI } from 'utils';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    tasks: selectTasks(state),
    stats: selectTasksStats(state),
    filters: selectFilters(state)
});

const mapDispatchToProps = {
    setModal,
    fetchTasks,
    setTasksFilters,
    resetTasksFilters
};

const DEF_DATE_FORMAT = 'YYYY/MM/DD';

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TasksTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {}
        };
    }

    componentDidMount() {
        this.props.fetchTasks();
    }

    onEmployeeSubTasks = id => {
        this.props.setModal(MODALS.EMPLOYEE_SUBTASKS_MODAL, {
            taskId: id
        });
    };

    handleTaskRedirect = async task => {
        const { user } = this.props;
        // if in NEW status then should be change to INWORK and task is assigned to manager

        if (task.status === 'NEW' && task.performerIds.includes(user.id)) {
            await fetchAPI(
                'PUT',
                `employee_tasks/${task.id}`,
                undefined,
                { status: 'INWORK' },
                { handleErrorInternally: true }
            ).then(() => {
                history.push({
                    pathname: `${book.myTasksPage}/${task.id}`
                });
            });
        } else {
            history.push({
                pathname: `${book.myTasksPage}/${task.id}`
            });
        }
    };

    getColumnSearchProps = dataIndexes => {
        return {
            filtered: _.get(this.props, `filters.${dataIndexes}`, false),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        placeholder={this.props.intl.formatMessage({ id: 'search' })}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        size='small'
                        style={{ width: 90, marginRight: 8 }}
                        type='primary'
                    >
                        <FormattedMessage id='search' />
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        <FormattedMessage id='reset' />
                    </Button>
                </div>
            ),
            filterIcon: filtered => <FilterOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />,
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            }
        };
    };

    getColumnDropdownProps = dataIndex => ({
        filtered: _.get(this.props, `filters.${dataIndex}`, false),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                {dataIndex == 'statuses' && (
                    <Menu>
                        {['NEW', 'INWORK', 'COMPLETED', 'DELEGATED', 'POSTPONED', 'ACCEPT', 'CANCELED'].map(status => (
                            <Menu.Item
                                key={status}
                                onClick={() => {
                                    setSelectedKeys([status]);
                                }}
                            >
                                <FormattedMessage id={`tasks_page.status_${status.toLowerCase()}`} />
                            </Menu.Item>
                        ))}
                    </Menu>
                )}
                {dataIndex == 'priorities' && (
                    <Menu>
                        {['LOW', 'MIDDLE', 'HIGH'].map(priority => (
                            <Menu.Item
                                key={priority}
                                onClick={() => {
                                    setSelectedKeys([priority]);
                                }}
                            >
                                {priority === 'HIGH'
                                    ? '!!!'
                                    : priority === 'MIDDLE'
                                    ? '!!'
                                    : priority === 'LOW'
                                    ? '!'
                                    : ''}
                            </Menu.Item>
                        ))}
                    </Menu>
                )}

                <Button
                    icon={<SearchOutlined />}
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    size='small'
                    style={{ width: 90, marginRight: 8 }}
                    type='primary'
                >
                    <FormattedMessage id='search' />
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                    <FormattedMessage id='reset' />
                </Button>
            </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        }
    });

    getColumnDaterangeProps = () => ({
        filtered: _.get(this.props, 'filters.scheduledDatetimeFrom', false),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <DateRangePicker
                    allowClear
                    dateRange={[
                        this.props.filters.scheduledDatetimeFrom
                            ? dayjs(this.props.filters.scheduledDatetimeFrom)
                            : undefined,
                        this.props.filters.scheduledDatetimeTo
                            ? dayjs(this.props.filters.scheduledDatetimeTo)
                            : undefined
                    ]}
                    // style={{margin: 0}}//prevent default space
                    maximize
                    onDateChange={daterange => {
                        const [startDate, endDate] = daterange;

                        this.props.setTasksFilters({
                            scheduledDatetimeFrom: startDate,
                            scheduledDatetimeTo: endDate,
                            page: 1
                        });
                    }}
                />
            </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />
    });

    handleSearch = (selectedKeys, confirm, dataIndexes) => {
        this.setState({ [`filters.${dataIndexes}`]: selectedKeys[0] });
        this.props.setTasksFilters({ [dataIndexes]: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.props.resetTasksFilters();
    };

    render() {
        const {
            user,
            tasks,
            stats,
            filters,
            setTasksFilters,
            intl: { formatMessage }
        } = this.props;

        const columns = columnsConfig({
            onEmployeeSubTasks: this.onEmployeeSubTasks,
            filters,
            handleTaskRedirect: this.handleTaskRedirect,
            getColumnSearchProps: this.getColumnSearchProps,
            getColumnDropdownProps: this.getColumnDropdownProps,
            getColumnDaterangeProps: this.getColumnDaterangeProps,
            formatMessage
        });

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / 25) * 25,
            current: filters.page,
            onChange: page => {
                setTasksFilters({ page });
            }
        };

        return (
            <div className={Styles.tableCont}>
                <Table
                    bordered
                    columns={columns}
                    dataSource={tasks}
                    pagination={pagination}
                    rowClassName={item => {
                        if (_.get(item, 'status') === 'NEW' || _.get(item, 'status') === 'ACCEPT') {
                            return Styles.newTaskRow;
                        }
                        if (_.get(item, 'status') === 'COMPLETED') return Styles.completedTaskRow;
                    }}
                    rowKey={() => v4()}
                    scroll={{ x: 'auto', y: '60vh' }}
                    size='small'
                />
            </div>
        );
    }
}
