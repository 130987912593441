import { Button, Form, Image, Input, InputNumber, notification, Select } from 'antd';
import { updateUser } from 'core/auth/duck';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, setCurrency } from 'utils';
import Styles from './styles.less';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    colon: false
};

export const CountryTabSettings = injectIntl(props => {
    const [countries, setCountries] = useState([]);
    const [id, setId] = useState();
    const [form] = Form.useForm();
    useEffect(() => {
        const fetchData = async () => {
            const countries = await fetchAPI('GET', 'countrysettings', null, null, {
                handleErrorInternally: true
            });

            const selected = countries.filter(({ businessId }) => businessId);
            if (_.get(selected, 'length')) {
                form.setFieldsValue(selected[selected.length - 1]);
                setId(selected[selected.length - 1].id);
            }
            setCountries(
                countries.filter(({ businessId }) => !businessId).sort((a, b) => a.country.localeCompare(b.country))
            );
        };
        fetchData();
    }, [form]);

    const postData = async values => {
        if (id) {
            await fetchAPI('PUT', `countrysettings/${id}`, null, {
                ...values,
                vat: Number(values.vat),
                timeZone: Number(values.timeZone)
            });
        } else {
            await fetchAPI('POST', 'countrysettings', null, {
                ...values,
                vat: Number(values.vat),
                timeZone: Number(values.timeZone)
            });
        }
        setCurrency(values.curencyLocal);
        updateUser({ ...props.user, ...values });
        notification.success({
            message: props.intl.formatMessage({
                id: 'barcode.success'
            })
        });
    };

    return (
        <Form
            className={Styles.profileForm}
            disabled={props.isDisabledCRUD}
            form={form}
            labelCol={formItemLayout.labelCol}
            name='countryForm'
            onFinish={postData}
            wrapperCol={formItemLayout.wrapperCol}
        >
            <Form.Item
                label={<FormattedMessage id='general_settings.choose_country' />}
                name='country'
                rules={[
                    {
                        required: true,
                        message: 'Country is required.'
                    }
                ]}
            >
                <Select
                    onChange={(value, props) => {
                        form.setFieldsValue(props);
                    }}
                    optionFilterProp='children'
                    placeholder={props.intl.formatMessage({
                        id: 'general_settings.choose_country'
                    })}
                    showSearch
                >
                    {countries.map(cn => (
                        <Option key={cn.country} {...cn}>
                            {cn.countryName}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label={<FormattedMessage id='general_settings.phone_format' />} name='phoneCode'>
                <Select
                    optionFilterProp='children'
                    placeholder={props.intl.formatMessage({
                        id: 'general_settings.phone_format'
                    })}
                    showSearch
                >
                    {_.uniqBy(countries, 'phoneCode').map(({ phoneCode, country, photoUrl }) => (
                        <Option key={phoneCode} style={{ textAlign: 'left' }} value={phoneCode}>
                            {country}
                            <div style={{ margin: '0px 8px', overflow: 'unset', display: 'inline-block' }}>
                                <Image
                                    preview={false}
                                    src={require(`../../../theme/images/flags/${photoUrl}.png`)}
                                    width={24}
                                />
                            </div>
                            +{phoneCode}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label={<FormattedMessage id='general_settings.currency' />} name='currencyInternational'>
                <Select
                    onChange={(value, props) => {
                        form.setFieldsValue(props);
                    }}
                    optionFilterProp='children'
                    placeholder={props.intl.formatMessage({
                        id: 'general_settings.currency'
                    })}
                    showSearch
                >
                    {_.uniqBy(countries, 'currencyInternational').map(
                        ({ currencyInternational, curencySign, curencyLocal, curencyDecimal }) => (
                            <Option
                                key={currencyInternational}
                                curencyDecimal={curencyDecimal}
                                curencyLocal={curencyLocal}
                                curencySign={curencySign}
                                value={currencyInternational}
                            >
                                {currencyInternational}
                            </Option>
                        )
                    )}
                </Select>
            </Form.Item>
            <Form.Item hidden name='curencySign'>
                <Input />
            </Form.Item>
            <Form.Item hidden name='curencyLocal'>
                <Input />
            </Form.Item>
            <Form.Item hidden name='curencyDecimal'>
                <Input />
            </Form.Item>
            <Form.Item label={<FormattedMessage id='general_settings.VAT' />} name='vat'>
                <InputNumber formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
            </Form.Item>
            <Form.Item label={<FormattedMessage id='general_settings.time_zone' />} name='timeZone'>
                <Select
                    placeholder={props.intl.formatMessage({
                        id: 'general_settings.time_zone'
                    })}
                >
                    {countries.map(({ timeZone, timeZoneAbbrev }) => (
                        <Option key={timeZone} value={timeZone}>
                            {timeZone} {timeZoneAbbrev}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item hidden name='timeZoneAbbrev'>
                <Input />
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 11,
                    span: 12
                }}
            >
                <Button htmlType='submit' type='primary'>
                    <FormattedMessage id='general_settings.submit' />
                </Button>
            </Form.Item>
        </Form>
    );
});
