import { Checkbox, Modal, Select, Space, TreeSelect } from 'antd';
import { debounce, get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildStoreGroupsTree, fetchAPI, filterTreeNodeByPart, orderTypes } from 'utils';

const mapStateToProps = state => ({ isMobile: state.ui.views.isMobile });

const InputSelect = ({ selectOptions, filters, isMobile, propname, placeholder, handleFiltersChange, ...props }) => {
    return (
        <Select
            allowClear
            onChange={value => handleFiltersChange(propname, value)}
            optionFilterProp='label'
            options={selectOptions[propname]}
            placeholder={placeholder}
            showSearch
            style={{
                minWidth: isMobile ? 200 : 450,
                color: 'var(--text)'
            }}
            value={filters[propname]}
            {...props}
        />
    );
};
const CheckboxComponent = ({ selectOptions, filters, propname, placeholder, handleFiltersChange, ...props }) => {
    return (
        <Checkbox
            checked={filters[propname]}
            onChange={() => handleFiltersChange(propname, !filters[propname])}
            {...props}
        >
            {placeholder}
        </Checkbox>
    );
};
const StoreGroupSelect = ({
    selectOptions,
    isMobile,
    filters,
    propname,
    placeholder,
    handleFiltersChange,
    ...props
}) => {
    return (
        <TreeSelect
            allowClear
            filterTreeNode={filterTreeNodeByPart}
            onChange={value => handleFiltersChange(propname, value)}
            optionFilterProp='children'
            placeholder={placeholder}
            popupMatchSelectWidth={false}
            showSearch
            style={{
                minWidth: isMobile ? 200 : 450,
                color: 'var(--text)'
            }}
            treeData={selectOptions[propname]}
            value={filters[propname]}
            {...props}
        />
    );
};

const FiltersModal = ({
    open,
    currentFilters,
    handleClose,
    handleOk,
    allStations,
    setChartSettings,
    activeModalFilters = [],
    isMobile = false,
    intl: { formatMessage }
}) => {
    const [selectOptions, setSelectOptions] = useState({});
    const [filters, setFilters] = useState({});

    const handleFiltersChange = (propname, value) => {
        setFilters(prev => ({ ...prev, [propname]: value }));
    };

    const debouncedOptionsChange = useCallback(
        propname =>
            debounce(async query => {
                if (!query) return;
                let res = [];
                if (propname === 'clientId' || propname === 'counterpartyId') {
                    res = await fetchAPI('GET', '/clients', { filters: { query }, sort: { pageSize: 25 } }, null, {
                        handleErrorInternally: true
                    });
                    res = res.clients.map(({ clientId, name, surname, phones }) => ({
                        id: clientId,
                        name: `${surname || ''} ${name || ''} ${get(phones, '[0]', '')}`
                    }));
                    if (propname === 'counterpartyId') {
                        setSelectOptions(prev => ({ ...prev, [propname]: { ...prev[propname], client: res } }));

                        return;
                    }
                } else if (propname === 'vehicleId') {
                    res = await fetchAPI('GET', '/vehicles', { filters: { query }, sort: { pageSize: 25 } }, null, {
                        handleErrorInternally: true
                    });
                    res = res.clientsVehicles.map(({ clientVehicleId, make, model, modification }) => ({
                        id: clientVehicleId,
                        name: `${make || ''} ${model || ''} ${modification || ''}`
                    }));
                } else if (propname === 'productId') {
                    res = await fetchAPI('GET', '/store_products', { query }, null, { handleErrorInternally: true });
                    res = res.list.map(({ id, code, name, brandName }) => ({
                        id,
                        name: `${code || ''} ${name || ''} ${brandName || ''}`
                    }));
                }
                setSelectOptions(prev => ({ ...prev, [propname]: res }));
            }, 500),
        []
    );

    const filterMap = useMemo(
        () => ({
            stationIds: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'businessName', value: 'businessId' },
                    optionFilterProp: 'businessName'
                }
            },
            clientId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    filterOption: false,
                    onSearch: debouncedOptionsChange('clientId'),
                    notFoundContent: null
                }
            },
            managerId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            responsibleId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            employeeId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            appurtenanciesResponsibleId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            stationNum: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'num' },
                    optionFilterProp: 'name'
                }
            },
            vehicleId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    filterOption: false,
                    onSearch: debouncedOptionsChange('vehicleId'),
                    notFoundContent: null
                }
            },
            businessRequisiteId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            cashBoxId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'businessRequisiteName', value: 'id' },
                    optionFilterProp: 'businessRequisiteName'
                }
            },
            businessSupplierId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            analyticsUniqueId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'analyticsName', value: 'analyticsUniqueId' },
                    optionFilterProp: 'analyticsName'
                }
            },
            productId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    filterOption: false,
                    onSearch: debouncedOptionsChange('productId'),
                    notFoundContent: null
                }
            },
            groupId: {
                Component: StoreGroupSelect
            },
            warehouseId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            counterpartBusinessSupplierId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            counterpartClientId: {
                Component: InputSelect,
                props: {
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name'
                }
            },
            orderType: {
                Component: InputSelect,
                props: {}
            },
            counterpartyType: {
                Component: InputSelect,
                props: {
                    onChange: value => {
                        handleFiltersChange('counterpartyType', value);
                        handleFiltersChange('counterpartyId', undefined);
                    }
                }
            },
            counterpartyId: {
                Component: InputSelect,
                props: {
                    options: get(selectOptions, `counterpartyId[${filters.counterpartyType}]`),
                    fieldNames: { label: 'name', value: 'id' },
                    optionFilterProp: 'name',
                    filterOption: filters.counterpartyType !== 'client',
                    onSearch: filters.counterpartyType === 'client' ? debouncedOptionsChange('counterpartyId') : void 0,
                    notFoundContent: filters.counterpartyType === 'client' ? null : void 0
                }
            },
            cumulation: {
                Component: CheckboxComponent,
                props: {}
            },
            tax: {
                Component: CheckboxComponent,
                props: {}
            }
        }),
        [debouncedOptionsChange, filters.counterpartyType, selectOptions]
    );

    useEffect(() => {
        setFilters(currentFilters);
    }, [currentFilters]);

    useEffect(() => {
        const fetchOptions = async () => {
            const [
                stations,
                // { clients: cl },
                empl,
                stationNums,
                // { clientsVehicles: vehicles },
                requisites,
                cashboxes,
                suppliers,
                { analytics },
                // { list: products },
                storeGroups,
                warehouses
            ] = await Promise.all([
                fetchAPI('GET', '/managers/businesses/get', null, null, { handleErrorInternally: true }),
                // fetchAPI('GET', '/clients', null, null, { handleErrorInternally: true }),
                fetchAPI('GET', '/employees', null, null, { handleErrorInternally: true }),
                fetchAPI('GET', '/businesses/stations', null, null, { handleErrorInternally: true }),
                // fetchAPI('GET', '/vehicles', null, null, { handleErrorInternally: true }),
                fetchAPI('GET', '/businesses/requisites', null, null, { handleErrorInternally: true }),
                fetchAPI('GET', '/cash_boxes', null, null, { handleErrorInternally: true }),
                fetchAPI('GET', '/business_suppliers', { all: true }, null, { handleErrorInternally: true }),
                fetchAPI('GET', '/report/analytics', null, null, { handleErrorInternally: true }),
                // fetchAPI('GET', '/store_products', null, null, { handleErrorInternally: true }),
                fetchAPI('GET', '/store_groups', null, null, { handleErrorInternally: true }),
                fetchAPI('GET', '/warehouses', null, null, { handleErrorInternally: true })
            ]);
            const clients = [].map(({ clientId, name, surname }) => ({
                id: clientId,
                name: `${surname || ''} ${name || ''}`
            }));
            const employees = empl.map(({ name, surname, id, posts, managerId }) => ({
                id,
                name: `${surname || ''} ${name || ''}`,
                posts: posts.map(({ postName }) => postName),
                managerId
            }));
            setSelectOptions({
                stationIds: stations,
                clientId: clients,
                managerId: employees.filter(({ managerId }) => managerId),
                responsibleId: employees.filter(({ managerId }) => managerId),
                employeeId: employees.filter(({ posts }) => posts.includes('MECHANIC')),
                appurtenanciesResponsibleId: employees.filter(({ posts }) => posts.includes('SPARE_PARTS_SPECIALIST')),
                stationNum: stationNums,
                vehicleId: [].map(({ clientVehicleId, make, model, modification }) => ({
                    id: clientVehicleId,
                    name: `${make || ''} ${model || ''} ${modification || ''}`
                })),
                businessRequisiteId: requisites,
                cashBoxId: cashboxes,
                businessSupplierId: suppliers,
                analyticsUniqueId: analytics,
                productId: [],
                groupId: buildStoreGroupsTree(storeGroups),
                warehouseId: warehouses,
                counterpartBusinessSupplierId: suppliers,
                counterpartClientId: clients,
                orderType: orderTypes.map(type => ({
                    label: formatMessage({ id: `aggregate.${type}` }),
                    value: type
                })),
                counterpartyType: ['client', 'supplier', 'employee'].map(el => ({
                    label: formatMessage({ id: el }),
                    value: el
                })),
                counterpartyId: { client: clients, supplier: suppliers, employee: employees }
            });
        };
        if (activeModalFilters.length && !Object.keys(selectOptions).length) {
            fetchOptions();
        }
    }, [activeModalFilters, selectOptions]);

    const filterComponents = useMemo(() => {
        return activeModalFilters.map(type => {
            if (!filterMap[type]) return type;
            const { Component, props = {} } = filterMap[type];

            return (
                typeof Component !== 'string' && (
                    <Component
                        key={type}
                        filters={filters}
                        handleFiltersChange={handleFiltersChange}
                        isMobile={isMobile}
                        placeholder={formatMessage({ id: `graphic_reports.placeholder.${type}` })}
                        propname={type}
                        selectOptions={selectOptions}
                        {...props}
                    />
                )
            );
        });
    }, [activeModalFilters, filterMap, filters, formatMessage, selectOptions]);

    useEffect(() => {
        setFilters({});
    }, [activeModalFilters]);

    return (
        <Modal
            maskClosable={false}
            onCancel={handleClose}
            onOk={() => handleOk(filters)}
            open={open}
            title={formatMessage({ id: 'filters' })}
        >
            <Space direction='vertical' style={{ ...(isMobile && { width: '100%' }) }}>
                {selectOptions.stationIds && (
                    <Select
                        allowClear
                        disabled={!allStations}
                        fieldNames={{ label: 'businessName', value: 'businessId' }}
                        mode='multiple'
                        onChange={stationIds => {
                            setChartSettings(prev => ({ ...prev, stationIds }));
                        }}
                        optionFilterProp='businessName'
                        options={selectOptions.stationIds}
                        placeholder={formatMessage({ id: 'dashboard-page.sto_network' })}
                        showSearch
                        style={{
                            minWidth: isMobile ? 200 : 450,
                            color: 'var(--text)'
                        }}
                    />
                )}
                {filterComponents}
            </Space>
        </Modal>
    );
};

export default connect(mapStateToProps)(injectIntl(FiltersModal));
