/* eslint-disable no-unused-vars */
import { Button, Space, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { Layout } from 'commons';
import dayjs from 'dayjs';
import FiltersModal from 'pages/GraphicReportsPage/components/FiltersModal';
import { ReportCashFlowPage } from 'pages/Reports';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AnalyticsTab from './components/Tabs/AnalyticsTab';
import IncreaseTab from './components/Tabs/IncreaseTab';

const increaseFilterList = [
    'orderType',
    'clientId',
    'managerId',
    'employeeId',
    'appurtenanciesResponsibleId',
    'stationNum',
    'vehicleId',
    'businessRequisiteId',
    'counterpartBusinessSupplierId'
];

const analyticsFilterList = [
    'clientId',
    'managerId',
    'employeeId',
    'appurtenanciesResponsibleId',
    'stationNum',
    'vehicleId',
    'businessRequisiteId'
];

const ManagerReportsPage = ({ user, intl: { formatMessage }, ...props }) => {
    const [filters, setFilters] = useState({});
    const [activeKey, setActiveKey] = useState('increase');
    const [activeModalFilters, setActiveFilters] = useState(increaseFilterList);
    const [settings, setSettings] = useState({
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month'),
        period: 'DAY',
        stationIds: undefined
    });

    const [filtersModalOpen, setFiltersModalOpen] = useState(false);

    const handleFiltersModalOpen = () => {
        setFiltersModalOpen(true);
    };

    const handleFiltersModalClose = () => {
        setFiltersModalOpen(false);
    };

    const handleFiltersModalOk = filters => {
        setFilters(filters);
        handleFiltersModalClose();
    };

    const handleChangeTab = activeKey => {
        setActiveKey(activeKey);
        if (activeKey === 'increase') {
            setActiveFilters(increaseFilterList);
        } else if (activeKey === 'analytics') {
            setActiveFilters(analyticsFilterList);
        }
    };

    useEffect(() => {
        if (isGrantAccessed(user, grants.MANAGER_REPORTS_RECEIPTS_INCOME_MARGIN)) {
            setActiveKey('increase');
        } else if (isGrantAccessed(user, grants.MANAGER_REPORTS_SALES_ANALYTICS)) {
            setActiveKey('analytics');
        } else {
            setActiveKey('cashflow');
        }
    }, [user]);

    const isDisabledCRUD = !isGrantAccessed(user, grants.REPORTS_GRAPHICAL_REPORTS_FILTERS, accesses.ROWO);

    return (
        <Layout
            controls={
                <Space>
                    <Button
                        disabled={isDisabledCRUD || activeKey === 'cashflow'}
                        onClick={handleFiltersModalOpen}
                        type={Object.values(filters).some(val => val) ? 'primary' : 'default'}
                    >
                        <FormattedMessage id='filters' />
                    </Button>
                </Space>
            }
            title={<FormattedMessage id='navigation.manager_reports' />}
        >
            <Tabs activeKey={activeKey} onChange={handleChangeTab}>
                {isGrantAccessed(user, grants.MANAGER_REPORTS_RECEIPTS_INCOME_MARGIN) && (
                    <TabPane key='increase' tab={<FormattedMessage id='cash-table.increase' />}>
                        <IncreaseTab
                            filters={filters}
                            isDisabledCRUD={isDisabledCRUD}
                            setSettings={setSettings}
                            settings={settings}
                        />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.MANAGER_REPORTS_SALES_ANALYTICS) && (
                    <TabPane key='analytics' tab={<FormattedMessage id='cash-table.order_analytics' />}>
                        <AnalyticsTab filters={filters} />
                    </TabPane>
                )}
                <TabPane key='cashflow' tab={<FormattedMessage id='navigation.flow_of_money' />}>
                    <ReportCashFlowPage withoutLayout {...props} />
                </TabPane>
            </Tabs>

            <FiltersModal
                activeModalFilters={activeModalFilters}
                allStations={false}
                currentFilters={filters}
                handleClose={handleFiltersModalClose}
                handleOk={handleFiltersModalOk}
                open={filtersModalOpen}
                setChartSettings={setSettings}
            />
        </Layout>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default connect(mapStateToProps)(injectIntl(ManagerReportsPage));
