import { emitError } from 'core/ui/duck';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    DELETE_REPORT_ANALYTICS,
    FETCH_REPORT_ANALYTICS,
    RESET_ALL_REPORT_ANALYTICS,
    deleteReportAnalyticsSuccess,
    fetchReportAnalyticsSuccess,
    resetAllReportAnalyticsSuccess
} from './duck';

export function* fetchReportAnalyticsSaga() {
    while (true) {
        try {
            yield take(FETCH_REPORT_ANALYTICS);

            const data = yield call(fetchAPI, 'GET', '/report/analytics', null, null, { handleErrorInternally: true });
            yield put(fetchReportAnalyticsSuccess(data));
        } catch (err) {
            emitError(err);
        }
    }
}

export function* deleteReportAnalyticsSaga() {
    while (true) {
        try {
            const {
                payload: { analyticsId }
            } = yield take(DELETE_REPORT_ANALYTICS);

            const filters = { analyticsId };

            yield call(fetchAPI, 'DELETE', '/report/analytics', { filters });
            yield put(deleteReportAnalyticsSuccess());
        } catch (err) {
            emitError(err);
        }
    }
}

export function* resetAllReportAnalytics() {
    while (true) {
        try {
            const {
                payload: { areYouSureToDeleteAll }
            } = yield take(RESET_ALL_REPORT_ANALYTICS);

            yield call(fetchAPI, 'DELETE', '/report/analytics', { areYouSureToDeleteAll });

            yield put(resetAllReportAnalyticsSuccess());
        } catch (err) {
            emitError(err);
        }
    }
}

export function* saga() {
    yield all([call(fetchReportAnalyticsSaga), call(deleteReportAnalyticsSaga), call(resetAllReportAnalytics)]);
}
