import { DollarOutlined, FilterFilled, PercentageOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, Select, Table, Tooltip } from 'antd';
import { Numeral } from 'commons';
import { DateRangePicker } from 'components';
import { _ } from 'core-js';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import styled from 'styled-components';
import { fetchAPI } from 'utils';
import { CalcSalaryModal, PayOutSalaryModal } from './components';
import Styles from './styles.m.css';

const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;

const { Panel } = Collapse;

@injectIntl
export default class StorageFundsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            dateRange: [],
            allEmployees: [],
            page: 1,
            totalCount: 0,
            pageSize: 25
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='order' />,
                key: 'order',
                width: '25%',
                ...this.getColumnSearchProps('filterOrder'),
                render: (data, row) => {
                    const orderId = _.get(_.split(row.order, '-', 3), '[2]');

                    return (
                        <div>
                            <Link className={Styles.orderLink} to={`${book.order}/${orderId}`}>
                                {row.order}
                            </Link>{' '}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='employee_salary_rules.position' />,
                key: 'position',
                width: '15%',
                render: (data, row) => (
                    <div>{row.jobTitle ? <FormattedMessage id={`employee_salary_rules.${row.jobTitle}`} /> : ''}</div>
                )
            },
            {
                title: <FormattedMessage id='navigation.labors_page' />,
                key: 'labors',
                width: '25%',
                render: (data, row) => (
                    <div>{_.get(row, 'labors') ? _.get(row, 'labors').map(({ name }) => `${name}; `) : '-'} </div>
                )
            },
            {
                title: <FormattedMessage id='report-orders-table.spare_parts' />,
                key: 'parts',
                width: '25%',
                render: (data, row) => (
                    <div>{_.get(row, 'parts') ? _.get(row, 'parts').map(({ name }) => `${name}; `) : '-'} </div>
                )
            },
            {
                title: <FormattedMessage id='add_order_form.delivery_date' />,
                key: 'deliveryDateTime',
                width: '15%',
                render: (data, row) => (
                    <div>
                        {_.get(row, 'deliveryDateTime')
                            ? dayjs(_.get(row, 'deliveryDateTime')).format('DD.MM.YYYY')
                            : '-'}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='storage_journal.closing_date' />,
                key: 'doneDateTime',
                width: '15%',
                ...this.getColumnSearchProps('startDate'),
                render: (data, row) => (
                    <div>
                        {_.get(row, 'doneDateTime') ? dayjs(_.get(row, 'doneDateTime')).format('DD.MM.YYYY') : '-'}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.rule_name' />,
                key: 'percentFrom',
                dataIndex: 'percentFrom',
                width: '15%',
                render: (data, row) => <div>{row.percentFrom}</div>
            },
            {
                title: <FormattedMessage id='locations.percent' />,
                key: 'ruleName',
                dataIndex: 'percentFrom',
                width: '24%',
                render: (data, row) => <div>{`${row.ruleName}: ${row.percent}%`}</div>
            },
            {
                title: <FormattedMessage id='employee_salary_rules.basis_of_calculation' />,
                key: 'mark',
                dataIndex: 'mark',
                width: '15%',
                render: (data, row) => (
                    <div>
                        <FormattedMessage id={`employee_salary_rules.${row.mark}`} />
                    </div>
                )
            },
            {
                title: <FormattedMessage id='sum' />,
                key: 'mark',
                dataIndex: 'mark',
                width: '12%',
                render: (data, row) => (
                    <div>{row.salary ? <Numeral mask='0,0.00'>{Number(row.salary)}</Numeral> : '-'} ₴</div>
                )
            }
        ];
    }

    componentDidMount() {
        this.getSalariesInfo();
        this.getAllEmployees();
    }

    getSalariesInfo = async () => {
        const { page, pageSize, filterOrder, startDate, endDate } = this.state;
        const data = await fetchAPI(
            'GET',
            '/employee/salary/journal',
            {
                page,
                pageSize,
                orderId: filterOrder,
                startDate: startDate ? String(startDate) : undefined,
                endDate: startDate ? String(endDate) : undefined
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: data.journal,
            count: data.count,
            total: data.total
        });
    };

    calcSalary = async (id, sDate, eDate) => {
        const calculatedSalary = await fetchAPI(
            'POST',
            '/recalculate/employee/salary',
            undefined,
            {
                employeeIds: id,
                startDate: sDate,
                endDate: eDate,
                status: 'ACCRUED'
            },
            { handleErrorInternally: true }
        );
        this.setState({
            calculatedSalary
        });
        this.getSalariesInfo();
    };

    calcSalaryByAllEmps = async (id, sDate, eDate) => {
        const calculatedSalary = await fetchAPI(
            'POST',
            '/recalculate/employee/salary',
            undefined,
            {
                employeeIds: id,
                startDate: sDate,
                endDate: eDate,
                status: 'ACCRUED'
            },
            { handleErrorInternally: true }
        );
        this.setState({
            calculatedSalary
        });
        this.getSalariesInfo();
    };

    getAllEmployees = async () => {
        const employees = await fetchAPI('GET', 'employees', null, null, { handleErrorInternally: true });
        this.setState({
            allEmployees: employees
        });
    };

    getColumnSearchProps = dataIndex => {
        const { startDate, endDate } = this.state;
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'startDate') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={[startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                startDate: dayjs(dateRange[0]).format(dateFormat),
                                endDate: dayjs(dateRange[1]).format(dateFormat)
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.getSalariesInfo();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        // if (dataIndex === 'filterDate') {
        //     filterComponent = (confirm, clearFilters) => (
        //         <DatePicker
        //             allowClear
        //             format='YYYY-MM-DD '
        //             onChange={date => {
        //                 this.setState({
        //                     [dataIndex]: date
        //                 });
        //             }}
        //             placeholder={this.props.intl.formatMessage({
        //                 id: 'select_date'
        //             })}
        //             style={{
        //                 width: '100%'
        //             }}
        //             value={this.state.dataIndex}
        //         />
        //     );
        // }

        // if (dataIndex === 'filterDocumentType') {
        //     filterComponent = (confirm, clearFilters) => (
        //         <Select
        //             allowClear
        //             getPopupContainer={trigger => trigger.parentNode}
        //             onChange={value => {
        //                 this.setState({
        //                     filterDocumentType: value
        //                 });
        //             }}
        //             // mode='multiple'
        //             placeholder={this.props.intl.formatMessage({
        //                 id: 'storage_journal.choose_type'
        //             })}
        //             style={{ marginBottom: 8, display: 'block', width: 180 }}
        //             value={this.state.filterDocumentType}
        //         >
        //             <Option value='INCOME'>
        //                 <FormattedMessage id='storage.INCOME' />
        //             </Option>
        //             <Option value='EXPENSE'>
        //                 <FormattedMessage id='storage.EXPENSE' />
        //             </Option>
        //         </Select>
        //     );
        // }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.getSalariesInfo();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1, startDate: undefined, endDate: undefined });
        this.getSalariesInfo();
    };

    render() {
        const {
            dataSource,
            page,
            pageSize,
            count,
            total,
            employeeId,
            allEmployees,
            visibleCalcSalaryModal,
            visiblePayOutSalaryModal,
            calculatedSalary
        } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(count / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.getSalariesInfo();
            }
        };

        const dataSourceFilterd = dataSource.map(({ rules }) => rules);

        return (
            <div>
                <DataRow>
                    <DataWrapper>
                        <span
                            style={{
                                marginRight: 4
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.total_accrued' />:
                        </span>
                        <Numeral currency='₴' mask='0,0.00'>
                            {Number(_.get(total, 'totalAccrued', 0))}
                        </Numeral>
                    </DataWrapper>
                    <DataWrapper>
                        <span
                            style={{
                                marginRight: 4
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.total_paid' />:
                        </span>
                        <Numeral currency='₴' mask='0,0.00'>
                            {Number(_.get(total, 'totalPaid'))}
                        </Numeral>
                    </DataWrapper>
                </DataRow>
                <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'end' }}>
                    <Tooltip title={<FormattedMessage id='setting-salary.to_pay_salary' />}>
                        <Button
                            icon={<DollarOutlined />}
                            onClick={() => {
                                this.setState({
                                    visiblePayOutSalaryModal: true
                                });
                            }}
                            style={{
                                marginRight: 8
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='setting-salary.to_calc_salary' />}>
                        <Button
                            icon={<PercentageOutlined />}
                            onClick={() => {
                                this.setState({
                                    visibleCalcSalaryModal: true
                                });
                            }}
                        />
                    </Tooltip>
                </div>
                <div>
                    <div className={Styles.searchEmployeeTitle}>
                        <FormattedMessage id='employee_salary_rules.search_employee' />
                    </div>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Select
                            allowClear
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={value => {
                                this.setState({
                                    employeeId: value
                                });
                            }}
                            optionFilterProp='children'
                            // mode='multiple'
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            showSearch
                            style={{ marginBottom: 8, display: 'block', width: '25%' }}
                            value={employeeId}
                        >
                            {allEmployees
                                .filter(({ disabled }) => !disabled)
                                .map(({ id, name, surname }) => (
                                    <Option key={id} value={id}>
                                        {`${name} ${surname}`}
                                    </Option>
                                ))}
                        </Select>
                        <Button
                            icon={<SearchOutlined />}
                            onClick={async () => {
                                const filteredData = await fetchAPI(
                                    'GET',
                                    '/employee/salary/journal',
                                    {
                                        page,
                                        pageSize,
                                        employeeId
                                    },
                                    null,
                                    { handleErrorInternally: true }
                                );
                                this.setState({
                                    dataSource: filteredData.journal
                                });
                            }}
                        />
                        <Button
                            icon={<UndoOutlined />}
                            onClick={async () => {
                                const data = await fetchAPI(
                                    'GET',
                                    '/employee/salary/journal',
                                    {
                                        employeeId: undefined,
                                        page,
                                        pageSize,
                                        orderId: undefined,
                                        startDate: undefined,
                                        endDate: undefined
                                    },
                                    null,
                                    { handleErrorInternally: true }
                                );
                                this.setState({
                                    dataSource: data.journal,
                                    count: data.count,
                                    total: data.total,
                                    orderId: undefined,
                                    startDate: undefined,
                                    endDate: undefined,
                                    employeeId: undefined
                                });
                            }}
                        />
                    </div>
                </div>
                {dataSource.map(({ employeeName, employeeId, accrued, rules, closed }) => (
                    <Collapse>
                        <Panel
                            key={employeeId}
                            header={
                                <div>
                                    <div>
                                        {`${employeeName},  (${this.props.intl.formatMessage({
                                            id: 'employee_salary_rules.salary_table'
                                        })}: `}
                                        <span>
                                            <Numeral mask='0,0.00'>{Number(accrued)}</Numeral>{' '}
                                            <FormattedMessage id='cur' />
                                        </span>{' '}
                                        <span>
                                            <FormattedMessage id='employee_salary_rules.total_paid' />:{' '}
                                            <Numeral mask='0,0.00'>{Number(closed)}</Numeral>{' '}
                                            <FormattedMessage id='cur' />
                                        </span>
                                        )
                                    </div>
                                </div>
                            }
                        >
                            <Table
                                columns={this.columns()}
                                dataSource={rules}
                                pagination={{
                                    pageSize,
                                    total: Math.ceil(_.get(rules, 'length') / pageSize) * pageSize,
                                    hideOnSinglePage: true,
                                    current: page,
                                    onChange: async (page, pageSize) => {
                                        await this.setState({ page, pageSize });
                                        this.getSalariesInfo();
                                    }
                                }}
                                rowKey='employeeId'
                                scroll={{
                                    x: 800,
                                    y: '60vh'
                                }}
                            />
                        </Panel>
                    </Collapse>
                ))}
                <CalcSalaryModal
                    allEmployees={allEmployees}
                    calcSalary={this.calcSalary}
                    calcSalaryByAllEmps={this.calcSalaryByAllEmps}
                    hideModal={() => {
                        this.setState({
                            visibleCalcSalaryModal: false
                        });
                    }}
                    visible={visibleCalcSalaryModal}
                />
                <PayOutSalaryModal
                    allEmployees={allEmployees}
                    calculatedSalary={calculatedSalary}
                    getSalariesInfo={this.getSalariesInfo}
                    hideModal={() => {
                        this.setState({
                            visiblePayOutSalaryModal: false
                        });
                    }}
                    visible={visiblePayOutSalaryModal}
                />
            </div>
        );
    }
}

const DataRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 14px;
    padding: 14px 0;

    border-bottom: 1px solid;
    border-top: 1px solid;
`;

const DataWrapper = styled.div`
    display: flex;
    font-size: 16px;
`;
