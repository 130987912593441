import AccountsContainer from './AccountsContainer';
import BarcodeContainer from './BarcodeContainer';
import BusinessPackageContainer from './BusinessPackageContainer';
import CallsContainer from './CallsContainer';
import CashAccountingContainer from './CashAccountingContainer';
import ChartContainer from './ChartContainer';
import ClientContainer from './ClientContainer';
import ClientRequisitesContainer from './ClientRequisitesContainer';
import ClientsContainer from './ClientsContainer';
import ClientsDebtsContainer from './ClientsDebtsContainer';
import ClientsFilterContainer from './ClientsFilterContainer';
import DashboardContainer from './DashboardContainer';
import DiagnosticPatternsContainer from './DiagnosticPatternsContainer';
import EmployeeDebtContainer from './EmployeeDebtContainer';
import EmployeesContainer from './EmployeesContainer';
import EntriesDirectoryContainer from './EntriesDirectoryContainer';
import FunelContainer from './FunelContainer';
import LocationsTabContainer from './LocationsTabContainer';
import ManagerRoleContainer from './ManagerRoleContainer';
import MyTasksContainer from './MyTasksContainer';
import OrderDrawerContainer from './OrderDrawerContainer';
import OrdersContainer from './OrdersContainer';
import OrdersFilterContainer from './OrdersFilterContainer';
import PackageContainer from './PackageContainer';
import QuickRequestsContainer from './QuickRequestsContainer';
import RequisiteSettingContainer from './RequisiteSettingContainer';
import ReviewsContainer from './ReviewsContainer';
import RoleContainer from './RoleContainer';
import SalariesDocumentContainer from './SalariesDocumentContainer';
import ServicesContainer from './ServicesContainer';
import StorageDocumentsContainer from './StorageDocumentsContainer';
import StorageTable from './StorageDocumentsContainer/storageTable';
import StorageFundsContainer from './StorageFundsContainer';
import StorageFundsEmployeeContainer from './StorageFundsEmployeeContainer';
import StoreProductsContainer from './StoreProductsContainer';
import SubscriptionProductsContainer from './SubscriptionProductsContainer';
import SuppliersDebtsContainer from './SuppliersDebtsContainer';
import TecDocActionsContainer from './TecDocActionsContainer';
import UnitsPageContainer from './UnitsPageContainer';
import UniversalFilters from './UniversalFilters';

export {
    AccountsContainer,
    BarcodeContainer,
    BusinessPackageContainer,
    CallsContainer,
    CashAccountingContainer,
    ChartContainer,
    ClientContainer,
    ClientRequisitesContainer,
    ClientsContainer,
    ClientsDebtsContainer,
    ClientsFilterContainer,
    DashboardContainer,
    DiagnosticPatternsContainer,
    EmployeeDebtContainer,
    EmployeesContainer,
    EntriesDirectoryContainer,
    FunelContainer,
    LocationsTabContainer,
    ManagerRoleContainer,
    MyTasksContainer, OrderDrawerContainer, OrdersContainer,
    OrdersFilterContainer,
    PackageContainer,
    QuickRequestsContainer,
    RequisiteSettingContainer,
    ReviewsContainer,
    RoleContainer,
    SalariesDocumentContainer,
    ServicesContainer,
    StorageDocumentsContainer,
    StorageFundsContainer,
    StorageFundsEmployeeContainer,
    StorageTable,
    StoreProductsContainer,
    SubscriptionProductsContainer,
    SuppliersDebtsContainer,
    TecDocActionsContainer,
    UnitsPageContainer,
    UniversalFilters
};

