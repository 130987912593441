import { Button, Form, Input, Select } from 'antd';
import { PhoneInput } from 'components';
import { onChangeProfileForm, submitProfileFormOther } from 'core/forms/profileForm/duck';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.less';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    colon: false
};

const mapDispatchToProps = {
    onChangeProfileForm,
    submitProfileFormOther
};

const mapStateToProps = state => {
    return {
        user: state.auth,
        profileUpdating: state.ui.profileUpdating
    };
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class NotificationTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificSettings: [],
            initialValues: {},
            allPhones: [],
            number: undefined
        };
    }

    componentDidMount() {
        this.getNotificSettings();
    }

    getNotificSettings = async value => {
        const response = await fetchAPI(
            'GET',
            '/business/notification/settings',
            { sendSmsWithDetails: false, sendEmailWithDetails: false },
            undefined,
            {
                handleErrorInternally: true
            }
        );

        this.setState({
            number: response.phone,
            emails: get(response, 'emails[0]', '')
        });
    };

    phoneNumberFormatter = (value, country) => {
        // /^\\+\\d{2}\\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$/
        const base = '+__(___) ___-__-__';

        const digits = String(value).replace(/\D/g, '');

        const formattedNumber = digits.split('').reduce((prev, cur) => (prev || '').replace('_', cur), base);
        const indexOfPlaceHolder = (formattedNumber || '').indexOf('_');
        const endPosition = indexOfPlaceHolder === -1 ? (formattedNumber || '').length : indexOfPlaceHolder;

        return (formattedNumber || '').slice(0, endPosition).replace(/[^\d]+$/, '');
    };

    submitProfileNotific = async () => {
        const { number, emails } = this.state;
        const formattedNumber = this.phoneNumberFormatter(number);
        await fetchAPI(
            'PUT',
            '/business/notification/settings',
            null,
            {
                phoneForNotifications: number ? formattedNumber : undefined,
                sendEmailWithDetails: false,
                sendSmsWithDetails: false,
                emailsForNotifications: [emails]
            },
            {
                handleErrorInternally: true
            }
        );
    };

    _submitProfile = () => {
        this.submitProfileNotific();
        // onChangeProfileForm();
    };

    render() {
        const {
            user,
            carbookValues,
            intl: { formatMessage },
            businessNetworks
        } = this.props;

        const { number, emails } = this.state;

        return (
            <Form
                className={Styles.profileForm}
                labelCol={formItemLayout.labelCol}
                name='profileForm'
                onFinish={this._submitProfile}
                wrapperCol={formItemLayout.wrapperCol}
            >
                <Form.Item
                    label={<FormattedMessage id='general_settings.e_mail' />}
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!'
                        }
                    ]}
                >
                    <Input
                        onChange={event =>
                            this.setState({
                                emails: event.target.value
                            })
                        }
                        value={emails}
                    />
                </Form.Item>

                <Form.Item label={<FormattedMessage id='add_client_form.phone_number_responsible' />}>
                    <div
                        style={{
                            marginBottom: 8
                        }}
                    >
                        <PhoneInput
                            onChange={value => {
                                this.setState({
                                    number: value
                                });
                            }}
                            style={{ width: '100%' }}
                            placeholder={formatMessage({
                                id: 'nRegNew'
                            })}
                            // formatter={value => phoneNumberFormatter(value, user.country)}
                            value={number}
                        />
                    </div>
                </Form.Item>
                <Form.Item
                    className={Styles.itemBtn}
                    wrapperCol={{
                        offset: 11,
                        span: 12
                    }}
                >
                    <Button className={Styles.saveBtn} htmlType='submit' type='primary'>
                        <FormattedMessage id='save' />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
