import { RollbackOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Radio } from 'antd';
import { Layout } from 'commons';
import Styles from 'components/DatePicker/styles.m.css';
import { statusValues } from 'pages/TaskPage/constants';
import TaskContainer from 'pages/TaskPage/containers/TaskContainer';
import { PostponedTaskModal, SetManagerModal } from 'pages/TaskPage/modals';
import {
    closeTask,
    fetchManagers,
    fetchTask,
    selectTask,
    setManagerModalVisibility,
    setPostponedTaskModalVisibility,
    updateTask
} from 'pages/TaskPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    user: state.auth,
    task: selectTask(state)
});
const mapDispatchToProps = {
    fetchTask,
    fetchManagers,
    setManagerModalVisibility,
    setPostponedTaskModalVisibility,
    closeTask,
    updateTask
    // setModal,
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TaskPage extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;

        this.props.fetchManagers();
        this.props.fetchTask(id);
    }

    componentDidUpdate(prevProps) {
        // if vehicle was created then after redirect need to prefetch data
        if (this.props.location !== prevProps.location) {
            this.props.fetchManagers();
            this.props.fetchTask(this.props.match.params.id);
        }
    }

    render() {
        const {
            user,
            filters,
            stats,
            task,
            updateTask,
            closeTask,
            setManagerModalVisibility,
            setPostponedTaskModalVisibility,
            intl: { formatMessage }
        } = this.props;

        const taskActionsDisabled =
            task.status === statusValues.COMPLETED ||
            task.status === statusValues.POSTPONED ||
            task.status === statusValues.DELEGATED;

        return (
            <Layout
                controls={
                    <div>
                        <Button
                            onClick={async () => {
                                await fetchAPI('POST', 'employee_tasks_cancel', null, {
                                    taskId: task.id
                                });
                                window.location.reload();
                            }}
                            style={{
                                marginRight: '15px'
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='cancel' />
                        </Button>
                        <span style={{ marginRight: '2em' }}>
                            <Dropdown
                                className={Styles.datePickerButton}
                                overlay={
                                    <Menu>
                                        <Menu.Item
                                            disabled={task.status != 'INWORK'}
                                            onClick={() => {
                                                updateTask(task.id, { status: 'NEW' });
                                            }}
                                        >
                                            <FormattedMessage id='task_page.new' />
                                        </Menu.Item>
                                        <Menu.Item disabled>
                                            <FormattedMessage id='task_page.inwork' />
                                        </Menu.Item>
                                        <Menu.Item
                                            disabled={taskActionsDisabled}
                                            onClick={() => {
                                                closeTask();
                                            }}
                                        >
                                            <FormattedMessage id='task_page.close' />
                                        </Menu.Item>
                                        <Menu.Item
                                            disabled={taskActionsDisabled}
                                            onClick={() => {
                                                setManagerModalVisibility(true);
                                            }}
                                        >
                                            <FormattedMessage id='task_page.delegate' />
                                        </Menu.Item>
                                        <Menu.Item
                                            disabled={taskActionsDisabled}
                                            onClick={() => {
                                                setPostponedTaskModalVisibility(true);
                                            }}
                                        >
                                            <FormattedMessage id='task_page.postponed' />
                                        </Menu.Item>
                                    </Menu>
                                }
                                overlayStyle={{ zIndex: 9999 }}
                            >
                                <Button type='primary'>
                                    <FormattedMessage id='task_page.change_status' />
                                </Button>
                            </Dropdown>
                        </span>
                        <span>
                            <Link to={`${book.myTasksPage}`}>
                                <Button>
                                    <RollbackOutlined />
                                </Button>
                            </Link>
                        </span>
                    </div>
                }
                title={<FormattedMessage id='task_page.title' />}
            >
                <TaskContainer />
                <SetManagerModal />
                <PostponedTaskModal />
            </Layout>
        );
    }
}
