import { Flex, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import StatusButtons, { statusDisplayMap } from '../StatusButtons';
import Styles from './styles.m.css';

const DiagnosticUnitRectangle = ({
    unit,
    aspectRatio,
    putOrderScheme,
    orderUnits,
    isMobile,
    intl: { formatMessage },
    downloadPhoto,
    photoLoading,
    disabledPhoto,
    orderId,
    orderNum
}) => {
    const { plusX, plusY, tooltip, id } = unit;
    const orderUnit = useMemo(() => orderUnits.find(({ unitId }) => unitId === unit.id) || {}, [orderUnits, unit.id]);
    const isSelected = useMemo(() => Object.keys(orderUnit).length, [orderUnit]);

    return (
        <Tooltip
            color='white'
            placement='top'
            title={
                <Flex gap={8} vertical>
                    <p className={Styles.tooltipText}>{tooltip}</p>

                    {!orderUnit.blocked && (
                        <StatusButtons
                            disabledPhoto={disabledPhoto}
                            downloadPhoto={downloadPhoto}
                            formatMessage={formatMessage}
                            isSelected={isSelected}
                            orderId={orderId}
                            orderNum={orderNum}
                            orderUnit={orderUnit}
                            photoLoading={photoLoading}
                            putOrderScheme={putOrderScheme}
                            unit={unit}
                        />
                    )}
                </Flex>
            }
        >
            <div
                className={Styles.unitRect}
                style={
                    isMobile
                        ? {
                              left: `${(plusX / 2047) * 100}%`,
                              top: `${(plusY / 2420) * 100 * 1.07}%`,
                              width: 14,
                              height: 14,
                              border: `2px solid ${
                                  isSelected ? statusDisplayMap[orderUnit.status].color : 'var(--db_reserve)'
                              }`,
                              ...(isSelected ? { backgroundColor: statusDisplayMap[orderUnit.status].color } : {}),
                              opacity: isSelected ? 0.5 : 1
                          }
                        : {
                              left: plusX * aspectRatio,
                              top: plusY * aspectRatio,
                              width: 60 * aspectRatio,
                              height: 60 * aspectRatio,
                              border: `2px solid ${
                                  isSelected ? statusDisplayMap[orderUnit.status].color : 'var(--db_reserve)'
                              }`,
                              ...(isSelected ? { backgroundColor: statusDisplayMap[orderUnit.status].color } : {}),
                              opacity: isSelected ? 0.5 : 1
                          }
                }
            ></div>
        </Tooltip>
    );
};

export default injectIntl(DiagnosticUnitRectangle);
