/* eslint-disable no-underscore-dangle */
/* eslint-disable react/sort-comp */
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Select, Table } from 'antd';
import { Catcher } from 'commons';
import { fetchWarehouses } from 'core/warehouses/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    fetchWarehouses
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class WMSCellsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            warehouseId: undefined,
            count: 1,
            pageSize: 15,
            page: 1
        };

        this.handleSearch = _.debounce(() => {
            this.fetchCells();
        }, 1000);

        this.columns = [
            {
                title: <FormattedMessage id='wms.address' />,
                key: 'address',
                dataIndex: 'address'
            },
            {
                title: <FormattedMessage id='wms.width' />,
                key: 'width',
                dataIndex: 'width'
            },
            {
                title: <FormattedMessage id='wms.height' />,
                key: 'height',
                dataIndex: 'height'
            },
            {
                title: <FormattedMessage id='wms.depth' />,
                key: 'depth',
                dataIndex: 'depth'
            },
            {
                title: <FormattedMessage id='wms.volume' />,
                key: 'volume',
                dataIndex: 'volume'
            },
            {
                title: <FormattedMessage id='wms.weight' />,
                key: 'weight',
                dataIndex: 'weight'
            },
            {
                title: <FormattedMessage id='wms.fullness' />,
                key: 'fullness',
                dataIndex: 'fullness'
            },
            {
                key: 'action',
                dataIndex: 'address',
                render: address => {
                    return (
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={() => {
                                this._handleOk(address);
                            }}
                        />
                    );
                }
            }
        ];
    }

    _showModal = async () => {
        const { warehouseId, warehouses } = this.props;

        await this.setState({
            warehouseId: warehouseId || warehouses[0].id
        });

        this.fetchCells();
    };

    fetchCells = async () => {
        const { warehouseId, pageSize, page, filterQuery } = this.state;
        const { list, stats } = await fetchAPI('GET', 'wms/cells', {
            warehouseId,
            page,
            filterQuery,
            pageSize
        });
        this.setState({
            dataSource: list.filter(({ enabled }) => enabled),
            stats
        });
    };

    _hideModal = async () => {
        this.state = {};
        this.setState({
            dataSource: []
        });
        this.props.hideModal();
    };

    _handleOk = async address => {
        const { warehouseId, count } = this.state;
        await this.props.confirmAction(address, warehouseId, count);
        await this._hideModal();
    };

    componentDidMount() {
        this.props.fetchWarehouses();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.visible && !prevProps.visible) {
            this._showModal();
        }
    }

    render() {
        const {
            user,
            intl: { formatMessage },
            visible,
            warehouses,
            selectedCell,
            fixedWarehouse
        } = this.props;
        const { dataSource, warehouseId, count, pageSize, page, stats, filterQuery } = this.state;

        const pagination = {
            pageSize,
            size: 'small',
            total: _.get(stats, 'count'),
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });

                this.fetchCells();
            }
        };

        return (
            <Catcher>
                <Modal
                    destroyOnClose
                    footer={null}
                    onCancel={this._hideModal}
                    style={{
                        minWidth: 580
                    }}
                    title={<FormattedMessage id='wms.cell_select' />}
                    visible={visible}
                    width='fit-content'
                >
                    <div className={Styles.modalHeader}>
                        <div
                            style={{
                                width: '100%'
                            }}
                        >
                            <span style={{ paddingLeft: 4, fontSize: 12 }}>
                                <FormattedMessage id='storage' />
                            </span>
                            <Select
                                disabled={fixedWarehouse}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999'
                                }}
                                onChange={async warehouseId => {
                                    await this.setState({
                                        warehouseId
                                    });

                                    this.fetchCells();
                                }}
                                placeholder={formatMessage({ id: 'storage' })}
                                showSearch
                                style={{
                                    width: '100%'
                                }}
                                value={warehouseId}
                            >
                                {warehouses.map(({ id, name }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        {!fixedWarehouse && (
                            <div>
                                <span style={{ paddingLeft: 4, fontSize: 12 }}>
                                    <FormattedMessage id='count' />
                                </span>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={fixedWarehouse}
                                    max={selectedCell && selectedCell.sum}
                                    min={0}
                                    onChange={count => {
                                        this.setState({
                                            count
                                        });
                                    }}
                                    style={{
                                        display: 'block'
                                    }}
                                    value={count}
                                />
                            </div>
                        )}
                    </div>

                    <Input.Search
                        allowClear
                        onChange={e => {
                            this.setState({
                                filterQuery: e.target.value,
                                page: 1
                            });
                            this.handleSearch();
                        }}
                        placeholder={this.props.intl.formatMessage({ id: 'barcode.search' })}
                        style={{
                            marginBottom: 16
                        }}
                        value={filterQuery}
                    />

                    <Table
                        bordered
                        columns={this.columns}
                        dataSource={dataSource || []}
                        getPopupContainer={trigger => trigger.parentNode}
                        pagination={pagination}
                        rowKey='address'
                        size='small'
                    />
                </Modal>
            </Catcher>
        );
    }
}
