import { Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import React, { Component } from 'react';

export default class PDFViewerModal extends Component {
    render() {
        const { visible, resetModal } = this.props;

        return (
            <Modal
                destroyOnClose
                footer={null}
                maskClosable={false}
                onCancel={() => resetModal()}
                visible={visible === MODALS.PDF_VIEWER}
                width='85%'
            ></Modal>
        );
    }
}
