import { Layout } from 'commons';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import OrderDetailBody from './components/OrderDetailBody';

const { injectIntl, FormattedMessage } = require('react-intl');

const OrderCataloguePage = ({ intl: { formatMessage } }) => {
    const location = useLocation();

    console.log(location.state, 'location.state');

    return (
        <Layout
            // className='settings_page'
            // controls={
            //     <a href={book.oldApp.settings}>
            //         <Button icon={<GlobalOutlined />} size='large' />
            //     </a>
            // }
            // description={<FormattedMessage id='navigation.web_settings' />}
            title={<FormattedMessage id='navigation.order_catalogue' />}
        >
            <OrderDetailBody location={location.state} />
        </Layout>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderCataloguePage));
