/**
 * Cash order has a type, depending on it will be used different requests, validation methods and options
 */
const CASH_ORDER_TYPES = Object.freeze({
    INCOME: 'INCOME',
    EXPENSE: 'EXPENSE',
    ADJUSTMENT_INCOME: 'ADJUSTMENT_INCOME',
    ADJUSTMENT_EXPENSE: 'ADJUSTMENT_EXPENSE'
});

/**
 * When we want ot create an order we have to provide counterparty. Each counterparty has its on logic for data processing.
 */
const COUNTERPARTY_TYPES = Object.freeze({
    CLIENT: 'CLIENT',
    EMPLOYEE: 'EMPLOYEE',
    BUSINESS_SUPPLIER: 'BUSINESS_SUPPLIER',
    OTHER: 'OTHER'
});

/**
 * Cash order modes, they define behavior of cash order modal
 */
const MODES = Object.freeze({
    ADD: 'ADD',
    EDIT: 'EDIT',
    PRINT: 'PRINT'
});

/**
 * Types of operations that cash order modal has to perform.
 * Different types of cash orders will be generated depending on it.
 */
const SERVICE_OPERATION_TYPES = Object.freeze({
    DEFAULT: 'DEFAULT',
    SERVICE_INPUT: 'SERVICE_INPUT',
    SERVICE_OUTPUT: 'SERVICE_OUTPUT',
    TRANSFER: 'TRANSFER'
});

/**
 * Storage document types(can be used to filter documents)
 */
const STORAGE_DOCUMENT_TYPES = {
    SUPPLIER: 'SUPPLIER',
    CLIENT: 'CLIENT',
    INVENTORY: 'INVENTORY',
    OWN_CONSUMPTION: 'OWN_CONSUMPTION',
    TRANSFER: 'TRANSFER',
    SERVICE: 'SERVICE'
};

/**
 * Storage document context is a place where documents were taken.
 * \
 * Developer says: context = environment = середовище документа
 */
const STORAGE_DOCUMENT_CONTEXTS = {
    STOCK: 'STOCK',
    ORDER: 'ORDER'
};

/**
 * Each storage document has its operation code(SRV, ORD, INC, OUT etc.)
 * Those constants can be used to filter store docs.
 */
const STORAGE_DOCUMENT_OPERATION_CODES = {
    INC: 'INC',
    CRT: 'CRT',
    STP: 'STP',
    SRV: 'SRV',
    OUT: 'OUT',
    SRT: 'SRT',
    CST: 'CST',
    STM: 'STM',
    TSF: 'TSF',
    RES: 'RES',
    ORD: 'ORD',
    BOR: 'BOR',
    COM: 'COM',
    TOL: 'TOL',
    TOR: 'TOR',
    VRT: 'VRT',
    KPM: 'KPM',
    KPP: 'KPP',
    PRI: 'PRI',
    PRO: 'PRO',
    DSI: 'DSI',
    DSO: 'DSO'
};

/** Used to represent date for a user */
const DEF_UI_DATE = 'DD.MM.YYYY';

/** Used to represent date and time for a user */
const DEF_UI_DATETIME = 'DD.MM.YYYY HH:mm';

/** Specific format for beck-end date, cast to this when request is sending */
const DEF_BACK_DATE = 'YYYY-MM-DD';

export {
    CASH_ORDER_TYPES,
    COUNTERPARTY_TYPES,
    DEF_BACK_DATE,
    DEF_UI_DATE,
    DEF_UI_DATETIME,
    MODES,
    SERVICE_OPERATION_TYPES,
    STORAGE_DOCUMENT_CONTEXTS,
    STORAGE_DOCUMENT_OPERATION_CODES,
    STORAGE_DOCUMENT_TYPES
};
