import { CarFilled } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Select, Tooltip, TreeSelect } from 'antd';
import { fetchStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import { fetchVehicleColors, selectColors } from 'modals/VehicleModal/redux/duck';
import { default as React, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildStoreGroupsTree, filterTreeNodeByPart } from 'utils';
import Styles from './styles.m.css';

const layout = {
    labelCol: {
        span: 8
    },
    wrapperCol: {
        span: 16
    }
};

const tripleCheckLayout = {
    labelCol: { span: 16 },
    wrapperCol: {
        span: 8
    }
};

const buttonFields = ['front', 'top', 'external', 'rear', 'bottom', 'internal', 'left', 'central', 'right'];

const conditionOptions = [
    { label: 'detail_order.new', value: 'NEW' },
    { label: 'detail_order.used', value: 'USED' }
];

const mapStateToProps = state => ({
    colors: selectColors(state),
    storeGroups: selectStoreGroups(state)
});
const mapDispatchToProps = {
    fetchVehicleColors,
    fetchStoreGroups
};

const SearchDetailModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    injectIntl(
        ({
            onClose,
            open,
            onOk,
            colors,
            fetchVehicleColors,
            storeGroups,
            fetchStoreGroups,
            intl: { formatMessage }
        }) => {
            const [form] = Form.useForm();
            const [vehicleIconColor, setVehicleIconColor] = useState();
            const [storeGroupsTree, setStoreGroupsTree] = useState(storeGroups);
            const [buttonState, setButtonState] = useState({});

            const handleOk = () => {
                const cleanObj = Object.fromEntries(
                    Object.entries({ ...form.getFieldsValue(), ...buttonState }).filter(([, value]) => value)
                );
                cleanObj.query = null;
                onOk(cleanObj);
                onClose();
            };

            const toggleButton = propName => {
                return () => setButtonState(prev => ({ ...prev, [propName]: !prev[propName] }));
            };

            const resetForm = () => {
                setButtonState({});
                form.resetFields();
            };

            useEffect(() => {
                if (!storeGroups.length) fetchStoreGroups();
                const tree = buildStoreGroupsTree(storeGroups);
                setStoreGroupsTree(tree);
            }, [fetchStoreGroups, storeGroups]);

            useEffect(() => {
                fetchVehicleColors();
            }, [fetchVehicleColors]);

            return (
                <Modal
                    footer={[
                        <Button onClick={resetForm}>
                            <FormattedMessage id='detail_order.reset_filters' />
                        </Button>,
                        <Button onClick={onClose}>
                            <FormattedMessage id='cancel' />
                        </Button>,
                        <Button onClick={handleOk} type='primary'>
                            <FormattedMessage id='ok' />
                        </Button>
                    ]}
                    onCancel={onClose}
                    open={open}
                    title={formatMessage({ id: 'filters' })}
                >
                    <Form form={form} name='detail-search' {...layout} layout='horizontal'>
                        <Form.Item label={formatMessage({ id: 'detail_order.name' })} name='name'>
                            <Input />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'detail_order.colorId' })} name='colorId'>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={(value, option) => {
                                    form.setFieldValue('colorId', value);
                                    setVehicleIconColor(option.color);
                                }}
                                optionFilterProp='label'
                                options={colors.map(({ colorId, name, colorCode }) => ({
                                    label: name,
                                    value: colorId,
                                    color: colorCode
                                }))}
                                placeholder={formatMessage({
                                    id: 'add_client_form.color_placeholder'
                                })}
                                showSearch
                                suffixIcon={
                                    form.getFieldsValue('colorId') !== 6 ? (
                                        <Tooltip title={<FormattedMessage id='add_client_form.color_placeholder' />}>
                                            <CarFilled
                                                style={{
                                                    color: vehicleIconColor,
                                                    backgroundColor: '#e3e3e3',
                                                    fontSize: 16,
                                                    borderRadius: 16,
                                                    padding: 3
                                                }}
                                            />
                                        </Tooltip>
                                    ) : undefined
                                }
                            />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'detail_order.groupId' })} name='groupId'>
                            <TreeSelect
                                filterTreeNode={filterTreeNodeByPart}
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                placeholder={formatMessage({
                                    id: 'storage.product_group'
                                })}
                                popupMatchSelectWidth={false}
                                showSearch
                                treeData={storeGroupsTree}
                            />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'detail_order.condition' })} name='condition'>
                            <Select
                                options={conditionOptions.map(({ label, value }) => ({
                                    label: formatMessage({ id: label }),
                                    value
                                }))}
                            />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'detail_order.length' })} name='length'>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'detail_order.width' })} name='width'>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'detail_order.height' })} name='height'>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'detail_order.weight' })} name='weight'>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({ id: 'detail_order.external_diameter' })}
                            name='externalDiameter'
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({ id: 'detail_order.internal_diameter' })}
                            name='internalDiameter'
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'comment' })} name='comment'>
                            <Input.TextArea />
                        </Form.Item>
                        <div className={Styles.buttonWrap}>
                            {buttonFields.map(propName => (
                                <Button
                                    key={propName}
                                    onClick={toggleButton(propName)}
                                    style={{ width: '30%' }}
                                    type={buttonState[propName] ? 'primary' : 'default'}
                                >
                                    {formatMessage({ id: `detail_order.${propName}` })}
                                </Button>
                            ))}
                        </div>
                    </Form>
                </Modal>
            );
        }
    )
);

export default SearchDetailModal;
