import classNames from 'classnames';
import { Numeral } from 'commons';
import { clearUniversalFilters } from 'core/forms/universalFiltersForm/duck';
import {
    fetchOrdersStats,
    resetOrdersDaterangeFilter,
    setOrdersSearchFilter,
    setOrdersStatusFilter
} from 'core/orders/duck';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import book from 'routes/book';
import { images } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    stats: state.orders.stats,
    query: state.orders.filter.query
});

const mapDispatchToProps = {
    fetchOrdersStats,
    setOrdersStatusFilter,
    resetOrdersDaterangeFilter,
    clearUniversalFilters,
    setOrdersSearchFilter
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class FunelContainer extends Component {
    setStatus = status => {
        if (status === 'success' || status === 'cancel' || status === 'stop' || status === 'review') {
            this.props.resetOrdersDaterangeFilter();
        }

        this.props.clearUniversalFilters();
        this.props.setOrdersStatusFilter(status);
        this.props.setOrdersSearchFilter(this.props.query);
    };

    render() {
        const { stats, query } = this.props;

        return stats ? (
            <div className={Styles.funel}>
                <div
                    className={Styles.funel__turn}
                    style={{
                        backgroundImage: `url('${images.funelArrowCurvedLeft}')`,
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <div
                    className={classNames(Styles.funel__main, Styles.funel__wrapper, Styles['funel__wrapper--column'])}
                >
                    <div className={Styles.funel__wrapper}>
                        <NavLink
                            activeClassName={Styles['funel__tabs__link--active']}
                            className={Styles.funel__tabs__link}
                            exact
                            onClick={() => this.setStatus('not_complete,required,call,reserve')}
                            to={{
                                pathname: `${book.orders}/appointments`,
                                state: {
                                    status: 'not_complete,required,call,reserve',
                                    searchValue: query
                                }
                            }}
                        >
                            <FormattedMessage id='appointments' />
                            <span className={Styles.count}>
                                (<Numeral>{stats.not_complete + stats.call + stats.required + stats.reserve}</Numeral>)
                            </span>
                        </NavLink>
                        <NavLink
                            activeClassName={Styles['funel__tabs__link--active']}
                            className={Styles.funel__tabs__link}
                            exact
                            onClick={() => this.setStatus('processing')}
                            to={{
                                pathname: `${book.orders}/processing`,
                                state: {
                                    status: 'processing',
                                    searchValue: query
                                }
                            }}
                        >
                            <FormattedMessage id='order_statuses_mapper.processing' />
                            <span className={Styles.count}>
                                (<Numeral>{stats.processing}</Numeral>)
                            </span>
                        </NavLink>
                        <NavLink
                            activeClassName={Styles['funel__tabs__link--active']}
                            className={Styles.funel__tabs__link}
                            exact
                            onClick={() => this.setStatus('approve')}
                            to={{
                                pathname: `${book.orders}/approve`,
                                state: { status: 'approve', searchValue: query }
                            }}
                        >
                            <FormattedMessage id='records' />
                            <span className={Styles.count}>
                                (<Numeral>{stats.approve}</Numeral>)
                            </span>
                        </NavLink>
                        <NavLink
                            activeClassName={Styles['funel__tabs__link--active']}
                            className={Styles.funel__tabs__link}
                            exact
                            onClick={() => this.setStatus('progress')}
                            to={{
                                pathname: `${book.orders}/progress`,
                                state: { status: 'progress', searchValue: query }
                            }}
                        >
                            <FormattedMessage id='repairs' />
                            <span className={Styles.count}>
                                (<Numeral>{stats.progress}</Numeral>)
                            </span>
                        </NavLink>
                        <NavLink
                            activeClassName={Styles['funel__tabs__link--active']}
                            className={Styles.funel__tabs__link}
                            exact
                            onClick={() => this.setStatus('success')}
                            to={{
                                pathname: `${book.orders}/success`,
                                state: { status: 'success', searchValue: query }
                            }}
                        >
                            <FormattedMessage id='done' />
                            <span className={Styles.count}>
                                (<Numeral>{stats.success}</Numeral>)
                            </span>
                        </NavLink>
                    </div>
                    <div className={classNames(Styles.funel__wrapper, Styles['funel__wrapper--bottom'])}>
                        <NavLink
                            activeClassName={Styles['funel__tabs__link--active--reverse']}
                            className={Styles['funel__tabs__link--reverse']}
                            exact
                            onClick={() => this.setStatus('invite')}
                            to={{
                                pathname: `${book.orders}/invitations`,
                                state: { status: 'invite', searchValue: query }
                            }}
                        >
                            <FormattedMessage id='invitations' />
                            <span className={Styles.count}>
                                (<Numeral>{stats.invite}</Numeral>)
                            </span>
                        </NavLink>
                        <NavLink
                            activeClassName={Styles['funel__tabs__link--active--reverse']}
                            className={Styles['funel__tabs__link--reverse']}
                            exact
                            onClick={() => this.setStatus('review')}
                            to={{
                                pathname: `${book.orders}/reviews`,
                                state: { status: 'review', searchValue: query }
                            }}
                        >
                            <FormattedMessage id='reviews' />
                            <span className={Styles.count}>
                                (<Numeral>{stats.review}</Numeral>)
                            </span>
                        </NavLink>
                    </div>
                </div>
                <div
                    className={Styles['funel__turn--arrow']}
                    style={{
                        backgroundImage: `url('${images.funelArrowCurvedRight}')`,
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <div
                    className={classNames(
                        Styles.funel__wrapper,
                        Styles.funel__additional,
                        Styles['funel__wrapper--column']
                    )}
                >
                    <NavLink
                        activeClassName={Styles['funel__tabs__link--active']}
                        className={Styles.funel__tabs__link}
                        exact
                        onClick={() => this.setStatus('cancel')}
                        to={{
                            pathname: `${book.orders}/cancel`,
                            state: { status: 'cancel', searchValue: query }
                        }}
                    >
                        <FormattedMessage id='cancels' />
                        <span className={Styles.count}>
                            (<Numeral>{stats.cancel}</Numeral>)
                        </span>
                    </NavLink>
                    <NavLink
                        activeClassName={Styles['funel__tabs__link--active--reverse']}
                        className={`${Styles['funel__tabs__link--reverse']} ${Styles['funel__tabs__link--bottom']} `}
                        exact
                        onClick={() => this.setStatus('stop')}
                        to={{
                            pathname: `${book.orders}/stop`,
                            state: { status: 'stop', searchValue: query }
                        }}
                    >
                        <FormattedMessage id='transfer_stop' />
                        <span className={Styles.count}>
                            (<Numeral>{stats.stop}</Numeral>)
                        </span>
                    </NavLink>
                    {/* <div
                        className={`${Styles['funel__tabs__link--reverse']} ${Styles['funel__tabs__link--bottom']} `}
                    /> */}
                </div>
                <div
                    className={Styles['funel__turn--arrow']}
                    style={{
                        backgroundImage: `url('${images.funelArrowCurvedRight}')`,
                        backgroundRepeat: 'no-repeat'
                    }}
                />
            </div>
        ) : (
            <div>
                <FormattedMessage id='loading' />
            </div>
        );
    }
}

export default FunelContainer;
