import { Button, Col, DatePicker, Input, notification, Radio, Row, Select, Spin, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { DEF_DATE_FORMAT, DEF_TIME_FORMAT, priorityArrayValues, statusValues } from 'pages/TaskPage/constants';
import {
    closeTask,
    selectFetchingTask,
    selectManagers,
    selectTask,
    setManagerModalVisibility,
    setPostponedTaskModalVisibility,
    updateTask
} from 'pages/TaskPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;
const { TextArea } = Input;

const mapStateToProps = state => ({
    user: state.auth,
    task: selectTask(state),
    managers: selectManagers(state),
    fetching: selectFetchingTask(state),
    isMobile: state.ui.views.isMobile
});
const mapDispatchToProps = {
    setManagerModalVisibility,
    setPostponedTaskModalVisibility,
    closeTask,
    updateTask
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TaskContainer extends Component {
    render() {
        const {
            user,
            task,
            managers,
            fetching,
            setManagerModalVisibility,
            setPostponedTaskModalVisibility,
            updateTask,
            closeTask,
            intl: { formatMessage }
        } = this.props;
        const taskActionsDisabled =
            task.status === statusValues.COMPLETED ||
            task.status === statusValues.POSTPONED ||
            task.status === statusValues.DELEGATED;
        const dueDatetime =
            task.status === statusValues.COMPLETED
                ? dayjs(task.completeDatetime).format('MMMM Do YYYY, h:mm:ss')
                : `${task.toDueDatetimeDay} ${formatMessage({
                      id: 'universal_filters_form.days'
                  })} ${task.toDueDatetimeHour}:${task.toDueDatetimeMinute} `;

        return fetching ? (
            <Spin />
        ) : (
            <div style={{ width: '100%' }}>
                {task.orderNum || task.storeDocNum || task.cashOrderId ? (
                    <Row className={Styles.row}>
                        <Col span={3}>
                            <span style={{ fontWeight: 'bold' }}>
                                <FormattedMessage id='storage_document.document' />
                            </span>
                            :
                        </Col>
                        <Col span={6}>
                            <div>
                                <div
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: task.orderNum
                                    }}
                                >
                                    {(task.orderId && (
                                        <Link to={`${book.order}/${task.orderId || this.props.orderId}`}>
                                            {task.orderNum}
                                        </Link>
                                    )) ||
                                        (task.storeDocNum && (
                                            <Link to={`${book.storageDocument}/${task.storeDocNum.split('-')[2]}`}>
                                                {task.storeDocNum}
                                            </Link>
                                        )) ||
                                        (task.cashOrderId && (
                                            <Link to={`${book.cashFlowPage}`}>{task.cashOrderId}</Link>
                                        ))}
                                </div>
                            </div>
                        </Col>

                        <Col offset={1} span={3}>
                            <FormattedMessage id='storage_journal.counterpartyName' />:
                        </Col>
                        <Col span={6}>
                            <Input
                                disabled
                                style={{ color: 'var(--text)', fontWeight: '400' }}
                                value={task.counterpartClientFullName || task.counterpartBusinessSupplierFullName}
                            />
                        </Col>
                    </Row>
                ) : null}
                <Row className={Styles.row}>
                    <Col span={3}>
                        <FormattedMessage id='task_page.name' />:
                    </Col>
                    <Col span={6}>
                        <Input disabled style={{ color: 'var(--text)', fontWeight: '400' }} value={task.name} />
                    </Col>

                    <Col offset={1} span={3}>
                        <FormattedMessage id='task_page.status' />:
                    </Col>
                    <Col span={6}>
                        <Input
                            disabled
                            style={{ color: 'var(--text)', fontWeight: '400' }}
                            value={
                                task.status
                                    ? formatMessage({
                                          id: `task_page.status_${String(task.status).toLowerCase()}`
                                      })
                                    : ''
                            }
                        />
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col span={3}>
                        <FormattedMessage id='task_page.created' />:
                    </Col>
                    <Col span={6}>
                        <Select
                            disabled
                            dropdownStyle={{ zIndex: '9999' }}
                            placeholder={<FormattedMessage id='task_page.created' />}
                            style={{ color: 'var(--text)', fontWeight: '400' }}
                            value={task.giverId}
                        >
                            {managers.map(elem => (
                                <Option key={elem.managerId} value={elem.managerId}>
                                    {`${_.get(elem, 'managerName', '')} ${_.get(elem, 'managerSurname', '')}`}
                                </Option>
                            ))}
                        </Select>
                    </Col>

                    <Col offset={1} span={3}>
                        <FormattedMessage id='task_page.performer' />:
                    </Col>
                    <Col span={6}>
                        <Select
                            disabled
                            dropdownStyle={{ zIndex: '9999' }}
                            mode='multiple'
                            placeholder={<FormattedMessage id='task_page.performer' />}
                            style={{ color: 'var(--text)', fontWeight: '400' }}
                            value={task.performerIds}
                        >
                            {managers.map(elem => (
                                <Option key={elem.managerId} value={elem.managerId}>
                                    {`${_.get(elem, 'managerName', '')} ${_.get(elem, 'managerSurname', '')}`}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col span={3}>
                        <FormattedMessage id='task_page.create' />:
                    </Col>
                    <Col span={6}>
                        <Input
                            disabled
                            style={{ color: 'var(--text)', fontWeight: '400' }}
                            value={task.creationDatetime}
                        />
                    </Col>

                    <Col offset={1} span={3}>
                        <FormattedMessage id='task_page.due' />:
                    </Col>
                    <Col span={3}>
                        <div className={Styles.datepickerContainer} style={{ color: 'var(--text)', fontWeight: '400' }}>
                            <DatePicker
                                disabled
                                value={task.scheduledDatetime ? dayjs(task.scheduledDatetime, DEF_DATE_FORMAT) : null}
                            />
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className={Styles.timepickerContainer}>
                            <TimePicker
                                // popupStyle={{color: 'var(--text)', fontWeight: '400'}}
                                disabled
                                format={DEF_TIME_FORMAT}
                                value={
                                    task.scheduledDatetime ? dayjs(task.scheduledDatetime /* DEF_TIME_FORMAT */) : null
                                }
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col span={3}>
                        <FormattedMessage id='task_page.priority' />:
                    </Col>
                    <Col span={6}>
                        <Select
                            disabled
                            dropdownStyle={{ zIndex: '9999' }}
                            placeholder={<FormattedMessage id='task_page.priority_placeholder' />}
                            style={{ color: 'var(--text)', fontWeight: '400' }}
                            value={task.priority}
                        >
                            {priorityArrayValues.map(elem => (
                                <Option key={elem} value={elem}>
                                    <FormattedMessage id={`task_page.priority.${String(elem).toLowerCase()}`} />
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col offset={1} span={3}>
                        <FormattedMessage id='task_page.time_left' />:
                    </Col>
                    <Col span={6}>
                        <Input disabled style={{ color: 'var(--text)', fontWeight: '400' }} value={dueDatetime} />
                    </Col>
                </Row>
                <Row className={(Styles.rowComment, Styles.row)}>
                    <Col span={3}>
                        <FormattedMessage id='task_page.comment_created' />:
                    </Col>
                    <Col span={6}>
                        <TextArea
                            autoSize={{ minRows: 2 }}
                            defaultValue={task.comment}
                            disabled={user.id !== task.giverId}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={e => {
                                this.setState({ comment: e.target.value });
                            }}
                            placeholder={formatMessage({
                                id: 'task_page.comment_placeholder'
                            })}
                            style={{ color: 'var(--text)', fontWeight: '400' }}
                        />
                    </Col>

                    <Col offset={1} span={3}>
                        <FormattedMessage id='task_page.comment_performer' />:
                    </Col>
                    <Col span={6}>
                        <TextArea
                            autoSize={{ minRows: 2 }}
                            defaultValue={task.counterpartComment}
                            disabled={!(task.performerIds || []).includes(user.id)}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={e => {
                                this.setState({ counterpartComment: e.target.value });
                            }}
                            placeholder={formatMessage({
                                id: 'task_page.comment_placeholder'
                            })}
                            style={{ color: 'var(--text)', fontWeight: '400' }}
                        />
                    </Col>
                </Row>

                <Row className={Styles.row}>
                    <Col span={19}>
                        <span style={{ float: 'right', padding: '1em 0 1em 0' }}>
                            <Button
                                disabled={!this.state || !(this.state.comment || this.state.counterpartComment)}
                                onClick={async () => {
                                    await fetchAPI('PUT', `employee_tasks/${task.id}`, null, {
                                        comment: this.state.comment,
                                        counterpartComment: this.state.counterpartComment
                                    });
                                    notification.success({
                                        message: formatMessage({ id: 'barcode.success' })
                                    });
                                    this.setState({
                                        comment: undefined,
                                        counterpartComment: undefined
                                    });
                                }}
                                type='primary'
                            >
                                <FormattedMessage id='save' />
                            </Button>
                        </span>
                    </Col>
                </Row>
            </div>
        );
    }
}
