/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import {
    AppstoreFilled,
    AppstoreOutlined,
    AreaChartOutlined,
    BarChartOutlined,
    BarcodeOutlined,
    BellFilled,
    BellOutlined,
    CarOutlined,
    ContactsOutlined,
    ControlOutlined,
    CreditCardOutlined,
    DatabaseOutlined,
    DeploymentUnitOutlined,
    DollarCircleOutlined,
    FileAddOutlined,
    FileTextOutlined,
    FundOutlined,
    GlobalOutlined,
    HomeOutlined,
    HourglassOutlined,
    IdcardOutlined,
    InsertRowAboveOutlined,
    IssuesCloseOutlined,
    LikeOutlined,
    LoginOutlined,
    LogoutOutlined,
    PhoneOutlined,
    PoweroffOutlined,
    PrinterOutlined,
    RocketOutlined,
    ScheduleOutlined,
    SearchOutlined,
    SettingOutlined,
    SolutionOutlined,
    TableOutlined,
    ToolOutlined,
    TransactionOutlined,
    UnorderedListOutlined,
    UploadOutlined,
    UserOutlined,
    UsergroupAddOutlined,
    VideoCameraFilled
} from '@ant-design/icons';
import { AutoComplete, Avatar, Badge, Dropdown, Input, Menu, Space, Tooltip } from 'antd';
import { Loader } from 'commons';
import { GlobalRequisitesSelector } from 'components';
import { selectAdmin, selectState } from 'core/auth/duck';
import {
    fetchEmployeeAcceptTaskCount,
    fetchEmployeeTaskCount,
    selectAcceptTaskCount,
    selectTaskCount,
    setTaskAcceptCount,
    setTaskCount
} from 'core/employees/duck';
import { setBusiness } from 'core/forms/switchBusinessForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { fetchHeaderStats, selectReservedOrderCount, setReservedOrderCount } from 'core/orders/duck';
import { setCollapsedState } from 'core/ui/duck';
import _ from 'lodash';
import { SwitchBusinessModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import book from 'routes/book';
import * as io from 'socket.io-client';
import history from 'store/history';
import { fetchAPI, getHeaderIcons, images } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import menuConfig from '../Navigation/menuConfig';
import { Banner } from './Banner';
import { Subscriptions } from './Subscriptions';
import Styles from './styles.m.css';

// const socket = io.connect('http://127.0.0.1:14281') //connect socket to server
const socket = io.connect(__API_URL__, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    transports: ['websocket', 'polling', 'flashsocket']
}); // connect socket to server

const headerIcons = [
    {
        icon: <FileAddOutlined />,
        link: book.newDocumentPage,
        tooltip: 'navigation.new_document',
        enabled: user => isGrantAccessed(user, grants.WORKPLACE_QUICK_NAVIGATION),
        key: 'FAST_NAVIGATION'
    },
    {
        icon: <BarcodeOutlined />,
        link: book.barcodePage,
        tooltip: 'navigation.barcode',
        enabled: user => isGrantAccessed(user, grants.WORKPLACE_BARCODES),
        key: 'BARCODE'
    },
    {
        icon: <ScheduleOutlined />,
        link: book.dashboard,
        tooltip: 'navigation.planner',
        enabled: user => isGrantAccessed(user, grants.OPERATIONS_PLANNER),
        key: 'SCHEDULE'
    },
    {
        icon: <ToolOutlined />,
        link: book.ordersAppointments,
        tooltip: 'navigation.workflow',
        enabled: user => isGrantAccessed(user, grants.OPERATIONS_ORDER_LIST),
        key: 'ORDERS'
    },
    {
        icon: <SettingOutlined />,
        link: book.sparePartsWorkplacePage,
        tooltip: 'profile.spare_parts_workplace.title',
        enabled: user => isGrantAccessed(user, grants.OPERATIONS_JOB_DETAILS),
        // enabled: user => true,
        key: 'ORDERS_DETAILS'
    },
    {
        icon: <ControlOutlined />,
        link: book.settingsDirectoriesPage,
        tooltip: 'navigation.other_settings',
        enabled: user => isGrantAccessed(user, grants.SETTINGS),
        key: 'OTHER_SETTINGS'
    },
    {
        icon: <ContactsOutlined />,
        link: book.directoriesPage,
        tooltip: 'navigation.directories',
        enabled: user => isGrantAccessed(user, grants.DIRECTORIES),
        key: 'CONTACTS'
    },
    {
        icon: <BarChartOutlined />,
        link: book.reportsPage,
        tooltip: 'navigation.reports',
        enabled: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS),
        key: 'REPORTS'
    },
    {
        icon: <DollarCircleOutlined />,
        link: book.cashBankPage,
        tooltip: 'navigation.cash_bank',
        enabled: user => isGrantAccessed(user, grants.ACCOUNTING_CASH_REGISTERS),
        key: 'CASH_BOX'
    },
    {
        icon: <AppstoreOutlined />,
        link: book.locationsPage,
        tooltip: 'navigation.locations',
        enabled: user => isGrantAccessed(user, grants.OPERATIONS_LOCATIONS_LOCATION_LIST),
        key: 'APPSTORE'
    },
    {
        icon: <DatabaseOutlined />,
        link: book.products,
        tooltip: 'navigation.products',
        enabled: user => isGrantAccessed(user, grants.DIRECTORIES_PRODUCTS_LIST),
        key: 'DATABASE'
    },
    {
        icon: <CarOutlined />,
        link: book.vehicles,
        tooltip: 'navigation.vehicles',
        enabled: user => isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST),
        key: 'CAR'
    },
    {
        icon: <IdcardOutlined />,
        link: book.employeesPage,
        tooltip: 'navigation.employees',
        enabled: user => isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST),
        key: 'IDCARD'
    },
    {
        icon: <RocketOutlined />,
        link: book.suppliersPage,
        tooltip: 'navigation.suppliers',
        enabled: user => isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST),
        key: 'ROCKET'
    },
    {
        icon: <HourglassOutlined />,
        link: book.laborsPage,
        tooltip: 'navigation.labors_page',
        enabled: user => isGrantAccessed(user, grants.DIRECTORIES_JOBS),
        key: 'HOURGLASS'
    },
    {
        icon: <CreditCardOutlined />,
        link: book.cashFlowPage,
        tooltip: 'navigation.flow_of_money',
        enabled: user => isGrantAccessed(user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER),
        key: 'CREDIT-CARD'
    },
    {
        icon: <PrinterOutlined />,
        link: book.reportCashOrdersLogs,
        tooltip: 'navigation.report_cash_orders_logs',
        enabled: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES),
        key: 'PRINTER'
    },
    {
        icon: <IssuesCloseOutlined />,
        link: book.receivablesAndPayablesPage,
        tooltip: 'navigation.receivables_and_payables',
        enabled: user => isGrantAccessed(user, grants.ACCOUNTING_OUTSTANDING_DEBTS),
        key: 'ISSUES-CLOSE'
    },
    {
        icon: <UnorderedListOutlined />,
        link: book.storageBalance,
        tooltip: 'navigation.storage_balance',
        enabled: user => isGrantAccessed(user, grants.WAREHOUSE_PRODUCTS_IN_STOCK),
        key: 'ORDERED-LIST'
    },
    {
        icon: <UploadOutlined />,
        link: book.storageOrders,
        tooltip: 'navigation.orders',
        enabled: user => isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS),
        key: 'UPLOAD'
    },
    {
        icon: <LoginOutlined />,
        link: book.storageIncomes,
        tooltip: 'navigation.incomes',
        enabled: user => isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS),
        key: 'LOGIN'
    },
    {
        icon: <LogoutOutlined />,
        link: book.storageExpenses,
        tooltip: 'navigation.expenses',
        enabled: user => isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES),
        key: 'LOGOUT'
    },
    {
        icon: <TableOutlined />,
        link: book.wms,
        tooltip: 'navigation.wms',
        enabled: user => isGrantAccessed(user, grants.SETTINGS_CELLS),
        key: 'TABLE'
    },
    {
        icon: <AreaChartOutlined />,
        link: book.reportOrders,
        tooltip: 'navigation.report_orders',
        enabled: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS),
        key: 'AREA-CHART'
    },
    {
        icon: <PhoneOutlined />,
        link: book.callsJournal,
        tooltip: 'navigation.call_statistics',
        enabled: user => isGrantAccessed(user, grants.CRM_CALL_LOG),
        key: 'PHONE'
    },
    {
        icon: <LikeOutlined />,
        link: book.feedback,
        tooltip: 'navigation.feedback',
        enabled: user => isGrantAccessed(user, grants.CRM_REVIEWS),
        key: 'LIKE'
    },
    {
        icon: <FundOutlined />,
        link: book.reportCashFlow,
        tooltip: 'navigation.report_cash_flow',
        enabled: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS),
        key: 'FUND'
    },
    {
        icon: <InsertRowAboveOutlined />,
        link: book.journalsPage,
        tooltip: 'navigation.journals',
        enabled: user => isGrantAccessed(user, grants.WORKPLACE_JOURNALS),
        // enabled: user => true,
        key: 'JOURNALS'
    },
    {
        icon: <UsergroupAddOutlined />,
        link: book.interactions,
        tooltip: 'navigation.interactions',
        enabled: user => isGrantAccessed(user, grants.CRM_INTERACTIONS_LIST),
        key: 'CRM'
    },
    {
        icon: <SolutionOutlined />,
        link: book.clientHotOperations,
        tooltip: 'navigation.clients',
        enabled: user => isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST),
        key: 'CLIENTS'
    },
    {
        icon: <TransactionOutlined />,
        link: book.exchangeRate,
        tooltip: 'navigation.currency',
        enabled: user => isGrantAccessed(user, grants.DIRECTORIES_CURRENCIES),
        key: 'CURRENCY'
    },
    {
        icon: <DeploymentUnitOutlined />,
        link: book.orderCatalogue,
        tooltip: 'navigation.order_catalogue',
        enabled: user => true,
        key: 'CATALOGUE'
    },
    {
        icon: <VideoCameraFilled />,
        link: book.gallaryPage,
        tooltip: 'navigation.gallary',
        enabled: user => isGrantAccessed(user, grants.SETTINGS_FILE_STORAGE),
        key: 'GALLARY'
    },
    {
        icon: <FileTextOutlined />,
        link: book.rtl,
        tooltip: 'new-document-page.item.rtl',
        enabled: user => isGrantAccessed(user, grants.CREWS),
        key: 'RTL'
    }
];

const mapStateToProps = state => {
    return {
        user: state.auth,
        isAdmin: selectAdmin(state),
        businessSynonym: selectState(state).businessSynonym,
        headerFetching: state.ui.headerFetching,
        taskCount: selectTaskCount(state),
        taskAcceptCount: selectAcceptTaskCount(state),
        reservedOrdersCount: selectReservedOrderCount(state)
    };
};

const mapDispatchToProps = {
    setBusiness,
    setModal,
    fetchEmployeeTaskCount,
    setTaskCount,
    setCollapsedState,
    setTaskAcceptCount,
    fetchEmployeeAcceptTaskCount,
    setReservedOrderCount,
    fetchHeaderStats
};

@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class HeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: {}
        };
    }
    // _fetchTasks = async () => {
    //     const response = await fetchAPI('GET', 'employee_new_tasks_count');
    //     // await this.setState({
    //     //     totalTaskCount: _.get(response, 'totalRowsCount') ? response.totalRowsCount : 0
    //     // });
    //     await setTimeout(this._fetchTasks, 5000);
    // }
    //
    //
    // componentDidMount = async () => {
    //     // await this._fetchTasks();
    // }

    componentDidMount() {
        // fetch base count then
        this.props.fetchEmployeeTaskCount();
        this._connectSocket();

        this.props.fetchEmployeeAcceptTaskCount();
        this._connectAcceptTaskCountSocket();
        this.props.fetchHeaderStats();
    }

    _connectSocket() {
        socket.once('connect_error', () => {
            console.log('Front failed to connect');
        });

        socket.on('employeeTaskCount', data => {
            this._updateEmployeeTaskCount(data);
        });

        socket.on('reservedOrdersCount', data => {
            const jsonData = JSON.parse(data);

            const { setReservedOrderCount } = this.props;

            _.get(jsonData, 'reservedOrdersCount') &&
                setReservedOrderCount({ reservedOrdersCount: jsonData.reservedOrdersCount });
        });
    }

    _updateEmployeeTaskCount(data) {
        // 1. parse data then check user
        const jsonData = JSON.parse(data);

        const { user, setTaskCount, setTaskAcceptCount } = this.props;

        if (user.id === _.get(jsonData, 'managerId')) {
            _.get(jsonData, 'count') && setTaskCount({ totalRowsCount: jsonData.count });
            _.get(jsonData, 'acceptedCount') && setTaskAcceptCount({ totalRowsCount: jsonData.acceptedCount });
        }
    }

    _connectAcceptTaskCountSocket() {
        socket.once('connect_error', () => {
            console.log('Front failed to connect');
        });

        socket.on('acceptedCount', data => {
            this._updateEmployeeAcceptTaskCount(data);
        });
    }

    _updateEmployeeAcceptTaskCount(data) {
        // 1. parse data then check user

        const jsonData = JSON.parse(data);

        const { user, setTaskAcceptCount } = this.props;

        if (user.id == _.get(jsonData, 'managerId')) {
            _.get(jsonData, 'acceptedCount') && setTaskAcceptCount({ totalRowsCount: jsonData.count });
        }
    }

    render() {
        const {
            isMobile,
            collapsed,
            user,
            location: { pathname },
            intl: { formatMessage }
        } = this.props;
        const { searchResults, timeoutHandler, searchValue } = this.state;
        const headerPanel = this._renderHeaderPanel();
        // const tasksStats = this._renderBellTasksStatsInfo();
        // const openYourSite = this._renderOpenYourSite();
        // const headerInfo = this._renderHeaderInfo();
        const searchOptions = [];
        Object.entries(searchResults).forEach(([key, value]) => {
            switch (key) {
                case 'clients':
                    if (Number(value.found)) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.clients' })}: ${Number(value.found)}`,
                            link: book.clientHotOperations
                        });
                    }
                    break;
                case 'vehicles':
                    if (Number(value.found)) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.vehicles' })}: ${Number(value.found)}`,
                            link: book.vehicles
                        });
                    }
                    break;
                case 'orders':
                    const { call, not_complete, reserve, required, processing } = searchResults.orders.found;
                    const newOrdersCount =
                        Number(call) + Number(not_complete) + Number(reserve) + Number(required) + Number(processing);
                    for (const [key, value] of Object.entries(searchResults.orders.found)) {
                        switch (key) {
                            case 'approve':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/approve`
                                    });
                                }
                                break;
                            case 'cancel':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: 'order_statuses_mapper.denied'
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/cancel`
                                    });
                                }
                                break;
                            case 'invite':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/invitations`
                                    });
                                }
                                break;
                            case 'not_complete':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(newOrdersCount)}`,
                                        link: book.ordersAppointments
                                    });
                                }
                                break;
                            case 'progress':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/progress`
                                    });
                                }
                                break;
                            case 'success':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/success`
                                    });
                                }
                                break;
                            case 'processing':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/processing`
                                    });
                                }
                                break;
                            case 'review':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({ id: 'reviews' })}: ${Number(value)}`,
                                        link: `${book.orders}/reviews`
                                    });
                                }
                                break;
                            case 'stop':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({ id: 'transfer_stop' })}: ${Number(value)}`,
                                        link: `${book.orders}/stop`
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                // case 'employees':
                //     if(Number(value.found)) {
                //         searchOptions.push({
                //             title: `${formatMessage({id: 'navigation.employees'})}: ${Number(value.found)}`,
                //             link: book.employeesPage,
                //         })
                //     }
                //     break;
                case 'storeProducts':
                    if (Number(value.found)) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.products' })}: ${Number(value.found)}`,
                            link: book.products
                        });
                    }
                    break;
                case 'storeDocs':
                    const { ORD, BOR, COM } = searchResults.storeDocs.found;
                    const storeOrdersCount = Number(ORD || 0) + Number(BOR || 0) + Number(COM || 0);
                    if (storeOrdersCount) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.orders' })}: ${Number(storeOrdersCount)}`,
                            link: book.storageOrders
                        });
                    }

                    const { INC, STP, SRV, CRT, PRI, DSI } = searchResults.storeDocs.found;
                    const storeIncomesCount =
                        Number(INC || 0) + Number(STP || 0) + Number(SRV || 0) + Number(CRT || 0) + Number(PRI || 0);
                    if (storeIncomesCount) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.incomes' })}: ${Number(storeIncomesCount)}`,
                            link: book.storageIncomes
                        });
                    }

                    const { SRT, OUT, STM, CST, AUT, DSO, PRO } = searchResults.storeDocs.found;
                    const storeExpensesCount =
                        Number(SRT || 0) +
                        Number(OUT || 0) +
                        Number(STM || 0) +
                        Number(CST || 0) +
                        Number(AUT || 0) +
                        Number(DSO || 0);
                    if (storeExpensesCount) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.expenses' })}: ${Number(storeExpensesCount)}`,
                            link: book.storageExpenses
                        });
                    }

                    const { TSF, TOL, TOR } = searchResults.storeDocs.found;
                    const storeTransfersCount = Number(TSF || 0) + Number(TOL || 0) + Number(TOR || 0);
                    if (storeTransfersCount) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.transfers' })}: ${Number(storeTransfersCount)}`,
                            link: book.storageTransfers
                        });
                    }
                    break;
                default:
                    break;
            }
        });

        menuConfig.sections.map(section => {
            if (section.items) {
                section.items
                    .filter(item =>
                        String(formatMessage({ id: item.name }))
                            .toLocaleLowerCase()
                            .includes(String(searchValue).toLocaleLowerCase())
                    )
                    .map(item => {
                        searchOptions.push({
                            title: formatMessage({ id: item.name }),
                            link: item.link,
                            menuItem: true
                        });
                    });
            }
        });
        // const options = searchOptions.map(opt => (
        //     <Option key={opt.title} link={opt.link} menu_item={opt.menuItem} value={opt.title}>
        //         {opt.title}
        //     </Option>
        // ));
        const options = searchOptions.map(({ link, title, menuItem }) => ({
            link,
            label: title,
            value: title,
            menu_item: menuItem
        }));
        const userIcons = getHeaderIcons();

        return (
            <div className={Styles.headerMenu}>
                <Space
                    size='small'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 64px 0px 0px',
                        width: '25%'
                    }}
                >
                    {/* {!isMobile && <SiderMenu {...this.props} />} */}
                    <Tooltip
                        getPopupContainer={trigger => trigger.parentNode}
                        title={<FormattedMessage id='header.sidebar' />}
                    >
                        <img
                            alt='logo'
                            className={Styles.logo}
                            onClick={() => {
                                this.props.setCollapsedState(!collapsed);
                            }}
                            src={user.secondaryLogoUrl || images.cb}
                            style={{ display: 'block' }}
                        />
                    </Tooltip>
                    <AutoComplete
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ maxWidth: 300, height: 'auto' }}
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={async value => {
                            if (String(value).length > 30) {
                                return;
                            }
                            if (value) {
                                const fetch = async () => {
                                    const searchResults = await fetchAPI('GET', 'search', { query: value }, null, {
                                        handleErrorInternally: true
                                    });
                                    this.setState({
                                        searchResults,
                                        isTyping: false
                                    });
                                };
                                clearTimeout(timeoutHandler);
                                this.state.timeoutHandler = setTimeout(fetch, 1000);
                                this.setState({
                                    searchValue: value
                                });
                            } else {
                                this.setState({
                                    searchResults: {},
                                    searchValue: value
                                });
                            }
                        }}
                        onSelect={(value, option) => {
                            clearTimeout(timeoutHandler);
                            console.log(searchValue, option);
                            if (option.props.menu_item) {
                                this.props.history.push(option.props.link);
                            } else {
                                this.props.history.push({
                                    pathname: option.props.link,
                                    state: { searchValue }
                                });
                                if (
                                    pathname === option.props.link ||
                                    (pathname.includes('/orders/') && option.props.link.includes('/orders/'))
                                ) {
                                    window.location.reload();
                                }
                            }
                        }}
                        options={options}
                        placeholder={formatMessage({ id: 'search' })}
                        style={{ marginLeft: 24 }}
                    >
                        <Input suffix={<SearchOutlined />} />
                    </AutoComplete>
                    <GlobalRequisitesSelector />
                </Space>
                <div
                    className={Styles.headerIcons}
                    onClick={() => {
                        this.props.setCollapsedState(true);
                    }}
                >
                    {userIcons.includes('TASKS') && isGrantAccessed(user, grants.CRM_TASKS) && (
                        <Tooltip
                            getPopupContainer={trigger => trigger.parentNode}
                            title={<FormattedMessage id='navigation.tasks' />}
                        >
                            <Link
                                className={Styles.appstore}
                                style={this._getHeaderElementStyle(book.myTasksPage)}
                                to={book.myTasksPage}
                            >
                                <BellOutlined />
                                <Badge
                                    count={_.get(this.props, 'taskCount', 0)}
                                    offset={[18, 10]}
                                    size='small'
                                    style={{
                                        fontSize: 12,
                                        position: 'absolute'
                                    }}
                                />
                                <Badge
                                    count={_.get(this.props, 'taskAcceptCount', 0)}
                                    offset={[18, 42]}
                                    size='small'
                                    style={{
                                        backgroundColor: '#52c41a',
                                        position: 'absolute',
                                        fontSize: 12
                                    }}
                                />
                            </Link>
                        </Tooltip>
                    )}
                    {headerIcons
                        .filter(({ key }) => userIcons.includes(key))
                        .map(({ icon, key, link, tooltip, enabled }) => {
                            if (enabled(user)) {
                                return (
                                    <Tooltip
                                        key={`${key}`}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        title={<FormattedMessage id={tooltip} />}
                                    >
                                        <Link
                                            className={Styles.appstore}
                                            style={this._getHeaderElementStyle(link)}
                                            to={link}
                                        >
                                            {icon}
                                            {key === 'ORDERS' && (
                                                <Badge
                                                    count={_.get(this.props, 'reservedOrdersCount')}
                                                    offset={[18, 10]}
                                                    size='small'
                                                    style={{
                                                        backgroundColor: '#52c41a',
                                                        fontSize: 12,
                                                        position: 'absolute'
                                                    }}
                                                />
                                            )}
                                        </Link>
                                    </Tooltip>
                                );
                            }
                        })}
                </div>
                {/*! isMobile && openYourSite */}
                {/* {!isMobile && headerInfo} */}
                {headerPanel}
            </div>
        );
    }

    _getHeaderElementStyle = link => {
        return link == this.props.location.pathname
            ? {
                  boxShadow: 'inset 0 -5px 2px -2px var(--link)',
                  height: '100%'
              }
            : { height: '100%' };
    };

    _renderBellTasksStatsInfo = () => {
        return (
            <div className={Styles.taskCount}>
                {_.get(this.props, 'taskCount') != 0 && (
                    <div>
                        <BellFilled
                            onClick={() => {
                                history.push(`${book.myTasksPage}`);
                            }}
                            style={{ color: 'red', fontSize: '24px' }}
                        />
                        <span style={{ color: 'white', fontSize: '18px' }}>({this.props.taskCount})a</span>
                    </div>
                )}
            </div>
        );
    };

    _renderHeaderInfo = () => {
        const { header, headerFetching } = this.props;

        return headerFetching ? (
            <Loader background='var(--blocks)' loading={headerFetching} />
        ) : (
            header && (
                <React.Fragment>
                    {!_.isEmpty(header.proBanners) && <Banner banners={header.proBanners} />}
                    <Subscriptions packages={header.rolePackage} suggestions={header.suggestionGroup} />
                </React.Fragment>
            )
        );
    };

    _renderHeaderPanel = () => {
        const { logout, isMobile, user, isAdmin, setModal, businessSynonym } = this.props;

        const tasksStats = this._renderBellTasksStatsInfo();

        return (
            <div className={`${Styles.headerPanel} ${isMobile && Styles.headerPanelMobile} `}>
                <div className={Styles.bussinesInfo}>
                    {user.businessName} ({user.businessId})
                </div>
                <Tooltip
                    getPopupContainer={trigger => trigger.parentNode}
                    placement='topLeft'
                    title={<FormattedMessage id='header.profile' />}
                >
                    {isGrantAccessed(user, grants.DIRECTORIES_USER_SETTINGS) ? (
                        <Link className={Styles.user} to={book.profile}>
                            <Avatar className={Styles.avatar} icon={<UserOutlined />} />
                            <span className={Styles.userName} style={{ color: 'white' }}>
                                {user.name} {user.surname}
                            </span>
                        </Link>
                    ) : (
                        <div>
                            <Avatar className={Styles.avatar} icon={<UserOutlined />} />
                            <span className={Styles.userName} style={{ color: 'white' }}>
                                {user.name} {user.surname}
                            </span>
                        </div>
                    )}
                </Tooltip>
                <Dropdown
                    overlay={
                        <Menu className={Styles.appstoreMenu}>
                            {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS) && (
                                <Menu.Item
                                    className={Styles.appstoreMenuItem}
                                    disabled={
                                        !isGrantAccessed(
                                            user,
                                            grants.SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS,
                                            accesses.ROWO
                                        )
                                    }
                                    onClick={() => setModal(MODALS.SWITCH_BUSINESS)}
                                >
                                    <HomeOutlined
                                        className={Styles.appstoreMenuItemIcon}
                                        disabled={
                                            !isGrantAccessed(
                                                user,
                                                grants.SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS,
                                                accesses.ROWO
                                            )
                                        }
                                    />
                                    <FormattedMessage id='header.business' />
                                </Menu.Item>
                            )}
                            <Menu.Item className={Styles.appstoreMenuItem}>
                                <a
                                    className={Styles.headerWebLink}
                                    href={`https://${businessSynonym}.carbook.ua`}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <GlobalOutlined className={Styles.appstoreMenuItemIcon} />
                                    <FormattedMessage id='header.site' />
                                </a>
                            </Menu.Item>
                            <Menu.Item className={Styles.appstoreMenuItem} onClick={logout}>
                                <PoweroffOutlined className={Styles.appstoreMenuItemIcon} />
                                <FormattedMessage id='header.logout' />
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Tooltip
                        getPopupContainer={trigger => trigger.parentNode}
                        title={<FormattedMessage id='navigation.operations' />}
                    >
                        <AppstoreFilled className={Styles.logout} />
                    </Tooltip>
                </Dropdown>
                <SwitchBusinessModal setBusiness={this.props.setBusiness} />
            </div>
        );
    };

    _renderOpenYourSite = () => {
        const {
            isAdmin,
            setModal,
            businessSynonym,
            user: { businessesAccess },
            user
        } = this.props;

        return (
            <div className={Styles.headerWeb}>
                {(isGrantAccessed(
                    user,
                    grants.SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS,
                    accesses.ROWO
                ) ||
                    businessesAccess) && (
                    <Tooltip placement='topLeft' title={<FormattedMessage id='header.switch_business' />}>
                        <HomeOutlined
                            className={Styles.homeIcon}
                            disabled={
                                !isGrantAccessed(
                                    user,
                                    grants.SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS,
                                    accesses.ROWO
                                )
                            }
                            onClick={() => setModal(MODALS.SWITCH_BUSINESS)}
                        />
                    </Tooltip>
                )}
                <Tooltip placement='topLeft' title={<FormattedMessage id='header.open_your_site' />}>
                    <a
                        className={Styles.headerWebLink}
                        href={`https://${businessSynonym}.cb24.eu`}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <GlobalOutlined className={Styles.siteIcon} />
                    </a>
                </Tooltip>
            </div>
        );
    };
}
