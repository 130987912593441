import { Button, Form, InputNumber } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const layout = {
    labelCol: { span: 8, offset: 4 },
    wrapperCol: { span: 16 }
};
const formTailLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8, offset: 12 }
};

@injectIntl
class ClientDiscountsTab extends Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = async values => {
        await fetchAPI(
            'PUT',
            `clients/${this.props.clientId}`,
            null,
            {
                ...values,
                name: this.props.client.name || null,
                middlename: this.props.client.middleName || null,
                surname: this.props.client.surname || null,
                phones: this.props.client.phones
            },
            { handleErrorInternally: true }
        );
        window.location.reload();
    };

    render() {
        const {
            client,
            intl: { formatMessage },
            disabled
        } = this.props;

        return (
            <div>
                <Form
                    disabled={disabled}
                    initialValues={{
                        laborsDiscount: _.get(client, 'laborsDiscount', 0),
                        detailsDiscount: _.get(client, 'detailsDiscount', 0),
                        discount: _.get(client, 'discount', 0)
                    }}
                    name='clientDiscounts'
                    onFinish={this.handleSubmit}
                    {...layout}
                >
                    <Form.Item
                        label={<FormattedMessage id='client_container.discounts.labors' />}
                        name='laborsDiscount'
                    >
                        <InputNumber
                            formatter={value => `${value}%`}
                            max={100}
                            min={-100}
                            parser={value => value.replace('%', '')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='client_container.discounts.details' />}
                        name='detailsDiscount'
                    >
                        <InputNumber
                            formatter={value => `${value}%`}
                            max={100}
                            min={-100}
                            parser={value => value.replace('%', '')}
                        />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='client_container.discounts.total' />} name='discount'>
                        <InputNumber
                            formatter={value => `${value}%`}
                            max={100}
                            min={-100}
                            parser={value => value.replace('%', '')}
                        />
                    </Form.Item>
                    <Form.Item {...formTailLayout}>
                        <Button htmlType='submit' type='primary'>
                            <FormattedMessage id='save' />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default ClientDiscountsTab;
