import { CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined, GlobalOutlined } from '@ant-design/icons';

import { Button, Col, Row, Switch, Table } from 'antd';
import { formKeys, formModes } from 'core/forms/reportAnalyticsForm/duck';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Style from './styles.m.css';
// const { Panel } = Collapse;
const mapStateToProps = state => ({
    user: state.auth
});

@connect(mapStateToProps)
@injectIntl
export default class AnalyticsDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedRowKeys: []
        };
    }

    /**
     * Takes cash order type and returns Its UI representation(translation)
     * @param {*} status Cash order status(in that case analytics's status field which represents for which order it can be used)
     */
    orderStatusesMapper(status) {
        switch (status.toLowerCase()) {
            case 'income':
                return <FormattedMessage id='report_analytics_page.income_cash_order' />;
            case 'expense':
                return <FormattedMessage id='report_analytics_page.expense_cash_order' />;
            default:
                return `Error, incorrect cash order value: ${status}`;
        }
    }

    /**
     * Generates block header using fields from analytics sush as "name".
     * @param {*} analytics Parent analytics
     */
    genParentHeader(analytics, containsAnalyticsWithDefaultCashOrderType) {
        const { onDeleteAnalytics, openAnalyticsModal, user, disabled } = this.props;

        return (
            <div>
                <Row className={Style.row}>
                    <Col className={Style.colHeader} span={18}>
                        {analytics.analyticsName}
                    </Col>
                    <Col className={Style.colCentered} span={2}>
                        {(() => {
                            if (analytics.analyticsIsCustom) {
                                return (
                                    <Button
                                        disabled={disabled}
                                        onClick={() =>
                                            openAnalyticsModal(formModes.VIEW, formKeys.catalogForm, analytics)
                                        }
                                        // size='large'
                                    >
                                        <EyeOutlined />
                                    </Button>
                                );
                            }

                            return '';
                        })()}
                    </Col>
                    <Col className={Style.colCentered} span={2}>
                        {
                            /* EDIT btn | Buttons only for non-custom fields otherwise just place an icon */
                            (() => {
                                if (analytics.analyticsIsCustom) {
                                    return (
                                        <Button
                                            disabled={disabled}
                                            onClick={() =>
                                                openAnalyticsModal(formModes.EDIT, formKeys.catalogForm, analytics)
                                            }
                                            // size='large'
                                        >
                                            <EditOutlined />
                                        </Button>
                                    );
                                }

                                return '';
                            })()
                        }
                    </Col>
                    <Col className={Style.colCentered} span={2}>
                        {
                            /* DELETE btn | Buttons only for non-custom fields in another case just place an icon */
                            (() => {
                                if (analytics.analyticsIsCustom) {
                                    return (
                                        <Button
                                            disabled={!_.isEmpty(containsAnalyticsWithDefaultCashOrderType) || disabled}
                                            onClick={() => onDeleteAnalytics(analytics.analyticsId)}
                                            // size='large'
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    );
                                }

                                return <GlobalOutlined style={{ fontSize: '14px', color: 'var(--primary)' }} />;
                            })()
                        }
                    </Col>
                </Row>
            </div>
        );
    }

    /**
     * Generate children analytics block
     * @param {*} chil children analytics
     */
    genChildren(chil) {
        const { onDeleteAnalytics, openAnalyticsModal, onUpdateAnalytics, user, disabled } = this.props;

        return (
            <div key={chil.analyticsId} className={Style.analyticsCont}>
                <Row className={Style.row}>
                    <Col className={Style.col} span={6}>
                        {chil.analyticsName}
                    </Col>

                    <Col span={2}>
                        {chil.analyticsDefaultOrderType == 'INCOME' && (
                            <CheckOutlined style={{ color: 'var(--primary)', fontSize: '1.4em' }} />
                        )}
                        {chil.analyticsDefaultOrderType == 'EXPENSE' && (
                            <CheckOutlined style={{ color: 'red', fontSize: '1.4em' }} />
                        )}
                        {!chil.analyticsDefaultOrderType && !disabled && (
                            <div
                                className={Style.notDefaultAnalyticsIcon}
                                onClick={() => {
                                    // Update anlytics by setting it up to be a default for a specific cash order type
                                    onUpdateAnalytics({
                                        analyticsId: chil.analyticsId,
                                        newAnalyticsEntity: {
                                            makeDefaultForCurrentCashOrderType: true
                                        }
                                    });
                                }}
                            />
                        )}
                    </Col>
                    <Col className={Style.col} span={4}>
                        {chil.analyticsBookkeepingAccount}
                    </Col>
                    <Col className={Style.col} span={4}>
                        {this.orderStatusesMapper(chil.analyticsOrderType)}
                    </Col>

                    <Col className={Style.colCentered} span={2}>
                        <Switch
                            checked={!chil.analyticsDisabled}
                            disabled={!_.isEmpty(chil.analyticsDefaultOrderType) || disabled}
                            onClick={() => {
                                // Update anlytics by changing ist's "disabled" value prop
                                onUpdateAnalytics({
                                    analyticsId: chil.analyticsId,
                                    newAnalyticsEntity: {
                                        analyticsDisabled: !chil.analyticsDisabled
                                    }
                                });
                            }} // Disable if analytics is default somewhere
                            // size='small'
                        />
                    </Col>
                    <Col className={Style.colCentered} span={2}>
                        {
                            /* VIEW btn | Buttons only for non-custom fields otherwise just place an icon */
                            (() => {
                                if (chil.analyticsIsCustom) {
                                    return (
                                        <Button
                                            disabled={disabled}
                                            onClick={() =>
                                                openAnalyticsModal(formModes.VIEW, formKeys.analyticsForm, chil)
                                            }
                                            // size='small'
                                        >
                                            <EyeOutlined />
                                        </Button>
                                    );
                                }

                                return '';
                            })()
                        }
                    </Col>
                    <Col className={Style.colCentered} span={2}>
                        {
                            /* EDIT btn | Buttons only for non-custom fields otherwise just place an icon */
                            (() => {
                                if (chil.analyticsIsCustom) {
                                    return (
                                        <Button
                                            disabled={disabled}
                                            onClick={() =>
                                                openAnalyticsModal(formModes.EDIT, formKeys.analyticsForm, chil)
                                            }
                                            // size='small'
                                        >
                                            <EditOutlined />
                                        </Button>
                                    );
                                }

                                return '';
                            })()
                        }
                    </Col>
                    <Col className={Style.colCentered} span={2}>
                        {
                            /* DELETE btn | Buttons only for non-custom fields in another case just place an icon */
                            (() => {
                                if (chil.analyticsIsCustom) {
                                    return (
                                        <Button
                                            disabled={!_.isEmpty(chil.analyticsDefaultOrderType) || disabled}
                                            onClick={() => onDeleteAnalytics(chil.analyticsId)}
                                            // size='small' // Disable if analytics is default somewhere
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    );
                                }

                                return <GlobalOutlined style={{ fontSize: '14px', color: 'var(--primary)' }} />;
                            })()
                        }
                    </Col>
                </Row>
            </div>
        );
    }

    // /**
    //  * Generate panel with all it's children components for one parent analytics
    //  * @param {*} parent parent analytics
    //  * @param {*} children children analytics
    //  */
    // genPanel(parent, children) {
    //     // Check if paret has default analyticst inside it(it means analytics which are default for a specific cash order type)
    //     const containsAnalyticsWithDefaultCashOrderType = !_.isEmpty(
    //         _.filter(children, chil => chil.analyticsDefaultOrderType)
    //     );

    //     return (
    //         <Panel
    //             key={parent.analyticsId}
    //             header={this.genParentHeader(parent, containsAnalyticsWithDefaultCashOrderType)}
    //         >
    //             {_.map(children, o => this.genChildren(o))}
    //         </Panel>
    //     );
    // }

    render() {
        const { analytics, visibleLevel } = this.props;
        const { expandedRowKeys } = this.state;

        const columns = [
            {
                title: <FormattedMessage id='description' />,
                dataIndex: 'analyticsName',
                key: 'name'
            }
        ];

        const renderChildAnalytics = parentAnalytics => {
            const children = analytics.filter(analytic => analytic.analyticsParentId === parentAnalytics.key);

            return children.map(child => this.genChildren(child));
        };

        const dataSource = analytics
            .filter(analytic => analytic.analyticsLevel === 1)
            .map(parent => ({
                key: parent.analyticsId,
                analyticsName: this.genParentHeader(parent, true),
                children: renderChildAnalytics(parent).map(child => ({
                    ...child
                }))
            }));

        return (
            <div className={Style.mainCont}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    expandable={{
                        expandedRowKeys: visibleLevel === 2 ? dataSource.map(record => record.key) : expandedRowKeys,
                        onExpand: (expanded, record) => {
                            if (visibleLevel !== 1) {
                                this.setState(prevState => ({
                                    expandedRowKeys: expanded
                                        ? [...prevState.expandedRowKeys, record.key]
                                        : prevState.expandedRowKeys.filter(key => key !== record.key)
                                }));
                            } else if (expanded) {
                                this.setState(prevState => ({
                                    expandedRowKeys: [...prevState.expandedRowKeys, record.key]
                                }));
                            } else {
                                this.setState(prevState => ({
                                    expandedRowKeys: prevState.expandedRowKeys.filter(key => key !== record.key)
                                }));
                            }
                        }
                    }}
                    expandedRowRender={record => (
                        <div>
                            {analytics
                                .filter(analytic => analytic.analyticsParentId === record.key)
                                .map(child => this.genChildren(child))}
                        </div>
                    )}
                    pagination={false}
                    rowExpandable={() => true}
                />
            </div>
        );
    }
}
