import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Popconfirm, Switch, Table, Tooltip, notification } from 'antd';
import { Select } from 'antd/lib';
import { Numeral } from 'commons';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import AddMarkupsModal from '../AddMarkupsModal';
import Styles from './styles.m.css';

const dateFormat = 'DD.MM.YYYY';
const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth
});

const MarksupTable = ({
    intl,
    query,
    reqfilters = {},
    reset,
    shouldReset,
    markups,
    loading,
    fetchMarkups,
    fetchMarkupsGroups,
    markupsGroups,
    user,
    accessAddButton
}) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    // const [markups, setMarkups] = useState([]);
    const [edit, setEdit] = useState(undefined);
    const [open, openAddModal] = useState(false);
    const [filters, setFilters] = useState({});
    const [addResponseDelete, setDeleteResponse] = useState(undefined);

    useEffect(() => {
        if (_.get(addResponseDelete, 'not_deleted', undefined)) {
            notification.error({
                message: intl.formatMessage({
                    id: 'storage.markup.can_not_delete'
                })
            });
            setDeleteResponse(undefined);
        }
    }, [addResponseDelete]);

    useEffect(() => {
        fetchMarkups();
        fetchMarkupsGroups();
    }, []);

    useEffect(() => {
        const func = async () => {
            await setPage(1);
            fetchMarkups();
        };
        func();
    }, [query, fetchMarkups]);

    const handleSearch = async confirm => {
        confirm();
        await setPage(1);
        fetchMarkups();
    };

    const handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        const tmp = filters;
        if (dataIndex == 'startDate') {
            delete tmp.creationStartDate;
            delete tmp.creationEndDate;
        } else if (dataIndex == 'doneDate') {
            delete tmp.lastStartDate;
            delete tmp.lastEndDate;
        } else if (dataIndex == 'nextDate') {
            delete tmp.nextStartDate;
            delete tmp.nextEndDate;
        } else {
            delete tmp[dataIndex];
        }
        await setFilters(tmp);
        await setPage(1);
        fetchMarkups();
    };
    useEffect(() => {
        if (shouldReset === 'reset') {
            reset();
            setFilters({});
            fetchMarkups({});
        }
    }, [shouldReset, reset, fetchMarkups]);

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='№' />,
                key: 'num',
                width: '3%',
                render: (row, number, index) => {
                    return index + 1;
                }
            },
            {
                title: () => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                disabled={!accessAddButton}
                                icon={<PlusOutlined />}
                                onClick={async () => {
                                    await openAddModal(true);
                                }}
                            />
                        </div>
                    );
                },
                key: 'num',
                width: '8%',
                render: (row, number, index) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Popconfirm
                                disabled={row.name == 'DEFAULT' || row.name == 'Стандартна' || !accessAddButton}
                                onConfirm={async () => {
                                    const response = await fetchAPI(
                                        'DELETE',
                                        '/markups',
                                        null,
                                        { id: row.id },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setDeleteResponse(response);
                                    await setTimeout(() => fetchMarkups(), 100);
                                }}
                                title={`${intl.formatMessage({ id: 'delete' })}?`}
                            >
                                <Button
                                    disabled={row.name == 'DEFAULT' || row.name == 'Стандартна' || !accessAddButton}
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                            <Button
                                disabled={row.name == 'DEFAULT' || row.name == 'Стандартна' || !accessAddButton}
                                icon={<EditOutlined />}
                                onClick={() => {
                                    openAddModal(true);
                                    setEdit(row);
                                }}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='task_page.name' />,
                key: 'name',
                width: '10%',
                render: row => {
                    return row.name ? row.name : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='product.purpose' />,
                key: 'purpose',
                width: '10%',
                // ...getColumnSearchProps('startDate'),
                render: row => {
                    return row.using ? (
                        <FormattedMessage id={`product_markups.${row.using}`} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                key: 'markupsNames',
                // ...getColumnSearchProps('startDate'),
                render: () => {
                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                <FormattedMessage id='storage.purchase_price_from' />
                            </div>
                            <div
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                <FormattedMessage id='product.markup' />
                            </div>
                        </div>
                    );
                }
            },
            ...[0, 1, 2, 3, 4].map(index => ({
                key: `markups${index}`,
                align: 'right',
                render: (row, data, i) => {
                    return (
                        <div className={Styles.cellStyleDayOff}>
                            <div>
                                <Numeral mask='0,0.00'>
                                    {_.get(row.markups, `[${index}].lowerLimit`)
                                        ? Number(_.get(row.markups, `[${index}].lowerLimit`))
                                        : 0}
                                </Numeral>
                            </div>
                            <div>
                                {_.get(row.markups, `[${index}].markup`) ? _.get(row.markups, `[${index}].markup`) : 0}
                            </div>
                        </div>
                    );
                }
            })),
            {
                key: 'more',
                align: 'center',
                width: '2%',
                render: (row, data, index) => {
                    return (
                        <div>
                            <Tooltip title={<FormattedMessage id='Більше 5' />}>
                                {row.markups[4] && row.markups.length > 5 && (
                                    <Button
                                        disabled={!accessAddButton}
                                        onClick={() => {
                                            openAddModal(true);
                                            setEdit(row);
                                        }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 700
                                            }}
                                        >
                                            ...
                                        </span>
                                    </Button>
                                )}
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='interactions.on_off' />,
                key: 'enabled',
                align: 'center',
                width: '10%',
                render: row => {
                    return (
                        <div>
                            <Tooltip title={<FormattedMessage id='edit_vehicle.display' />}>
                                <Switch
                                    checked={row.enabled}
                                    disabled={!row.businessId || !accessAddButton}
                                    onChange={async () => {
                                        await fetchAPI(
                                            'PUT',
                                            `markups_group/${row.id}`,
                                            undefined,
                                            {
                                                enabled: !row.enabled,
                                                defaultByClients: false,
                                                defaultByProducts: false,
                                                defaultByStoreGroups: false
                                            },
                                            {
                                                handleErrorInternally: true
                                            }
                                        );

                                        // if (!response.enabled) {
                                        //     await fetchAPI(
                                        //         'PUT',
                                        //         '/markups_group_in_directory',
                                        //         undefined,
                                        //         {
                                        //             groupId: row.id,
                                        //             defaultByClients: false,
                                        //             defaultByProducts: false,
                                        //             defaultByStoreGroups: false
                                        //         },
                                        //         {
                                        //             handleErrorInternally: true
                                        //         }
                                        //     );
                                        // }
                                        await setTimeout(() => fetchMarkups(), 100);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='default_group' />
                        <FormattedMessage id='navigation.clients' />
                    </div>
                ),
                key: 'defaultByClients',
                align: 'center',
                width: '8%',
                render: row => {
                    return (
                        <div>
                            <Checkbox
                                checked={row.defaultByClients}
                                disabled={!row.enabled || row.defaultByClients || !accessAddButton}
                                onChange={() => {
                                    Modal.confirm({
                                        title: intl.formatMessage({
                                            id: 'Оберіть варіант застосування'
                                        }),
                                        width: '35%',
                                        content: (
                                            <div style={{}}>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByClients: true,
                                                                    updateOnlyGroup: true
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і не оновлювати для клієнтів'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByClients: true,
                                                                    updateOnlyGroup: false,
                                                                    onlyEmpty: true
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і оновити для клієнтів, в яких відсутня група'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByClients: true,
                                                                    onlyEmpty: false
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і оновити для всіх клієнтів'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        danger
                                                        onClick={() => {
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        type='primary'
                                                    >
                                                        {intl.formatMessage({
                                                            id: 'cancel'
                                                        })}
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                        footer: null,
                                        okType: 'default'
                                    });
                                }}
                            />
                            {/* </Popconfirm> */}
                        </div>
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='default_group' />
                        <FormattedMessage id='navigation.products' />
                    </div>
                ),
                key: 'defaultByProducts',
                align: 'center',
                width: '8%',
                render: row => {
                    return (
                        <div>
                            <Checkbox
                                checked={row.defaultByProducts}
                                disabled={!row.enabled || row.defaultByProducts || !accessAddButton}
                                onChange={() => {
                                    Modal.confirm({
                                        title: intl.formatMessage({
                                            id: 'Оберіть варіант застосування'
                                        }),
                                        width: '35%',
                                        content: (
                                            <div style={{}}>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByProducts: true,
                                                                    updateOnlyGroup: true
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і не оновлювати для товарів'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByProducts: true,
                                                                    updateOnlyGroup: false,
                                                                    onlyEmpty: true
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і оновити для товарів, в яких відсутня група'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByProducts: true,
                                                                    onlyEmpty: false
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і оновити для всіх товарів'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        danger
                                                        onClick={() => {
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        type='primary'
                                                    >
                                                        {intl.formatMessage({
                                                            id: 'cancel'
                                                        })}
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                        footer: null,
                                        okType: 'default'
                                    });
                                }}
                            />
                        </div>
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='default_group' />
                        <FormattedMessage id='navigation.products_groups' />
                    </div>
                ),
                key: 'defaultByStoreGroups',
                align: 'center',
                width: '8%',
                render: row => {
                    return (
                        <div>
                            <Checkbox
                                checked={row.defaultByStoreGroups}
                                disabled={!row.enabled || row.defaultByStoreGroups || !accessAddButton}
                                onChange={() => {
                                    Modal.confirm({
                                        title: intl.formatMessage({
                                            id: 'Оберіть варіант застосування'
                                        }),
                                        width: '35%',
                                        content: (
                                            <div style={{}}>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByStoreGroups: true,
                                                                    updateOnlyGroup: true
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і не оновлювати для груп товарів'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByStoreGroups: true,
                                                                    updateOnlyGroup: false,
                                                                    onlyEmpty: true
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і оновити для груп товарів в яких відсутня група націнки'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'PUT',
                                                                '/markups_group_in_directory',
                                                                undefined,
                                                                {
                                                                    groupId: row.id,
                                                                    defaultByStoreGroups: true,
                                                                    onlyEmpty: false
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            await setTimeout(() => fetchMarkups(), 100);
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            backgroundColor: 'var(--primary)'
                                                        }}
                                                        type='primary'
                                                    >
                                                        <span
                                                            style={{
                                                                whiteSpace: 'pre-line'
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: 'Застос. за замовч. і оновити для всіх груп товарів'
                                                            })}
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: 8
                                                    }}
                                                >
                                                    <Button
                                                        danger
                                                        onClick={() => {
                                                            Modal.destroyAll();
                                                        }}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        type='primary'
                                                    >
                                                        {intl.formatMessage({
                                                            id: 'cancel'
                                                        })}
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                        footer: null,
                                        okType: 'default'
                                    });
                                }}
                            />
                        </div>
                    );
                }
            }
        ];
    }, [fetchMarkups, intl, !accessAddButton]);

    // const pagination = {
    //     pageSize,
    //     total: Math.ceil(totalCount / pageSize) * pageSize,
    //     hideOnSinglePage: true,
    //     current: page,
    //     onChange: async (page, pageSize) => {
    //         await setPage(page);
    //         await setPageSize(pageSize);
    //         fetchMarkups();
    //     }
    // };

    return (
        <div>
            <Table columns={columns} dataSource={markups} loading={loading} pagination={false} size='small' />
            <AddMarkupsModal
                closeModal={() => {
                    openAddModal(false);
                    setEdit(undefined);
                }}
                edit={edit}
                fetchMarkups={fetchMarkups}
                fetchMarkupsGroups={fetchMarkupsGroups}
                markupsGroups={markupsGroups}
                open={open}
            />
        </div>
    );
};

export default connect(mapStateToProps)(injectIntl(MarksupTable));
