import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchWarehousesSuccess, FETCH_WAREHOUSES } from './duck';

export function* fetchWarehousesSaga() {
    while (true) {
        try {
            yield take(FETCH_WAREHOUSES);
            yield nprogress.start();

            const warehouses = yield call(fetchAPI, 'GET', 'warehouses', undefined, undefined, {
                handleErrorInternally: true
            });

            yield put(fetchWarehousesSuccess(warehouses));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([call(fetchWarehousesSaga)]);
}
