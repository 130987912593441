/* eslint-disable complexity */
import { Form, InputNumber } from 'antd';
import { Catcher, Numeral } from 'commons';
import { DecoratedInputNumber } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency } from 'utils';
import Styles from './styles.m.css';

@injectIntl
class DiscountPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showProfit: true
        };
    }

    // shouldComponentUpdate(nextProps) {
    //     return !_.isEqual(nextProps, this.props);
    // }

    _updateTimeMultiplier = async multiplier => {
        if (multiplier) {
            const { orderId, orderServices, laborTimeMultiplier } = this.props;
            const payload = {
                updateMode: true,
                laborTimeMultiplier: multiplier,
                services: []
            };

            orderServices.map(({ id, laborId, count, hours }) => {
                if (id) {
                    payload.services.push({
                        id,
                        serviceId: laborId,
                        count: hours
                            ? Math.round(hours * multiplier * 10) / 10
                            : Number(Math.round((count / laborTimeMultiplier) * multiplier * 10) / 10)
                    });
                }
            });

            await fetchAPI('PUT', `/orders/${orderId}`, null, payload);
            await this.props.reloadOrderForm(undefined, 'all');
        }
    };

    _updateOrderField = field => {
        const { reloadOrderForm, orderId } = this.props;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${orderId}`;
        url += params;
        fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(field)
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                reloadOrderForm(undefined, 'all');

                return response.json();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    render() {
        const {
            form: { getFieldDecorator, getFieldValue, getFieldsValue },
            price,
            totalDetailsProfit,
            totalServicesProfit,
            discountFieldName,
            fetchedOrder,
            forbidden,
            detailsMode,
            servicesMode,
            isServiceMarkupForbidden,
            laborTimeMultiplier,
            isMobile,
            generalSumProfit,
            generalDiscount,
            isRetailOrder
        } = this.props;

        const { showProfit } = this.state;

        const discount = getFieldValue(discountFieldName) || _.get(fetchedOrder, `order.${discountFieldName}`, 0);
        const total = Math.round((price - price * (discount / 100)) * 100) / 100;
        const profitMob = servicesMode ? totalServicesProfit : (discount / 100) * price;
        const mask = '0,0.00';
        let totalPurchasePriceDetails = 0;
        let totalPurchasePriceServices = 0;

        if (this.props.orderDetails) {
            this.props.orderDetails.forEach(detail => {
                totalPurchasePriceDetails += detail.purchasePrice * detail.count;
            });
        }

        if (this.props.orderServices) {
            this.props.orderServices.forEach(service => {
                totalPurchasePriceServices += service.purchasePrice * service.count;
            });
        }

        const field = getFieldsValue([discountFieldName]);

        const profit =
            generalDiscount !== null || generalDiscount > 0
                ? generalSumProfit - (generalSumProfit * generalDiscount) / 100
                : generalDiscount < 0
                ? generalSumProfit + (generalSumProfit * generalDiscount) / 100
                : generalSumProfit;

        return (
            <Catcher>
                {!isMobile ? (
                    <React.Fragment>
                        {servicesMode && (
                            <div className={Styles.servicesMarkup}>
                                <InputNumber
                                    data-qa='input_mark_up_discount_panel_order_page'
                                    decimalSeparator=','
                                    disabled={isServiceMarkupForbidden}
                                    formatter={value => `${Math.round(value)}%`}
                                    min={10}
                                    onChange={value => {
                                        if (value) {
                                            this._updateTimeMultiplier(Math.round(value) / 100);
                                        }
                                    }}
                                    parser={value => value.replace('%', '')}
                                    step={10}
                                    style={{ fontWeight: 700, margin: '0 12px 0 12px' }}
                                    value={laborTimeMultiplier * 100}
                                />
                                <FormattedMessage id='labors_table.mark_up' />
                            </div>
                        )}

                        <div className={Styles.discountPanel}>
                            {!isRetailOrder && (
                                <Form.Item
                                    className={Styles.formItem}
                                    colon={false}
                                    initialValue={_.get(fetchedOrder, `order.${discountFieldName}`) || 0}
                                    label={<FormattedMessage id='order_form_table.discount' />}
                                    name={discountFieldName}
                                >
                                    <InputNumber
                                        data-qa='input_number_discount_discount_panel_order_page'
                                        disabled={forbidden}
                                        formatter={value => `${Math.round(value)}%`}
                                        max={100}
                                        min={-100}
                                        onChange={value => {
                                            field[discountFieldName] = value;
                                            this._updateOrderField(field);
                                        }}
                                        parser={value => value.replace('%', '')}
                                        step={1}
                                    />
                                </Form.Item>
                            )}
                            {(detailsMode || servicesMode) && (
                                <Form.Item
                                    className={Styles.formItem}
                                    colon={false}
                                    label={<FormattedMessage id='storage.purchase_sum' />}
                                >
                                    <InputNumber
                                        decimalSeparator=','
                                        disabled
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        min={0}
                                        parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                                        style={{ color: 'black' }}
                                        value={
                                            !this.props.purchasePrices
                                                ? 0
                                                : servicesMode
                                                ? Math.round(totalPurchasePriceServices)
                                                : Math.round(totalPurchasePriceDetails)
                                        }
                                    />
                                </Form.Item>
                            )}
                            <Form.Item
                                className={Styles.formItem}
                                colon={false}
                                label={<FormattedMessage id='order_form_table.sum_without_discount' />}
                            >
                                <InputNumber
                                    data-qa='input_number_sum_without_discount_discount_panel_order_page'
                                    decimalSeparator=','
                                    disabled
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    min={0}
                                    parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                                    style={{ color: 'black' }}
                                    value={Math.round(price)}
                                />
                            </Form.Item>
                            <Form.Item
                                className={Styles.formItem}
                                colon={false}
                                label={<FormattedMessage id='order_form_table.total_sum' />}
                            >
                                <InputNumber
                                    data-qa='input_number_total_sum_discount_panel_order_page'
                                    decimalSeparator=','
                                    disabled
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    min={0}
                                    parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                                    style={{ color: 'black' }}
                                    value={Math.round(profit)}
                                />
                            </Form.Item>
                            {(detailsMode || servicesMode) && (
                                <Form.Item
                                    className={Styles.formItem}
                                    colon={false}
                                    label={
                                        <FormattedMessage
                                            id={`${
                                                servicesMode
                                                    ? 'order_form_table.services_profit'
                                                    : 'order_form_table.details_profit'
                                            }`}
                                        />
                                    }
                                >
                                    <InputNumber
                                        data-qa='input_number_total_discount_panel_order_page'
                                        decimalSeparator=','
                                        disabled
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                                        style={{ color: profit < 0 ? 'red' : 'black' }}
                                        value={
                                            !this.props.purchasePrices
                                                ? 0
                                                : servicesMode
                                                ? Math.round(totalServicesProfit)
                                                : Math.round(totalDetailsProfit)
                                        }
                                    />
                                    {/* <Button
                                        icon={
                                            showProfit ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                        }
                                        onClick={() => {
                                            this.setState({
                                                showProfit: !showProfit
                                            });
                                        }}
                                        type='text'
                                    /> */}
                                </Form.Item>
                            )}
                        </div>
                    </React.Fragment>
                ) : (
                    <div className={Styles.mobileDiscountPanel}>
                        <div className={Styles.mobileDiscount}>
                            <FormattedMessage id='order_form_table.discount' />
                            <DecoratedInputNumber
                                className={Styles.mobileFormItem}
                                colon={false}
                                data-qa='input_number_discount_field_discount_panel_order_page'
                                disabled={forbidden}
                                field={discountFieldName}
                                formatter={value => `${Math.round(value)}%`}
                                formItem
                                getFieldDecorator={getFieldDecorator}
                                initialValue={_.get(fetchedOrder, `order.${discountFieldName}`) || 0}
                                max={100}
                                min={-100}
                                onChange={value => {
                                    field[discountFieldName] = value;
                                    this._updateOrderField(field);
                                }}
                                parser={value => value.replace('%', '')}
                                step={1}
                            />
                        </div>
                        <div className={Styles.mobileSumBlock}>
                            <div className={Styles.mobileSumRow}>
                                <FormattedMessage id='order_form_table.sum_without_discount' />
                                <Numeral
                                    className={Styles.sumNumeral}
                                    currency={getCurrency()}
                                    data-qa='numeral_price_discount_panel_order_page'
                                    mask={mask}
                                    nullText='0'
                                >
                                    {Number(price)}
                                </Numeral>
                            </div>
                            <div className={Styles.mobileSumRow}>
                                <FormattedMessage id='order_form_table.discount' />
                                <Numeral
                                    className={Styles.sumNumeral}
                                    currency={getCurrency()}
                                    data-qa='numeral_profit_discount_panel_order_page'
                                    mask={mask}
                                    nullText='0'
                                >
                                    {Number(profitMob)}
                                </Numeral>
                            </div>
                            <div className={Styles.mobileSumRow}>
                                <FormattedMessage id='order_form_table.total_sum' />
                                <Numeral
                                    className={Styles.sumNumeral}
                                    currency={getCurrency()}
                                    data-qa='numeral_total_discount_panel_order_page'
                                    mask={mask}
                                    nullText='0'
                                >
                                    {Number(total)}
                                </Numeral>
                            </div>
                        </div>
                    </div>
                )}
            </Catcher>
        );
    }
}

export default DiscountPanel;
