/* eslint-disable max-classes-per-file */
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    CommentOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
    SettingOutlined,
    UndoOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Drawer,
    Image,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Select,
    Switch,
    Table,
    Tooltip,
    TreeSelect,
    message,
    notification
} from 'antd';
import { Layout } from 'commons';
import { Barcode } from 'components';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { AddCommetModal, AddLaborModal } from './modals';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => {
    return {
        user: state.auth,
        isMobile: state.ui.views.isMobile
    };
};

@injectIntl
@connect(mapStateToProps, void 0)
export default class LaborsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            labors: [],
            masterLabors: [],
            storeGroups: [],
            filterCode: null,
            laborUnitId: null,
            filterCrossId: null,
            filterId: null,
            filterDetail: null,
            filterDefaultName: null,
            filterName: null,
            currentPage: 1,
            selectedRows: [],
            selectedRowKeys: [],
            specifications: [],
            units: [],
            allLinks: [],
            helperDrawerOpen: false,
            visibleCommentModal: false,
            count: 0,
            page: 1,
            pageSize: 25,
            crossId: undefined,
            id: undefined,
            masterLaborId: undefined,
            storeGroupId: undefined,
            search: undefined,
            masterLaborName: undefined,
            laborExist: undefined,
            loadingModal: false
        };
        this.treeData = [];
        const disabled = !isGrantAccessed(this.props.user, grants.DIRECTORIES_JOBS, accesses.ROWO);

        this.columns = () => [
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.labors_code' />
                            <Input
                                allowClear
                                controls={false}
                                onChange={async event => {
                                    this.setState({
                                        filterCode: event.target.value.replace(/-/g, ''),
                                        id: Number(event.target.value.replace(/-/g, '')),
                                        page: 1
                                    });

                                    await this.handleSearchLabors();
                                }}
                                // parser={value => value.replace('-', '')}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.labors_code'
                                })}
                                // style={{
                                //     width: '100%'
                                // }}
                                value={this.state.filterCode}
                            />
                        </div>
                    );
                },
                key: 'laborCode',
                dataIndex: 'laborCode',
                render: (data, row) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Barcode
                                disabled={disabled}
                                enableScanIcon
                                iconStyle={{
                                    margin: '0 8px 0 0',
                                    fornSize: 18,
                                    verticalAlign: 'sub'
                                }}
                                onConfirm={(code, pref, fullCode) => {
                                    row.barcode = fullCode;
                                    this.setState({});
                                }}
                                prefix='LBS'
                                referenceId={row.id}
                                table='LABORS'
                                value={row.barcode}
                            />
                            {data}
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>ID</p>
                            <Input
                                allowClear
                                onChange={async event => {
                                    this.setState({
                                        filterId: event.target.value,
                                        masterLaborId: event.target.value,
                                        page: 1
                                    });

                                    await this.handleSearchLabors();
                                }}
                                placeholder='ID'
                                value={this.state.filterId}
                            />
                        </div>
                    );
                },
                dataIndex: 'masterLaborId',
                key: 'masterLaborId',
                render: (data, elem) => {
                    const { key } = elem;

                    return !elem.new ? (
                        <p>{data}</p>
                    ) : (
                        <Select
                            disabled={disabled}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(option.props.value).indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value, option) => {
                                if (
                                    elem.storeGroupId &&
                                    this.state.labors.findIndex(
                                        ({ storeGroupId, masterLaborId }) =>
                                            storeGroupId === elem.storeGroupId && masterLaborId === value
                                    ) !== -1
                                ) {
                                    notification.warning({
                                        message: this.props.intl.formatMessage({
                                            id: 'labor_form.notification'
                                        })
                                    });
                                }
                                this.state.labors[key].masterLaborId = value;
                                this.state.labors[key].name = option.props.children;
                                this.state.labors[key].laborCode = `${value}-${
                                    elem.storeGroupId ? elem.storeGroupId : '0000000'
                                }`;
                                this.state.labors[
                                    key
                                ].customName = `${option.props.children} ${this.state.labors[key].detailTitle}`;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder='ID'
                            showSearch
                            style={{ minWidth: '50px' }}
                            value={
                                this.state.labors[key].masterLaborId ? this.state.labors[key].masterLaborId : undefined
                            }
                        >
                            {this.state.masterLabors.map((elem, index) => (
                                <Option key={index} value={elem.masterLaborId}>
                                    {elem.defaultMasterLaborName}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.external_id' />
                            <Input
                                allowClear
                                onChange={async event => {
                                    this.setState({
                                        filterCrossId: event.target.value,
                                        crossId: event.target.value,
                                        page: 1
                                    });

                                    await this.handleSearchLabors();
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.external_id'
                                })}
                                style={{ color: 'var(--text)', width: '85px' }}
                                value={this.state.filterCrossId}
                            />
                        </div>
                    );
                },
                key: 'crossId',
                dataIndex: 'crossId',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <Input
                            disabled={disabled}
                            onChange={event => {
                                elem.changed = true;
                                elem.crossId = event.target.value;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.external_id'
                            })}
                            style={{ color: 'var(--text)', width: '85px' }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.detail_code' />
                            <Input
                                allowClear
                                onChange={async event => {
                                    this.setState({
                                        filterDetail: event.target.value,
                                        storeGroupId: event.target.value,
                                        page: 1
                                    });

                                    await this.handleSearchLabors();
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_code'
                                })}
                                value={this.state.filterDetail}
                            />
                        </div>
                    );
                },
                dataIndex: 'storeGroupId',
                key: 'storeGroupId',
                render: (data, elem) => {
                    const { key } = elem;

                    return !elem.new ? (
                        <p>{data}</p>
                    ) : (
                        <TreeSelect
                            disabled={disabled}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            filterTreeNode={(input, node) =>
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={(value, option) => {
                                if (
                                    elem.masterLaborId &&
                                    this.state.labors.findIndex(
                                        ({ storeGroupId, masterLaborId }) =>
                                            masterLaborId === elem.masterLaborId && storeGroupId === value
                                    ) !== -1
                                ) {
                                    notification.warning({
                                        message: this.props.intl.formatMessage({
                                            id: 'labor_form.notification'
                                        })
                                    });
                                }
                                this.state.labors[key].storeGroupId = value;
                                this.state.labors[key].laborCode = `${
                                    elem.masterLaborId ? elem.masterLaborId : '0000'
                                }-${value}`;
                                this.state.labors[key].detailTitle = option.props.title;
                                this.state.labors[
                                    key
                                ].customName = `${this.state.labors[key].name} ${option.props.title}`;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.detail_code'
                            })}
                            showSearch
                            style={{ minWidth: '130px' }}
                            treeData={this.treeData}
                            value={
                                this.state.labors[key].storeGroupId ? this.state.labors[key].storeGroupId : undefined
                            }
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.service_type' />
                            <Input
                                allowClear
                                onChange={async event => {
                                    this.setState({
                                        filterDefaultName: event.target.value,
                                        masterLaborName: event.target.value,
                                        page: 1
                                    });

                                    await this.handleSearchLabors();
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.service_type'
                                })}
                                value={this.state.filterDefaultName}
                            />
                        </div>
                    );
                },
                dataIndex: 'masterLaborName',
                key: 'name'
            },
            {
                title: <FormattedMessage id='services_table.short_specification' />,
                dataIndex: 'specificationId',
                key: 'specificationId',
                render: (data, row) => (
                    <Tooltip title={<FormattedMessage id='services_table.specification_placeholder' />}>
                        <Select
                            allowClear
                            disabled={disabled}
                            dropdownMatchSelectWidth={55}
                            onChange={value => {
                                row.changed = true;
                                row.specificationId = value;
                                this.setState({});
                            }}
                            value={data}
                        >
                            {this.state.specifications.map(({ specificationId, specificationName }) => (
                                <Option key={specificationId} value={specificationId}>
                                    {specificationName}
                                </Option>
                            ))}
                        </Select>
                    </Tooltip>
                )
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='name' />
                            <Input
                                allowClear
                                onChange={async event => {
                                    this.setState({
                                        filterName: event.target.value,
                                        search: event.target.value,
                                        page: 1
                                    });

                                    await this.handleSearchLabors();
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'name'
                                })}
                                value={this.state.filterName}
                            />
                        </div>
                    );
                },
                key: 'customName',
                render: elem => {
                    const { key } = elem;

                    return (
                        <Input
                            ref={input => {
                                this.nameInput = input;
                            }}
                            disabled={disabled}
                            onChange={event => {
                                elem.changed = true;
                                elem.customName = event.target.value;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'name'
                            })}
                            style={{
                                color: 'var(--text)',
                                width: '360px'
                            }}
                            value={elem.customName ? elem.customName : null}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='labor.show' />
                            </p>
                            <p>
                                <Checkbox
                                    disabled={disabled}
                                    onChange={event => {
                                        this.state.selectedRows.map(elem => {
                                            elem.changed = true;
                                            elem.disabled = !event.target.checked;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </p>
                        </div>
                    );
                },
                key: 'disabled',
                dataIndex: 'disabled',
                render: (data, elem) => {
                    return (
                        <Tooltip title={<FormattedMessage id='labor.long_show' />}>
                            <Checkbox
                                checked={!data}
                                disabled={disabled}
                                onChange={event => {
                                    elem.disabled = !event.target.checked;
                                    elem.changed = true;
                                    this.setState({});
                                }}
                            />
                        </Tooltip>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='services_table.short_units_placeholder' />
                            <Select
                                allowClear
                                disabled={disabled}
                                dropdownMatchSelectWidth={70}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={async value => {
                                    await this.setState({
                                        laborUnitId: value
                                    });

                                    this.state.selectedRows.map(elem => {
                                        elem.changed = true;
                                        elem.laborUnitId = value;
                                    });

                                    await this.saveLabors();
                                }}
                                optionFilterProp='children'
                                placeholder={this.props.intl.formatMessage({
                                    id: 'services_table.units'
                                })}
                                showSearch
                                style={{ maxWidth: '100px' }}
                                value={this.state.laborUnitId}
                            >
                                {(this.state.units || []).map((elem, index) => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.shortcut}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    );
                },

                dataIndex: 'laborUnitId',
                key: 'laborUnitId',
                render: (data, row) => {
                    return (
                        <Tooltip title={<FormattedMessage id='services_table.units_tooltip' />}>
                            <Select
                                allowClear
                                disabled={disabled}
                                dropdownMatchSelectWidth={70}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    row.changed = true;
                                    row.laborUnitId = value;
                                    this.setState({});
                                }}
                                optionFilterProp='children'
                                placeholder={this.props.intl.formatMessage({
                                    id: 'services_table.units'
                                })}
                                showSearch
                                style={{ maxWidth: '100px' }}
                                value={data}
                            >
                                {(this.state.units || []).map((elem, index) => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.shortcut}
                                    </Option>
                                ))}
                            </Select>
                        </Tooltip>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='order_form_table.fixed' />
                            </p>
                            <p>
                                <Switch
                                    disabled={disabled}
                                    onClick={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.changed = true;
                                            elem.fixed = value;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'fixed',
                key: 'fixed',
                render: (fixed, elem) => {
                    const { key } = elem;

                    return (
                        <Switch
                            checked={fixed}
                            disabled={disabled}
                            onClick={value => {
                                elem.changed = true;
                                elem.fixed = value;
                                this.setState({});
                            }}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='hours' />
                            </p>
                            <p>
                                <InputNumber
                                    decimalSeparator=','
                                    defaultValue={1}
                                    disabled={disabled}
                                    min={0.1}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.changed = true;
                                            elem.normHours = value;
                                        });
                                        this.setState({});
                                    }}
                                    step={0.2}
                                    style={{ color: 'var(--text)' }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'normHours',
                key: 'normHours',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={elem.fixed || disabled}
                            min={0.1}
                            onChange={value => {
                                elem.changed = true;
                                elem.normHours = value;
                                this.setState({});
                            }}
                            step={0.2}
                            style={{ color: 'var(--text)' }}
                            value={data || 1}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <p>
                                <FormattedMessage id='order_form_table.price' />
                            </p>
                            <p>
                                <InputNumber
                                    decimalSeparator=','
                                    defaultValue={1}
                                    disabled={disabled}
                                    min={1}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.changed = true;
                                            elem.price = value;
                                        });
                                        this.setState({});
                                    }}
                                    style={{ color: 'var(--text)' }}
                                />
                            </p>
                        </div>
                    );
                },
                dataIndex: 'price',
                key: 'price',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={!elem.fixed || disabled}
                            min={1}
                            onChange={value => {
                                elem.changed = true;
                                elem.price = value;
                                this.setState({});
                            }}
                            style={{ color: 'var(--text)' }}
                            value={data || 1}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <Popconfirm
                                disabled={disabled}
                                onConfirm={() => {
                                    this.state.selectedRows.map(elem => {
                                        if (elem.businessId) elem.deleted = true;
                                    });
                                    this.setState({});
                                }}
                                title={<FormattedMessage id='add_order_form.delete_confirm' />}
                            >
                                <Button
                                    disabled={disabled}
                                    // type='primary'
                                    style={{
                                        padding: '0px 8px'
                                    }}
                                >
                                    <UndoOutlined />
                                    <span style={{ marginLeft: 4 }}>|</span>
                                    <DeleteOutlined
                                        style={{
                                            color: 'red',
                                            marginLeft: 4
                                        }}
                                    />
                                </Button>
                            </Popconfirm>
                        </div>
                    );
                },
                key: 'delete',
                render: row => {
                    const buttonType = row.masterLaborId >= 9000 ? 'danger' : 'primary';
                    const iconType = row.masterLaborId >= 9000 ? 'delete' : 'undo';

                    return (
                        <Popconfirm
                            disabled={disabled}
                            onConfirm={async () => {
                                await fetchAPI('PUT', `labors/reset?laborIds=[${row.id}]`);
                                this.fetchLabors();
                            }}
                            title={<FormattedMessage id='add_order_form.delete_confirm' />}
                        >
                            <Button
                                disabled={disabled}
                                style={{
                                    width: '100%'
                                }}
                                title={
                                    row.masterLaborId >= 9000
                                        ? this.props.intl.formatMessage({ id: 'delete' })
                                        : this.props.intl.formatMessage({ id: 'update' })
                                }
                                type={buttonType}
                            >
                                <LegacyIcon type={iconType} />
                            </Button>
                        </Popconfirm>
                    );
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                render: row => {
                    return (
                        <Button
                            disabled={disabled}
                            icon={<CommentOutlined />}
                            onClick={() => {
                                this.setState({
                                    visibleCommentModal: true,
                                    laborInfo: row
                                });
                            }}
                            style={{
                                width: '100%'
                            }}
                        />
                    );
                }
            }
        ];
    }

    async updatePrice() {
        const {
            intl: { formatMessage }
        } = this.props;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = '/labors/recalc_prices';
        url += params;

        await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                message.success(formatMessage({ id: 'barcode.success' }));
            })
            .catch(function (error) {
                message.error('Цена не задана!');
            });

        await this.fetchLabors();
    }

    async saveLabors() {
        const labors = [];
        const newLabors = [];
        this.state.labors.map(elem => {
            if (elem.changed && !elem.new) {
                labors.push({
                    id: elem.id,
                    masterLaborId: elem.masterLaborId,
                    storeGroupId: elem.storeGroupId,
                    disabled: Boolean(elem.disabled),
                    crossId: elem.crossId || null,
                    name: elem.customName,
                    fixed: Boolean(elem.fixed),
                    price: elem.price || 1,
                    normHours: elem.normHours || 1,
                    specificationId: elem.specificationId || null,
                    laborUnitId: elem.laborUnitId || null
                });
            } else if (elem.new && elem.masterLaborId && elem.storeGroupId) {
                newLabors.push({
                    id: `${elem.masterLaborId}${elem.storeGroupId}`,
                    masterLaborId: elem.masterLaborId,
                    storeGroupId: elem.storeGroupId,
                    disabled: Boolean(elem.disabled),
                    crossId: elem.crossId || null,
                    name: elem.customName,
                    fixed: Boolean(elem.fixed),
                    price: elem.price || 1,
                    normHours: elem.normHours || 1,
                    specificationId: elem.specificationId || null,
                    laborUnitId: elem.laborUnitId || null
                });
            }
        });

        if (newLabors.length) {
            await fetchAPI('PUT', 'labors', null, newLabors);
        }
        if (labors.length) {
            await fetchAPI('PUT', 'labors', null, labors);
        }
        this.fetchLabors();
    }

    handleSearchLabors = _.debounce(() => {
        this.fetchLabors();
    }, 500);

    fetchLabors = async () => {
        const { page, pageSize, crossId, id, masterLaborId, storeGroupId, masterLaborName, search } = this.state;
        try {
            await this.setState({
                loading: true,
                selectedRowKeys: []
            });

            const response = await fetchAPI(
                'GET',
                'labors',
                { page, pageSize, all: true, id, crossId, masterLaborId, storeGroupId, masterLaborName, search },
                null,
                {
                    handleErrorInternally: true
                }
            );
            response.labors.sort((a, b) =>
                a.masterLaborId < b.masterLaborId ? -1 : a.masterLaborId > b.masterLaborId ? 1 : 0
            );
            response.labors.map((elem, index) => {
                elem.key = index;
                elem.laborCode = `${elem.masterLaborId}-${elem.storeGroupId}`;
                elem.price = elem.laborPrice.price;
                elem.fixed = elem.laborPrice.fixed;
                elem.normHours = elem.laborPrice.normHours;
                elem.customName = elem.customName || elem.name;
                elem.laborUnitId = elem.laborUnitId || null;
            });
            this.setState({
                labors: response.labors,
                count: response.laborsCount,
                currentPage:
                    this.props.location.state && this.props.location.state.showForm
                        ? Math.ceil(response.labors.length / 10)
                        : 1
            });

            if (this.props.location.state && this.props.location.state.showForm) {
                this.nameInput.focus();
            }

            this.setState({
                loading: false
            });
        } catch (err) {
            this.setState({
                loading: false
            });
        }
    };

    findExistingLabors = async (storeGroupId, masterLaborId) => {
        try {
            await this.setState({
                loadingModal: true
            });

            const response = await fetchAPI('GET', 'labors', { storeGroupId, masterLaborId }, null, {
                handleErrorInternally: true
            });

            this.setState({
                loadingModal: false
            });

            return response.labors;
        } catch (err) {
            this.setState({
                loadingModal: false
            });
        }
    };

    fetchUnitsSettings = async () => {
        const unitDefault = await fetchAPI('GET', 'business/measurement/units/settings', undefined, undefined);
        this.setState({
            unitDefault: unitDefault || []
        });
    };

    fetchData = async () => {
        const masterLabors = await fetchAPI('GET', 'labors/master');
        const storeGroups = await fetchAPI('GET', 'store_groups');
        this.buildStoreGroupsTree();

        this.fetchLabors();
        this.fetchUnitsSettings();

        const specifications = await fetchAPI('GET', 'salary/specifications', { presetId: 4 });
        const units = await fetchAPI('GET', 'business/measurement/units');
        this.setState({
            specifications,
            units,
            storeGroups,
            masterLabors: masterLabors.masterLabors
        });
    };

    buildStoreGroupsTree() {
        const treeData = [];
        for (let i = 0; i < this.state.storeGroups.length; i++) {
            const parentGroup = this.state.storeGroups[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id})`,
                value: parentGroup.id,
                key: `${i}`,
                children: []
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id})`,
                    value: childGroup.id,
                    key: `${i}-${j}`,
                    children: []
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id})`,
                        value: lastNode.id,
                        key: `${i}-${j}-${k}`,
                        children: []
                    });
                    for (let l = 0; l < lastNode.childGroups.length; l++) {
                        const elem = lastNode.childGroups[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id})`,
                            value: elem.id,
                            key: `${i}-${j}-${k}-${l}`
                        });
                    }
                }
            }
        }
        this.treeData = treeData;
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_labors' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    componentDidMount() {
        const { location } = this.props;
        if (location.state && location.state.laborId) {
            this.setState({
                filterCode: location.state.laborId
            });
        }
        this.fetchData();
    }

    render() {
        const { user, isMobile } = this.props;
        const {
            loading,
            labors,
            filterCode,
            filterCrossId,
            filterId,
            filterDetail,
            filterDefaultName,
            filterName,
            currentPage,
            selectedRowKeys,
            visibleAddLaborModal,
            unitDefault,
            helperDrawerOpen,
            allLinks,
            visibleCommentModal,
            laborInfo,
            count,
            pageSize,
            page,
            laborExist,
            loadingModal
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        const columns = this.columns();
        let dataSource = [...labors];
        dataSource = dataSource.filter(elem => !elem.deleted);
        // if (filterCode) {
        //     dataSource = dataSource.filter((data, i) => String(data.laborCode).replace('-', '').includes(filterCode));
        // }
        // if (filterCrossId) {
        //     dataSource = dataSource.filter((data, i) => String(data.crossId).includes(filterCrossId));
        // }
        // if (filterId) {
        //     dataSource = dataSource.filter((data, i) => String(data.masterLaborId).includes(String(filterId)));
        // }
        // if (filterDetail) {
        //     dataSource = dataSource.filter((data, i) => String(data.storeGroupId).includes(String(filterDetail)));
        // }
        // if (filterDefaultName) {
        //     dataSource = dataSource.filter((data, i) =>
        //         String(data.masterLaborName).toLowerCase().includes(filterDefaultName.toLowerCase()));
        // }
        // if (filterName) {
        //     dataSource = dataSource.filter((data, i) => {
        //         const parts = filterName.toLowerCase().split(' ');

        //         return parts.every(part => String(data.customName).toLowerCase().includes(part));
        //     });
        // }

        const pagination = {
            pageSize,
            size: 'large',
            page,
            total: Math.ceil(count / pageSize) * pageSize,
            hideOnSinglePage: false,
            current: page,
            position: 'bottom',
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchLabors();
            }
        };

        return (
            <Layout
                controls={
                    isGrantAccessed(this.props.user, grants.DIRECTORIES_JOBS, accesses.ROWO) && (
                        <React.Fragment>
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    this.setState({ visibleAddLaborModal: true });
                                }}
                                style={{ marginRight: 10 }}
                            />

                            <NormHourPriceModal updatePrice={() => this.updatePrice()} />

                            <Button onClick={() => this.updatePrice()} style={{ marginRight: 10 }}>
                                <FormattedMessage id='update_price' />
                            </Button>
                            
                            <Button onClick={() => this.saveLabors()} type='primary'>
                                {isMobile ? <SaveOutlined /> : <FormattedMessage id="save" />}
                            </Button>

                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    this.setState({
                                        helperDrawerOpen: true
                                    });
                                    await this.fetchHelperLinks();
                                }}
                                style={{
                                    fontSize: 22,
                                    marginLeft: 8,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </React.Fragment>
                    )
                }
                title={<FormattedMessage id='navigation.labors_page' />}
            >
                <Table
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={pagination}
                    rowSelection={rowSelection}
                    scroll={{ x: 1000 }}
                    size='small'
                />
                <AddLaborModal
                    fetchLabors={this.fetchLabors}
                    findExistingLabors={this.findExistingLabors}
                    hideModal={() => {
                        this.setState({ visibleAddLaborModal: false });
                    }}
                    laborExist={laborExist}
                    loadingModal={loadingModal}
                    location={this.props.location}
                    unitDefault={unitDefault}
                    visible={visibleAddLaborModal}
                />
                <AddCommetModal
                    fetchLabors={this.fetchLabors}
                    hideModal={() => {
                        this.setState({ visibleCommentModal: false });
                    }}
                    laborInfo={laborInfo}
                    location={this.props.location}
                    visible={visibleCommentModal}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}

const headerIconStyle = {
    fontSize: 24,
    cursor: 'pointer',
    margin: '0 8px 0 0'
};

@injectIntl
@connect(mapStateToProps, void 0)
class NormHourPriceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            normHourPrice: 300,
            visible: false
        };

        const { formatMessage } = props.intl;
    }

    buildRegionsTree(regions) {
        const treeData = [];
        for (let i = 0; i < regions.length; i++) {
            const parentGroup = regions[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id})`,
                name: parentGroup.name,
                value: parentGroup.id,
                key: parentGroup.id,
                children: []
            });
            for (let j = 0; j < parentGroup.childs.length; j++) {
                const childGroup = parentGroup.childs[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id})`,
                    name: childGroup.singleName,
                    value: childGroup.id,
                    key: childGroup.id,
                    children: []
                });
                for (let k = 0; k < childGroup.childs.length; k++) {
                    const lastNode = childGroup.childs[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id})`,
                        name: lastNode.singleName,
                        value: lastNode.id,
                        key: lastNode.id,
                        children: []
                    });
                    for (let l = 0; l < lastNode.childs.length; l++) {
                        const elem = lastNode.childs[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id})`,
                            name: elem.singleName,
                            value: elem.id,
                            key: elem.id
                        });
                    }
                }
            }
        }

        return treeData;
    }

    handleOk = async () => {
        const { normHourPrice, region } = this.state;
        await fetchAPI('PUT', 'businesses', undefined, { normHourPrice, region });
        this.handleCancel();
        this.props.updatePrice();
    };

    handleCancel() {
        this.setState({
            visible: false
        });
    }

    componentDidMount = async () => {
        const { normHourPrice, region } = await fetchAPI('GET', 'business');
        const regions = await fetchAPI('GET', 'regions_tree');
        this.setState({
            region,
            normHourPrice: Number(normHourPrice),
            regions: this.buildRegionsTree(regions)
        });
    };

    render() {
        const { visible, normHourPrice, regions, region } = this.state;
        const {
            intl: { formatMessage },
            isMobile
        } = this.props;
        
        return (
            <div>
                <SettingOutlined
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    style={headerIconStyle}
                    title={formatMessage({ id: 'navigation.settings' })}
                />
                <Modal
                    maskClosable={false}
                    onCancel={() => {
                        this.handleCancel();
                    }}
                    onOk={() => {
                        this.handleOk();
                    }}
                    title={<FormattedMessage id='navigation.settings' />}
                    visible={visible}
                    width='fit-content'
                >
                    <div className={isMobile ? Styles.formItemMobile : Styles.formItem}>
                        <FormattedMessage id='norm_hour_price' />
                        <InputNumber
                            decimalSeparator=','
                            min={0}
                            onChange={normHourPrice => {
                                this.setState({ normHourPrice });
                            }}
                            style={{
                                marginLeft: 8
                            }}
                            value={normHourPrice}
                        />
                    </div>
                    <div className={isMobile ? Styles.formItemMobile : Styles.formItem}>
                        <FormattedMessage id='locations.region' />
                        <TreeSelect
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterTreeNode={(input, node) => {
                                return (
                                    node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onSelect={region => {
                                this.setState({ region });
                            }}
                            showSearch
                            style={{ width: '60%' }}
                            treeData={regions}
                            treeDefaultExpandedKeys={[region]}
                            value={region}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
