import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { AddSalaryDocModal } from 'containers/SalariesDocumentContainer/components/AddSalaryDocModal';
import _ from 'lodash';
import AddAccDocsModal from 'pages/ACCDocumentsPage/components/AddAccDocsModal';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const struct = [
    {
        blockTitle: 'repairs',
        grant: grants.OPERATIONS_ORDER_LIST,
        color: 'var(--db_progress)',
        items: [
            {
                itemName: 'order',
                formLink: book.addOrder,
                grant: grants.OPERATIONS_ORDER_LIST,
                catalogueLink: book.ordersAppointments
            },
            {
                itemName: 'approve',
                disabled: true,
                formLink: book.exception,
                catalogueLink: `${book.orders}/approve`
            },
            {
                itemName: 'repair',
                disabled: true,
                formLink: book.exception,
                catalogueLink: `${book.orders}/progress`
            },
            {
                itemName: 'done',
                disabled: true,
                formLink: book.exception,
                catalogueLink: `${book.orders}/success`
            },
            {
                itemName: 'refuse',
                disabled: true,
                formLink: book.exception,
                catalogueLink: `${book.orders}/cancel`
            },
            {
                itemName: 'interactions',
                disabled: false,
                grant: grants.CRM_INTERACTIONS_LIST,
                formLink: book.addInteraction,
                catalogueLink: book.interactions
            },
            {
                itemName: 'rtl',
                disabled: false,
                // grant: grants.CRM_INTERACTIONS_LIST,
                catalogueLink: book.rtl
            },
            {
                itemName: 'invitation',
                disabled: true,
                formLink: book.exception,
                catalogueLink: `${book.orders}/invitations`
            },
            {
                itemName: 'feedback',
                grant: grants.CRM_REVIEWS,
                formLink: book.feedback,
                catalogueLink: `${book.orders}/reviews`
            }
        ]
    },
    {
        blockTitle: 'storage',
        grant: grants.WAREHOUSE,
        color: 'var(--db_reserve)',
        items: [
            {
                itemName: 'order-to-supplier',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS,
                formLink: book.storageDocument,
                catalogueLink: book.storageOrders,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'ORDER',
                        documentType: 'SUPPLIER'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'ORDER',
                        operationCode: 'ORD'
                    }
                }
            },
            {
                itemName: 'incoming-by-order',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS,
                formLink: book.storageDocument,
                catalogueLink: book.storageOrders,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'ORDER',
                        documentType: 'ORDERINCOME'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'ORDER',
                        operationCode: 'COM'
                    }
                }
            },
            {
                itemName: 'order-correction',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS,
                formLink: book.storageDocument,
                catalogueLink: book.storageOrders,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'ORDER',
                        documentType: 'ADJUSTMENT'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'ORDER',
                        operationCode: 'BOR'
                    }
                }
            },
            {
                itemName: 'prod_quick_nav',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageIncomes,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        documentType: 'PRODUCTION'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        operationCode: 'PRI'
                    }
                }
            },
            {
                itemName: 'disassembly_quick_nav',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES,
                formLink: book.storageDocument,
                catalogueLink: book.storageExpenses,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        documentType: 'DISASSEMBLY'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        operationCode: 'DSO'
                    }
                }
            },
            {
                itemName: 'income',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageIncomes,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        documentType: 'SUPPLIER'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        operationCode: 'INC'
                    }
                }
            },
            {
                itemName: 'service',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageIncomes,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        documentType: 'SERVICE'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        operationCode: 'SRV'
                    }
                }
            },
            {
                itemName: 'return-to-supplier',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES,
                formLink: book.storageDocument,
                catalogueLink: book.storageExpenses,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        documentType: 'SUPPLIER'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        operationCode: 'SRT'
                    }
                }
            },
            {
                itemName: 'for-storage',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageIncomes,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        documentType: 'PRESERVATION'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        operationCode: 'KPP'
                    }
                }
            },
            {
                itemName: 'excess',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageIncomes,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        documentType: 'INVENTORY'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        operationCode: 'STP'
                    }
                }
            },

            {
                itemName: 'expense',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES,
                formLink: book.storageDocument,
                catalogueLink: book.storageExpenses,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        documentType: 'CLIENT'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        operationCode: 'OUT'
                    }
                }
            },
            {
                itemName: 'services-return',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES,
                formLink: book.storageDocument,
                catalogueLink: book.storageExpenses,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        documentType: 'SERVICE'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        operationCode: 'VRT'
                    }
                }
            },
            {
                itemName: 'customer-return',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageIncomes,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        documentType: 'CLIENT'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'INCOME',
                        operationCode: 'CRT'
                    }
                }
            },
            {
                itemName: 'product-delivery',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageExpenses,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        documentType: 'PRESERVATION'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        operationCode: 'KPM'
                    }
                }
            },
            {
                itemName: 'lack',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageExpenses,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        documentType: 'INVENTORY'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        operationCode: 'STM'
                    }
                }
            },
            {
                itemName: 'storage-orders-expenses',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageExpenses,
                disabled: true,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        documentType: 'OWN_CONSUMPTION'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        operationCode: 'AUT'
                    }
                }
            },
            {
                itemName: 'write-off',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageExpenses,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        documentType: 'OWN_CONSUMPTION'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'EXPENSE',
                        operationCode: 'CST'
                    }
                }
            },
            {
                itemName: 'transfer',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageTransfers,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'TRANSFER',
                        documentType: 'TRANSFER'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'TRANSFER',
                        documentType: 'TRANSFER'
                    }
                }
            },
            {
                itemName: 'tool-issuance',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageTransfers,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'TRANSFER',
                        documentType: 'REPAIR_AREA'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'TRANSFER',
                        operationCode: 'TOL'
                    }
                }
            },
            {
                itemName: 'tool-return',
                grant: grants.WAREHOUSE_DOCUMENTS_LIST_MOVEMENTS,
                formLink: book.storageDocument,
                catalogueLink: book.storageTransfers,
                formLinkState: {
                    showForm: true,
                    formData: {
                        type: 'TRANSFER',
                        documentType: 'TOOL'
                    }
                },
                catalogueLinkState: {
                    showForm: true,
                    formData: {
                        type: 'TRANSFER',
                        operationCode: 'TOR'
                    }
                }
            }
        ]
    },
    {
        blockTitle: 'accounting',
        grant: grants.ACCOUNTING,
        color: 'var(--db_success)',
        items: [
            {
                itemName: 'cash-order',
                grant: grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER,
                formLink: book.cashFlowPage,
                catalogueLink: book.cashFlowPage,
                formLinkState: { showForm: true }
            },
            {
                itemName: 'acc_docs',
                grant: grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST,
                catalogueLink: book.accDocs,
                formLinkState: { showForm: true }
            },
            {
                itemName: 'salaries',
                grant: grants.ACCOUNTING_SALARIES,
                catalogueLink: book.salariesPage,
                formLinkState: { showForm: true }
            },
            {
                itemName: 'journal-vat',
                grant: grants.ACCOUNTING_VAT_JOURNAL,
                catalogueLink: book.journalVAT,
                disabled: true,
                formLinkState: { showForm: true }
            },
            {
                itemName: 'account-plan',
                grant: grants.ACCOUNTING_GENERAL_LEDGER_CHART_OF_ACCOUNTS,
                catalogueLink: book.accountPlan,
                disabled: true,
                formLinkState: { showForm: true }
            }
        ]
    }
];

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
class NewDocumentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            allEmployees: [],
            helperDrawerOpen: false,
            visibleAddSalaryDocModal: false,
            visibleAddAccDocsModal: false
        };
    }

    componentDidMount() {
        this.getAllEmployees();
    }

    renderBlock = ({ blockTitle, grant, items, color }, key) => {
        return (
            isGrantAccessed(this.props.user, grants[grant]) && (
                <div key={key} className={Styles.block}>
                    <div className={Styles.blockTitle}>
                        <FormattedMessage id={`new-document-page.block.${blockTitle}`} />
                    </div>
                    <div className={Styles.blockItems}>
                        {items.map((item, key) => this.renderItem(blockTitle, item, key, color))}
                    </div>
                </div>
            )
        );
    };

    renderItem = (
        blockTitle,
        { itemName, dataQA, formLink, formLinkState, catalogueLink, catalogueLinkState, disabled, emptyItem, grant },
        key,
        color
    ) => {
        const { user } = this.props;

        const isAccessable = grant ? isGrantAccessed(user, grant) : true;
        const isAccessableCRUD = grant ? isGrantAccessed(user, grant, accesses.ROWO) : true;

        return !emptyItem && isAccessable ? (
            <div key={key} className={isAccessable ? Styles.item : `${Styles.disabledItem} ${Styles.item}`}>
                <Link
                    className={Styles.buttonLink}
                    to={
                        isAccessable && {
                            pathname: catalogueLink,
                            state: catalogueLinkState
                        }
                    }
                >
                    <Button
                        className={Styles.itemButton}
                        data-qa={`new-document-page.${itemName}.button`}
                        style={{ border: `2px solid ${color}` }}
                    >
                        <FormattedMessage id={`new-document-page.item.${itemName}`} />
                    </Button>
                </Link>
                {catalogueLink !== book.salariesPage && catalogueLink !== book.accDocs && catalogueLink !== book.rtl ? (
                    <Link
                        className={Styles.folderLink}
                        disabled={disabled || !isAccessableCRUD}
                        to={
                            isAccessableCRUD && {
                                pathname: formLink,
                                state: formLinkState
                            }
                        }
                    >
                        <PlusOutlined className={Styles.folderIcon} data-qa={`new-document-page.${itemName}.plus`} />
                    </Link>
                ) : catalogueLink === book.salariesPage ? (
                    <Button
                        disabled={
                            !isGrantAccessed(
                                user,
                                grants.ACCOUNTING_SALARIES_DOCUMENT_LIST_SALARY_UNPOSTING,
                                accesses.ROWO
                            )
                        }
                        icon={
                            <PlusOutlined
                                style={{
                                    fontSize: 22,
                                    verticalAlign: 'middle'
                                }}
                            />
                        }
                        onClick={() => {
                            this.setState({
                                visibleAddSalaryDocModal: true
                            });
                        }}
                        type='text'
                    />
                ) : catalogueLink === book.rtl ? (
                    <Button
                        icon={
                            <PlusOutlined
                                style={{
                                    fontSize: 22,
                                    verticalAlign: 'middle'
                                }}
                            />
                        }
                        onClick={async () => {
                            const res = await fetchAPI('POST', '/retails', undefined, undefined, {
                                handleErrorInternally: true
                            });

                            if (res && res.id) {
                                goTo(`${book.rtl}/${res.id}`);
                            }
                        }}
                        type='text'
                    />
                ) : (
                    <Button
                        icon={
                            <PlusOutlined
                                style={{
                                    fontSize: 22,
                                    verticalAlign: 'middle'
                                }}
                            />
                        }
                        onClick={() => {
                            this.setState({
                                visibleAddAccDocsModal: true
                            });
                        }}
                        type='text'
                    />
                )}
            </div>
        ) : (
            <div key={key} className={`${Styles.emptyItem} ${Styles.item}`} />
        );
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'fast_navigation' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    fetchSalariesList = async () => {
        const { status, page, pageSize } = this.state;
        const { data, count } = await fetchAPI('GET', 'salary/docs', {
            page,
            pageSize,
            status
            // startDate: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined,
            // endDate: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined
        });
    };

    getAllEmployees = async () => {
        const employees = await fetchAPI('GET', 'employees', null, null, { handleErrorInternally: true });
        this.setState({
            allEmployees: employees
        });
    };

    render() {
        const { helperDrawerOpen, allLinks, visibleAddSalaryDocModal, allEmployees, visibleAddAccDocsModal } =
            this.state;

        return (
            <Layout
                controls={
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            const links = await fetchAPI('GET', '/helps', { helpId: 'fast_navigation' }, undefined, {
                                handleErrorInternally: true
                            });
                            this.setState({
                                allLinks: links,
                                helperDrawerOpen: true
                            });
                        }}
                        style={{
                            fontSize: 22,
                            marginLeft: 8,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                }
                description={<FormattedMessage id='new-document-page.description' />}
                title={<FormattedMessage id='navigation.new_document' />}
            >
                {struct.map((block, key) => this.renderBlock(block, key))}
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
                <AddSalaryDocModal
                    allEmployees={allEmployees}
                    fetchSalariesList={this.fetchSalariesList}
                    hideModal={() => {
                        this.setState({
                            visibleAddSalaryDocModal: false
                        });
                    }}
                    visible={visibleAddSalaryDocModal}
                />
                <AddAccDocsModal
                    hideModal={() => {
                        this.setState({
                            visibleAddAccDocsModal: false
                        });
                    }}
                    open={visibleAddAccDocsModal}
                />
            </Layout>
        );
    }
}

export default NewDocumentPage;
