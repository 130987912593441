import { Tabs } from 'antd';
import { Layout } from 'commons';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { HistoryPartsTab, HistoryVinTab } from './components';

const { TabPane } = Tabs;

const HistoryTrackingPage = ({ intl: { formatMessage } }) => {
    // const rowSelection = useMemo(() => {
    //     return {
    //         selectedRowKeys,
    //         onChange: (selectedRowKeys, selectedRows) => {
    //             setSelectedRows(selectedRows);
    //             setSelectedRowKeys(selectedRowKeys);
    //         }
    //     };
    //     // }, [actionsMenu, selectedRowKeys, selectedRows]);
    // }, [selectedRowKeys, selectedRows]);

    return (
        <Layout title={<FormattedMessage id='search_history' />}>
            <Tabs>
                <TabPane
                    key='1'
                    tab={formatMessage({
                        id: 'vehicle_page.spare_parts'
                    })}
                >
                    <HistoryPartsTab />
                </TabPane>

                <TabPane
                    key='2'
                    tab={formatMessage({
                        id: 'vehicle_page.vin'
                    })}
                >
                    <HistoryVinTab />
                </TabPane>
            </Tabs>
        </Layout>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default injectIntl(connect(mapStateToProps)(HistoryTrackingPage));
