import { DatePicker, InputNumber, Table } from 'antd';
import dayjs from 'dayjs';
import {
    selectVehicleInspectionIntervals,
    selectVehicleInspectionIntervalsFetching
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const DATETIME_FORMAT = 'DD.MM.YYYY';

const mapStateToProps = state => ({
    user: state.auth,
    inspectionIntervals: selectVehicleInspectionIntervals(state),
    fetchingVehicleInspectionIntervals: selectVehicleInspectionIntervalsFetching(state)
});

const mapDispatchToProps = {};

/**
 * Table of inspection intervals
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleInspectionIntervalsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commonSelectedOdometerValue: undefined,
            commonSelectedDateValue: dayjs(), // dayjs value
            odometers: {}
        };

        this.setDateOdometerValuesDebounce = _.debounce((key, odometerValue, dateValue) => {
            this.setDateOdometerValues(key, odometerValue, dateValue);
        }, 1000);
    }

    /**
     * Predicate odometer value based on statistics and provided date
     * @param {*} options
     * @returns
     */
    calculateOdometer = options => {
        const {
            prevServiceOdometer,
            beforePrevServiceOdometer,
            prevServiceMoment,
            beforePrevServiceMoment,
            selectedCalcMoment
        } = options;

        const odometerDiff = prevServiceOdometer - beforePrevServiceOdometer;

        console.log('odometerDiff: ', odometerDiff, prevServiceOdometer, beforePrevServiceOdometer);
        console.log('prevServiceDate: ', prevServiceMoment.format(DATETIME_FORMAT));
        console.log('beforePrevServiceDate: ', beforePrevServiceMoment.format(DATETIME_FORMAT));
        console.log('selectedCalcMoment: ', selectedCalcMoment.format(DATETIME_FORMAT));

        if (
            prevServiceOdometer &&
            beforePrevServiceOdometer &&
            prevServiceMoment.isValid() &&
            beforePrevServiceMoment.isValid() &&
            selectedCalcMoment.isValid()
        ) {
            const diffOfTwoServices = 0 + prevServiceMoment - (0 + beforePrevServiceMoment);
            const diffFromSelected = 0 + selectedCalcMoment - (0 + prevServiceMoment);

            console.log('diff: ', diffOfTwoServices, diffFromSelected);

            // nextPossibleServiceDate = prevServiceMoment.add(diffOfTwoServices, "milliseconds");
            const nextOdometrWillBe =
                prevServiceOdometer + odometerDiff * (diffFromSelected / diffOfTwoServices);
            console.log('nextOdometrWillBe: ', nextOdometrWillBe);

            return nextOdometrWillBe;
        }

        return undefined;
    };

    /**
     * Predicate date value based on statistics and provided date
     * @param {*} options
     * @returns
     */
    calculateDate = options => {
        const {
            prevServiceOdometer,
            beforePrevServiceOdometer,
            prevServiceMoment,
            beforePrevServiceMoment,
            selectedCalcOdometer
        } = options;

        const odometerDiff = prevServiceOdometer - beforePrevServiceOdometer;

        console.log('odometerDiff: ', odometerDiff, prevServiceOdometer, beforePrevServiceOdometer);
        console.log('prevServiceDate: ', prevServiceMoment.format(DATETIME_FORMAT));
        console.log('beforePrevServiceDate: ', beforePrevServiceMoment.format(DATETIME_FORMAT));
        console.log('selectedCalcOdometer: ', selectedCalcOdometer);

        if (
            prevServiceOdometer &&
            beforePrevServiceOdometer &&
            prevServiceMoment.isValid() &&
            beforePrevServiceMoment.isValid() &&
            selectedCalcOdometer
        ) {
            const diffOfTwoServices = 0 + prevServiceMoment - (0 + beforePrevServiceMoment);
            // const diffFromSelected = ((0 + selectedCalcMoment) - (0 + prevServiceMoment) );

            console.log('diff: ', diffOfTwoServices);

            // nextPossibleServiceDate = prevServiceMoment.add(diffOfTwoServices, "milliseconds");
            // const nextDateWillBe = (selectedCalcOdometer*diffOfTwoServices)/odometerDiff    //prevServiceOdometer + odometerDiff*(diffFromSelected/diffOfTwoServices);
            const nextDateWillBe =
                ((selectedCalcOdometer - prevServiceOdometer) * diffOfTwoServices) / odometerDiff +
                (0 + prevServiceMoment);
            console.log('nextDateWillBe: ', nextDateWillBe);

            return dayjs('1970.01.02').add(nextDateWillBe);
        }

        return undefined;
    };

    calculateDateInterpolate = options => {
        const {
            // prevServiceOdometer,
            beforePrevServiceOdometer,
            // prevServiceMoment,
            beforePrevServiceMoment,
            selectedCalcOdometer
        } = options;

        const {
            commonSelectedOdometerValue,
            commonSelectedDateValue // dayjs value
        } = this.state;

        if (!commonSelectedOdometerValue || !commonSelectedDateValue) {
            console.log('Invalid selected values provided');

            return;
        }

        const odometerDiff = commonSelectedOdometerValue - beforePrevServiceOdometer;

        console.log(
            'odometerDiff: ',
            odometerDiff,
            commonSelectedOdometerValue,
            beforePrevServiceOdometer
        );
        console.log('prevServiceDate: ', commonSelectedDateValue.format(DATETIME_FORMAT));
        console.log('beforePrevServiceDate: ', beforePrevServiceMoment.format(DATETIME_FORMAT));
        console.log('selectedCalcOdometer: ', selectedCalcOdometer);

        if (
            commonSelectedOdometerValue &&
            beforePrevServiceOdometer &&
            commonSelectedDateValue.isValid() &&
            beforePrevServiceMoment.isValid() &&
            selectedCalcOdometer
        ) {
            const diffOfTwoServices = 0 + commonSelectedDateValue - (0 + beforePrevServiceMoment);
            console.log('diff: ', diffOfTwoServices);

            const nextDateWillBe =
                ((selectedCalcOdometer - commonSelectedOdometerValue) * diffOfTwoServices) /
                    odometerDiff +
                (0 + commonSelectedDateValue);
            console.log('nextDateWillBe: ', nextDateWillBe);

            return dayjs('1970.01.02').add(nextDateWillBe);
        }

        return undefined;
    };

    setDateOdometerValues = (key, odometerValue, dateValue) => {
        this.setState({
            odometers: {
                ...this.state.odometers,
                [key]: {
                    odometerValue,
                    dateValue
                }
            }
        });
    };

    getOdometerValue = key => {
        const value = _.get(this.state, `odometers[${key}].odometerValue`, undefined);

        return value;
    };

    getDateValue = key => {
        const value = _.get(this.state, `odometers[${key}].dateValue`, undefined);

        return value;
    };

    render() {
        const { inspectionIntervals, fetchingVehicleInspectionIntervals } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            hideOnSinglePage: true
        };

        const columns = columnsConfig({
            setDateOdometerValues: this.setDateOdometerValues,
            getOdometerValue: this.getOdometerValue,
            getDateValue: this.getDateValue,
            calculateOdometer: this.calculateOdometer,
            calculateDate: this.calculateDate,
            setDateOdometerValuesDebounce: this.setDateOdometerValuesDebounce,
            calculateDateInterpolate: this.calculateDateInterpolate
        });

        return (
            <div className={Styles.mainCont}>
                <div className={Styles.buttonsCont}>
                    <div>
                        <span className={Styles.currentMileage}>
                            <FormattedMessage id='vehicle_page.current_mileage' />:
                        </span>
                        <InputNumber
                            className={Styles.inputNumber}
                            decimalSeparator=','
                            min={0}
                            onChange={value => {
                                this.setState({
                                    commonSelectedOdometerValue: value
                                });
                            }}
                            // formatter={ value => `${value}KM`}
                            // parser={ value => value.replace('K', '').replace('M', '')}
                        />
                        <span className={Styles.currentMileage}>км</span>
                    </div>

                    <DatePicker
                        className={Styles.inputDate}
                        defaultValue={dayjs()}
                        format={DATETIME_FORMAT}
                        onChange={value => {
                            this.setState({
                                commonSelectedDateValue: value
                            });
                            console.log('dateValue: ', value);
                        }}
                    />
                    {/* <Button
                        className={Styles.calculateButton} 
                        type={"primary"}
                    >
                        Calculate
                    </Button> */}
                </div>
                <div className={Styles.tableCont}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={inspectionIntervals}
                        loading={fetchingVehicleInspectionIntervals}
                        pagination={pagination}
                        rowKey={() => v4()}
                        scroll={{ x: 'auto', y: '60vh' }}
                        size='small'
                    />
                </div>
            </div>
        );
    }
}
