import { Input, Modal, Radio, Select } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import TasksTable from 'modals/EmployeeSubTasksModal/components/tables/TasksTable';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    fetchSubtasks,
    resetFields,
    selectFilters,
    selectSubtasks,
    setFilters
} from './redux/duck';

const { Option } = Select;
const { TextArea } = Input;

const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    filters: selectFilters(state),
    subtasks: selectSubtasks(state)
});

const mapDispatchToProps = {
    fetchSubtasks,
    setFilters,
    resetModal,
    resetFields
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class EmployeeSubTasksModal extends Component {
    handleSubmit = e => {
        e.preventDefault();

        this.props.resetModal();
        this.props.resetFields();
    };

    render() {
        const {
            resetModal,
            visible,
            subtasks,
            resetFields,
            filters,

            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    footer={null}
                    maskClosable={false}
                    onCancel={() => {
                        resetModal();
                        resetFields();
                    }}
                    onOk={this.handleSubmit}
                    title={<FormattedMessage id='tasks_page.tasks_chain' />}
                    visible={visible === MODALS.EMPLOYEE_SUBTASKS_MODAL}
                    width='70vw'
                >
                    <TasksTable taskId={this.props.modalProps.taskId} />
                </Modal>
            </div>
        );
    }
}
