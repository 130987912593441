/* eslint-disable complexity */
export const INCOME = 'INCOME';
export const EXPENSE = 'EXPENSE';
export const SUPPLIER = 'SUPPLIER';
export const SERVICE = 'SERVICE';
export const RESERVE = 'RESERVE';
export const CLIENT = 'CLIENT';
export const INVENTORY = 'INVENTORY';
export const OWN_CONSUMPTION = 'OWN_CONSUMPTION';
export const TRANSFER = 'TRANSFER';
export const ADJUSTMENT = 'ADJUSTMENT';
export const ORDERINCOME = 'ORDERINCOME';
export const ORDER = 'ORDER';
export const NEW = 'NEW';
export const DONE = 'DONE';
export const MAIN = 'MAIN';
export const TOOL = 'TOOL';
export const REPAIR_AREA = 'REPAIR_AREA';
export const STORAGE = 'STORAGE';
export const STOCK = 'STOCK';
export const PRESERVATION = 'PRESERVATION';
export const CORRECTIVE_SERVICE = 'CORRECTIVE_SERVICE';
export const PRODUCTION = 'PRODUCTION';
export const PRODUCTION_OUT = 'PRODUCTION_OUT';
export const DISASSEMBLY = 'DISASSEMBLY';
export const DISASSEMBLING_IN = 'DISASSEMBLING_OUT';
// export const KEEP_MINUS = 'KEEP_MINUS';

export const INC = 'INC';
export const CRT = 'CRT';
export const STP = 'STP';
export const SRV = 'SRV';
export const OUT = 'OUT';
export const AUT = 'AUT';
export const SRT = 'SRT';
export const CST = 'CST';
export const STM = 'STM';
export const TSF = 'TSF';
export const RES = 'RES';
export const ORD = 'ORD';
export const BOR = 'BOR';
export const COM = 'COM';
export const TOL = 'TOL';
export const TOR = 'TOR';
export const VRT = 'VRT';
export const KPP = 'KPP';
export const KPM = 'KPM';
export const MAN = 'MAN';
export const PRI = 'PRI';
export const PRO = 'PRO';
export const DSO = 'DSO';
export const DSI = 'DSI ';

export const posibleDocCodes = [
    INC,
    CRT,
    STP,
    SRV,
    OUT,
    AUT,
    SRT,
    CST,
    STM,
    TSF,
    RES,
    ORD,
    BOR,
    COM,
    TOL,
    TOR,
    VRT,
    KPP,
    KPM,
    PRI,
    PRO,
    DSO,
    DSI
];

export const CLIENT_CTP = 'CLIENT_CTP';
export const SUPPLIER_CTP = 'SUPPLIER_CTP';

export const typeToDocumentType = {
    income: {
        type: INCOME,
        documentType: [SUPPLIER, CLIENT, INVENTORY, SERVICE, PRESERVATION, CORRECTIVE_SERVICE, PRODUCTION]
    },
    expense: {
        type: EXPENSE,
        documentType: [
            CLIENT,
            SUPPLIER,
            INVENTORY,
            OWN_CONSUMPTION,
            SERVICE,
            PRESERVATION,
            CORRECTIVE_SERVICE,
            DISASSEMBLY
        ]
    },
    transfer: {
        type: EXPENSE,
        documentType: [TRANSFER, RESERVE, TOOL, REPAIR_AREA]
    },
    order: {
        type: ORDER,
        documentType: [SUPPLIER, ADJUSTMENT, ORDERINCOME]
    }
};

export const operationCodesByType = {
    income: [INC, STP, SRV, CRT, KPP, PRI, DSI],
    expense: [SRT, OUT, STM, CST, AUT, VRT, KPM, DSO, PRO],
    transfer: [TSF, TOL, TOR],
    order: [ORD, BOR, COM]
};

export function getStoreDocByOperationCode(operationCode) {
    switch (operationCode) {
        case INC:
            return {
                type: INCOME,
                documentType: SUPPLIER
            };
        case STP:
            return {
                type: INCOME,
                documentType: INVENTORY
            };
        case SRV:
            return {
                type: INCOME,
                documentType: SERVICE
            };
        case KPP:
            return {
                type: INCOME,
                documentType: PRESERVATION
            };
        case CRT:
            return {
                type: INCOME,
                documentType: CLIENT
            };
        case PRI:
            return {
                type: INCOME,
                documentType: PRODUCTION
            };
        case DSI:
            return {
                type: INCOME,
                documentType: DISASSEMBLY
            };
        case SRT:
            return {
                type: EXPENSE,
                documentType: SUPPLIER
            };
        case OUT:
            return {
                type: EXPENSE,
                documentType: CLIENT
            };
        case AUT:
            return {
                type: EXPENSE,
                documentType: CLIENT
            };
        case STM:
            return {
                type: EXPENSE,
                documentType: INVENTORY
            };
        case CST:
            return {
                type: EXPENSE,
                documentType: OWN_CONSUMPTION
            };
        case TSF:
            return {
                type: EXPENSE,
                documentType: TRANSFER
            };
        case KPM:
            return {
                type: EXPENSE,
                documentType: PRESERVATION
            };
        case PRO:
            return {
                type: EXPENSE,
                documentType: PRODUCTION
            };
        case DSO:
            return {
                type: EXPENSE,
                documentType: DISASSEMBLY
            };
        case TOL:
            return {
                type: TRANSFER,
                documentType: REPAIR_AREA
            };
        case TOR:
            return {
                type: TRANSFER,
                documentType: TOOL
            };
        case ORD:
            return {
                type: ORDER,
                documentType: SUPPLIER
            };
        case BOR:
            return {
                type: ORDER,
                documentType: ADJUSTMENT
            };
        case COM:
            return {
                type: ORDER,
                documentType: ORDERINCOME
            };
        case VRT:
            return {
                type: EXPENSE,
                documentType: SERVICE
            };

        default:
            return {};
    }
}
