import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, InputNumber, Spin, Table, Tooltip, notification } from 'antd';
import { get } from 'lodash';
import { CommentaryButton } from 'modals';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PistonIcon, WrenchIcon } from 'theme';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { fetchAPI } from 'utils';
import './Tab3D.css';
import path from './models/car.glb';
import texturePath from './texture/green.png';

const Tab3D = ({
    open,
    setOrderData,
    resetModal,
    orderId,
    reloadOrderForm,
    toOrderData,
    setToOrderData,
    setUnitComment,
    setUnitCommentPlaces,
    defaultEmployeeId,
    intl: { formatMessage }
}) => {
    const [systems, setSystems] = useState([]);
    const [units, setUnits] = useState([]);
    const [xx, setX] = useState(0);
    const [yy, setY] = useState(0);
    const [zz, setZ] = useState(0);
    const [loading, setLoading] = useState(true);
    const mountRef = useRef(null);
    const modalRef = useRef(null);
    const iconsRef = useRef([]);
    const cameraRef = useRef(null);
    let model;
    let animationId;

    useEffect(() => {
        const fetch = async () => {
            const systems = await fetchAPI('GET', '/graphic_systems', { twoDimensional: false }, null, {
                handleErrorInternally: true
            });
            setSystems(systems);
            setUnits(get(systems, '[0].units', []));
        };
        if (open && !systems.length) {
            fetch();
        }
    }, [open, systems]);

    const updatePlusIconsPosition = () => {
        if (model && units.length && cameraRef.current) {
            const width = mountRef.current.clientWidth;
            const height = mountRef.current.clientHeight;
            const raycaster = new THREE.Raycaster();

            units.forEach((unit, index) => {
                if (iconsRef.current[index]) {
                    // Vector representing the 3D position of the point
                    const vector = new THREE.Vector3(unit.plusX, unit.plusY, unit.plusZ);

                    // Project the vector to normalized device coordinates (NDC)
                    vector.project(cameraRef.current);

                    // Convert NDC to screen coordinates
                    const x = (vector.x * 0.5 + 0.5) * width;
                    const y = (-vector.y * 0.5 + 0.5) * height;

                    // Unproject the screen coordinates back to a 3D direction
                    const direction = new THREE.Vector3(vector.x, vector.y, -1)
                        .unproject(cameraRef.current)
                        .sub(cameraRef.current.position)
                        .normalize();

                    // Set the raycaster from the camera in the direction of the point
                    raycaster.set(cameraRef.current.position, direction);

                    // Perform raycasting
                    const intersects = raycaster.intersectObjects(model.children, true);

                    // Calculate distance from camera to the vector
                    const distanceToCamera = cameraRef.current.position.distanceTo(
                        new THREE.Vector3(unit.plusX, unit.plusY, unit.plusZ)
                    );
                    const isBehindModel = intersects.length > 0 && intersects[0].distance < distanceToCamera;

                    // Set icon visibility and position based on whether it is behind the model
                    if (isBehindModel) {
                        iconsRef.current[index].style.display = 'none';
                    } else {
                        iconsRef.current[index].style.display = 'block';
                        iconsRef.current[index].style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`;
                        iconsRef.current[index].style.position = 'absolute';
                    }
                }
            });
        }
    };

    const columns = useMemo(
        () => [
            {
                key: 'icon',
                render: record => (record.laborId ? <WrenchIcon /> : <PistonIcon />)
            },
            {
                title: <FormattedMessage id='storage_journal.name' />,
                key: 'name',
                render: record => (
                    <Flex vertical>
                        <div>{record.storeGroupName || record.laborName}</div>
                    </Flex>
                )
            },
            {
                key: 'comment',
                render: record => {
                    let name = record.storeGroupName || record.laborName;
                    if (name && name.indexOf(' - ') > -1) {
                        name = name.slice(0, name.indexOf(' - '));
                    }

                    return (
                        <CommentaryButton
                            commentary={
                                record.comment || {
                                    comment: undefined,
                                    positions: [],
                                    problems: []
                                }
                            }
                            detail={name}
                            setComment={(comment, positions, problems) => {
                                if (record.laborId) {
                                    setToOrderData(prev => ({
                                        labors: prev.labors.map(lbr =>
                                            record.laborId === lbr.laborId
                                                ? {
                                                      ...lbr,
                                                      laborName: comment || record.laborName,
                                                      comment: {
                                                          comment,
                                                          positions,
                                                          problems
                                                      }
                                                  }
                                                : lbr),
                                        storeGroups: prev.storeGroups
                                    }));
                                } else {
                                    setToOrderData(prev => ({
                                        labors: prev.labors,
                                        storeGroups: prev.storeGroups.map(grp =>
                                            record.storeGroupId === grp.storeGroupId
                                                ? {
                                                      ...grp,
                                                      storeGroupName: comment || record.storeGroupName,
                                                      comment: {
                                                          comment,
                                                          positions,
                                                          problems
                                                      }
                                                  }
                                                : grp)
                                    }));
                                }
                            }}
                        />
                    );
                }
            },
            {
                key: 'delete',
                render: record => (
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            if (record.laborId) {
                                setToOrderData(prev => ({
                                    labors: prev.labors.filter(({ laborId }) => laborId !== record.laborId),
                                    storeGroups: prev.storeGroups
                                }));
                            } else {
                                setToOrderData(prev => ({
                                    labors: prev.labors,
                                    storeGroups: prev.storeGroups.filter(
                                        ({ storeGroupId }) => storeGroupId !== record.storeGroupId
                                    )
                                }));
                            }
                        }}
                    />
                )
            }
        ],
        []
    );

    const handleCloseToOrderModal = useCallback(() => {
        setOrderData(undefined);
    }, []);

    const handleAddToOrder = useCallback(() => {
        const fetch = async () => {
            const payload = {
                insertMode: true,
                services: toOrderData.labors.map(
                    ({ laborId, laborName, laborHour, laborPrice, measureUnitId, comment }) => ({
                        serviceId: laborId,
                        serviceName: laborName,
                        count: laborHour,
                        servicePrice: laborPrice,
                        laborUnitId: measureUnitId,
                        employeeId: defaultEmployeeId,
                        comment
                    })
                ),
                details: toOrderData.storeGroups.map(({ storeGroupId, storeGroupName, measureUnitId, comment }) => ({
                    storeGroupId,
                    name: storeGroupName,
                    partUnitId: measureUnitId,
                    comment
                }))
            };

            if (!payload.services.length) {
                delete payload.services;
            }
            if (!payload.details.length) {
                delete payload.details;
            }

            await fetchAPI('PUT', `orders/${orderId}`, null, payload);
            notification.success({
                message: formatMessage({
                    id: 'barcode.success'
                })
            });
            resetModal();
            reloadOrderForm();
        };
        fetch();
        setToOrderData({ labors: [], storeGroups: [] });
        handleCloseToOrderModal();
    }, [handleCloseToOrderModal, toOrderData, orderId, formatMessage, resetModal, reloadOrderForm]);

    useEffect(() => {
        if (!open || !units.length) return;

        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        cameraRef.current = camera;
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(width, height);
        renderer.setClearColor(0xffffff);
        mountRef.current.appendChild(renderer.domElement);

        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffeedd, 1);
        directionalLight.position.set(9, 10, 10.5);
        scene.add(directionalLight);

        const directionalLight1 = new THREE.DirectionalLight(0xffeedd, 1);
        directionalLight1.position.set(-9, 10, 10.5);
        scene.add(directionalLight1);

        const directionalLight2 = new THREE.DirectionalLight(0xffeedd, 1);
        directionalLight2.position.set(0, 15, -13.5);
        scene.add(directionalLight2);

        const loader = new GLTFLoader();
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(texturePath);

        const hemisphereLight = new THREE.HemisphereLight(0xddeeff, 0x202020, 1);
        scene.add(hemisphereLight);

        loader.load(
            path,
            gltf => {
                model = gltf.scene;
                model.traverse(child => {
                    if (child.isMesh) {
                        // child.material.map = texture;
                        child.material.metalness = 0.2;
                        child.material.roughness = 0.5;
                        // child.material.transparent = true;
                    }
                });

                scene.add(model);
                setLoading(false);
            },
            undefined,
            error => {
                console.error(error);
                setLoading(false);
            }
        );

        camera.position.set(0, 3, -7);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableZoom = true;
        controls.enablePan = false;

        const animate = () => {
            animationId = requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
            // pointLight.position.copy(camera.position);
            updatePlusIconsPosition();
        };
        animate();

        const handleResize = () => {
            const width = mountRef.current.clientWidth;
            const height = mountRef.current.clientHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            if (mountRef.current) {
                mountRef.current.removeChild(renderer.domElement);
            }
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(animationId);
            setLoading(true);
        };
    }, [open, units, xx, yy, zz]);

    return (
        <Flex>
            <div style={{ display: 'none' }}>
                <InputNumber decimalSeparator=',' onChange={value => setX(value)} value={xx} />
                <InputNumber decimalSeparator=',' onChange={value => setY(value)} value={yy} />
                <InputNumber decimalSeparator=',' onChange={value => setZ(value)} value={zz} />
            </div>

            <div ref={modalRef} style={{ width: '100%', minWidth: 1080, height: 800, position: 'relative' }}>
                {loading && (
                    <div className='loading-indicator'>
                        <Spin size='large' />
                    </div>
                )}
                <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
                {!loading &&
                    units.map((unit, index) => (
                        <div key={unit.id} ref={el => (iconsRef.current[index] = el)} className='plus-icon'>
                            <Tooltip title={unit.name}>
                                <Button
                                    icon={<PlusOutlined style={{ verticalAlign: 'sub' }} />}
                                    onClick={() => {
                                        setUnitComment(unit.unitComment);
                                        setUnitCommentPlaces(unit.commentPointers);
                                        setOrderData({
                                            storeGroups: unit.storeGroups,
                                            labors: unit.labors
                                        });
                                    }}
                                    shape='circle'
                                    style={{}}
                                    type='primary'
                                />
                            </Tooltip>
                        </div>
                    ))}
            </div>
            <div
                style={{
                    background: 'white',
                    minWidth: 360,
                    maxWidth: 660
                }}
            >
                <Table
                    bordered
                    columns={columns}
                    dataSource={[...get(toOrderData, 'labors', []), ...get(toOrderData, 'storeGroups', [])]}
                    footer={() => (
                        <Flex>
                            <Button
                                block
                                disabled={
                                    ![...get(toOrderData, 'labors', []), ...get(toOrderData, 'storeGroups', [])].length
                                }
                                onClick={handleAddToOrder}
                                type='primary'
                            >
                                <FormattedMessage id='ok' />
                            </Button>
                        </Flex>
                    )}
                    pagination={false}
                    rowKey={record => record.laborId || record.storeGroupId}
                    size='small'
                />
            </div>
        </Flex>
    );
};

export default injectIntl(Tab3D);
