/* Constants */
import dayjs from 'dayjs';

export const moduleName = 'supplierReportModal';
const prefix = `cpb/${moduleName}`;

export const DOWNLOAD_SALES_REPORT = `${prefix}/DOWNLOAD_SALES_REPORT`;

export const SET_REPORT_FILTERS = `${prefix}/SET_REPORT_FILTERS`;

export const RESET_REPORT_FILTERS = `${prefix}/RESET_REPORT_FILTERS`;

export const FETCH_SUPPLIER_REQUISITES = `${prefix}/FETCH_SUPPLIER_REQUISITES`;
export const FETCH_SUPPLIER_REQUISITES_SUCCESS = `${prefix}/FETCH_SUPPLIER_REQUISITES_SUCCESS`;

export const FETCH_REQUISITES = `${prefix}/FETCH_REQUISITES`;
export const FETCH_REQUISITES_SUCCESS = `${prefix}/FETCH_REQUISITES_SUCCESS`;

export const SET_ACT_BUTTON_DISABLED = `${prefix}/SET_ACT_BUTTON_DISABLED`;

/**
 * Available values to send on server, used to define detailing of sales report
 */
export const detailingValues = {
    withoutDetainling: 'without',
    docsDetailing: 'docs',
    actDetailing: 'act'
};

/** Date format for backend */
export const DEF_DATE_FORMAT = 'YYYY/MM/DD';

/* Reducer */
const ReducerState = {
    filters: {
        createdFromDate: dayjs().startOf('month'),
        createdToDate: dayjs().endOf('month'),

        detailing: detailingValues.withoutDetainling,
        supplierId: undefined,
        businessRequisiteId: undefined,
        businessSupplierRequisiteId: undefined
    },

    businessRequisites: [],
    requisites: [],

    actButtonDisabled: true
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_REPORT_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...payload }
            };

        case RESET_REPORT_FILTERS:
            return {
                ...state,
                filters: {
                    createdFromDate: dayjs().startOf('month'),
                    createdToDate: dayjs().endOf('month'),

                    detailing: detailingValues.withoutDetainling,
                    supplierId: undefined
                },
                actButtonDisabled: true
            };
        case FETCH_SUPPLIER_REQUISITES_SUCCESS:
            return {
                ...state,
                businessRequisites: payload
            };

        case FETCH_REQUISITES_SUCCESS:
            return {
                ...state,
                requisites: payload
            };

        case SET_ACT_BUTTON_DISABLED:
            return {
                ...state,
                actButtonDisabled: payload,
                ...(payload
                    ? {
                          filters: {
                              ...state.filters,
                              detailing: detailingValues.withoutDetainling
                          }
                      }
                    : {})
            };

        default:
            return state;
    }
}

/* Selectors */

export const selectDetailing = state => state[moduleName].filters.detailing;
export const selectFilters = state => state[moduleName].filters;
export const selectBusinessRequisites = state => state[moduleName].businessRequisites;
export const selectRequisites = state => state[moduleName].requisites;
export const selectActButtonDisabled = state => state[moduleName].actButtonDisabled;

/* Actions */

export const downloadSalesReport = () => ({
    type: DOWNLOAD_SALES_REPORT
});

export const setReportsFilters = filters => ({
    type: SET_REPORT_FILTERS,
    payload: filters
});

export const resetFilters = () => ({
    type: RESET_REPORT_FILTERS
});

export const fetchSupplierRequisites = supplierId => ({
    type: FETCH_SUPPLIER_REQUISITES,
    payload: supplierId
});

export const fetchSupplierRequisitesSuccess = requisites => ({
    type: FETCH_SUPPLIER_REQUISITES_SUCCESS,
    payload: requisites
});

export const fetchRequisites = () => ({
    type: FETCH_REQUISITES
});

export const fetchRequisitesSuccess = requisites => ({
    type: FETCH_REQUISITES_SUCCESS,
    payload: requisites
});

export const setActButtonDisabled = disabled => ({
    type: SET_ACT_BUTTON_DISABLED,
    payload: disabled
});
