/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, Select, Spin, Table, Tooltip } from 'antd';
import { AvailabilityIndicator, WarehouseSelect } from 'components';
import { API_URL } from 'core/forms/orderDiagnosticForm/saga';
import _, { get } from 'lodash';
import { DetailSupplierModal, StoreProductTrackingModal } from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getCurrency, images, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { info } = Modal;
const { Option } = Select;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

@injectIntl
class DetailStorageModal extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fetched: false,
            dataSource: [],
            storeOptions: [],
            storeFilter: undefined,
            brandOptions: [],
            brandFilter: [],
            codeFilter: undefined,
            attributesFilters: [],
            inStock: false,
            reserveModalVisible: false,
            reserveModalData: undefined,
            page: 1,
            total: 0
        };

        this.columns = [
            {
                title: <FormattedMessage id='photo' />,
                key: 'photo',
                render: elem => {
                    const src = elem.images[0]
                        ? `${__TECDOC_IMAGES_URL__}/${elem.images[0].pictureName}`
                        : `${__TECDOC_IMAGES_URL__}/not_found.png`;

                    return <PhotoModal attributes={elem.attributes} src={src} />;
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.detail_code' />
                            {this.state.storeOptions.length ? (
                                <Select
                                    data-qa='select_storeFilter_detail_storage_modal'
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                        zIndex: '9999',
                                        minWidth: 380
                                    }}
                                    filterOption={(input, option) => {
                                        return (
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            String(option.props.value).indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                    onSelect={(value, option) => {
                                        this.setState({
                                            storeFilter: value
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'order_form_table.store_group'
                                    })}
                                    showSearch
                                    style={{ maxWidth: 280 }}
                                    value={this.state.storeFilter}
                                >
                                    {this.state.storeOptions.map((elem, i) => (
                                        <Option key={i} value={elem.id}>
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            ) : null}
                            <Input
                                allowClear
                                data-qa='input_codeFilter_detail_storage_modal'
                                onChange={async event => {
                                    await this.setState({
                                        codeFilter: event.target.value
                                    });
                                    if (this.props.stockMode) {
                                        this.fetchData();
                                    }
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_code'
                                })}
                                style={{ maxWidth: 280 }}
                                value={this.state.codeFilter}
                            />
                        </div>
                    );
                },
                key: 'code',
                dataIndex: 'partNumber',
                sorter: (a, b) => a.partNumber.localeCompare(b.partNumber),
                sortDirections: ['descend', 'ascend'],
                render: (data, elem) => {
                    return (
                        <div>
                            <div style={{ fontWeight: 'bold' }}>{data}</div>
                            <div>{elem.description}</div>
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.brand' />
                            <Select
                                data-qa='select_brandFilter_detail_storage_modal'
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.props.value).indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                mode='multiple'
                                onDeselect={(value, option) => {
                                    const index = this.state.brandFilter.indexOf(value);
                                    const tmp = this.state.brandFilter.filter((_, i) => i != index);
                                    this.setState({
                                        brandFilter: tmp,
                                        update: true
                                    });
                                }}
                                onSelect={(value, option) => {
                                    this.state.brandFilter.push(value);
                                    this.setState({
                                        update: true
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.brand'
                                })}
                                showSearch
                                style={{ minWidth: 130 }}
                                value={this.state.brandFilter}
                            >
                                {this.state.brandOptions.map((elem, i) => (
                                    <Option key={i} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    );
                },
                key: 'brand',
                dataIndex: 'supplierName',
                sorter: (a, b) => a.supplierName && a.supplierName.localeCompare(b.supplierName),
                sortDirections: ['descend', 'ascend'],
                render: (data, elem) => {
                    // <div>{getSupplier(elem.suplierId, elem.partNumber)}</div>
                    return <div>{data}</div>;
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.info' />
                            <div style={{ display: 'flex' }}>
                                {this.getAttributeFilter(1)}
                                {this.getAttributeFilter(2)}
                            </div>
                            <div style={{ display: 'flex' }}>
                                {this.getAttributeFilter(3)}
                                {this.getAttributeFilter(4)}
                            </div>
                        </div>
                    );
                },
                key: 'attributes',
                dataIndex: 'attributes',
                render: (attributes, elem) => {
                    let title = '';
                    let data = '';
                    for (let i = 0; i < attributes.length; i++) {
                        const attribute = attributes[i];
                        title += `${attribute.description}: ${attribute.value}\n`;
                        data += `${attribute.value}`;
                        if (i == attributes.length - 1) {
                            data += '. ';
                        } else {
                            data += ', ';
                        }
                    }
                    data += `${elem.productId}.`;

                    return (
                        <div style={{ textTransform: 'capitalize' }} title={title}>
                            {data}
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            {!this.props.stockMode ? (
                                <FormattedMessage id='order_form_table.supplier' />
                            ) : (
                                <React.Fragment>
                                    <FormattedMessage id='storage' /> / <FormattedMessage id='wms.cell' />
                                </React.Fragment>
                            )}
                            <WarehouseSelect onChange={warehouseId => this.fetchData(warehouseId)} />
                        </div>
                    );
                },
                key: 'businessSupplierName',
                dataIndex: 'businessSupplierName',
                render: (data, elem) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Input
                                data-qa='input_cellAddress_data_detail_storage_modal'
                                disabled
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.supplier'
                                })}
                                style={{ maxWidth: 180, color: 'black' }}
                                value={elem.cellAddress || data}
                            />
                            {this.props.stockMode ? (
                                <DetailWarehousesCountModal
                                    onSelect={async (cellAddress, warehouseId, warehouseName) => {
                                        elem.cellAddress = cellAddress;
                                        elem.warehouseId = warehouseId;
                                        await this.setState({});
                                        await this.handleOk(elem);
                                    }}
                                    productId={elem.id}
                                />
                            ) : (
                                <DetailSupplierModal
                                    brandId={elem.brandId}
                                    detailCode={elem.partNumber}
                                    disabled={this.props.stockMode}
                                    keyValue={elem.key}
                                    setStoreSupplier={this.setSupplier}
                                    storeGroupId={this.props.storeGroupId || elem.storeGroupId}
                                    user={this.props.user}
                                />
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                render: data => {
                    const strVal = String(Math.round(data * 10) / 10);

                    return data ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'salePrice',
                dataIndex: 'salePrice',
                sorter: (a, b) => {
                    if (!this.state.inStock) {
                        this.setState({
                            inStock: true
                        });
                    }
                    if (!b.salePrice) {
                        return -1;
                    }

                    return Number(a.salePrice) - Number(b.salePrice);
                },
                sortDirections: ['descend', 'ascend'],
                render: data => {
                    const strVal = String(Math.round(data * 10) / 10);

                    return data ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            {this.props.stockMode ? (
                                <span>
                                    <FormattedMessage id='storage.in_stock' /> /{' '}
                                    <FormattedMessage id='storage.available' />
                                </span>
                            ) : (
                                <FormattedMessage id='order_form_table.store' />
                            )}
                            <div style={{ fontWeight: '400', fontSize: 12 }}>
                                <FormattedMessage id='in_stock' />
                                <Checkbox
                                    checked={this.state.inStock}
                                    data-qa='checkbox_inStock_detail_storage_modal'
                                    onChange={async () => {
                                        await this.setState({
                                            inStock: !this.state.inStock
                                        });
                                        if (this.props.stockMode) {
                                            this.fetchData();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                },
                key: 'store',
                dataIndex: 'store',
                sorter: (a, b) => {
                    const aStore = a.store ? a.store[0] : 0;
                    const bStore = b.store ? b.store[0] : 0;

                    return Number(aStore) - Number(bStore);
                },
                sortDirections: ['descend', 'ascend'],
                render: (store, elem) => {
                    return this.props.stockMode ? (
                        <span
                            onClick={() =>
                                this.setState({
                                    reserveModalVisible: elem.id
                                })
                            }
                            style={{
                                color: 'var(--link)',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                        >
                            {elem.countInWarehouses} / {elem.available}
                        </span>
                    ) : (
                        <AvailabilityIndicator indexArray={store} />
                    );
                }
            },
            {
                key: 'select',
                render: elem => {
                    return (
                        <Button
                            data-qa='button_handle_ok_select_detail_storage_modal'
                            onClick={() => {
                                this.handleOk(elem);
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='select' />
                        </Button>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.visible && !prevProps.visible) {
            this.fetchData(undefined, true);
            this.setState({
                visible: true
            });
        }
    }

    handleOk(elem) {
        const supplierBrandId = elem.supplierBrandId
            ? elem.supplierBrandId
            : elem.price
            ? elem.price.supplierBrandId
            : undefined;
        const brandId = elem.brandId ? elem.brandId : elem.price ? elem.price.brandId : undefined;
        const name = elem.storeGroupId == 1000000 ? elem.description : elem.storeGroupName;
        const supplierOriginalCode = elem.price ? elem.price.supplierOriginalCode : undefined;
        const supplierProductNumber = elem.price ? elem.price.supplierProductNumber : undefined;
        const supplierPartNumber = elem.price ? elem.price.supplierPartNumber : undefined;
        const isFromStock = elem.price ? elem.price.isFromStock : undefined;
        const defaultWarehouseId = elem.price ? elem.price.defaultWarehouseId : undefined;

        if (this.props.onSelect) {
            this.props.onSelect(
                elem.partNumber,
                brandId,
                elem.productId,
                this.props.tableKey,
                elem.storeGroupId,
                name,
                supplierOriginalCode,
                supplierProductNumber,
                supplierPartNumber
            );
        }
        if (this.props.setSupplier) {
            this.props.setSupplier(
                elem.businessSupplierId,
                elem.businessSupplierName,
                supplierBrandId,
                elem.purchasePrice,
                elem.salePrice,
                elem.store,
                supplierOriginalCode,
                supplierProductNumber,
                supplierPartNumber,
                this.props.tableKey,
                isFromStock,
                defaultWarehouseId,
                elem.productId,
                brandId,
                elem.cellAddress,
                elem.warehouseId
            );
        }
        if (this.props.selectProduct) {
            this.props.selectProduct({
                warehouseId: elem.warehouseId,
                productId: elem.productId,
                cellAddress: elem.cellAddress,
                code: elem.partNumber,
                brandId
            });
        }
        this.handleCancel();
    }

    getAttributeFilter(key) {
        if (this.state.attributesFilters[`index${key}`] == undefined) {
            return null;
        }

        return (
            <Select
                allowClear
                data-qa='select_attributesFilters_detail_storage_modal'
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                    zIndex: '9999',
                    textTransform: 'capitalize'
                }}
                onChange={value => {
                    this.state.attributesFilters[`index${key}`].current = value;
                    this.setState({
                        update: true
                    });
                }}
                placeholder={this.state.attributesFilters[`index${key}`].description}
                style={{ display: 'block', width: '50%', textTransform: 'capitalize' }}
                value={this.state.attributesFilters[`index${key}`].current}
            >
                {this.state.attributesFilters[`index${key}`].values.map((option, i) => (
                    <Option key={i} value={option}>
                        {option}
                    </Option>
                ))}
            </Select>
        );
    }

    filterDataSourceByAttribute(data, key) {
        const { attributesFilters } = this.state;
        if (attributesFilters[`index${key}`] && attributesFilters[`index${key}`].current) {
            data = data.filter(elem => {
                const dataAttributes = elem.attributes.find(
                    attr => attr.description == attributesFilters[`index${key}`].description
                );
                if (dataAttributes && dataAttributes.value == attributesFilters[`index${key}`].current) {
                    return true;
                }

                return false;
            });
        }

        return data;
    }

    setSupplier = item => {
        const {
            businessSupplierId,
            businessSupplierName,
            supplierBrandId,
            purchasePrice,
            price,
            store,
            supplierOriginalCode,
            supplierProductNumber,
            supplierPartNumber,
            key,
            isFromStock,
            defaultWarehouseId,
            productId
        } = item;
        console.log(item, this);
        this.state.dataSource[key].businessSupplierId = businessSupplierId;
        this.state.dataSource[key].businessSupplierName = businessSupplierName;
        this.state.dataSource[key].purchasePrice = purchasePrice;
        this.state.dataSource[key].supplierBrandId = supplierBrandId;
        this.state.dataSource[key].salePrice = price;
        this.state.dataSource[key].store = store;
        this.state.dataSource[key].price.supplierOriginalCode = supplierOriginalCode;
        this.state.dataSource[key].price.supplierProductNumber = supplierProductNumber;
        this.state.dataSource[key].price.supplierPartNumber = supplierPartNumber;
        this.state.dataSource[key].price.isFromStock = isFromStock;
        this.state.dataSource[key].price.defaultWarehouseId = defaultWarehouseId;
        this.state.dataSource[key].productId = productId;
        this.setState({});

        this.handleOk(this.state.dataSource[key]);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            fetched: false,
            dataSource: [],
            brandOptions: [],
            brandFilter: [],
            storeFilter: undefined,
            storeOptions: [],
            codeFilter: undefined,
            attributesFilters: [],
            inStock: false,
            page: 1
        });
        if (this.props.hideModal) {
            this.props.hideModal();
        }
    };

    fetchData(warehouseId) {
        if (this.props.stockMode) {
            if (!this.state.visible) {
                this.state.codeFilter = this.props.codeFilter;
            }
            var that = this;
            const token = localStorage.getItem('_my.carbook.pro_token');
            let url = `${__API_URL__}/store_products?page=${this.state.page}&pageSize=6`;
            if (warehouseId) {
                url += `&warehouseId=${warehouseId}`;
            }
            if (this.state.codeFilter) {
                url += `&query=${this.state.codeFilter}`;
            }
            if (this.state.inStock) {
                url += '&onlyAvailable=true';
            }
            fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        return Promise.reject(new Error(response.statusText));
                    }

                    return Promise.resolve(response);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    const brandOptions = [];
                    data.list.map((elem, key) => {
                        elem.key = key;
                        elem.productId = elem.id;
                        elem.images = [];
                        elem.attributes = [];
                        elem.supplierId = elem.brand && elem.brand.id;
                        elem.supplierName = (elem.brand && elem.brand.name) || '';
                        elem.businessSupplierId = 0;
                        elem.businessSupplierName = that.props.intl.formatMessage({
                            id: 'navigation.storage'
                        });
                        elem.purchasePrice = elem.stockPrice;
                        elem.salePrice =
                            elem.sellingPrice || elem.stockPrice * (elem.priceGroup ? elem.priceGroup.multiplier : 1.4);
                        elem.partNumber = elem.code;
                        elem.description = elem.name;
                        elem.storeGroupId = elem.groupId;
                        elem.storeGroupName = elem.name;
                        elem.price = {
                            isFromStock: true,
                            defaultWarehouseId: elem.defaultWarehouseId
                        };
                        elem.store = [elem.available, 0, 0, 0];

                        if (
                            !that.state.visible &&
                            that.state.brandFilter.length == 0 &&
                            that.props.brandFilter == elem.supplierName
                        ) {
                            that.state.brandFilter.push(elem.supplierId);
                        }

                        if (brandOptions.findIndex(brand => brand.id == elem.supplierId) == -1 && elem.supplierId) {
                            brandOptions.push({
                                id: elem.supplierId,
                                name: elem.supplierName
                            });
                        }
                    });

                    that.setState({
                        fetched: true,
                        dataSource: data.list,
                        brandOptions,
                        // codeFilter: that.props.codeFilter,
                        total: data.stats.count
                    });
                })
                .catch(function (error) {
                    console.log('error', error);
                    that.setState({
                        fetched: true,
                        codeFilter: that.props.codeFilter
                    });
                });

            return;
        }
        if (this.props.codeSearch) {
            var that = this;
            const token = localStorage.getItem('_my.carbook.pro_token');
            let url = API_URL;
            let params = `/tecdoc/replacements?query=${String(this.props.codeFilter).replace(
                /[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g,
                ''
            )}`;
            // if(this.props.storeGroupId) params += `&storeGroupId=${this.props.storeGroupId}`
            if (this.props.brandId) {
                params += `&brandIds=[${this.props.brandId}]`;
            }
            url += params;
            fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        return Promise.reject(new Error(response.statusText));
                    }

                    return Promise.resolve(response);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    let brandOptions = [];
                    const storeOptions = [];
                    const defaultBrand = [];
                    const brandsWithSupplier = [];
                    const otherBrands = [];
                    data.map((elem, i) => {
                        elem.key = i;
                        if (elem.price) {
                            elem.productId = elem.price.id;
                            elem.store = elem.price.store;
                            elem.purchasePrice = elem.price.purchasePrice;
                            elem.businessSupplierId = elem.price.businessSupplierId;
                            elem.businessSupplierName = elem.price.businessSupplierName;
                            elem.salePrice =
                                elem.sellingPrice ||
                                elem.price.purchasePrice * (elem.price.markup ? elem.price.markup : 1.4);
                        }
                        if (brandOptions.findIndex(brand => brand.id == elem.supplierId) == -1) {
                            if (that.state.brandFilter.length == 0 && that.props.brandFilter == elem.supplierName) {
                                that.state.brandFilter.push(elem.supplierId);
                            }
                            brandOptions.push({
                                id: elem.supplierId,
                                name: elem.supplierName
                            });
                            if (elem.supplierName == that.props.defaultBrandName) {
                                defaultBrand.push({
                                    id: elem.supplierId,
                                    name: elem.supplierName
                                });
                            } else if (elem.price) {
                                brandsWithSupplier.push({
                                    id: elem.supplierId,
                                    name: elem.supplierName
                                });
                            } else {
                                otherBrands.push({
                                    id: elem.supplierId,
                                    name: elem.supplierName
                                });
                            }
                        }
                        if (storeOptions.findIndex(store => store.id == elem.storeGroupId) == -1) {
                            storeOptions.push({
                                id: elem.storeGroupId,
                                name: elem.storeGroupName
                            });
                        }
                    });
                    brandsWithSupplier.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
                    otherBrands.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
                    brandOptions = [...defaultBrand, ...brandsWithSupplier, ...otherBrands];
                    that.setState({
                        fetched: true,
                        dataSource: data,
                        brandOptions,
                        storeOptions
                    });
                })
                .catch(function (error) {
                    console.log('error', error);
                    that.setState({
                        fetched: true
                    });
                });

            return;
        }
        var that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __TECDOC_URL__;
        let params = `/tecdoc/products/parts?modificationId=${this.props.tecdocId}&storeGroupId=${this.props.storeGroupId}`;
        if (this.props.supplierId) {
            params += `&businessSupplierId=${this.props.supplierId}`;
        }
        url += params;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                let brandOptions = [];
                const defaultBrand = [];
                const brandsWithSupplier = [];
                const otherBrands = [];
                data.map((elem, i) => {
                    elem.key = i;
                    if (elem.price) {
                        elem.productId = elem.price.id;
                        elem.store = elem.price.store;
                        elem.purchasePrice = elem.price.purchasePrice;
                        elem.businessSupplierId = elem.price.businessSupplierId;
                        elem.businessSupplierName = elem.price.businessSupplierName;
                        elem.salePrice =
                            elem.sellingPrice ||
                            elem.price.purchasePrice * (elem.price.markup ? elem.price.markup : 1.4);
                    }
                    if (brandOptions.findIndex(brand => brand.id == elem.supplierId) == -1) {
                        if (that.state.brandFilter.length == 0 && that.props.brandFilter == elem.supplierName) {
                            that.state.brandFilter.push(elem.supplierId);
                        }
                        brandOptions.push({
                            id: elem.supplierId,
                            name: elem.supplierName
                        });
                        if (elem.supplierName == that.props.defaultBrandName) {
                            defaultBrand.push({
                                id: elem.supplierId,
                                name: elem.supplierName
                            });
                        } else if (elem.price) {
                            brandsWithSupplier.push({
                                id: elem.supplierId,
                                name: elem.supplierName
                            });
                        } else {
                            otherBrands.push({
                                id: elem.supplierId,
                                name: elem.supplierName
                            });
                        }
                    }
                });
                brandsWithSupplier.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
                otherBrands.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
                brandOptions = [...defaultBrand, ...brandsWithSupplier, ...otherBrands];
                that.setState({
                    fetched: true,
                    dataSource: data,
                    brandOptions,
                    codeFilter: that.props.codeFilter
                });
            })
            .catch(function (error) {
                console.log('error', error);
                that.setState({
                    fetched: true
                });
            });

        params = `/tecdoc/filtering_attributes?modificationId=${this.props.tecdocId}&storeGroupId=${this.props.storeGroupId}`;
        url = API_URL + params;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.setState({
                    attributesFilters: {
                        index1: data[0],
                        index2: data[1],
                        index3: data[2],
                        index4: data[3]
                    }
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    render() {
        const { stockMode } = this.props;
        const { dataSource, storeFilter, brandFilter, codeFilter, inStock, reserveModalVisible, page, total } =
            this.state;
        const disabled =
            this.props.disabled ||
            (this.props.stockMode && isForbidden(this.props.user, permissions.ACCESS_STOCK)) ||
            isForbidden(this.props.user, permissions.ACCESS_TECDOC_MODAL_WINDOW);
        let tblData = [...dataSource];
        if (!stockMode) {
            if (storeFilter) {
                tblData = tblData.filter(
                    elem => String(elem.storeGroupId).toLowerCase().indexOf(String(storeFilter).toLowerCase()) >= 0
                );
            }
            if (codeFilter) {
                tblData = tblData.filter(elem => {
                    const partNumber = elem.partNumber
                        .replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g, '')
                        .toLowerCase();
                    const description = String(elem.description)
                        .replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g, '')
                        .toLowerCase();
                    const barcode = String(elem.barcode)
                        .replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g, '')
                        .toLowerCase();
                    const filter = codeFilter
                        .replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g, '')
                        .toLowerCase();

                    return partNumber.includes(filter) || description.includes(filter) || barcode.includes(filter);
                });
            }
            if (inStock) {
                if (this.props.stockMode) {
                    tblData = tblData.filter(elem => elem.store[0]);
                } else {
                    tblData = tblData.filter(elem => elem.store);
                }
            }
            tblData = this.filterDataSourceByAttribute(tblData, 1);
            tblData = this.filterDataSourceByAttribute(tblData, 2);
            tblData = this.filterDataSourceByAttribute(tblData, 3);
            tblData = this.filterDataSourceByAttribute(tblData, 4);
        }
        if (brandFilter.length) {
            tblData = tblData.filter(elem => brandFilter.indexOf(elem.supplierId) != -1);
        }

        return (
            <div style={{ display: 'flex' }}>
                {!this.props.hideButton && (
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='details_table.details_catalogue' />}
                        zIndex={2001}
                    >
                        <Button
                            data-qa='button_visible_catalogue_detail_storage_modal'
                            disabled={disabled}
                            onClick={() => {
                                this.fetchData();
                                this.setState({
                                    visible: true
                                });
                            }}
                        >
                            <div
                                style={{
                                    width: 18,
                                    height: 18,
                                    backgroundColor: disabled ? 'var(--text2)' : 'var(--text)',
                                    mask: `url(${
                                        this.props.stockMode ? images.stockIcon : images.bookIcon
                                    }) no-repeat center / contain`,
                                    WebkitMask: `url(${
                                        this.props.stockMode ? images.stockIcon : images.bookIcon
                                    }) no-repeat center / contain`
                                }}
                            />
                        </Button>
                    </Tooltip>
                )}
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='order_form_table.catalog' />}
                    visible={this.state.visible}
                    width='90%'
                >
                    {this.state.fetched ? (
                        <Table
                            bordered
                            columns={this.columns}
                            dataSource={tblData}
                            pagination={
                                !stockMode
                                    ? {
                                          pageSize: 6,
                                          hideOnSinglePage: true
                                      }
                                    : {
                                          pageSize: 6,
                                          total: Math.ceil(total / 6) * 6,
                                          hideOnSinglePage: true,
                                          current: page,
                                          onChange: async page => {
                                              await this.setState({ page });
                                              this.fetchData();
                                          }
                                      }
                            }
                            rowClassName={Styles.tableRow}
                            size='small'
                        />
                    ) : (
                        <Spin indicator={spinIcon} />
                    )}
                </Modal>
                {this.props.stockMode && (
                    <StoreProductTrackingModal
                        hideModal={() => {
                            this.setState({
                                reserveModalVisible: undefined
                            });
                        }}
                        productId={reserveModalVisible}
                        visible={Boolean(reserveModalVisible)}
                    />
                )}
            </div>
        );
    }
}
export default DetailStorageModal;

export class PhotoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        return (
            <div>
                <div
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    style={{ verticalAlign: 'middle' }}
                >
                    <img
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                        }}
                        src={this.props.src}
                        style={{ cursor: 'pointer' }}
                        width={80}
                    />
                </div>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='photo' />}
                    visible={this.state.visible}
                    width='45%'
                >
                    <div style={{ textAlign: 'center' }}>
                        <img
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                            }}
                            src={this.props.src}
                            style={{ cursor: 'pointer' }}
                            width='70%'
                        />
                    </div>
                    <div>
                        {this.props.attributes.map((attribute, i) => (
                            <div key={i} style={{ border: '1px solid', padding: '5pxs' }}>
                                {attribute.description}: {attribute.value}
                            </div>
                        ))}
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
export class DetailWarehousesCountModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            warehousesData: [],
            markup: 1.4
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false
        });
        if (this.props.hideModal) {
            this.props.hideModal();
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetchData();
            this.setState({
                visible: true
            });
        }
    }

    fetchData = async () => {
        let warehousesData = [];

        const product = await fetchAPI('GET', `store_products/${this.props.productId}`, null, null, {
            handleErrorInternally: true
        });

        const warehouses = await fetchAPI('GET', 'warehouses');
        warehouses.map(({ id, name, attribute }) => {
            warehousesData.push({
                id,
                name,
                count: 0,
                attribute,
                childs: [
                    {
                        // cellAddress: `${id}.`,
                        count: 0
                    }
                ]
            });
        });

        if (!this.props.user.disableFifoCalcForWarehouses && this.props.count) {
            const productWarehouses = await fetchAPI(
                'GET',
                `store_products/${this.props.productId}/warehouses`,
                { withFIFO: true, productCount: this.props.count, withFIFOAsList: true },
                null,
                { handleErrorInternally: true }
            );

            for (const [key, value] of Object.entries(productWarehouses)) {
                const index = warehousesData.findIndex(({ id }) => id == key);
                warehousesData[index].count = Number(value.availableQuantity);
                warehousesData[index].purchasePrice = Number(_.get(value, 'price.value', 0));
                warehousesData[index].allPrices = _.get(value, 'price.values', []);
                warehousesData[index].childs[0].count = Number(value.availableQuantity);
                warehousesData[index].childs[0].purchasePrice = Number(_.get(value, 'price.value', 0));
            }
        } else {
            const productWarehouses = await fetchAPI(
                'GET',
                `store_products/${this.props.productId}/warehouses`,
                null,
                null,
                { handleErrorInternally: true }
            );

            for (const [key, value] of Object.entries(productWarehouses)) {
                const index = warehousesData.findIndex(({ id }) => id == key);
                warehousesData[index].count = Number(value);
                warehousesData[index].childs[0].count = Number(value);
            }
        }

        const payload = await fetchAPI('GET', 'wms/cells/statuses', {
            storeProductId: this.props.productId
        });

        payload.list.map(elem => {
            if (elem.warehouse.id) {
                const index = warehousesData.findIndex(({ id }) => id == elem.warehouse.id);
                warehousesData[index].count += Number(elem.sum);
                warehousesData[index].childs.push({
                    cellAddress: elem.wmsCellOptions.address,
                    count: Number(elem.sum)
                });
            }
        });
        warehousesData = warehousesData.filter(({ count }) => count > 0);

        this.setState({
            warehousesData,
            code: _.get(product, 'code'),
            brandName: _.get(product, 'brand.name'),
            name: _.get(product, 'name'),
            markup: _.get(product, 'priceGroup.multiplier', 1.4)
        });
    };

    render() {
        const { code, name, brandName, warehousesData, visible, markup } = this.state;
        const { productUnit } = this.props;

        return (
            <div>
                {!this.props.hideButton && (
                    <Button
                        data-qa='btn_visible_details_warehouses_storage_modal'
                        onClick={() => {
                            this.fetchData();
                            this.setState({
                                visible: true
                            });
                        }}
                    >
                        <div
                            style={{
                                width: 18,
                                height: 18,
                                backgroundColor: 'white',
                                mask: `url(${images.stockIcon}) no-repeat center / contain`,
                                WebkitMask: `url(${images.stockIcon}) no-repeat center / contain`
                            }}
                        />
                    </Button>
                )}
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    open={visible}
                    style={{
                        maxWidth: 680,
                        fontSize: 16
                    }}
                    title={<FormattedMessage id='storage.in_stock' />}
                >
                    <div
                        style={{
                            padding: '0px 4px 14px',
                            fontSize: 16,
                            fontWeight: 500
                        }}
                    >
                        <p>{brandName}</p>
                        <p>{code}</p>
                    </div>
                    <div style={{ display: 'flex', fontWeight: 500, padding: 8 }}>
                        <div style={{ width: '20%' }}>
                            <FormattedMessage id='storage' />
                        </div>
                        <div style={{ width: '20%' }}>
                            <FormattedMessage id='wms.cell' />
                        </div>
                        <div style={{ width: '20%' }}>
                            <FormattedMessage id='count' />
                        </div>
                        <div style={{ width: '20%' }}>
                            <FormattedMessage id='price' />
                        </div>
                    </div>
                    {warehousesData.map((warehouse, key) => {
                        console.log(warehouse, 'warehouse');

                        const allPrices =
                            warehouse &&
                            (warehouse.allPrices || []).slice(0, 10).map(({ purchasePrice, quantity }) => {
                                return (
                                    <div
                                        style={{
                                            borderBottom: '1px solid lightgray'
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginBottom: 6
                                            }}
                                        >
                                            {purchasePrice.toFixed(2)} {getCurrency()};{' '}
                                            {(Math.round(Number(quantity) * 100) / 100).toFixed(2)} {productUnit}
                                        </div>
                                    </div>
                                );
                            });

                        return (
                            <React.Fragment>
                                <div
                                    key={warehouse.id}
                                    style={{
                                        display: 'flex',
                                        borderTop: '1px solid gray',
                                        padding: '2px 8px',
                                        fontWeight: 500,
                                        alignItems: 'center'
                                    }}
                                >
                                    <div style={{ width: '20%' }}>{warehouse.name}</div>
                                    <div style={{ width: '20%' }} />
                                    <div style={{ width: '20%' }}>
                                        {(Math.round(Number(warehouse.count) * 100) / 100).toFixed(2)}
                                    </div>
                                    <div style={{ width: '20%' }}></div>
                                    <Button
                                        data-qa='btn_modal_close_warehouses_details_storage_modal'
                                        onClick={() => {
                                            this.handleCancel();
                                            if (this.props.onSelect) {
                                                this.props.onSelect(undefined, warehouse.id, warehouse.name);
                                            }
                                        }}
                                        style={{
                                            opacity: 0,
                                            pointerEvents: 'none'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='select' />
                                    </Button>
                                </div>
                                {warehouse.childs.map(elem => (
                                    <div
                                        key={elem.cellAddress}
                                        style={{
                                            display: 'flex',
                                            padding: '2px 8px',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ width: '20%' }}>{warehouse.name}</div>
                                        <div style={{ width: '20%' }}>{elem.cellAddress}</div>
                                        <div style={{ width: '20%' }}>
                                            {(Math.round(Number(elem.count) * 100) / 100).toFixed(2)}
                                        </div>
                                        <Tooltip
                                            title={
                                                get(allPrices, 'length') ? (
                                                    <div>
                                                        {allPrices}
                                                        <div>
                                                            {get(warehouse.allPrices, 'length') > 10
                                                                ? '...'
                                                                : undefined}
                                                        </div>
                                                    </div>
                                                ) : undefined
                                            }
                                        >
                                            <div style={{ width: '20%' }}>
                                                {warehouse.purchasePrice ? warehouse.purchasePrice.toFixed(2) : null}
                                            </div>
                                        </Tooltip>
                                        <Button
                                            data-qa='btn_select_warehouse_storage_modal'
                                            disabled={_.get(warehouse, 'attribute') == 'RESERVE'}
                                            onClick={() => {
                                                this.handleCancel();
                                                if (this.props.onSelect) {
                                                    this.props.onSelect(
                                                        elem.cellAddress,
                                                        warehouse.id,
                                                        warehouse.name,
                                                        warehouse.purchasePrice,
                                                        warehouse.purchasePrice * markup
                                                    );
                                                }
                                            }}
                                            type='primary'
                                        >
                                            <FormattedMessage id='select' />
                                        </Button>
                                    </div>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </Modal>
            </div>
        );
    }
}
