exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__headerActions---2KdHm{text-align:center;display:flex;align-items:center;justify-content:space-evenly}.styles-m__icon---KuGF1{font-size:20px;color:var(--text3);cursor:pointer;padding:1px}.styles-m__icon---KuGF1:hover{color:var(--primary)!important}.styles-m__disabledIcon---2auhA{font-size:18px;pointer-events:none;color:rgba(0,0,0,.25)!important}.styles-m__rowActions---3Ji3V{text-align:center;display:flex;align-items:center;justify-content:space-evenly}.styles-m__actionMenuIcon---2y9Po{padding:0 4px 0 0;font-size:18px;vertical-align:sub;display:inline-block}.styles-m__detailsTableRow---3c3Wu td{vertical-align:middle}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,iCACE,iBAAkB,CAClB,YAAa,CACb,kBAAmB,CACnB,4BACF,CAEA,wBACE,cAAe,CACf,kBAAmB,CACnB,cAAe,CACf,WAKF,CAHE,8BACE,8BACF,CAGF,gCACE,cAAe,CACf,mBAAoB,CACpB,+BACF,CAEA,8BACE,iBAAkB,CAClB,YAAa,CACb,kBAAmB,CACnB,4BACF,CAEA,kCACE,iBAAkB,CAClB,cAAe,CACf,kBAAmB,CACnB,oBACF,CAGE,sCACE,qBACF","file":"styles.m.css","sourcesContent":[".headerActions {\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n}\n\n.icon {\n  font-size: 20px;\n  color: var(--text3);\n  cursor: pointer;\n  padding: 1px;\n\n  &:hover {\n    color: var(--primary) !important;\n  }\n}\n\n.disabledIcon {\n  font-size: 18px;\n  pointer-events: none;\n  color: rgba(0, 0, 0, 0.25) !important;\n}\n\n.rowActions {\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n}\n\n.actionMenuIcon {\n  padding: 0 4px 0 0;\n  font-size: 18px;\n  vertical-align: sub;\n  display: inline-block;\n}\n\n.detailsTableRow {\n  & td {\n    vertical-align: middle;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"headerActions": "styles-m__headerActions---2KdHm",
	"icon": "styles-m__icon---KuGF1",
	"disabledIcon": "styles-m__disabledIcon---2auhA",
	"rowActions": "styles-m__rowActions---3Ji3V",
	"actionMenuIcon": "styles-m__actionMenuIcon---2y9Po",
	"detailsTableRow": "styles-m__detailsTableRow---3c3Wu"
};