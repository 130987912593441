import {
    DeleteOutlined,
    DollarOutlined,
    EditOutlined,
    MenuOutlined,
    PercentageOutlined,
    PlusOutlined,
    PrinterOutlined,
    SaveOutlined,
    SwapOutlined
} from '@ant-design/icons';
import {
    Button,
    DatePicker,
    Dropdown,
    Input,
    Menu,
    Modal,
    Select,
    Space,
    Table,
    Tabs,
    Tooltip,
    notification
} from 'antd';
import { Layout, Numeral } from 'commons';
import { DateRangePicker, HamburgerMenu } from 'components';
import { AddSalaryDocModalCalculated } from 'containers/SalariesDocumentContainer/components/AddSalaryDocModalCalculated';
import { fetchCashboxes } from 'core/cash/duck';
import { MODALS, loadModal, saveModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { PencilIcon } from 'theme';
import { fetchAPI, getCurrency, goTo, isForbidden, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { SalaryAddRowModal, SalaryEditRowModal } from './components';

import Styles from './styles.m.css';

const { TabPane } = Tabs;

const { TextArea } = Input;

const { Option } = Select;

const requiredField = () => <b style={{ color: 'var(--required)' }}> *</b>;

const defWidth = {
    laborParts: '20%',
    type: '7%',
    btns: '5%',
    menu: '5%',
    date: '8%',
    condition: '10%',
    order: '15%',
    basis: '8%',
    caller: '10%',
    calculation: '10%',
    value: '7%',
    discount: '5%',
    sumPercent: '7%',
    sal: '8%'
};

const mapStateToProps = state => ({
    user: state.auth,
    cashboxes: state.cash.cashboxes,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    saveModal,
    loadModal,
    fetchCashboxes
};

const statuses = [
    {
        title: <FormattedMessage id='employee_salary_rules.status.NEW' />,
        key: 'NEW',
        status: 'NEW'
    },
    {
        title: <FormattedMessage id='employee_salary_rules.status.DONE' />,
        key: 'DONE',
        status: 'DONE'
    },
    {
        title: <FormattedMessage id='employee_salary_rules.status.CANCEL' />,
        key: 'CANCEL',
        status: 'CANCEL'
    }
];

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SalaryDocumentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allEmployees: [],
            data: {},
            dataRows: [],
            selectedRows: [],
            selectedRowKeys: undefined,
            requisites: [],
            onlyForCurrentEmployee: true
        };

        this.columns = () => [
            {
                title: () => {
                    const {
                        intl: { formatMessage },
                        match: {
                            params: { id }
                        }
                    } = this.props;

                    const { selectedRows, data } = this.state;
                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item
                                key='groupSalaryTable.deleted'
                                disabled={!selectedRows.length || _.get(data, 'status') !== 'NEW'}
                            >
                                <div
                                    onClick={() => {
                                        if (_.get(data, 'status') === 'NEW') {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'add_order_form.delete_confirm'
                                                }),
                                                onOk: async () => {
                                                    await fetchAPI(
                                                        'DELETE',
                                                        'delete/salary/rows',
                                                        null,
                                                        {
                                                            id: Number(id),
                                                            rowIds: selectedRows.map(({ id }) => id)
                                                        },
                                                        {
                                                            handleErrorInternally: true
                                                        }
                                                    );
                                                    notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.deleted'
                                                        })
                                                    });
                                                    this.fetchSalaryDocData();
                                                },
                                                zIndex: 2001
                                            });
                                        }
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} />;
                },
                key: 'actions',
                align: 'center',
                width: defWidth.menu,
                render: row => {
                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item
                                key='groupSalaryTable.delete'
                                disabled={_.get(this.state.data, 'status') !== 'NEW'}
                            >
                                <div
                                    onClick={() => {
                                        if (_.get(this.state.data, 'status') === 'NEW') {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'add_order_form.delete_confirm'
                                                }),
                                                onOk: async () => {
                                                    await fetchAPI(
                                                        'DELETE',
                                                        'delete/salary/rows',
                                                        null,
                                                        {
                                                            id: Number(this.props.match.params.id),
                                                            rowIds: [row.id]
                                                        },
                                                        {
                                                            handleErrorInternally: true
                                                        }
                                                    );
                                                    notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.deleted'
                                                        })
                                                    });
                                                    this.fetchSalaryDocData();
                                                },
                                                zIndex: 2001
                                            });
                                        }
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    <FormattedMessage id='delete' />
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu}>
                            <Button
                                data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },
            {
                title: () => (
                    <div className={Styles.headerActions}>
                        <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                            <Button
                                disabled={
                                    // _.get(this.state.data, 'status') !== 'NEW'
                                    this.state.data
                                        ? this.state.data.status !== 'NEW'
                                        : null &&
                                          !isGrantAccessed(
                                              this.props.user,
                                              grants.ACCOUNTING_SALARIES_SALARY_LINES_MANUAL_ADDITION_CORRECTION_LINE
                                          )
                                }
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    this.setState({
                                        rowModalVisible: true
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                ),
                key: 'buttonGroup',
                align: 'center',
                width: defWidth.btns,
                render: (row, _, key) => {
                    return (
                        <div className={Styles.rowActions}>
                            <Tooltip placement='top' title={<FormattedMessage id='labors_table.add_edit_button' />}>
                                <Button
                                    data-qa='btn_add_edit_button_service_product_modal_services_table_order_page'
                                    disabled={this.state.data ? this.state.data.status !== 'NEW' : null}
                                    icon={<PencilIcon />}
                                    onClick={() => {
                                        this.editProduct(row);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='employee_salary_rules.condition' />,
                width: defWidth.condition,
                dataIndex: 'ruleName',
                key: 'ruleName',
                render: ruleName => (
                    <div className={Styles.datetime}>{ruleName || <FormattedMessage id='long_dash' />}</div>
                )
            },
            {
                title: <FormattedMessage id='locations.type' />,
                width: defWidth.type,
                dataIndex: 'type',
                key: 'type',
                render: type => (
                    <div className={Styles.datetime}>
                        {<FormattedMessage id={`employee_salary_rules.${type}`} /> || (
                            <FormattedMessage id='long_dash' />
                        )}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.rule_one' />,
                width: defWidth.basis,
                dataIndex: 'percentFromConstant',
                key: 'percentFromConstant',
                render: (percentFromConstant, row) => (
                    <div className={Styles.datetime}>
                        <div>
                            {percentFromConstant ? (
                                <FormattedMessage id={`employee_salary_rules.${percentFromConstant}`} />
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </div>
                        <div>
                            {row.mark ? (
                                <FormattedMessage id={`employee_salary_rules.${row.mark}`} />
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </div>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.order_n_car' />,
                width: defWidth.order,
                key: 'orderNCar',
                render: row => (
                    <div>
                        <Link to={`${book.order}/${row.orderId}`}>{row.order || ''}</Link> {row.vehicle || ''}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.labors_parts_name' />,
                width: defWidth.laborParts,
                key: 'laborsParts',
                render: row => {
                    const allLabors = row.labors
                        ? row.labors.map(elem => <div className={Styles.datetime}>{`${elem.name};`}</div>)
                        : undefined;

                    return allLabors || row.comment;
                }
            },

            {
                title: <FormattedMessage id='date' />,
                dataIndex: 'date',
                width: defWidth.date,
                align: 'center',
                key: 'date',
                render: date => (
                    <div className={Styles.datetime}>
                        {date ? dayjs(date).format('YYYY-MM-DD') : <FormattedMessage id='long_dash' />}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='info_modal.value' />,
                width: defWidth.value,
                dataIndex: 'orderSum',
                key: 'orderSum',
                render: orderSum => (
                    <div className={Styles.datetime}>
                        {orderSum ? Number(orderSum).toFixed(2) : <FormattedMessage id='long_dash' />}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.sum_%' />,
                width: defWidth.sumPercent,
                key: 'percentOrSum',
                render: row =>
                    row.mark == 'COUNT' && row.percent ? (
                        <div className={Styles.datetime}>{Number(row.percent).toFixed(2)}</div>
                    ) : row.percent && row.mark !== 'COUNT' ? (
                        <div className={Styles.datetime}>{`${row.percent} %`}</div>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    )
            },
            {
                title: <FormattedMessage id='order_form_table.discount' />,
                width: defWidth.discount,
                dataIndex: 'discount',
                key: 'discount',
                render: discount => (
                    <div className={Styles.datetime}>
                        {discount ? <FormattedMessage id='yes' /> : <FormattedMessage id='no' />}
                    </div>
                )
            },

            {
                title: <FormattedMessage id='employee_salary_rules.sal' />,
                width: defWidth.sal,
                dataIndex: 'salary',
                key: 'salary',
                render: (salary, row) => (
                    <div
                        style={{
                            color: row.creationType == 'AUTO' ? 'black' : 'red'
                        }}
                    >
                        {Number(salary).toFixed(2)}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.creation_type' />,
                width: defWidth.type,
                dataIndex: 'creationType',
                render: creationType => (
                    <div className={Styles.datetime}>{creationType == 'AUTO' ? 'Auto' : 'Manual'}</div>
                )
            }
        ];

        const { user } = props;
    }

    componentDidMount() {
        this.fetchSalaryDocData();
        this.props.fetchCashboxes();
        this.getAllEmployees();
        this.getRequisites();
    }

    componentDidUpdate(prevProps, prevState) {
        const { data, employeeId } = this.state;
        if (prevState.data !== this.state.data) {
            this.setState({
                employeeId: _.get(data, 'employeeId'),
                startDate: dayjs(_.get(data, 'startDate')),
                endDate: dayjs(_.get(data, 'endDate')),
                creationDate: dayjs(_.get(data, 'creationDate')),
                accountingDate:
                    _.get(data, 'accountingDate') !== null
                        ? dayjs(_.get(data, 'accountingDate'))
                        : dayjs(_.get(data, 'creationDate')),

                doneDate: _.get(data, 'doneDate') && dayjs(_.get(data, 'doneDate')),
                sum: _.get(data, 'sum'),
                comment: _.get(data, 'comment')
            });
        }
    }

    getRequisites = async () => {
        const data = await fetchAPI('GET', 'businesses/requisites', null, null, {
            handleErrorInternally: true
        });
        this.setState({ requisites: data });
    };

    getAllEmployees = async () => {
        const employees = await fetchAPI('GET', 'employees', null, null, { handleErrorInternally: true });
        this.setState({
            allEmployees: employees
        });
    };

    fetchSalaryDocData = async () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const { page, pageSize, query } = this.state;

        const data = await fetchAPI('GET', `/salary_doc/${id}`, undefined, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            data,
            dataRows: data.documentRows,
            ...(data.requisiteId ? { selectedRequisiteId: data.requisiteId } : {})
        });
    };

    calcSalary = async (id, sDate, eDate) => {
        await fetchAPI(
            'POST',
            '/recalculate/employee/salary',
            undefined,
            {
                employeeIds: id,
                startDate: sDate,
                endDate: eDate,
                status: 'ACCRUED'
            },
            { handleErrorInternally: true }
        );
    };

    calcSalaryByAllEmps = async (id, sDate, eDate) => {
        const { data } = this.state;
        await fetchAPI(
            'POST',
            '/recalculate/employee/salary',
            undefined,
            {
                employeeIds: _.get(data, 'employeeId'),
                startDate: sDate,
                endDate: eDate,
                status: 'ACCRUED'
            },
            { handleErrorInternally: true }
        );
    };

    printSalaryReport = async () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const response = await fetchAPI(
            'GET',
            '/employee/salaryDoc/report',
            {
                id: Number(id)
            },
            null,
            { rawResponse: true, handleErrorInternally: true }
        );

        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
    };

    editProduct(row) {
        this.setState({
            rowEditVisible: true,
            editRow: row
        });
    }

    _redirectToStorageFundsFlow = () => {
        if (!isForbidden(this.props.user, permissions.ACCESS_ACCOUNTING)) {
            goTo(book.storageFunds);
        }
    };

    render() {
        const {
            match: {
                params: { id }
            },
            user,
            setModal,
            cashboxes,
            isMobile
        } = this.props;

        const {
            allEmployees,
            data,
            visibleCalcSalaryModal,
            dataRows,
            employeeId,
            startDate,
            endDate,
            sum,
            creationDate,
            doneDate,
            otherTaxes,
            comment,
            selectedRowKeys,
            selectedRows,
            rowModalVisible,
            accountingDate,
            rowEditVisible,
            editRow,
            selectedRequisiteId,
            requisites
        } = this.state;

        const dateFormat = 'DD-MM-YYYY';

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        const remainingRateSum = _.get(data, 'sum', 0) + _.get(data, 'amountLeft', 0) + _.get(data, 'rateSum', 0);

        const remaining = _.get(data, 'sum', 0) + _.get(data, 'amountLeft', 0);

        const filteredCashboxes = cashboxes ? cashboxes.filter(({ active }) => active) : [];

        console.log(cashboxes, 'cashboxes');

        const menu = (
            <Menu>
                {_.get(data, 'status') !== 'NEW' &&
                    isGrantAccessed(user, grants.ACCOUNTING_SALARIES_SALARY_HEADER_SALARY_UNPOSTING) && (
                        <Menu.Item
                            key='NEW'
                            onClick={async () => {
                                await fetchAPI(
                                    'PUT',
                                    `/salary_doc/${id}`,
                                    null,
                                    {
                                        requisiteId: selectedRequisiteId,
                                        employeeId: data.employeeId,
                                        startDate: data.startDate,
                                        endDate: data.endDate,
                                        accountingDate: data.accountingDate || data.endDate,
                                        doneDate: data.doneDate || data.creationDate,
                                        status: 'NEW',
                                        type: data.type,
                                        sum: data.sum,
                                        rateSum: data.rateSum,
                                        incomeTax: data.incomeTax || 0,
                                        insuranceTax: data.insuranceTax || 0,
                                        otherTaxes: data.otherTaxes || 0,
                                        comment: data.comment || ' '
                                    },
                                    { handleErrorInternally: true }
                                );
                                notification.success({
                                    message: this.props.intl.formatMessage({ id: 'barcode.success' })
                                });
                                this.fetchSalaryDocData();
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.status.NEW' />
                        </Menu.Item>
                    )}
                {_.get(data, 'status') !== 'DONE' && (
                    <Menu.Item
                        key='DONE'
                        onClick={async () => {
                            await fetchAPI(
                                'PUT',
                                `/salary_doc/${id}`,
                                null,
                                {
                                    requisiteId: selectedRequisiteId,
                                    employeeId: data.employeeId,
                                    startDate: data.startDate,
                                    endDate: data.endDate,
                                    accountingDate: data.accountingDate || data.endDate,
                                    doneDate: data.doneDate || data.creationDate,
                                    status: 'DONE',
                                    type: data.type,
                                    sum: data.sum,
                                    rateSum: data.rateSum,
                                    incomeTax: data.incomeTax || 0,
                                    insuranceTax: data.insuranceTax || 0,
                                    otherTaxes: data.otherTaxes || 0,
                                    comment: data.comment || ' '
                                },
                                { handleErrorInternally: true }
                            );
                            notification.success({
                                message: this.props.intl.formatMessage({ id: 'barcode.success' })
                            });
                            this.fetchSalaryDocData();
                        }}
                    >
                        <FormattedMessage id='employee_salary_rules.status.DONE' />
                    </Menu.Item>
                )}
                {_.get(data, 'status') !== 'CANCEL' && _.get(data, 'status') !== 'DONE' && (
                    <Menu.Item
                        key='CANCEL'
                        onClick={async () => {
                            await fetchAPI(
                                'PUT',
                                `/salary_doc/${id}`,
                                null,
                                {
                                    requisiteId: selectedRequisiteId,
                                    employeeId: data.employeeId,
                                    startDate: data.startDate,
                                    endDate: data.endDate,
                                    accountingDate: data.accountingDate || data.endDate,
                                    doneDate: data.doneDate || data.creationDate,
                                    status: 'CANCEL',
                                    type: data.type,
                                    sum: data.sum,
                                    rateSum: data.rateSum,
                                    incomeTax: data.incomeTax || 0,
                                    insuranceTax: data.insuranceTax || 0,
                                    otherTaxes: data.otherTaxes || 0,
                                    comment: data.comment || ' '
                                },
                                { handleErrorInternally: true }
                            );
                            notification.success({
                                message: this.props.intl.formatMessage({ id: 'barcode.success' })
                            });
                            this.fetchSalaryDocData();
                        }}
                    >
                        <FormattedMessage id='employee_salary_rules.status.CANCEL' />
                    </Menu.Item>
                )}
            </Menu>
        );

        const isHeaderAccessible = isGrantAccessed(user, grants.ACCOUNTING_SALARIES_SALARY_HEADER);

        return (
            <Layout
                controls={
                    <Space size='small'>
                        <div className={isMobile ? Styles.headerActionBtnsMobile : Styles.headerActionBtns}>
                            <Dropdown data-qa='dropdown_current_salries_table' overlay={menu}>
                                <div>
                                    <Button
                                        disabled={
                                            _.get(data, 'status') == 'CANCEL' ||
                                            (_.get(data, 'status') == 'DONE' &&
                                                !isGrantAccessed(
                                                    user,
                                                    grants.ACCOUNTING_SALARIES_SALARY_HEADER_SALARY_UNPOSTING
                                                ))
                                        }
                                        icon={
                                            <SwapOutlined
                                                style={{
                                                    fontSize: 24
                                                }}
                                            />
                                        }
                                        type='text'
                                    >
                                        {!isMobile && (
                                            <span style={{ verticalAlign: 'text-bottom' }}>
                                                <FormattedMessage id='change_status_dropdown.change_status' />
                                            </span>
                                        )}
                                    </Button>
                                </div>
                            </Dropdown>
                        </div>
                        <div className={isMobile ? Styles.headerActionBtnsMobile : Styles.headerActionBtns}>
                            <Button
                                disabled={_.get(data, 'status') == 'CANCEL' || _.get(data, 'status') == 'DONE'}
                                icon={
                                    <SaveOutlined
                                        style={{
                                            fontSize: 24
                                        }}
                                    />
                                }
                                onClick={async () => {
                                    await fetchAPI(
                                        'PUT',
                                        `/salary_doc/${id}`,
                                        null,
                                        {
                                            requisiteId: selectedRequisiteId,
                                            employeeId,
                                            startDate,
                                            endDate,
                                            accountingDate,
                                            creationDate,
                                            doneDate,
                                            status: _.get(data, 'status'),
                                            type: _.get(data, 'type'),
                                            sum: _.get(data, 'sum'),
                                            rateSum: _.get(data, 'rateSum'),
                                            incomeTax: _.get(data, 'incomeTax') || 0,
                                            insuranceTax: _.get(data, 'insuranceTax') || 0,
                                            otherTaxes: _.get(data, 'otherTaxes') || 0,
                                            comment: comment || ' '
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    this.fetchSalaryDocData();
                                }}
                                type='text'
                            />
                        </div>
                        {isGrantAccessed(user, grants.ACCOUNTING_SALARIES_SALARY_HEADER_PAYMENT) && (
                            <div className={isMobile ? Styles.headerActionBtnsMobile : Styles.headerActionBtns}>
                                <Button
                                    disabled={_.get(data, 'status') == 'CANCEL'}
                                    icon={
                                        <DollarOutlined
                                            style={{
                                                fontSize: 24
                                            }}
                                        />
                                    }
                                    onClick={() => {
                                        setModal(MODALS.REFACTORED_CASH_ORDER, {
                                            initValues: {
                                                type: CASH_ORDER_TYPES.EXPENSE,
                                                counterpartyType: COUNTERPARTY_TYPES.EMPLOYEE,
                                                employeeId: _.get(data, 'employeeId'),
                                                analyticsUniqueId: 31,
                                                comment: _.get(data, 'comment'),
                                                salary: true,
                                                cashBoxId: _.get(filteredCashboxes, '[0].id'),
                                                salaryDocId: Number(id),
                                                sum: remainingRateSum
                                            },
                                            onCashOrderCreatedCallback: () => this.fetchSalaryDocData()
                                        });
                                    }}
                                    type='text'
                                />
                            </div>
                        )}
                        {isGrantAccessed(user, grants.ACCOUNTING_SALARIES_SALARY_HEADER_PRINT) && (
                            <div className={isMobile ? Styles.headerActionBtnsMobile : Styles.headerActionBtns}>
                                <Button
                                    disabled={_.get(data, 'status') == 'CANCEL'}
                                    icon={
                                        <PrinterOutlined
                                            style={{
                                                fontSize: 24
                                            }}
                                        />
                                    }
                                    onClick={() => this.printSalaryReport()}
                                    type='text'
                                />
                            </div>
                        )}
                        <div className={isMobile ? Styles.headerActionBtnsMobile : Styles.headerActionBtns}>
                            <Tooltip title={<FormattedMessage id='setting-salary.to_calc_salary' />}>
                                <Button
                                    disabled={_.get(data, 'status') == 'CANCEL' || _.get(data, 'status') == 'DONE'}
                                    icon={
                                        <PercentageOutlined
                                            style={{
                                                fontSize: 24
                                            }}
                                        />
                                    }
                                    onClick={() => {
                                        this.setState({
                                            visibleCalcSalaryModal: true
                                        });
                                    }}
                                    type='text'
                                />
                            </Tooltip>
                        </div>
                    </Space>
                }
                title={
                    <div>
                        {_.get(data, 'status') ? (
                            <FormattedMessage id={`employee_salary_rules.status.${_.get(data, 'status')}`} />
                        ) : undefined}{' '}
                        {`SAL-${_.get(user, 'businessId', undefined)}-${id}`}
                    </div>
                }
            >
                <React.Fragment>
                    <div
                        style={{
                            padding: '0 0 16px',
                            borderBottom: '1px solid var(--lightGray)'
                        }}
                    >
                        <div
                            style={{
                                margin: '15px 0',
                                padding: '0 0 15px',
                                display: isMobile ? 'block' : 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div
                                style={{
                                    width: isMobile ? '100%' : '20%'
                                }}
                            >
                                <FormattedMessage id='employee' />
                                {requiredField()}
                                <div
                                    style={{
                                        marginBottom: 12,
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Select
                                        allowClear
                                        disabled={_.get(data, 'status') == 'CANCEL' || _.get(data, 'status') == 'DONE' || !isHeaderAccessible}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => {
                                            this.setState({
                                                employeeId: value
                                            });
                                        }}
                                        showSearch
                                        style={{ display: 'block', width: '100%' }}
                                        value={employeeId}
                                        optionFilterProp='children'
                                        // mode='multiple'
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'search'
                                        })}
                                    >
                                        {allEmployees
                                            .filter(({ disabled }) => !disabled)
                                            .map(({ id, name, surname }) => (
                                                <Option key={id} value={id}>
                                                    {`${name} ${surname}`}
                                                </Option>
                                            ))}
                                    </Select>

                                    {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST) && (
                                        <Tooltip title={<FormattedMessage id='edit' />}>
                                            <Button
                                                className={Styles.editIcon}
                                                disabled={!isHeaderAccessible}
                                                icon={<EditOutlined />}
                                                onClick={() => {
                                                    goTo(`${book.employeesPage}/${employeeId}`);
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>

                                <div className={Styles.fieldsMargin}>
                                    <span
                                        style={{
                                            marginBottom: 8
                                        }}
                                    >
                                        <FormattedMessage id='setting-salary.period' />
                                    </span>
                                    <DateRangePicker
                                        allowClear
                                        dateRange={[startDate, endDate]}
                                        disabled={
                                            _.get(data, 'status') == 'CANCEL' ||
                                            _.get(data, 'status') == 'DONE' ||
                                            !isHeaderAccessible
                                        }
                                        onDateChange={([startDate, endDate]) => {
                                            this.setState({ startDate, endDate });
                                        }}
                                        popupStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                            zIndex: '9999',
                                            minWidth: 200
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={Styles.fieldsMargin}>
                                    <span
                                        style={{
                                            marginBottom: 8
                                        }}
                                    >
                                        <FormattedMessage id='storage_document.business_requisites' />
                                    </span>
                                    <Select
                                        disabled={_.get(data, 'status') == 'CANCEL' || _.get(data, 'status') == 'DONE'|| !isHeaderAccessible}
                                        fieldNames={{ value: 'id', label: 'name' }}
                                        onSelect={value => this.setState({ selectedRequisiteId: value })}
                                        options={requisites}
                                        value={selectedRequisiteId}
                                        showSearch
                                        // style={{ display: 'block', width: '300px' }}
                                        // value={employeeId}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    width: isMobile ? '100%' : '20%'
                                }}
                            >
                                <div className={Styles.fieldsMargin}>
                                    <FormattedMessage id='universal_filters_tags.beginDate' />

                                    <DatePicker
                                        allowClear
                                        disabled={
                                            _.get(data, 'status') == 'CANCEL' ||
                                            _.get(data, 'status') == 'DONE' ||
                                            !isHeaderAccessible
                                        }
                                        format={dateFormat}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => {
                                            this.setState({
                                                creationDate: value
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'select_date'
                                        })}
                                        style={{
                                            width: '100%',
                                            marginTop: 4
                                        }}
                                        value={creationDate}
                                    />
                                </div>
                                <div className={Styles.fieldsMargin}>
                                    <FormattedMessage id='storage_journal.closing_date' />

                                    <DatePicker
                                        allowClear
                                        disabled
                                        // disabled={
                                        //     _.get(data, 'status') == 'CANCEL' ||
                                        //     _.get(data, 'status') == 'DONE' ||
                                        //     !isHeaderAccessible
                                        // }
                                        format={dateFormat}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => {
                                            this.setState({
                                                doneDate: value
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'storage_journal.closing_date'
                                        })}
                                        style={{
                                            width: '100%',
                                            marginTop: 4
                                        }}
                                        value={doneDate}
                                    />
                                </div>

                                <div className={Styles.fieldsMargin}>
                                    <FormattedMessage id='order_from_table_date_doc' />
                                    <DatePicker
                                        allowClear
                                        disabled={
                                            _.get(data, 'status') == 'CANCEL' ||
                                            _.get(data, 'status') == 'DONE' ||
                                            !isHeaderAccessible
                                        }
                                        format={dateFormat}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => {
                                            this.setState({
                                                accountingDate: value
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'select_date'
                                        })}
                                        style={{
                                            width: '100%',
                                            marginTop: 4
                                        }}
                                        value={accountingDate}
                                    />
                                </div>
                            </div>

                            {/* <div
                                style={{
                                    width: '20%'
                                }}
                            >
                                <div className={Styles.fieldsMargin}>
                                    <div>
                                        <FormattedMessage id='sum' />
                                    </div>
                                    <InputNumber min={0} precision={2} readOnly value={sum || 0} />
                                </div>

                                <div className={Styles.fieldsMargin}>
                                    <div>
                                        <FormattedMessage id='employee_salary_rules.taxes' />
                                    </div>
                                    <InputNumber min={0} precision={2} readOnly value={otherTaxes || 0} />
                                </div>
                            </div> */}

                            <div
                                style={{
                                    width: isMobile ? '100%' : '30%'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            paddingRight: 8
                                        }}
                                    >
                                        <div className={Styles.sumWrapper}>
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                <FormattedMessage id='sum' />
                                            </span>
                                            <Numeral
                                                className={Styles.sumNumeral}
                                                currency={getCurrency()}
                                                mask='0,0.00'
                                                nullText='0'
                                            >
                                                {_.get(data, 'sum') || 0}
                                            </Numeral>
                                        </div>

                                        <div className={Styles.sumWrapper}>
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                <FormattedMessage id='employee_salary_rules.taxes' />
                                            </span>
                                            <Numeral
                                                className={Styles.sumNumeral}
                                                currency={getCurrency()}
                                                mask='0,0.00'
                                                nullText='0'
                                            >
                                                {otherTaxes || 0}
                                            </Numeral>
                                        </div>

                                        <div className={Styles.sumWrapper}>
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                <FormattedMessage id='employee_salary_rules.salary' />
                                            </span>
                                            <Numeral
                                                className={Styles.sumNumeral}
                                                currency={getCurrency()}
                                                mask='0,0.00'
                                                nullText='0'
                                            >
                                                {_.get(data, 'rateSum') || 0}
                                            </Numeral>
                                        </div>
                                    </div>
                                    <div
                                        className={Styles.sumWrapper}
                                        data-qa='remain_storage_document_form'
                                        style={{
                                            background: 'var(--static)',
                                            fontSize: 16,
                                            height: '110px',
                                            minWidth: '180px',
                                            width: '100%',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            padding: '0 12px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                textAlign: 'center',
                                                marginBottom: 12,
                                                color: null
                                            }}
                                        >
                                            <FormattedMessage id='remain' />:
                                            <span
                                                className={Styles.remainSum}
                                                onClick={() => this._redirectToStorageFundsFlow()}
                                            >
                                                <Numeral
                                                    className={Styles.totalSum}
                                                    currency={getCurrency()}
                                                    mask='0,0.00'
                                                    nullText='0'
                                                >
                                                    {remainingRateSum}
                                                </Numeral>
                                            </span>
                                        </div>
                                        <div
                                            data-qa='paid_storage_document_form'
                                            style={{
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            <FormattedMessage id='paid' />:
                                            <span
                                                className={Styles.remainSum}
                                                onClick={() => this._redirectToStorageFundsFlow()}
                                            >
                                                <Numeral
                                                    className={Styles.totalSum}
                                                    currency={getCurrency()}
                                                    mask='0,0.00'
                                                    nullText='0'
                                                >
                                                    {_.get(data, 'sum') - remaining}
                                                </Numeral>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: '0 -1px 15px 10px',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div
                                data-qa='comment_storage_document_form'
                                style={{
                                    width: isMobile ? '100%' : '73.5%'
                                }}
                            >
                                <FormattedMessage id='comment' />
                                <TextArea
                                    onChange={e => {
                                        this.setState({
                                            comment: e.target.value
                                        });
                                    }}
                                    type='textarea'
                                    value={comment}
                                />
                            </div>
                        </div>
                    </div>

                    <Table
                        className={Styles.table}
                        columns={this.columns()}
                        dataSource={dataRows}
                        pagination={false}
                        rowKey='id'
                        rowSelection={rowSelection}
                        scroll={isMobile ? { x: 1100 } : { x: 800, y: '60vh' }}
                    />
                    <RefactoredCashOrderModal />
                    <AddSalaryDocModalCalculated
                        allEmployees={allEmployees}
                        empId={_.get(data, 'employeeId')}
                        endD={_.get(data, 'endDate')}
                        fetchSalaryDocData={this.fetchSalaryDocData}
                        hideModal={() => {
                            this.setState({
                                visibleCalcSalaryModal: false
                            });
                        }}
                        id={id}
                        onlyForCurrentEmployee
                        startD={_.get(data, 'startDate')}
                        visible={visibleCalcSalaryModal}
                    />
                    {/* <CalcSalaryModal
                        allEmployees={allEmployees}
                        calcSalary={this.calcSalary}
                        calcSalaryByAllEmps={this.calcSalaryByAllEmps}
                        empId={_.get(data, 'employeeId')}
                        onlyForCurrentEmployee
                    /> */}
                    <SalaryAddRowModal
                        allEmployees={allEmployees}
                        empId={_.get(data, 'employeeId')}
                        fetchSalaryDocData={this.fetchSalaryDocData}
                        hideModal={() => {
                            this.setState({
                                rowModalVisible: false
                            });
                        }}
                        id={id}
                        isMobile={this.props.isMobile}
                        visible={rowModalVisible}
                    />
                    <SalaryEditRowModal
                        allEmployees={allEmployees}
                        editRow={editRow}
                        fetchSalaryDocData={this.fetchSalaryDocData}
                        hideModal={() => {
                            this.setState({
                                rowEditVisible: false
                            });
                        }}
                        id={id}
                        isMobile={this.props.isMobile}
                        visible={rowEditVisible}
                    />
                </React.Fragment>
            </Layout>
        );
    }
}
