/* eslint-disable max-classes-per-file */
import {
    BuildOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    ImportOutlined,
    InboxOutlined,
    PlayCircleOutlined,
    PlusOutlined,
    RedoOutlined,
    SaveOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, Select, Table, Tabs, Upload } from 'antd';
import { Catcher, Layout, Spinner } from 'commons';
import { LocationsTabContainer } from 'containers';
import { fetchPriceGroups } from 'core/storage/priceGroups';
import { fetchSuppliers } from 'core/suppliers/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildSupplierOptions, fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { TabPane } = Tabs;
const { Option } = Select;
const { Dragger } = Upload;

const mapStateToProps = state => ({
    suppliers: state.suppliers.suppliers,
    user: state.auth
});

const mapDispatchToProps = {
    fetchSuppliers,
    fetchWarehouses,
    fetchPriceGroups
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class PricelistsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suppliers: [],
            page: 1,
            pageSize: 25,
        };
        const isDisabledCRUD = !isGrantAccessed(this.props.user, grants.ADMINISTRATION_PRICELISTS, accesses.ROWO);

        this.columns = [
            {
                title: <FormattedMessage id='pricelists.period' />,
                key: 'period',
                dataIndex: 'period',
                render: (data, row) => {
                    return (
                        <Input
                            disabled={isDisabledCRUD}
                            onBlur={async () => {
                                await fetchAPI(
                                    'PUT',
                                    `pricelists/${row.id}`,
                                    undefined,
                                    { period: data },
                                    { handleErrorInternally: true }
                                );
                                this._updatePricelists();
                            }}
                            onChange={async ({ target }) => {
                                row.period = target.value;
                                this.setState({});
                            }}
                            placeholder='Period'
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='pricelists.supplier' />,
                key: 'businessSupplierId',
                dataIndex: 'businessSupplierId',
                render: (data, row) => {
                    return (
                        <Select
                            disabled={isDisabledCRUD}
                            onChange={async value => {
                                await fetchAPI(
                                    'PUT',
                                    `pricelists/${row.id}`,
                                    undefined,
                                    { businessSupplierId: value },
                                    { handleErrorInternally: true }
                                );
                                this._updatePricelists();
                            }}
                            onSearch={input => this.handleSearchSuppliers(input)}
                            optionFilterProp='children'
                            placeholder='Supplier'
                            showSearch
                            value={data}
                        >
                            {row.businessSupplierId
                                ? buildSupplierOptions(this.state.suppliers, {
                                      id: row.businessSupplierId,
                                      name: row.businessSupplierName
                                  }).map(({ id, name }) => (
                                      <Option key={id} value={id}>
                                          {name}
                                      </Option>
                                  ))
                                : this.state.suppliers.map(({ id, name }) => (
                                      <Option key={id} value={id}>
                                          {name}
                                      </Option>
                                  ))}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'date',
                dataIndex: 'date',
                render: (data, row) => {
                    return (
                        <Input
                            disabled={isDisabledCRUD}
                            onChange={async ({ target }) => {
                                row.period = target.value;
                                await fetchAPI(
                                    'PUT',
                                    `pricelists/${row.id}`,
                                    undefined,
                                    { date: target.value },
                                    { handleErrorInternally: true }
                                );
                                this._updatePricelists();
                            }}
                            placeholder='Date'
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='pricelists.last_completed_statistics' />,
                key: 'statistics',
                children: [
                    {
                        title: <FormattedMessage id='pricelists.prices' />,
                        key: 'prices',
                        dataIndex: 'lastPricesCount'
                    },
                    {
                        title: <FormattedMessage id='pricelists.availabilities' />,
                        key: 'availabilities',
                        dataIndex: 'lastAvailabilitiesCount'
                    },
                    {
                        title: <FormattedMessage id='pricelists.skipped' />,
                        key: 'skipped',
                        dataIndex: 'skipsCount'
                    }
                ]
            },
            {
                title: <FormattedMessage id='pricelists.status' />,
                key: 'status',
                dataIndex: 'status',
                render: (data, row) => {
                    return data == 'READY' ? (
                        <PlayCircleOutlined style={{ fontSize: 22, color: 'var(--green)' }} />
                    ) : data == 'IN_PROCESS' ? (
                        <ClockCircleOutlined style={{ fontSize: 22, color: 'var(--primary)' }} />
                    ) : null;
                }
            },
            {
                title: <FormattedMessage id='delete' />,
                key: 'delete',
                dataIndex: 'id',
                render: (id, row) => {
                    return isDisabledCRUD ? null : (
                        <DeleteOutlined
                            onClick={async () => {
                                await fetchAPI('DELETE', `pricelists/${id}`, null, null, {
                                    handleErrorInternally: true
                                });
                                this._updatePricelists();
                            }}
                            style={{ fontSize: 22 }}
                        />
                    );
                }
            }
        ];

        this.logsColumns = [
            {
                title: <FormattedMessage id='№' />,
                key: 'logId',
                dataIndex: 'logId',
                render: (data) => data
            },
            {
                title: <FormattedMessage id='storage_journal.row_id' />,
                key: 'id',
                dataIndex: 'id',
                render: (data) => data
            },
            {
                title: <FormattedMessage id='storage.supplier_id' />,
                key: 'businessSupplierId',
                dataIndex: 'businessSupplierId',
                render: (data) => data
            },
            {
                title: <FormattedMessage id='storage.supplier' />,
                key: 'businessSupplierName',
                dataIndex: 'businessSupplierName',
                render: (data) => data
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'datetime',
                dataIndex: 'datetime',
                render: (data) => data
            },
            {
                title: <FormattedMessage id='info_modal.value' />,
                key: 'message',
                dataIndex: 'message',
                render: (data) => data
            },
        ];
    }

    _updatePricelists = async () => {
        const [pricelists, currencies] = await Promise.all([
            fetchAPI('GET', 'pricelists', null, null, { handleErrorInternally: true }),
            fetchAPI('GET', 'business/currency/list', null, null, { handleErrorInternally: true })
        ]);
        const defaultCurrencyOption = currencies.find(({ currencyCode }) => currencyCode === 'UAH');
        pricelists.map(elem => {
            elem.files = [
                {
                    id: -1,
                    config: {
                        availabilities: {},
                        prices: {
                            currencyId: defaultCurrencyOption.id
                        }
                    },
                    downloadConfig: {}
                },
                ...elem.files
            ];
        });
        this.setState({
            pricelists,
            currencies,
            uahId: defaultCurrencyOption.id
        });
    };

    _fetchPricelists = async () => {
        this._updatePricelists();
    };

    _fetchLog = async (query) => {
        const logs = await fetchAPI('GET', 'pricelists/logs', query, null, { handleErrorInternally: true });
        this.setState({
            logs: logs.list,
            countLogs: logs.count
        });
    };

    componentDidMount = async () => {
        this.fetchSuppliers();
        await Promise.all([this._fetchPricelists()]);
        // const intervalId = setInterval(() => this._fetchLog(), 5000);
        this._fetchLog();
        // this.setState({ intervalId });
    };

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    componentDidUpdate(prevProps) {}

    handleSearchSuppliers = _.debounce(value => {
        this.fetchSuppliers(value);
    }, 1000);

    fetchSuppliers = async query => {
        const suppliers = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                showHidden: false,
                query,
                all: true
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            suppliers
        });
    };

    render() {
        const {
            intl: { formatMessage },

            user
        } = this.props;
        const isDisabledCRUD = !isGrantAccessed(user, grants.ADMINISTRATION_PRICELISTS, accesses.ROWO);
        const { pricelists, logs, period, businessSupplierId, date, currencies, suppliers, countLogs, page, pageSize } = this.state;
        const expandedRowRender = (record, files) => {
            const columns = [
                {
                    title: <FormattedMessage id='pricelists.from' />,
                    key: 'from',
                    render: row => {
                        return (
                            <Select
                                disabled={isDisabledCRUD}
                                onChange={async value => {
                                    row.downloadConfig.from = value;
                                    this.setState({});
                                }}
                                placeholder='From'
                                value={row.downloadConfig.from}
                            >
                                <Option value='HTTP'>HTTP</Option>
                                <Option value='SMTP'>SMTP</Option>
                                <Option value='FILE'>FILE</Option>
                            </Select>
                        );
                    }
                },
                {
                    key: 'file',
                    render: row => {
                        if (row.downloadConfig.from == 'HTTP') {
                            return (
                                <Input
                                    disabled={isDisabledCRUD}
                                    onChange={({ target }) => {
                                        row.downloadConfig.serverUrl = target.value;
                                        this.setState({});
                                    }}
                                    value={row.downloadConfig.serverUrl}
                                />
                            );
                        }
                        if (row.downloadConfig.from == 'SMTP') {
                            return (
                                <Input
                                    disabled={isDisabledCRUD}
                                    onChange={({ target }) => {
                                        row.downloadConfig.mail = target.value;
                                        this.setState({});
                                    }}
                                    value={row.downloadConfig.mail}
                                />
                            );
                        }
                        if (row.downloadConfig.from == 'FILE') {
                            return (
                                <ImportFileModal
                                    callback={file => {
                                        row.config.customFileName = file.name;
                                        this.setState({});
                                    }}
                                    updateDataSource={() => {
                                        this._updatePricelists();
                                    }}
                                />
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage id='pricelists.format' />,
                    key: 'format',
                    render: row => {
                        return (
                            <Select
                                disabled={isDisabledCRUD}
                                onChange={async value => {
                                    row.downloadConfig.format = value;
                                    this.setState({});
                                }}
                                placeholder='Format'
                                value={row.downloadConfig.format}
                            >
                                <Option value='xlsx'>xlsx</Option>
                                <Option value='csv'>csv</Option>
                                <Option value='txt'>txt</Option>
                            </Select>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='pricelists.parse_format' />,
                    key: 'parseFormat',
                    render: row => {
                        return (
                            <Select
                                disabled={isDisabledCRUD}
                                onChange={async value => {
                                    row.config.parseFormat = value;
                                    this.setState({});
                                }}
                                placeholder='ParseFormat'
                                value={row.config.parseFormat}
                            >
                                <Option value='xlsx'>xlsx</Option>
                                <Option value='csv'>csv</Option>
                                <Option value='magnit_txt'>magnit_txt</Option>
                            </Select>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='pricelists.unarchive' />,
                    key: 'unarchive',
                    render: row => {
                        return (
                            <Select
                                disabled={isDisabledCRUD}
                                onChange={async value => {
                                    row.downloadConfig.unarchive = value;
                                    this.setState({});
                                }}
                                placeholder='Unarchive'
                                value={row.downloadConfig.unarchive}
                            >
                                <Option value='rar'>rar</Option>
                                <Option value='zip'>zip</Option>
                            </Select>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='pricelists.enable_downloading' />,
                    key: 'enableDownloading',
                    render: row => {
                        return (
                            <Checkbox
                                checked={row.downloadConfig.enableDownloading}
                                disabled={isDisabledCRUD}
                                onChange={() => {
                                    row.downloadConfig.enableDownloading = !row.downloadConfig.enableDownloading;
                                    this.setState({});
                                }}
                            />
                        );
                    }
                },
                {
                    title: <FormattedMessage id='pricelists.get_file_name_from' />,
                    key: 'getFileNameFrom',
                    render: row => {
                        return (
                            <Select
                                disabled={isDisabledCRUD}
                                onChange={async value => {
                                    row.config.getFileNameFrom = value;
                                    this.setState({});
                                }}
                                placeholder='GetFileNameFrom'
                                value={row.config.getFileNameFrom}
                            >
                                <Option value='CUSTOM'>CUSTOM</Option>
                                <Option value='INTERCARS'>INTERCARS</Option>
                            </Select>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='pricelists.settings' />,
                    key: 'settings',
                    dataIndex: 'id',
                    render: (id, row) => {
                        return (
                            <div>
                                <SettingsModal
                                    config={row.config}
                                    currencies={currencies}
                                    disabled={isDisabledCRUD}
                                    setConfig={config => {
                                        row.config = config;
                                        this.setState({});
                                    }}
                                />
                            </div>
                        );
                    }
                },
                {
                    title: <FormattedMessage id='save' />,
                    key: 'save',
                    dataIndex: 'id',
                    render: (id, row) => {
                        return isDisabledCRUD ? null : (
                            <SaveOutlined
                                onClick={async () => {
                                    const { id } = row;
                                    row.config.businessSupplierName = undefined;
                                    row.id = undefined;
                                    if (id > -1) {
                                        await fetchAPI('PUT', `pricelists/${record.id}/sources/${id}`, undefined, row, {
                                            handleErrorInternally: true
                                        });
                                    } else {
                                        await fetchAPI('POST', `pricelists/${record.id}/sources`, undefined, row, {
                                            handleErrorInternally: true
                                        });
                                    }
                                    this._updatePricelists();
                                }}
                                style={{ fontSize: 18 }}
                            />
                        );
                    }
                },
                {
                    title: <FormattedMessage id='delete' />,
                    key: 'delete',
                    dataIndex: 'id',
                    render: (id, row) => {
                        return isDisabledCRUD ? null : (
                            <CloseOutlined
                                onClick={async () => {
                                    await fetchAPI('DELETE', `pricelists/${record.id}/sources/${id}`, null, null, {
                                        handleErrorInternally: true
                                    });
                                    this._updatePricelists();
                                }}
                                style={{ fontSize: 18 }}
                            />
                        );
                    }
                }
            ];

            return (
                <div>
                    <Table bordered columns={columns} dataSource={files} pagination={false} rowKey='id' />
                </div>
            );
        };

        return !pricelists ? (
            <Spinner spin />
        ) : (
            <Layout
                controls={
                    <React.Fragment>
                        <RedoOutlined
                            onClick={async () => {
                                this._updatePricelists();
                            }}
                            style={{ fontSize: 22 }}
                        />
                    </React.Fragment>
                }
                title={<FormattedMessage id='navigation.pricelists' />}
            >
                <Catcher>
                    <Tabs>
                        <TabPane key='settings' tab={<FormattedMessage id='pricelists.settings' />}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '8px'
                                }}
                            >
                                <Input
                                    disabled={isDisabledCRUD}
                                    onChange={({ target }) => {
                                        this.setState({
                                            period: target.value
                                        });
                                    }}
                                    placeholder='Period'
                                    style={{ width: '30%' }}
                                    value={period}
                                />
                                <Select
                                    disabled={isDisabledCRUD}
                                    onChange={value => {
                                        this.setState({
                                            businessSupplierId: value
                                        });
                                    }}
                                    onSearch={input => this.handleSearchSuppliers(input)}
                                    optionFilterProp='children'
                                    placeholder='Supplier'
                                    showSearch
                                    style={{ width: '30%' }}
                                    value={businessSupplierId}
                                >
                                    {suppliers.map(elem => (
                                        <Option key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                                <Input
                                    disabled={isDisabledCRUD}
                                    onChange={({ target }) => {
                                        this.setState({
                                            date: target.value
                                        });
                                    }}
                                    placeholder='Date'
                                    style={{ width: '30%' }}
                                    value={date}
                                />
                                <Button
                                    disabled={isDisabledCRUD}
                                    onClick={async () => {
                                        await fetchAPI(
                                            'POST',
                                            'pricelists',
                                            undefined,
                                            { period, businessSupplierId, date },
                                            { handleErrorInternally: true }
                                        );
                                        await this.setState({
                                            period: undefined,
                                            businessSupplierId: undefined,
                                            date: undefined
                                        });
                                        this._updatePricelists();
                                    }}
                                    type='primary'
                                >
                                    <PlusOutlined />
                                </Button>
                            </div>
                            <Table
                                bordered
                                columns={this.columns}
                                dataSource={pricelists}
                                expandedRowRender={record => expandedRowRender(record, record.files)}
                                pagination={false}
                                rowKey='id'
                                size='small'
                            />
                        </TabPane>
                        <TabPane key='locations' tab={<FormattedMessage id='pricelists.locations' />}>
                            <LocationsTabContainer
                                handleSearchSuppliers={this.handleSearchSuppliers}
                                isDisabledCRUD={isDisabledCRUD}
                                suppliers={this.state.suppliers}
                            />
                        </TabPane>
                        <TabPane key='logs' tab={<FormattedMessage id='pricelists.logs' />}>
                            <Table
                                bordered
                                columns={this.logsColumns}
                                dataSource={logs}
                                pagination={{     
                                    pageSize,
                                    size: 'small',
                                    total: Math.ceil(countLogs / pageSize) * pageSize,
                                    hideOnSinglePage: true,
                                    current: page,
                                    onChange: (page, pageSize) => {
                                        this.setState({ page, pageSize });
                                        this._fetchLog({page, pageSize});
                                    }}
                                }
                                size='small'
                            />
                        </TabPane>
                    </Tabs>
                </Catcher>
            </Layout>
        );
    }
}

@injectIntl
class ImportFileModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fileList: []
        };
    }

    handleOk = async () => {
        const formData = new FormData();
        this.props.callback(this.state.fileList[0]);
        formData.append('file', this.state.fileList[0]);
        const url = `${__API_URL__}/pricelists/file`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: localStorage.getItem('_my.carbook.pro_token')
                }
            });
            const result = await response.json();
            // this.props.updateDataSource();
        } catch (error) {
            console.error('error:', error);
            // this.props.updateDataSource();
        }
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            fileList: []
        });
    };

    render() {
        const { fileList } = this.state;
        const uploadFileProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));

                return false;
            },
            fileList
        };

        return (
            <div>
                <Button
                    className={Styles.ownIcon}
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    style={{
                        padding: '0px 8px',
                        fontSize: 18
                    }}
                    title={this.props.intl.formatMessage({ id: 'export_import_pages.import_data' })}
                >
                    <ImportOutlined />
                </Button>
                <Modal
                    maskClosable={false}
                    okButtonProps={{
                        disabled: fileList.length < 1
                    }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='export_import_pages.import_data' />}
                    visible={this.state.visible}
                    width={480}
                >
                    <Dragger {...uploadFileProps}>
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    </Dragger>
                </Modal>
            </div>
        );
    }
}

@injectIntl
class SettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    handleOk = async () => {
        this.props.setConfig(this.state.config);
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            config: undefined
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.visible && !prevState.visible) {
            this.setState({
                config: this.props.config
            });
        }
    }

    render() {
        const { setConfig, currencies } = this.props;
        const { visible, config, uahId } = this.state;

        return (
            <div>
                <BuildOutlined
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    style={{
                        height: 18,
                        width: 18,
                        fontSize: 18
                    }}
                />
                <Modal
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={visible}
                    title={<FormattedMessage id='pricelists.settings' />}
                    width={480}
                >
                    {config && (
                        <React.Fragment>
                            {/* <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.import_currency' />
                                </span>
                                <span>UAH</span>
                            </div> */}
                            <div className={Styles.settingsItem}>
                                <span>
                                    {console.log(config)}
                                    <FormattedMessage id='pricelists.import_currency' />
                                </span>
                                <Select
                                    fieldNames={{ label: 'currencyCode', value: 'id' }}
                                    onChange={value => {
                                        config.prices.currencyId = value;
                                        this.setState({});
                                    }}
                                    optionFilterProp='label'
                                    options={currencies}
                                    showSearch
                                    style={{ width: 200 }}
                                    value={config.prices.currencyId ? config.prices.currencyId : uahId}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.remove_file_on_complete' />
                                </span>
                                <Checkbox
                                    checked={config.removeFile}
                                    onChange={() => {
                                        config.removeFile = !config.removeFile;
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.insert_prices' />
                                </span>
                                <Checkbox
                                    checked={config.insertPrices}
                                    onChange={() => {
                                        config.insertPrices = !config.insertPrices;
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.default_brand_id' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.defaultBrandId = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.defaultBrandId}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.item_id' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.partNumber = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.partNumber}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.supplier_item_id' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.prices.supplierPartNumber = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.prices.supplierPartNumber}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.brand_id' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.pricelistBrand = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.pricelistBrand}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.item_name' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.prices.name = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.prices.name}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.comment' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.prices.comment = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.prices.comment}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.retail_price' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.prices.retailPrice = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.prices.retailPrice}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.weight' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.prices.weight = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.prices.weight}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.custom_code' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.prices.customCode = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.prices.customCode}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.first_line' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.firstLine = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.firstLine}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.retail_factor' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.prices.retailFactor = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.prices.retailFactor}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.header_line' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.availabilities.headerLine = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.availabilities.headerLine}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.locations_first_column' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.availabilities.firstColumn = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.availabilities.firstColumn}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.locations_last_column' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.availabilities.lastColumn = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.availabilities.lastColumn}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.сondition' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.availabilities.condition = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.availabilities.condition}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.abroad' />
                                </span>
                                <Checkbox
                                    checked={config.availabilities.abroad}
                                    onChange={() => {
                                        config.availabilities.abroad = !config.availabilities.abroad;
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.limit' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.availabilities.limit = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.availabilities.limit}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.increment_symbol' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.availabilities.incSymbol = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.availabilities.incSymbol}
                                />
                            </div>
                            <div className={Styles.settingsItem}>
                                <span>
                                    <FormattedMessage id='pricelists.sheet_number' />
                                </span>
                                <Input
                                    onChange={({ target }) => {
                                        config.sheetNumber = target.value;
                                        this.setState({});
                                    }}
                                    style={{ width: 200 }}
                                    value={config.sheetNumber}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </Modal>
            </div>
        );
    }
}
