import { DatePicker, Input, Modal, notification, Select } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, isDisabledByDetailStatuses } from 'utils';
import '../styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {};
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class SuppliersIncomeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requisites: []
        };
    }

    componentDidUpdate(prevProps) {
        const { visible, row, selectedRows, user } = this.props;
        if (!prevProps.visible && visible) {
            if (row) {
                this.fetchSupplierRequisites(row.supplierId);
                this.setState({
                    supplierName: row.supplierName,
                    toWarehouseId: user.warehouseId
                });
            } else {
                const groupedData = selectedRows
                    .filter(row => row.supplierId)
                    .filter(row => !isDisabledByDetailStatuses('intake', row))
                    .sort((a, b) => a.supplierId - b.supplierId);
                this.prepareGroupedData(groupedData);
            }
        }
    }

    fetchSupplierRequisites = async id => {
        const { requisites } = await fetchAPI('GET', `business_suppliers/${id}`, null, null, {
            handleErrorInternally: true
        });
        this.setState({
            requisites
        });
    };

    prepareGroupedData = groupedData => {
        if (groupedData.length) {
            this.fetchSupplierRequisites(groupedData[0].supplierId);
            this.setState({
                clicked: false,
                groupedData,
                supplierName: groupedData[0].supplierName,
                supplierDocNumber: undefined,
                datetime: undefined,
                requisiteSupplierId: undefined,
                toWarehouseId: this.props.user.warehouseId || undefined,
                comment: undefined
            });
        } else {
            notification.warning({
                message: this.props.intl.formatMessage({ id: 'not_found' })
            });
            this.handleCancel();
        }
    };

    handleOkForSingleOperation = async () => {
        const {
            row,
            updateDataSource,
            intl: { formatMessage }
        } = this.props;

        const { datetime, supplierDocNumber, toWarehouseId, requisiteSupplierId, comment } = this.state;
        this.setState({ clicked: true });
        await fetchAPI(
            'POST',
            'v2/store_docs/receive_all_possible',
            undefined,
            {
                toWarehouseId: toWarehouseId || undefined,
                requisiteSupplierId,
                supplierDocNumber,
                datetime: dayjs(datetime).toISOString(),
                ordersAppurtenanciesIds: [row.id],
                comment
            },
            {
                handleErrorInternally: true
            }
        );
        await this.checkStatus(0, { orderId: this.props.orderId, pcode: 'RECEIVE' });
        updateDataSource();

        this.handleCancel();
    };

    checkStatus = async (index = 0, { orderId, pcode, productId } = {}) => {
        const {
            updateDataSource,
            intl: { formatMessage }
        } = this.props;

        if (index >= 20) {
            notification.error({ message: 'Invalid' });

            return;
        }
        const resp = await fetchAPI('GET', 'mq_processes', {
            orderId,
            pcode,
            productId
        });
        const status = get(resp, 'list[0].state');

        if (status && status !== 'OK') {
            setTimeout(() => this.checkStatus(index + 1, { orderId, pcode, productId }), 1000);

            return;
        }
        if (status === 'OK') {
            notification.success({
                message: formatMessage({
                    id: 'details_table.details_successfully_intook'
                })
            });
        }
        updateDataSource();
    };

    handleOkForGroupOperation = async () => {
        const { updateDataSource, intl } = this.props;
        const { groupedData, datetime, supplierDocNumber, toWarehouseId, requisiteSupplierId, comment } = this.state;

        const filtredData = groupedData.filter(el => el.supplierId === get(groupedData, '[0].supplierId'));
        const restData = groupedData.filter(el => el.supplierId !== get(groupedData, '[0].supplierId'));
        this.setState({ clicked: true });

        const response = await fetchAPI(
            'POST',
            'v2/store_docs/receive_all_possible',
            undefined,
            {
                toWarehouseId: toWarehouseId || undefined,
                requisiteSupplierId,
                supplierDocNumber,
                datetime: dayjs(datetime).toISOString(),
                ordersAppurtenanciesIds: filtredData.map(({ id }) => id),
                comment
            },
            { handleErrorInternally: true }
        );
        this.checkStatus(0, { orderId: this.props.orderId, pcode: 'RECEIVE' });
        updateDataSource();
        if (restData.length) {
            this.prepareGroupedData(restData);
        } else {
            this.handleCancel();
        }
    };

    handleCancel = () => {
        this.props.hideModal();
        this.setState({
            supplierDocNumber: undefined,
            datetime: undefined,
            toWarehouseId: undefined,
            requisiteSupplierId: undefined,
            requisites: [],
            clicked: false,
            comment: undefined
        });
    };

    render() {
        const {
            visible,
            intl: { formatMessage },
            loading,
            row,
            warehouses
        } = this.props;

        const {
            supplierName,
            supplierDocNumber,
            datetime,
            toWarehouseId,
            requisiteSupplierId,
            requisites,
            comment,
            clicked
        } = this.state;

        return (
            <Modal
                maskClosable={false}
                okButtonProps={{ disabled: clicked }}
                onCancel={this.handleCancel}
                onOk={row ? this.handleOkForSingleOperation : this.handleOkForGroupOperation}
                open={visible}
                title={`${formatMessage({ id: 'status.INTAKE' })} ${supplierName && `(${supplierName})`}`}
            >
                <FormattedMessage id='order_from_table_number_doc' />
                <Input
                    onChange={event => {
                        this.setState({ supplierDocNumber: event.target.value });
                    }}
                    placeholder={formatMessage({ id: 'order_from_table_number_doc_enter' })}
                    style={{
                        marginTop: 8,
                        marginBottom: 8
                    }}
                    value={supplierDocNumber}
                />
                <FormattedMessage id='order_from_table_date_doc' />
                <DatePicker
                    disabled={loading}
                    format='dddd, DD MMM YYYY'
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={datetime => {
                        this.setState({ datetime });
                    }}
                    placeholder={formatMessage({
                        id: 'order_from_table_date_doc_enter'
                    })}
                    style={{
                        marginTop: 8,
                        marginBottom: 8,
                        width: '100%',
                        textTransform: 'capitalize'
                    }}
                    value={datetime}
                />
                <div>
                    <FormattedMessage id='storage_document.storage_income' />

                    <Select
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={toWarehouseId => {
                            this.setState({ toWarehouseId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({ id: 'storage' })}
                        showSearch
                        style={{
                            marginTop: 8,
                            marginBottom: 8,
                            width: '100%',
                            textTransform: 'capitalize'
                        }}
                        value={toWarehouseId}
                    >
                        {warehouses.map(elem => {
                            const isDisabled = elem.attribute === 'RESERVE';

                            return (
                                <Option key={elem.id} disabled={isDisabled} value={elem.id}>
                                    {elem.name}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
                <div>
                    <FormattedMessage id='storage_document.business_supplier_requisites' />
                    <Select
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={requisiteSupplierId => {
                            this.setState({ requisiteSupplierId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'storage_document.business_supplier_requisites'
                        })}
                        value={requisiteSupplierId}
                    >
                        {requisites.map(elem => {
                            return (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.name}
                                    {elem.isTaxPayer && (
                                        <span
                                            style={{
                                                marginLeft: 8,
                                                color: 'var(--text2)'
                                            }}
                                        >
                                            (<FormattedMessage id='with_VAT' />)
                                        </span>
                                    )}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
                <div>
                    <FormattedMessage id='comment' />
                    <Input.TextArea
                        onChange={event => {
                            this.setState({
                                comment: event.target.value
                            });
                        }}
                        placeholder={formatMessage({
                            id: 'comment'
                        })}
                        value={comment}
                    />
                </div>
            </Modal>
        );
    }
}

export default SuppliersIncomeModal;
