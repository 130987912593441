import { Button, Tabs } from 'antd';
import { Catcher } from 'commons';
import { ClientDiscountsTab, ClientMRDsTab, ClientOrdersTab } from 'components';
import { fetchCalls } from 'core/calls/duck';
import { createClientVehicle, deleteClientVehicle, updateClientVehicle } from 'core/client/duck';
import { fetchClientMRDs, selectClientMRDsStats } from 'core/clientMRDs/duck';
import {
    fetchClientOrders,
    selectClientOrders,
    selectClientOrdersFilter
} from 'core/clientOrders/duck';
import { EditClientForm, EditClientVehicleForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    ordersData: selectClientOrders(state),
    filterOrders: selectClientOrdersFilter(state),
    mrdsStats: selectClientMRDsStats(state)
});

const mapDispatchToProps = {
    createClientVehicle,
    updateClientVehicle,
    deleteClientVehicle,

    fetchClientOrders,
    fetchClientMRDs,
    fetchCalls
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientContainer extends Component {
    componentDidMount() {
        const { clientId, filterOrders } = this.props;
        this.props.fetchClientOrders({ clientId, filterOrders });
        this.props.fetchClientMRDs({ clientId });
    }

    render() {
        const {
            clientEntity,
            clientId,
            user,
            specificTab,
            isMobile,
            fetchClient,
            ordersData,
            mrdsStats,
            vehicleTypes,
            onAddVehicle
        } = this.props;
        const {
            CREATE_EDIT_DELETE_CLIENTS,
            GET_CLIENTS_ADDITIONAL_INFORMATION,
            ACCESS_CLIENTS_REQUISITES,
            ACCESS_RECEIVABLES_GET
        } = permissions;

        return (
            <Catcher>
                <Tabs defaultActiveKey={specificTab || 'generalInfo'} tabPosition='top'>
                    <TabPane
                        key='generalInfo'
                        tab={<FormattedMessage id='client_container.general_info' />}
                    >
                        <EditClientForm client={clientEntity} clientId={clientId} />
                        <EditClientVehicleForm
                            clientEntity={clientEntity}
                            clientId={clientId}
                            deleteClientVehicle={this.props.deleteClientVehicle}
                            fetchClient={fetchClient}
                            updateClientVehicle={this.props.updateClientVehicle}
                            vehicleTypes={vehicleTypes}
                        />
                        {isMobile && (
                            <Button
                                disabled={isForbidden(user, CREATE_EDIT_DELETE_CLIENTS)}
                                onClick={onAddVehicle}
                                style={{
                                    width: '100%',
                                    margin: '-8px 0 12px'
                                }}
                                type='primary'
                            >
                                <FormattedMessage id='add_client_form.add_vehicle' />
                            </Button>
                        )}
                    </TabPane>

                    <TabPane
                        key='orders'
                        disabled={isForbidden(user, GET_CLIENTS_ADDITIONAL_INFORMATION)}
                        tab={
                            <div>
                                <FormattedMessage id='client_container.orders' />(
                                {ordersData && ordersData.count})
                            </div>
                        }
                    >
                        <ClientOrdersTab clientId={clientId} />
                    </TabPane>
                    <TabPane
                        key='clientDebt'
                        disabled={isForbidden(user, ACCESS_RECEIVABLES_GET)}
                        tab={
                            <div>
                                <FormattedMessage id='client_container.debt' />(
                                {mrdsStats && mrdsStats.countMRDs})
                            </div>
                        }
                    >
                        <ClientMRDsTab client={clientEntity} clientId={clientId} />
                    </TabPane>
                    <TabPane
                        key='discounts'
                        tab={<FormattedMessage id='client_container.discounts' />}
                    >
                        <ClientDiscountsTab
                            client={clientEntity}
                            clientId={clientId}
                            fetchClient={fetchClient}
                        />
                    </TabPane>
                </Tabs>
            </Catcher>
        );
    }
}
