import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip, notification } from 'antd';
import { Catcher } from 'commons';
import { AddRuleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';

@withRouter
@injectIntl
export default class SalaryRulesTable extends Component {
    constructor(props) {
        super(props);
        this.state = { rules: [] };
        this.columns = () => [
            {
                title: <FormattedMessage id='employee_salary_rules.rule_name' />,
                width: 'auto',
                key: 'name',
                dataIndex: 'ruleName'
            },
            {
                title: <FormattedMessage id='employee_salary_rules.position' />,
                dataIndex: 'postName',
                width: 'auto',
                key: 'position',
                render: data => <FormattedMessage id={`employee_salary_rules.${data}`} />
            },
            {
                title: <FormattedMessage id='employee_salary_rules.basis_of_calculation' />,
                dataIndex: 'presetName',
                width: 'auto',
                key: 'basis',
                render: data => <FormattedMessage id={`employee_salary_rules.${data}`} />
            },
            {
                title: <FormattedMessage id='employee_salary_rules.base_details' />,
                dataIndex: 'specificationName',
                width: 'auto',
                key: 'base',
                render: data => (data ? <FormattedMessage id={`employee_salary_rules.${data}`} /> : undefined)
            },
            {
                title: <FormattedMessage id='employee_salary_rules.indicator_for_calculation' />,
                dataIndex: 'markName',
                width: 'auto',
                key: 'indicator',
                render: data => (data ? <FormattedMessage id={`employee_salary_rules.${data}`} /> : undefined)
            },
            {
                title: <FormattedMessage id='locations.percent' />,
                dataIndex: 'precent',
                width: 'auto',
                key: 'percent',
                render: data => (data ? <div>{`${data} %`}</div> : '')
            },
            {
                title: <FormattedMessage id='sum' />,
                dataIndex: 'sum',
                width: 'auto',
                key: 'sum'
            },
            {
                title: <FormattedMessage id='order_form_table.discount' />,
                dataIndex: 'discount',
                align: 'center',
                width: 'auto',
                key: 'discount',
                render: discount => (
                    <React.Fragment>
                        {discount ? (
                            <CheckCircleOutlined
                                style={{
                                    color: 'var(--secondary)',
                                    fontSize: 24
                                }}
                            />
                        ) : (
                            <CloseCircleOutlined
                                style={{
                                    color: 'var(--warning)',
                                    fontSize: 24
                                }}
                            />
                        )}
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='comment' />,
                dataIndex: 'comment',
                width: 'auto',
                key: 'com'
            },
            {
                width: 'auto',
                key: 'edit',
                render: row => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <div>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    this.editRule(row);
                                }}
                            />
                        </div>
                        <div>
                            <Popconfirm
                                disabled={Boolean(row.inUse)}
                                onConfirm={async () => {
                                    const token = localStorage.getItem('_my.carbook.pro_token');
                                    const url = `${__API_URL__}/salary/rule/${row.ruleId}`;
                                    await fetch(url, {
                                        method: 'DELETE',
                                        headers: {
                                            Authorization: token
                                        }
                                    }).then(function (response) {
                                        if (response.status !== 200) {
                                            return notification.error({
                                                duration: 3,
                                                message: props.intl.formatMessage({
                                                    id: 'employee_salary_rules.error_text'
                                                })
                                            });
                                        }

                                        return Promise.resolve(response);
                                    });

                                    this.getBussinessRules();
                                }}
                                title={`${props.intl.formatMessage({ id: 'delete' })} ?`}
                                zIndex={999}
                            >
                                <Button disabled={Boolean(row.inUse)} icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </div>
                    </div>
                )
            }
        ];
    }

    componentDidMount() {
        this.getBussinessRules();
    }

    getBussinessRules = async () => {
        const rules = await fetchAPI('GET', 'salary/rules', null, null);
        this.setState({
            rules
        });
    };

    editRule = row => {
        this.setState({
            visibleAddRuleModal: true,
            addRuleModalRow: row
        });
    };

    render() {
        const { employees } = this.props;

        const { rules, visibleAddRuleModal, addRuleModalRow } = this.state;

        return (
            <Catcher>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    <Tooltip title={<FormattedMessage id='employee_salary_rules.add_rule' />}>
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => {
                                this.setState({
                                    visibleAddRuleModal: true,
                                    addRuleModalRow: undefined
                                });
                            }}
                        />
                    </Tooltip>
                </div>
                <Table
                    bordered
                    columns={this.columns()}
                    dataSource={rules}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    scroll={{ x: 840 }}
                    size='small'
                    style={{ marginTop: 18 }}
                />
                <AddRuleModal
                    addRuleModalRow={addRuleModalRow}
                    getBussinessRules={this.getBussinessRules}
                    hideModal={() => {
                        this.setState({
                            visibleAddRuleModal: false
                        });
                    }}
                    visible={visibleAddRuleModal}
                />
            </Catcher>
        );
    }
}
