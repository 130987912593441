import { HomeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { MODALS, resetModal } from 'core/modals/duck';
import { SwitchBusinessForm } from 'forms';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        modal: state.modals.modal,
        loading: state.ui.searchBusinessesFetching
    };
};

const mapDispatch = {
    resetModal
};

@connect(mapStateToProps, mapDispatch)
export default class SwitchBusinessModal extends Component {
    render() {
        const { modal: visible, resetModal, loading, setBusiness } = this.props;

        return (
            <Modal
                destroyOnClose
                footer={null}
                maskClosable={false}
                onCancel={() => resetModal()}
                title={<HomeOutlined />}
                visible={visible === MODALS.SWITCH_BUSINESS}
            >
                <SwitchBusinessForm
                    loading={loading}
                    resetModal={resetModal}
                    setBusiness={setBusiness}
                />
            </Modal>
        );
    }
}
