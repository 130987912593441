import { Form } from '@ant-design/compatible';
import { Button } from 'antd';
import schema from 'async-validator';
import { createPriceGroup } from 'core/storage/priceGroups';
import { DecoratedInputNumber } from 'forms/DecoratedFields';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isForbidden, permissions } from 'utils';

const StyledInput = styled(DecoratedInputNumber)`
    & .ant-input-number {
        width: 240px;
        margin-top: 28px;
    }

    &.ant-form-item {
        margin-bottom: 0;
    }
`;

const StyledForm = styled(Form)`
    display: flex;
    align-items: center;
`;

const PriceGroup = props => {
    const _submit = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                props.createPriceGroup(values);
                props.form.resetFields();
            }
        });
    };

    const descriptor = {
        name: {
            type: 'number',
            required: true,
            validator: (rule, value) => !value.match(/[a-zA-Z]+/)
        }
    };

    const _handleErrors = (errors, fields) =>
        console.error('_handleErrors(errors, fields)', errors, fields);

    const validator = new schema(descriptor);

    validator.validate({ name: 'multiplier' }, (errors, fields) => {
        if (errors) {
            // validation failed, errors is an array of all errors
            // fields is an object keyed by field name with an array of
            // errors per field
            return _handleErrors(errors, fields);
        }
        // validation passed
    });

    const isCRUDForbidden = isForbidden(props.user, permissions.ACCESS_CATALOGUE_PRICE_GROUPS_CRUD);

    return (
        !isCRUDForbidden && (
            <StyledForm>
                <StyledInput
                    disabled={isCRUDForbidden}
                    field='multiplier'
                    fields={{}}
                    formItem
                    getFieldDecorator={props.form.getFieldDecorator}
                    min={0.1}
                    onPressEnter={() => _submit()}
                    placeholder={props.intl.formatMessage({ id: 'storage.markup' })}
                    rules={[
                        {
                            required: true,
                            message: props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <Button disabled={isCRUDForbidden} onClick={() => _submit()} type='primary'>
                    {props.intl.formatMessage({ id: 'create' })}
                </Button>
            </StyledForm>
        )
    );
};

export const PriceGroupForm = injectIntl(
    connect(
        state => ({
            user: state.auth
        }),
        { createPriceGroup }
    )(Form.create()(PriceGroup))
);
