import { FilterFilled, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table, Tag, Tooltip } from 'antd';
import { Layout, Numeral } from 'commons';
import { DateRangePicker } from 'components';
import { _ } from 'core-js';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import styled from 'styled-components';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { AddRowModal } from './modals';
import Styles from './styles.m.css';

const dateFormat = 'DD.MM.YYYY';

const { Option } = Select;

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    businessRequisites: selectBusinessRequisites(state)
});

const mapDispatchToProps = {
    fetchBrands,
    fetchBusinessRequisites,
    fetchWarehouses
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StorageJournalPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            page: 1,
            totalCount: 0,
            pageSize: 25,
            listBrands: [],
            sum: [],
            clientRequisites: [],
            supplierRequisites: [],
            labors: []
        };

        this.columns = [
            {
                title: <FormattedMessage id='storage_journal.product_code' />,
                key: 'productCode',
                dataIndex: 'code',
                width: 200,
                ...this.getColumnSearchProps('filterCode'),
                render: (code, { product }) =>
                    isGrantAccessed(this.props.user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD) ? (
                        <Link to={`${book.product}/${product.id}`}>
                            <div
                                style={{
                                    fontWeight: 500,
                                    color: 'var(--link)'
                                }}
                            >
                                {product.code}
                            </div>
                            <div
                                style={{
                                    fontWeight: 500,
                                    color: 'var(--text3)',
                                    fontSize: 12
                                }}
                            >
                                {product.name}
                            </div>
                        </Link>
                    ) : (
                        <div>
                            <div
                                style={{
                                    fontWeight: 500,
                                    color: 'var(--link)'
                                }}
                            >
                                {product.code}
                            </div>
                            <div
                                style={{
                                    fontWeight: 500,
                                    color: 'var(--text3)',
                                    fontSize: 12
                                }}
                            >
                                {product.name}
                            </div>
                        </div>
                    )
            },
            {
                title: <FormattedMessage id='storage_journal.brand' />,
                key: 'brand',
                dataIndex: 'brandName',
                align: 'center',
                width: 200,
                ...this.getColumnSearchProps('filterBrand')
            },
            {
                title: <FormattedMessage id='storage_journal.document_date' />,
                key: 'documentDate',
                width: 200,
                align: 'center',
                ...this.getColumnSearchProps('dateRange'),
                render: data => {
                    const operationCode = _.get(data, 'doc.operationCode');

                    return operationCode === 'AUT' ||
                        operationCode === 'INC' ||
                        operationCode === 'KPP' ||
                        operationCode === 'TSF' ||
                        operationCode === 'KPM'
                        ? dayjs(_.get(data, 'datetime')).format('DD.MM.YYYY HH:mm')
                        : dayjs(_.get(data, 'doc.recordDatetime')).format('DD.MM.YYYY HH:mm');
                }
            },
            {
                title: <FormattedMessage id='storage_calculations.doc_type' />,
                key: 'doc_type',
                align: 'center',
                width: 200,
                // ...this.getColumnSearchProps('filterDocumentType'),
                render: data => {
                    const operationCode = _.get(data, 'doc.operationCode');

                    return (
                        <Tooltip
                            title={
                                <div>
                                    {operationCode} -{' '}
                                    <FormattedMessage id={`storage_document.docType.${operationCode}`} />
                                </div>
                            }
                        >
                            <div>{operationCode}</div>
                        </Tooltip>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.document_number' />,
                key: 'docNumber',
                width: 200,
                ...this.getColumnSearchProps('filterDocumentNumber'),
                render: (key, data) => {
                    const docId = _.get(data, 'doc.id');
                    const documentNumber = _.get(data, 'doc.documentNumber');

                    return data.orderId ? (
                        <div>
                            <a
                                href={`${book.order}/${data.orderId}`}
                                style={{ color: 'var(--link)', fontWeight: 'bold' }}
                            >
                                {data.orderId}
                            </a>
                        </div>
                    ) : docId && isGrantAccessed(this.props.user, grants.WAREHOUSE_DOCUMENT) ? (
                        <div>
                            <a
                                href={`${book.storageDocument}/${docId}`}
                                style={{ color: 'var(--link)', fontWeight: 'bold' }}
                            >
                                {documentNumber}
                            </a>
                        </div>
                    ) : (
                        documentNumber
                    );
                }
            },

            {
                title: <FormattedMessage id='storage_journal.operation_type' />,
                key: 'operationType',
                width: 150,
                align: 'center',
                ...this.getColumnSearchProps('filterDocumentType'),
                render: (key, data) => {
                    const type = _.get(data, 'doc.type');

                    return data.orderId ? (
                        <div>
                            <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                                {props.intl.formatMessage({
                                    id: `storage.${
                                        type ||
                                        (props.type === 'reserves' || props.tab === 'RESERVE' ? 'reserve' : 'EXPENSE')
                                    }`
                                })}
                            </Tag>
                        </div>
                    ) : (
                        <div>
                            <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                                {props.intl.formatMessage({
                                    id: `storage.${
                                        type ||
                                        (props.type === 'reserves' || props.tab === 'RESERVE' ? 'reserve' : 'EXPENSE')
                                    }`
                                })}
                            </Tag>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.amount' />,
                key: 'amount',
                dataIndex: 'quantity',
                width: 100,
                align: 'right'
            },
            {
                title: <FormattedMessage id='services_table.units' />,
                key: 'unit',
                width: 80,
                align: 'right',
                render: (key, data) => {
                    const unit = _.get(data, 'product.unit');

                    return <div>{unit || undefined}</div>;
                }
            },
            {
                title: <FormattedMessage id='storage_journal.purchase_price' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                width: 150,
                align: 'right',
                render: purchasePrice => <Numeral mask='0,0.00'>{Number(purchasePrice)}</Numeral>
            },
            {
                title: <FormattedMessage id='storage_journal.selling_price' />,
                key: 'sellingPrice',
                dataIndex: 'sellingPrice',
                width: 150,
                align: 'right',
                render: sellingPrice => <Numeral mask='0,0.00'>{Number(sellingPrice)}</Numeral>
            },
            {
                title: <FormattedMessage id='storage_journal.purchase_sum' />,
                key: 'purchaseSum',
                dataIndex: 'purchaseSum',
                width: 150,
                align: 'right',
                render: (purchaseSum, data) => {
                    return (
                        <React.Fragment>
                            {this.getMinus(_.get(data, 'doc.type'), purchaseSum)}
                            <Numeral mask='0,0.00'>{Number(purchaseSum)}</Numeral>
                        </React.Fragment>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.selling_sum' />,
                key: 'sellingSum',
                dataIndex: 'sellingSum',
                width: 150,
                align: 'right',
                render: (sellingSum, data) => {
                    return (
                        <React.Fragment>
                            {this.getMinus(_.get(data, 'doc.type'), sellingSum)}
                            <Numeral mask='0,0.00'>{Number(sellingSum)}</Numeral>
                        </React.Fragment>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.counterparty_type' />,
                key: 'counterpartyType',
                width: 200,
                align: 'center',
                ...this.getColumnSearchProps('filterCounterpart'),
                render: row => _.get(row, 'doc.documentType')
            },
            {
                title: <FormattedMessage id='storage_journal.counterpartyName' />,
                key: 'counterpartyName',
                width: 200,
                ...this.getColumnSearchProps('filterCounterpartName'),
                render: (doc, data) => {
                    const clientSurname = _.get(data, 'doc.counterpartClientSurname');

                    const supplierName = _.get(data, 'doc.businessSupplier.name');

                    const counterpartEmployeeName = _.get(data, 'doc.counterpartEmployeeName');

                    // const clientName = clientSurname
                    //      _.get(data, 'doc.counterpartClientName', '')
                    //      _.get(data, 'doc.counterpartClientSurname')
                    //      _.get(data, 'doc.counterpartClientName', '');

                    let clientName;

                    if (clientSurname) {
                        clientName = `${_.get(data, 'doc.counterpartClientName', '')} ${_.get(
                            data,
                            'doc.counterpartClientSurname'
                        )}`;
                    } else {
                        clientName = _.get(data, 'doc.counterpartClientName', '');
                    }

                    return (
                        <Link
                            to={
                                supplierName
                                    ? `${book.supplier}/${_.get(data, 'doc.counterpartBusinessSupplierId')}`
                                    : clientName
                                    ? `${book.client}/${_.get(data, 'doc.counterpartClientId')}`
                                    : counterpartEmployeeName
                                    ? `${book.employeesPage}/${_.get(data, 'doc.counterpartEmployeeId')}`
                                    : undefined
                            }
                        >
                            {clientName || supplierName || counterpartEmployeeName}
                        </Link>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_funds.phone_number' />,
                key: 'phone',
                width: 200,
                ...this.getColumnSearchProps('filterPhone'),
                render: (doc, data) => {
                    const income = Boolean(doc);

                    const clientPhone = _.get(data, 'doc.counterpartClientPhone')
                        ? _.get(data, 'doc.counterpartClientPhone')
                        : '';

                    const counterpartEmployeePhone = _.get(data, 'doc.counterpartEmployeePhone')
                        ? `+${_.get(data, 'doc.counterpartEmployeePhone')}`
                        : '';

                    const businessSupplierPhone = _.get(data, 'doc.businessSupplier.phones')
                        ? _.get(data, 'doc.businessSupplier.phones')
                        : '';

                    return <div>{clientPhone || counterpartEmployeePhone || businessSupplierPhone}</div>;
                }
            },
            {
                title: <FormattedMessage id='storage_journal.storage_income' />,
                key: 'storageIncome',
                width: 200,
                align: 'center',
                ...this.getColumnSearchProps('filterWarehouseName'),
                render: data => {
                    const type = _.get(data, 'doc.type');

                    return type === 'INCOME' ? <div>{_.get(data, 'doc.warehouseName')}</div> : null;
                }
            },
            {
                title: <FormattedMessage id='storage_journal.storage_outcome' />,
                key: 'storageOutcome',
                width: 200,
                align: 'center',
                ...this.getColumnSearchProps('filterCounterpartWarehouseName'),
                render: data => {
                    const type = _.get(data, 'doc.type');

                    return type === 'EXPENSE' ? <div>{_.get(data, 'doc.counterpartWarehouseName')}</div> : null;
                }
            },
            {
                title: <FormattedMessage id='storage_journal.counterparty_id' />,
                key: 'counterpartyId',
                width: 200,
                align: 'right',
                ...this.getColumnSearchProps('filterBusinessSupplierId'),
                render: (doc, data) => {
                    const counterpartClientId = _.get(data, 'doc.counterpartClientId');

                    const counterpartBusinessSupplierId = _.get(data, 'doc.counterpartBusinessSupplierId');
                    const counterpartEmployeeId = _.get(data, 'doc.counterpartEmployeeId');

                    const clientSurname = _.get(data, 'doc.counterpartClientSurname');

                    const supplierName = _.get(data, 'doc.businessSupplier.name');

                    const counterpartEmployeeName = _.get(data, 'doc.counterpartEmployeeName');

                    // const clientName = clientSurname
                    //      _.get(data, 'doc.counterpartClientName', '')
                    //      _.get(data, 'doc.counterpartClientSurname')
                    //      _.get(data, 'doc.counterpartClientName', '');

                    let clientName;

                    if (clientSurname) {
                        clientName = `${_.get(data, 'doc.counterpartClientName', '')} ${_.get(
                            data,
                            'doc.counterpartClientSurname'
                        )}`;
                    } else {
                        clientName = _.get(data, 'doc.counterpartClientName', '');
                    }

                    return (
                        <Link
                            to={
                                supplierName
                                    ? `${book.supplier}/${_.get(data, 'doc.counterpartBusinessSupplierId')}`
                                    : clientName
                                    ? `${book.client}/${_.get(data, 'doc.counterpartClientId')}`
                                    : counterpartEmployeeName
                                    ? `${book.employeesPage}/${_.get(data, 'doc.counterpartEmployeeId')}`
                                    : undefined
                            }
                        >
                            <div>{counterpartClientId || counterpartBusinessSupplierId || counterpartEmployeeId}</div>
                        </Link>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.responsible' />,
                key: 'responsible',
                width: 200,
                align: 'center',
                ...this.getColumnSearchProps('filterManager'),
                render: (order, data) => {
                    return (
                        <Link to={`${book.employeesPage}/${_.get(data, 'doc.manager.employeeId')}`}>
                            {`${_.get(data, 'doc.manager.name', '')} ${_.get(data, 'doc.manager.surname', '')}`}
                        </Link>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.date_of_creation' />,
                key: 'dateCreation',
                width: 200,
                align: 'center',
                ...this.getColumnSearchProps('filterCreatedDate'),
                render: row => dayjs(_.get(row, 'doc.createdDatetime')).format('DD.MM.YYYY HH:mm')
            },
            {
                title: <FormattedMessage id='storage_journal.closing_date' />,
                key: 'closingDate',
                width: 200,
                align: 'center',
                ...this.getColumnSearchProps('filtertDoneDate'),
                render: row =>
                    _.get(row, 'doc.doneDatetime') ? (
                        dayjs(_.get(row, 'doc.doneDatetime')).format('DD.MM.YYYY HH:mm')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    )
            },
            {
                title: <FormattedMessage id='storage_journal.row_id' />,
                key: 'rowId',
                dataIndex: 'id',
                align: 'right',
                width: 200,
                sorter: (a, b) => a.id - b.id,
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('filterIdRow')
            },
            {
                title: <FormattedMessage id='storage_journal.product_id' />,
                key: 'productId',
                dataIndex: 'productId',
                align: 'right',
                width: 200,
                ...this.getColumnSearchProps('filterIdProduct'),
                render: (data, { product }) =>
                    isGrantAccessed(this.props.user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD) ? (
                        <Link to={`${book.product}/${product.id}`}>
                            <div
                                style={{
                                    fontWeight: 500,
                                    color: 'var(--link)'
                                }}
                            >
                                {data}
                            </div>
                        </Link>
                    ) : (
                        data
                    )
            },
            {
                title: <FormattedMessage id='storage_journal.name' />,
                key: 'name',
                width: 300,
                ...this.getColumnSearchProps('filterProductName'),
                render: row => _.get(row, 'product.name')
            },
            {
                title: <FormattedMessage id='storage_document.business_requisites' />,
                key: 'businessRequisiteName',
                width: 200,
                align: 'right',
                ...this.getColumnSearchProps('filterBusinessRequisiteId'),
                render: (key, data) => {
                    const businessRequisiteName = _.get(data, 'doc.businessRequisiteName');

                    return <div>{businessRequisiteName}</div>;
                }
            },
            {
                title: <FormattedMessage id='storage_document.clients_requisites' />,
                key: 'clientRequisiteName',
                dataIndex: 'clientRequisiteName',
                ...this.getColumnSearchProps('filterClientRequisiteId'),
                width: 200,
                align: 'right',
                render: (key, data) => {
                    const clientRequisiteName = _.get(data, 'doc.clientRequisiteName');

                    return <div>{clientRequisiteName}</div>;
                }
            },
            {
                title: <FormattedMessage id='storage_document.business_supplier_requisites' />,
                key: 'businessSupplierRequisiteName',
                dataIndex: 'businessSupplierRequisiteName',
                ...this.getColumnSearchProps('filterBusinessSupplierRequisiteId'),
                width: 200,
                align: 'right',
                render: (key, data) => {
                    const businessSupplierRequisiteName = _.get(data, 'doc.businessSupplierRequisiteName');

                    return <div>{businessSupplierRequisiteName}</div>;
                }
            }
        ];
    }

    componentDidMount() {
        this.getStoreDocProducts();
        this.props.fetchBrands();
        this.props.fetchBusinessRequisites();
        this.fetchClientRequisites();
        this.fetchLabors();
        this.props.fetchWarehouses();
    }

    fetchClientRequisites = async () => {
        const dataClients = await fetchAPI('GET', '/clients', null, null, {
            handleErrorInternally: true
        });

        const dataSuppliers = await fetchAPI('GET', 'business_suppliers', {
            all: true,
            showHidden: false
        });

        const supplierRequisites = _.uniqBy(
            _.flattenDeep(
                dataSuppliers.filter(({ requisites }) => _.get(requisites, 'length')).map(el => el.requisites)
            ),
            'id'
        );
        const clientRequisites = _.uniqBy(
            _.flattenDeep(
                dataClients.clients.filter(({ requisites }) => _.get(requisites, 'length')).map(el => el.requisites)
            ),
            'id'
        );
        this.setState({ clientRequisites, supplierRequisites });
    };

    fetchLabors = async () => {
        const data = await fetchAPI('GET', '/labors', null, null, {
            handleErrorInternally: true
        });

        this.setState({ labors: data.labors });
    };

    getStoreDocProducts = async () => {
        const {
            dateRange,
            page,
            pageSize,
            filterIdRow,
            filterIdProduct,
            filterBrand,
            filterCode,
            filterCounterpartWarehouseName,
            filterWarehouseName,
            filterDocumentNumber,
            filterDocumentType,
            filterBusinessSupplierId,
            filterProductName,
            filterManager,
            filterType,
            filterBusinessRequisiteId,
            filterPhone,
            filterCounterpartName,
            filterCounterpart,
            purchaseSumAvg,
            purchaseSumSum,
            quantitySum,
            sellingSumAvg,
            sellingSumSum,
            filterStartRecordDate,
            filterEndRecordDate,
            filterStartCreatedDate,
            filterEndCreatedDate,
            filterStartDoneDate,
            filterEndDoneDate,
            filtertDoneDate,
            filterCreatedDate,
            filterBusinessSupplierRequisiteId,
            filterClientRequisiteId
        } = this.state;
        const data = await fetchAPI(
            'GET',
            'store_doc_products',
            {
                page,
                pageSize,
                showOnlyOrders: false,
                showOnlyReserves: false,
                filterStartRecordDate: _.get(dateRange, '[0]') ? dateRange[0].format('YYYY-MM-DD') : undefined,
                filterEndRecordDate: _.get(dateRange, '[1]') ? dateRange[1].format('YYYY-MM-DD') : undefined,
                filterStartDoneDate: _.get(filtertDoneDate, '[0]')
                    ? filtertDoneDate[0].format('YYYY-MM-DD')
                    : undefined,
                filterEndDoneDate: _.get(filtertDoneDate, '[1]') ? filtertDoneDate[1].format('YYYY-MM-DD') : undefined,
                filterStartCreatedDate: _.get(filterCreatedDate, '[0]')
                    ? filterCreatedDate[0].format('YYYY-MM-DD')
                    : undefined,
                filterEndCreatedDate: _.get(filterCreatedDate, '[1]')
                    ? filterCreatedDate[1].format('YYYY-MM-DD')
                    : undefined,
                filterIdRow,
                filterIdProduct,
                filterBrand,
                filterCode,
                filterCounterpartWarehouseName,
                filterWarehouseName,
                filterDocumentNumber,
                filterDocumentType,
                filterBusinessSupplierId,
                filterProductName,
                filterManager,
                filterType,
                filterBusinessRequisiteId,
                filterBusinessSupplierRequisiteId,
                filterClientRequisiteId,
                filterPhone,

                filterCounterpartName,
                filterCounterpart,
                purchaseSumAvg,
                purchaseSumSum,
                quantitySum,
                sellingSumAvg,
                sellingSumSum
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: data.list,
            stats: data.stats,
            sum: data.sum
        });
    };

    getMinus = (docType, value) => {
        if (docType) {
            return '';
        }
        if (!docType && value === 0) {
            return '';
        }

        return '-';
    };

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'dateRange' || dataIndex === 'filterCreatedDate' || dataIndex === 'filtertDoneDate') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={this.state[dataIndex]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                [dataIndex]: dateRange
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.getStoreDocProducts();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        if (dataIndex === 'filterBrand') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterBrand: value
                        });
                    }}
                    optionFilterProp='children'
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'search'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state.filterBrand}
                >
                    {this.props.brands
                        .filter(({ brandName }) => brandName)
                        .map(({ brandId, brandName }) => (
                            <Option key={brandId} value={brandName}>
                                {brandName}
                            </Option>
                        ))}
                </Select>
            );
        }
        if (dataIndex === 'filterDocumentType') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterDocumentType: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state.filterDocumentType}
                >
                    <Option value='INCOME'>
                        <FormattedMessage id='storage.INCOME' />
                    </Option>
                    <Option value='EXPENSE'>
                        <FormattedMessage id='storage.EXPENSE' />
                    </Option>
                </Select>
            );
        }

        if (dataIndex === 'filterBusinessRequisiteId') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterBusinessRequisiteId: value
                        });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_document.business_requisites'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterBusinessRequisiteId}
                >
                    {this.props.businessRequisites.map(elem => (
                        <Select.Option key={elem.id} value={elem.id}>
                            {elem.name} ({elem.id})
                        </Select.Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'filterClientRequisiteId') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterClientRequisiteId: value
                        });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_document.clients_requisites'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterClientRequisiteId}
                >
                    {this.state.clientRequisites.map(elem => (
                        <Select.Option key={elem.id} value={elem.id}>
                            {elem.name} ({elem.id})
                        </Select.Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'filterBusinessSupplierRequisiteId') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterBusinessSupplierRequisiteId: value
                        });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_document.business_supplier_requisites'
                    })}
                    showSearch
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterBusinessSupplierRequisiteId}
                >
                    {this.state.supplierRequisites.map(elem => (
                        <Select.Option key={elem.id} value={elem.id}>
                            {elem.name} ({elem.id})
                        </Select.Option>
                    ))}
                </Select>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' &&
                        dataIndex !== 'filterCreatedDate' &&
                        dataIndex !== 'filtertDoneDate' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Button
                                    icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                    onClick={() => this.handleSearch(confirm, dataIndex)}
                                    size='small'
                                    type='primary'
                                >
                                    <FormattedMessage id='search' />
                                </Button>
                                <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.getStoreDocProducts();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({
            [dataIndex]: undefined,
            page: 1
        });
        this.getStoreDocProducts();
    };

    render() {
        const { user } = this.props;
        const { dataSource, page, pageSize, stats, sum, visibleAddRowModal, labors } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(_.get(stats, 'count', 0) / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.getStoreDocProducts();
            }
        };

        const dataSourceFilterd = dataSource.map(({ rules }) => rules);

        return (
            <React.Fragment>
                <Layout
                    controls={
                        <div style={{ display: 'flex' }}>
                            <Tooltip title={<FormattedMessage id='storage.reset_all_filters' />}>
                                <Button
                                    icon={<RedoOutlined />}
                                    onClick={async () => {
                                        await this.setState({
                                            dateRange: undefined,
                                            filterCreatedDate: undefined,
                                            filtertDoneDate: undefined,
                                            page: 1,
                                            filterIdRow: undefined,
                                            filterIdProduct: undefined,
                                            filterBrand: undefined,
                                            filterCode: undefined,
                                            filterCounterpartWarehouseName: undefined,
                                            filterWarehouseName: undefined,
                                            filterDocumentNumber: undefined,
                                            filterDocumentType: undefined,
                                            filterBusinessSupplierId: undefined,
                                            filterProductName: undefined,
                                            filterManager: undefined,
                                            filterType: undefined,
                                            filterBusinessRequisiteId: undefined,
                                            filterBusinessSupplierRequisiteId: undefined,
                                            filterClientRequisiteId: undefined,
                                            filterPhone: undefined,
                                            filterCounterpartName: undefined,
                                            filterCounterpart: undefined,
                                            filterStartRecordDate: undefined,
                                            filterEndRecordDate: undefined,
                                            filterStartCreatedDate: undefined,
                                            filterEndCreatedDate: undefined,
                                            filterStartDoneDate: undefined,
                                            filterEndDoneDate: undefined
                                        });
                                        this.getStoreDocProducts();
                                    }}
                                    style={{
                                        marginRight: 8
                                    }}
                                />
                            </Tooltip>
                            {/* {!isForbidden(user, permissions.UPDATE_SUCCESS_ORDER) && (
                                <Tooltip title={<FormattedMessage id='add' />}>
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                            this.setState({ visibleAddRowModal: true });
                                        }}
                                        style={{
                                            marginRight: 8
                                        }}
                                    />
                                </Tooltip>
                            )} */}
                            {/* <DateRangePicker
                            dateFormat={dateFormat}
                            dateRange={dateRange}
                            minimize
                            onDateChange={async dateRange => {
                                await this.setState({
                                    dateRange
                                });
                                this.getStoreDocProducts();
                            }}
                        /> */}
                        </div>
                    }
                    paper
                    title={<FormattedMessage id='navigation.storage_journal' />}
                >
                    <DataRow>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_journal.row_amount' />
                            </span>
                            <Numeral currency={this.props.intl.formatMessage({ id: 'pc' })}>
                                {Number(_.get(stats, 'count', 0))}
                            </Numeral>
                        </DataWrapper>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_journal.general_amount' />
                            </span>
                            <Numeral>{Number(_.get(sum, '[0].quantitySum'))}</Numeral>
                        </DataWrapper>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_journal.purchase_sum_general' />
                            </span>
                            <Numeral mask='0,0.00'>{Number(_.get(sum, '[0].purchaseSumSum'))}</Numeral>
                        </DataWrapper>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_journal.selling_sum_general' />
                            </span>
                            <Numeral mask='0,0.00'>{Number(_.get(sum, '[0].sellingSumSum'))}</Numeral>
                        </DataWrapper>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_journal.avarage_purchase_price' />
                            </span>
                            <Numeral mask='0,0.00'>{Number(_.get(sum, '[0].purchaseSumAvg'))}</Numeral>
                        </DataWrapper>
                        <DataWrapper>
                            <span
                                style={{
                                    marginRight: 4
                                }}
                            >
                                <FormattedMessage id='storage_journal.avarage_selling_price' />
                            </span>
                            <Numeral mask='0,0.00'>{Number(_.get(sum, '[0].sellingSumAvg'))}</Numeral>
                        </DataWrapper>
                    </DataRow>
                    <Table
                        columns={this.columns}
                        dataSource={dataSource}
                        page={page}
                        pageSize={pageSize}
                        pagination={pagination}
                        scroll={{
                            x: 800,
                            y: '60vh'
                        }}
                        setPage={async (page, pageSize) => {
                            await this.setState({ page, pageSize });
                            this.getStoreDocProducts();
                        }}
                    />
                </Layout>
                <AddRowModal
                    hideModal={() => {
                        this.setState({ visibleAddRowModal: false });
                    }}
                    labors={labors}
                    updateData={this.getStoreDocProducts}
                    visible={visibleAddRowModal}
                    warehouses={this.props.warehouses}
                />
            </React.Fragment>
        );
    }
}

const DataRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 14px;
    padding: 14px 0;

    border-bottom: 1px solid;
    border-top: 1px solid;
`;

const DataWrapper = styled.div`
    display: flex;
    font-size: 16px;
`;
