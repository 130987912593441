import { Input, Select, Space } from 'antd';
import { Layout } from 'commons';
import PaddedWrapper from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/PaddedWrapper';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useLocation, withRouter } from 'react-router-dom';
import { fetchAPI } from 'utils';
import EntriesTable from './EntriesTable';
import RelatedEntriesModal from './RelatedEntriesModal';

const EntriesJournalPage = ({ intl: { formatMessage } }) => {
    const { state } = useLocation();

    const [entries, setEntries] = useState([]);
    const [totalEntries, setTotalEntries] = useState(0);
    const [isQueryTouched, setIsQueryTouched] = useState(false);
    const [relatedModalOpen, setRelatedModalOpen] = useState(false);
    const [selectedRelatedEntry, setSelectedRelatedEntry] = useState({});
    const [pagination, setPagination] = useState({
        pageSize: 10,
        page: 1
    });
    const [filters, setFilters] = useState(
        state
            ? {
                  query: state.id || null,
                  dateFrom: state.dateFrom,
                  dateTo: state.dateTo,
                  operationSide: state.operationSide || null,
                  operationId: state.operationId || null
              }
            : {}
    );
    const [requisites, setRequisites] = useState([]);

    const handlePaginationChange = useCallback(
        filterObj => {
            setPagination(prev => ({ ...prev, ...filterObj }));
            !isQueryTouched && setIsQueryTouched(true);
        },
        [isQueryTouched]
    );

    const handleFiltersChange = useCallback(
        filterObj => {
            setFilters(prev => ({ ...prev, ...filterObj }));
            handlePaginationChange({ page: 1 });
            !isQueryTouched && setIsQueryTouched(true);
        },
        [handlePaginationChange, isQueryTouched]
    );

    const handleSearchChange = e => {
        handleFiltersChange({ query: e.target.value });
    };

    const handleRelatedModalOpen = useCallback(record => {
        setRelatedModalOpen(true);
        setSelectedRelatedEntry(record);
    }, []);

    const handleRelatedModalClose = useCallback(() => {
        setRelatedModalOpen(false);
        setSelectedRelatedEntry({});
    }, []);

    const fetchEntries = useCallback(async query => {
        const res = await fetchAPI('GET', '/general_ledger/entries', query, null, {
            handleErrorInternally: true
        });
        setEntries(res.list);
        setTotalEntries(res.count);
    }, []);

    const fetchRequisites = useCallback(async () => {
        const res = await fetchAPI('GET', '/businesses/requisites', null, null, {
            handleErrorInternally: true
        });

        setRequisites(res.map(({ id, name }) => ({ value: id, label: name })));
    }, []);

    const debouncedFetchEntries = useMemo(() => debounce(fetchEntries, 300), [fetchEntries]);

    const combineFilters = useMemo(() => ({ ...filters, ...pagination }), [filters, pagination]);

    useEffect(() => {
        fetchRequisites();
    }, [fetchRequisites]);

    useEffect(() => {
        if (isQueryTouched) {
            debouncedFetchEntries(combineFilters);
        } else {
            fetchEntries(combineFilters);
        }
    }, [fetchEntries, isQueryTouched, combineFilters, debouncedFetchEntries]);

    const getFilterInput = useCallback(
        (propname, translationId) => {
            return {
                filterDropdown: () => {
                    return (
                        <PaddedWrapper>
                            <Input
                                allowClear
                                onChange={e => handleFiltersChange({ [propname]: e.target.value })}
                                placeholder={
                                    translationId &&
                                    `${formatMessage({ id: 'search_by' })} ${formatMessage({
                                        id: translationId
                                    })}`
                                }
                                value={filters[propname]}
                            />
                        </PaddedWrapper>
                    );
                },
                filtered: filters[propname]
            };
        },
        [filters, formatMessage, handleFiltersChange]
    );

    const getFilterSelect = useCallback(
        (propname, translationId, selectProps) => {
            return {
                filterDropdown: () => {
                    return (
                        <PaddedWrapper>
                            <Select
                                allowClear
                                onChange={value => handleFiltersChange({ [propname]: value })}
                                placeholder={
                                    translationId &&
                                    `${formatMessage({ id: 'search_by' })} ${formatMessage({
                                        id: translationId
                                    })}`
                                }
                                value={filters[propname]}
                                {...selectProps}
                            />
                        </PaddedWrapper>
                    );
                },
                filtered: filters[propname]
            };
        },
        [filters, formatMessage, handleFiltersChange]
    );

    return (
        <Layout className='entries_journal_page' title={<FormattedMessage id='navigation.entries' />}>
            <Space direction='vertical'>
                <Input
                    onChange={handleSearchChange}
                    placeholder={formatMessage({ id: 'entry_table.search' })}
                    style={{ width: 400 }}
                    value={filters.query}
                />
                <EntriesTable
                    entries={entries}
                    filters={filters}
                    getFilterInput={getFilterInput}
                    getFilterSelect={getFilterSelect}
                    handleFiltersChange={handleFiltersChange}
                    handlePaginationChange={handlePaginationChange}
                    handleRelatedModalOpen={handleRelatedModalOpen}
                    pagination={pagination}
                    requisites={requisites}
                    totalEntries={totalEntries}
                />
            </Space>
            <RelatedEntriesModal
                onClose={handleRelatedModalClose}
                open={relatedModalOpen}
                selectedRelatedEntry={selectedRelatedEntry}
            />
        </Layout>
    );
};

export default withRouter(injectIntl(EntriesJournalPage));
