import { Col, Modal, Radio, Row, Select } from 'antd';
import { DateRangePicker } from 'components';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import { fetchSuppliers, selectSuppliers } from 'core/suppliers/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    detailingValues,
    downloadSalesReport,
    fetchRequisites,
    fetchSupplierRequisites,
    resetFilters,
    selectActButtonDisabled,
    selectBusinessRequisites,
    selectFilters,
    selectRequisites,
    setActButtonDisabled,
    setReportsFilters
} from './redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    suppliers: selectSuppliers(state),
    filters: selectFilters(state),
    businessRequisites: selectBusinessRequisites(state),
    requisites: selectRequisites(state),
    actButtonDisabled: selectActButtonDisabled(state)
});

const mapDispatchToProps = {
    resetModal,
    fetchSuppliers,
    resetFilters,
    fetchSupplierRequisites,
    fetchRequisites,

    downloadSalesReport,
    setReportsFilters,
    setActButtonDisabled
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SuppliersReportModal extends Component {
    componentDidMount() {
        // fetch suppliers
        this.props.fetchSuppliers();
        this.props.fetchRequisites();
    }

    setFilters = (key, value) => {
        this.props.setReportsFilters({ [key]: value });
    };

    render() {
        const {
            resetModal,
            visible,
            filters,

            suppliers,
            businessRequisites,
            requisites,
            fetchSuppliers,

            resetFilters,
            downloadSalesReport,

            actButtonDisabled
        } = this.props;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={() => {
                        resetModal();
                        resetFilters();
                    }}
                    onOk={() => {
                        downloadSalesReport();
                        resetModal();
                        resetFilters();
                    }}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='accounts_payable_report' />
                        </div>
                    }
                    visible={visible === MODALS.REPORT_SUPPLIERS_FILTER}
                    width='50vw'
                >
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={8}>
                            <FormattedMessage id='storage.business_supplier' />
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                dropdownStyle={{ zIndex: '9999' }}
                                filterOption={false}
                                onChange={value => {
                                    this.setFilters('supplierId', value);
                                    value && this.props.fetchSupplierRequisites(value);
                                    this.props.setActButtonDisabled(!value);
                                }}
                                onSearch={input => {
                                    fetchSuppliers(input);
                                }}
                                placeholder={<FormattedMessage id='storage.business_supplier' />}
                                showSearch
                            >
                                {this.props.suppliers.map(elem => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={8}>
                            <FormattedMessage id='storage.business_supplier_requisites' />
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                dropdownStyle={{ zIndex: '9999' }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children &&
                                        String(option.props.children)
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={value => {
                                    this.setFilters('businessSupplierRequisiteId', value);
                                    // this.props.fetchSupplierRequisites(value);
                                }}
                                placeholder={
                                    <FormattedMessage id='storage.business_supplier_requisites' />
                                }
                                showSearch
                            >
                                {this.props.businessRequisites.map(elem => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={8}>
                            <FormattedMessage id='storage.business_requisites' />
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                dropdownStyle={{ zIndex: '9999' }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children &&
                                        String(option.props.children)
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={value => {
                                    this.setFilters('businessRequisiteId', value);
                                }}
                                placeholder={<FormattedMessage id='storage.business_requisites' />}
                                showSearch
                            >
                                {this.props.requisites.map(elem => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row className={Styles.rowData}>
                        <Col className={Styles.boldCol} span={8}>
                            <FormattedMessage id='calls-table.date' />
                        </Col>
                        <Col span={16}>
                            <DateRangePicker
                                dateRange={[filters.createdFromDate, filters.createdToDate]}
                                maximize
                                onDateChange={daterange => {
                                    const [startDate, endDate] = daterange;

                                    this.setFilters('createdFromDate', startDate);
                                    this.setFilters('createdToDate', endDate);
                                }}
                                overlayStyle={{ zIndex: '5000' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4} style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id='detailing' />
                        </Col>
                        <Col offset={4} span={8}>
                            <Radio.Group
                                className={Styles.radioButtons}
                                onChange={e => this.setFilters('detailing', e.target.value)}
                                value={filters.detailing}
                            >
                                <Radio value={detailingValues.withoutDetainling}>
                                    <FormattedMessage id='date_without_detailing' />
                                </Radio>

                                <Radio value={detailingValues.docsDetailing}>
                                    <FormattedMessage id='by_documents' />
                                </Radio>
                                <Radio
                                    disabled={actButtonDisabled}
                                    value={detailingValues.actDetailing}
                                >
                                    <FormattedMessage id='act_of_reconciliation' />
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
