import { FilterFilled, LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Radio, Select, Switch, Table } from 'antd';
import classNames from 'classnames/bind';
import { Catcher } from 'commons';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { Link } from 'react-router-dom';
import book from 'routes/book';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;

const cx = classNames.bind(Styles);

@withRouter
@injectIntl
export default class GeneralScheduleTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTableDate: dayjs(),
            postId: undefined,
            employeeId: undefined,
            planned: true,
            fact: false,
            page: 1,
            pageSize: 25
        };

        this.columns = () => {
            const days = [];
            for (let i = 0; i < this.state.currentTableDate.daysInMonth(); i++) {
                days.push(dayjs(this.state.currentTableDate).date(i + 1));
            }

            return [
                {
                    title: <FormattedMessage id='order_form_table.employee' />,
                    ...this.getColumnSearchProps('employeeId'),
                    render: row =>
                        isGrantAccessed(this.props.user, grants.DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS) ? (
                            <Link to={book.editEmployee.replace(':id', row.employeeId)}>
                                {`${row.employeeName} ${row.employeeSurname}`}
                            </Link>
                        ) : (
                            <div>
                                {row.employeeName} {row.employeeSurname}
                            </div>
                        )
                },
                {
                    title: <FormattedMessage id='employee_salary_rules.position' />,
                    ...this.getColumnSearchProps('postId'),
                    render: row => {
                        const allPosts = row.posts.map(({ postName }) => postName);

                        return allPosts.map(elem => (
                            <div>
                                <FormattedMessage id={`employee_salary_rules.${elem}`} />
                            </div>
                        ));
                    }
                },
                ...days.map(day => ({
                    title: day.format('DD dd'),
                    render: row => {
                        const { fact, planned } = this.state;
                        const schedule = row.schedule.find(({ date }) => dayjs(date).isSame(day, 'day'));

                        const plannedWorkingTime = schedule
                            ? dayjs(schedule.plannedEndTime || '00:00:00', 'HH:mm:ss').diff(
                                  dayjs(schedule.plannedStartTime || '00:00:00', 'HH:mm:ss'),
                                  'hours'
                              )
                            : undefined;

                        const tillCurrentDay = schedule
                            ? dayjs(schedule.date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
                            : undefined;

                        const registerWorkingTime = schedule ? schedule.totalWorkingTime || 0 : undefined;

                        if (schedule && schedule.plannedDayOff === false) {
                            const [startHours, startMinutes] = schedule.plannedStartTime.split(':');
                            const [endHours, endMinutes] = schedule.plannedEndTime.split(':');
                            const startDate = dayjs().set('h', Number(startHours)).set('m', Number(startMinutes));
                            const endDate = dayjs().set('h', Number(endHours)).set('m', Number(endMinutes));

                            return (
                                <div
                                    className={
                                        dayjs(schedule.date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') &&
                                        fact
                                            ? Styles.cellStyleCurrentDay
                                            : registerWorkingTime >= plannedWorkingTime && fact && tillCurrentDay
                                            ? Styles.cellStyleFullDay
                                            : registerWorkingTime !== 0 &&
                                              plannedWorkingTime > registerWorkingTime &&
                                              fact &&
                                              tillCurrentDay
                                            ? Styles.cellStyleNotFullDay
                                            : registerWorkingTime == 0 &&
                                              plannedWorkingTime > registerWorkingTime &&
                                              fact &&
                                              tillCurrentDay
                                            ? Styles.cellStyleNotStarted
                                            : schedule.plannedDayOff && fact && tillCurrentDay
                                            ? Styles.cellStyleDayOff
                                            : Styles.cellStyle
                                    }
                                >
                                    <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                                        {Math.round(endDate.diff(startDate, 'hours', true))}
                                    </p>
                                    <p style={{ fontSize: 11, textAlign: 'center' }}>{startDate.format('HH:mm')}</p>
                                    <p style={{ fontSize: 11, textAlign: 'center' }}>{endDate.format('HH:mm')}</p>
                                </div>
                            );
                        }

                        return fact && tillCurrentDay ? <div className={Styles.cellStyleDayOff}></div> : null;
                    },
                    onCell: () => ({ style: { padding: '6px' } })
                }))
            ];
        };
    }

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'employeeId') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <Select
                        allowClear
                        onChange={value =>
                            this.setState({
                                employeeId: value
                            })
                        }
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={this.state.employeeId}
                    >
                        {this.props.employees
                            ? this.props.employees.map(({ name, surname, id }) => (
                                  <Option key={id} value={id}>
                                      {name} {surname}
                                  </Option>
                              ))
                            : undefined}
                    </Select>
                </div>
            );
        }

        if (dataIndex === 'postId') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <Select
                        allowClear
                        // filterOption={(input, option) => {
                        //     console.log(input, option, 'sss')

                        //     return (
                        //         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        //         String(option.value).indexOf(input.toLowerCase()) >= 0
                        //     );
                        // }}
                        onChange={value =>
                            this.setState({
                                postId: value
                            })
                        }
                        placeholder={this.props.intl.formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        value={this.state.postId}
                        optionFilterProp='children'
                        // mode='multiple'
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                    >
                        {this.props.posts.map(({ postName, postId }) => (
                            <Option key={postId} value={postId}>
                                {this.props.intl.formatMessage({
                                    id: `employee_salary_rules.${postName}`
                                })}
                            </Option>
                        ))}
                    </Select>
                </div>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' &&
                        dataIndex !== 'filterCreatedDate' &&
                        dataIndex !== 'filtertDoneDate' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Button
                                    icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                    onClick={() => this.handleSearch(confirm, dataIndex)}
                                    size='small'
                                    type='primary'
                                >
                                    <FormattedMessage id='search' />
                                </Button>
                                <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.props.fetchGeneralSchedule(
            this.state.employeeId,
            this.state.postId,
            this.state.page,
            this.state.pageSize,
            this.state.currentTableDate.startOf('month').format('YYYY-MM-DD'),
            this.state.currentTableDate.endOf('month').format('YYYY-MM-DD')
        );
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({
            [dataIndex]: undefined,
            page: 1
        });
        this.props.fetchGeneralSchedule(
            this.state.employeeId,
            this.state.postId,
            this.state.page,
            this.state.pageSize,
            this.state.currentTableDate.startOf('month').format('YYYY-MM-DD'),
            this.state.currentTableDate.endOf('month').format('YYYY-MM-DD')
        );
    };

    render() {
        const { data, fetchGeneralSchedule, count } = this.props;
        const { currentTableDate, fact, page, pageSize } = this.state;
        console.log('currentTableDate', currentTableDate);
        const pagination = {
            pageSize,
            total: Math.ceil(count / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                fetchGeneralSchedule(
                    undefined,
                    undefined,
                    page,
                    pageSize,
                    currentTableDate.startOf('month').format('YYYY-MM-DD'),
                    currentTableDate.endOf('month').format('YYYY-MM-DD')
                );
            }
        };

        return (
            <Catcher>
                <FormattedMessage id='add-employee-page.schedule' />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <Button
                            icon={<LeftOutlined />}
                            onClick={() => {
                                this.setState({
                                    currentTableDate: currentTableDate.subtract(1, 'M')
                                });
                                fetchGeneralSchedule(
                                    undefined,
                                    undefined,
                                    page,
                                    pageSize,
                                    currentTableDate.startOf('month').format('YYYY-MM-DD'),
                                    currentTableDate.endOf('month').format('YYYY-MM-DD')
                                );
                            }}
                            type='text'
                        />

                        <DatePicker
                            format='MMMM, YYYY'
                            onChange={date => this.setState({ currentTableDate: date })}
                            picker='month'
                            style={{ width: 160 }}
                            value={currentTableDate}
                        />
                        <Button
                            icon={<RightOutlined />}
                            onClick={() => {
                                this.setState({
                                    currentTableDate: currentTableDate.add(1, 'M')
                                });
                                fetchGeneralSchedule(
                                    undefined,
                                    undefined,
                                    page,
                                    pageSize,
                                    currentTableDate.add(1, 'M').startOf('month').format('YYYY-MM-DD'),
                                    currentTableDate.add(1, 'M').endOf('month').format('YYYY-MM-DD')
                                );
                            }}
                            type='text'
                        />
                    </div>
                    <div>
                        <span
                            style={{
                                marginLeft: 6,
                                marginRight: 6
                            }}
                        >
                            <FormattedMessage id='employee-page.plan_fact' />
                        </span>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Switch
                                checked={fact}
                                onClick={() =>
                                    this.setState({
                                        fact: !fact
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <Table
                    bordered
                    columns={this.columns()}
                    dataSource={data}
                    pagination={pagination}
                    scroll={{ x: true }}
                    size='small'
                    style={{ marginTop: 18 }}
                />
            </Catcher>
        );
    }
}
