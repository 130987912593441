import { Form } from '@ant-design/compatible';
import { InputNumber } from 'antd';
import _ from 'lodash';
import React, { forwardRef, memo } from 'react';
import { numeralFormatter, numeralParser } from 'utils';

const FormItem = Form.Item;

export const DecoratedInputNumber = memo(
    forwardRef((props, ref) => {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            onPressEnter,
            precision,
            disableFormatter,

            getFieldDecorator,
            disabled,
            rules,
            field,
            min,
            max,

            initialValue,
            onChange,

            // formatter,
            //     parser,
            style,

            placeholder,

            cnStyles,
            phoneNumber,
            readOnly,
            ignoreVoid0
        } = props;

        const defaultValue = [initialValue].find(_.isNumber);
        const numberInitialValue = _.isNumber(defaultValue) || ignoreVoid0 ? defaultValue : void 0;

        const renderElement = (
            <InputNumber
                ref={ref}
                className={cnStyles}
                decimalSeparator=','
                disabled={disabled}
                formatter= {!disableFormatter ? (props.formatter || numeralFormatter) : undefined}
                max={max}
                min={min}
                onChange={onChange}
                onKeyDown={e => {
                    const validKeys = [
                        '0',
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '.',
                        ',',
                        'Tab',
                        'Enter',
                        'Backspace',
                        'Delete'
                    ];

                    const actions = ['Tab', 'Enter', 'Backspace', 'Delete'];

                    const value = String(e.target.value).replace(/[+() ]/g, '');
                    if (phoneNumber) {
                        if (
                            (validKeys.includes(e.key) && value.length < 14) ||
                            actions.includes(e.key)
                        ) {
                            return e.key === 'Enter' && onPressEnter && onPressEnter();
                        }
                    } else if (validKeys.includes(e.key)) {
                        return e.key === 'Enter' && onPressEnter && onPressEnter();
                    }

                    return e.preventDefault();
                }}
                parser={props.parser || numeralParser}
                precision={precision}
                readOnly={readOnly}
                placeholder={placeholder}
                // TODO: move to onChange
                style={style}
            />
        );

        let element = null;
        if (getFieldDecorator) {
            element = getFieldDecorator(field, {
                ...(initialValue || ignoreVoid0 ? { initialValue: numberInitialValue } : { initialValue: void 0 }),
                rules
            })(renderElement);
        } else {
            element = renderElement;
        }

        return formItem ? (
            <FormItem className={className} colon={colon} hasFeedback={hasFeedback} label={label} {...formItemLayout}>
                {element}
            </FormItem>
        ) : (
            element
        );
    })
);
