/* eslint-disable max-classes-per-file */
import { Switch } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

class ServiceElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked,
            data: props.data
        };
    }

    render() {
        const { data } = this.state;
        const { isMobile, checked, detailsList } = this.props;
        const disabled = data.agreement == 'REJECTED' || data.agreement == 'AGREED';

        return isMobile ? (
            <div
                className={`${Styles.serviceElement} ${disabled || !checked ? Styles.disabledRow : null}`}
                style={data.isCritical ? { backgroundColor: 'rgb(250,175,175)' } : null}
            >
                <div style={{ width: '6%', fontSize: '18px' }}>{this.props.num}</div>
                <div style={{ width: '65%', padding: '0 4px' }}>
                    <p style={{ padding: '4px 0' }}>{data.serviceName}</p>
                    {data.comment ? (
                        <p style={{ fontStyle: 'italic', padding: '4px 0' }}>
                            {data.comment.problems
                                ? `${data.comment.problems.map(problem => ` ${problem.toLowerCase()}`)}`
                                : ''}
                        </p>
                    ) : null}
                </div>
                <div style={{ width: '15%', fontSize: '16px' }}>{data.measureUnit}</div>
                <div style={{ width: '15%', fontSize: '16px' }}>
                    {Number(data.count)
                        .toFixed(1)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div style={{ width: '15%' }}>
                    <div style={{ width: '100%', padding: '4px 0', whiteSpace: 'nowrap' }}>
                        {Number(data.sum)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </div>
                    <div style={{ width: '100%', padding: '4px 0' }}>
                        <Switch
                            checked={disabled ? data.agreement == 'AGREED' : checked}
                            disabled={disabled}
                            onClick={value => {
                                this.props.onSwitchService(this.props.num - 1, value);
                            }}
                        />
                    </div>
                </div>
            </div>
        ) : (
            <div
                className={`${Styles.serviceElement} ${disabled || !checked ? Styles.disabledRow : null}`}
                style={data.isCritical ? { backgroundColor: 'rgb(250,175,175)' } : null}
            >
                <div className={Styles.rowKey}>
                    <span>{this.props.num}</span>
                </div>
                <div className={Styles.rowName}>
                    <span>{data.serviceName}</span>
                </div>
                {detailsList.find(({ brandEnabled }) => brandEnabled) && (
                    <div className={data.brandEnabled && data.codeEnabled ? Styles.rowBrandSeparate : Styles.rowBrand}>
                        <span>{data.brandName}</span>
                    </div>
                )}
                {detailsList.find(({ codeEnabled }) => codeEnabled) && (
                    <div className={data.brandEnabled && data.codeEnabled ? Styles.rowCodeSeparate : Styles.rowCode}>
                        <span>{data.oap_code}</span>
                    </div>
                )}
                {data.comment && (
                    <div className={Styles.rowComment}>
                        <span>
                            {data.comment && data.comment.problems
                                ? `${data.comment.problems.map(problem => ` ${problem.toLowerCase()}`)}`
                                : null}
                        </span>
                    </div>
                )}
                <div className={Styles.rowPrice}>
                    <span>
                        {Number(data.price)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </span>
                </div>
                <div className={Styles.rowDiscount}>
                    <span>{Number(data.rowDiscount) || 0} %</span>
                </div>
                <div className={Styles.rowDiscount}>
                    <span>{data.measureUnit} </span>
                </div>
                <div className={Styles.rowCount}>
                    <span>
                        {Number(data.count)
                            .toFixed(1)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                        <FormattedMessage id='add_order_form.hours_shortcut' />
                    </span>
                </div>
                <div className={Styles.rowSum}>
                    <span>
                        {Number(data.sum)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </span>
                </div>
                <div className={Styles.rowSwitch}>
                    <Switch
                        checked={disabled ? data.agreement == 'AGREED' : checked}
                        disabled={disabled}
                        onClick={value => {
                            this.props.onSwitchService(this.props.num - 1, value);
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default ServiceElement;
