/*
Analytics is used to create report about different types of expenses.
This page has functionality to create, edit or delete them.
For example: We have a "Computers and other stuff like that" analytics, and want a report about how much money it costs for business per year,
all we have to do is to calculate all expenses which were marked with this analytics.

Release date: 28.01.2021;
Author: Anatolii Kotvytskyi;
*/

import { Button, Radio, Tabs } from 'antd';
import { Layout } from 'commons';
import { AccountsContainer, EntriesDirectoryContainer } from 'containers';
import { updateAnalytics } from 'core/forms/reportAnalyticsForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import {
    deleteReportAnalytics,
    fetchReportAnalytics,
    resetAllReportAnalytics
} from 'core/reports/reportAnalytics/duck';
import { get } from 'lodash';
import { ConfirmModal, ReportAnalyticsModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AnalyticsDropdown from './AnalyticsDropdown';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    analytics: state.reportAnalytics.analytics,
    modal: state.modals.modal,
    modalProps: state.modals.modalProps
});

const mapDispatchToProps = {
    fetchReportAnalytics,
    deleteReportAnalytics,
    resetAllReportAnalytics,
    updateAnalytics,

    setModal,
    resetModal
};

const { TabPane } = Tabs;

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class ReportAnalyticsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            treeDataQuery: undefined,
            treeData: undefined,
            visibleLevel: 1
        };

        this.openAnalyticsModal = this.openAnalyticsModal.bind(this);
        this._onDeleteAnalytics = this._onDeleteAnalytics.bind(this);
        this.onOpenConfirm = this.onOpenConfirm.bind(this);
        this._onResettingAllAnalyticsConfirmed = this._onResettingAllAnalyticsConfirmed.bind(this);
        this.onResettingAllAnalyticsCanceled = this.onResettingAllAnalyticsCanceled.bind(this);
        this.onUpdateAnalytics = this.onUpdateAnalytics.bind(this);
    }

    componentDidMount() {
        this.props.fetchReportAnalytics();
        this.getAccountsList();
    }

    // buildTree = (data, parentId = null) => {
    //     const tree = [];

    //     data.forEach(item => {
    //         if (item.parentId === parentId) {
    //             const childGroups = this.buildTree(data, item.id);
    //             if (childGroups.length) {
    //                 item.childGroups = childGroups;
    //             }
    //             tree.push(item);
    //         }
    //     });

    //     return tree;
    // };

    getAccountsList = async query => {
        const response = await fetchAPI('GET', '/general_ledger/accounts', { query }, null, {
            handleErrorInternally: true
        });
        this.setState({ accounts: response, treeDataQuery: response });
    };

    openAnalyticsModal(mode, initialTab, analyticsEntity) {
        this.props.setModal(MODALS.REPORT_ANALYTICS, { mode, initialTab, analyticsEntity });
    }

    /**
     * Very danger zone, be carefull. If you will not provide correct analytics Id
     * you will receive error, if you want to reset all analytics(remove all) use anothe action
     * @param {int} analyticsId Analytics or analytics catalog Id
     */
    _onDeleteAnalytics(analyticsId) {
        const { deleteReportAnalytics } = this.props;

        analyticsId && deleteReportAnalytics(analyticsId); // Delete specific analytics
    }

    onOpenConfirm(e) {
        this.props.setModal(MODALS.CONFIRM, {});
    }

    _onResettingAllAnalyticsConfirmed() {
        this.props.resetAllReportAnalytics({ areYouSureToDeleteAll: true });
        this.props.resetModal();
    }

    onResettingAllAnalyticsCanceled() {
        this.props.resetModal();
    }

    onUpdateAnalytics({ analyticsId, newAnalyticsEntity }) {
        this.props.updateAnalytics({ analyticsId, newAnalyticsEntity });
    }

    handleRadioChange = e => {
        const { value } = e.target;
        this.setState({ visibleLevel: value });
    };

    render() {
        const {
            analytics,
            modal,
            intl: { formatMessage },
            user
        } = this.props;

        const { treeDataQuery, accounts, treeData, visibleLevel } = this.state;

        return (
            <Layout
                title={
                    <div>
                        <div>
                            <FormattedMessage id='navigation.accounts_entries_long_title' />
                        </div>
                    </div>
                }
            >
                <div>
                    <Tabs tabPosition='top'>
                        {isGrantAccessed(user, grants.SETTINGS_INVOICES_AND_ANALYTICS_INVOICES) && (
                            <TabPane key='1' tab={<FormattedMessage id='accounts_tab.tab' />}>
                                <AccountsContainer
                                    accounts={accounts}
                                    getAccountsList={this.getAccountsList}
                                    treeData={treeData}
                                    treeDataQuery={treeDataQuery}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.SETTINGS_INVOICES_AND_ANALYTICS_ANALYTICS) && (
                            <TabPane key='2' tab={<FormattedMessage id='navigation.report_analytics' />}>
                                <div className={Styles.buttonGroup}>
                                    <div className={Styles.radioGroup}>
                                        <Radio.Group onChange={this.handleRadioChange} value={this.state.visibleLevel}>
                                            <Radio.Button value={1}>1</Radio.Button>
                                            <Radio.Button value={2}>2</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <Button disabled={!isGrantAccessed(user, grants.SETTINGS_INVOICES_AND_ANALYTICS_ANALYTICS, accesses.ROWO)} onClick={this.onOpenConfirm} type='primary'>
                                        <FormattedMessage id='report_analytics_page.reset_all' />
                                    </Button>
                                    <Button
                                        onClick={() => this.openAnalyticsModal() /* Call with defaults */}
                                        type='primary'
                                        disabled={!isGrantAccessed(user, grants.SETTINGS_INVOICES_AND_ANALYTICS_ANALYTICS, accesses.ROWO)}
                                    >
                                        <FormattedMessage id='report_analytics_page.add' />
                                    </Button>
                                </div>
                                <AnalyticsDropdown
                                    analytics={analytics}
                                    onDeleteAnalytics={this._onDeleteAnalytics}
                                    onUpdateAnalytics={this.onUpdateAnalytics}
                                    disabled={!isGrantAccessed(user, grants.SETTINGS_INVOICES_AND_ANALYTICS_ANALYTICS, accesses.ROWO)}
                                    openAnalyticsModal={this.openAnalyticsModal}
                                    visibleLevel={visibleLevel}
                                />

                                <ConfirmModal
                                    modalContent={formatMessage({
                                        id: 'report_analytics_page.confirm_dialog_content'
                                    })}
                                    onCancel={this.onResettingAllAnalyticsCanceled}
                                    onOk={this._onResettingAllAnalyticsConfirmed}
                                    title={formatMessage({ id: 'report_analytics_page.confirm_dialog' })}
                                    visible={modal}
                                />

                                <ReportAnalyticsModal visible={modal} />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.SETTINGS_INVOICES_AND_ANALYTICS_ACCOUNTING_OPERATIONS) && (
                            <TabPane
                                key='3'
                                disabled={!get(accounts, 'length')}
                                tab={<FormattedMessage id='entries_tab.title' />}
                            >
                                <EntriesDirectoryContainer
                                    accounts={accounts}
                                    analytics={analytics}
                                    treeData={treeData}
                                    treeDataQuery={treeDataQuery}
                                    user={user}
                                />
                            </TabPane>
                        )}
                    </Tabs>
                </div>
            </Layout>
        );
    }
}
