import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class UserAgreementPage extends Component {
    render() {
        return (
            <Layout title={<FormattedMessage id='user_agreement' />}>
                <div className={Styles.license}>
                    <h1>Угода Користувача</h1>
                    <h2>1. Умови використання Сайтів</h2>
                    <ul>
                        <li>
                            <b>1.1.</b> Ця Угода Користувача (надалі - Угода) є публічною офертою щодо умов користування 
                            проєктом Карбук, розміщеним на веб-сайтах cb24.eu, carbook.ua, carbook.pro та 
                            їхніх піддоменах (надалі - Сайти), представленим Адміністрацією Сайтів та 
                            фізичною особою або представником юридичної особи (надалі - Користувач), та регулює
                             умови надання Користувачем інформації для розміщення на Сайтах.
                        </li>

                        <li>
                            <b>1.2.</b> Користувачем Сайтів вважається будь-яка фізична особа, яка 
                            коли-небудь отримала доступ до Сайтів та досягла віку, 
                            допустимого для прийняття цієї Угоди.

                        </li>

                        <li>
                            <b>1.3.</b> Користувач зобов'язаний повністю ознайомитися з цією Угодою до 
                            моменту реєстрації на Сайтах. Реєстрація Користувача на Сайтах означає повне 
                            та беззастережне прийняття Користувачем цієї Угоди. У разі незгоди з умовами
                            Угоди використання Сайтів Користувачем повинно бути негайно припинено.
                        </li>

                        <li>
                            <b>1.4.</b> Ця Угода може бути змінена та/або доповнена Адміністрацією Сайтів в 
                            односторонньому порядку без спеціального повідомлення. Ці Правила є 
                            відкритим та загальнодоступним документом.
                        </li>

                        <li>
                            <b>1.5.</b> Угода передбачає взаємні права та обов'язки Користувача і 
                            Адміністрації Сайтів.
                        </li>
                    </ul>
                    <h2>2. Порядок використання Сайтів</h2>
                    <ul>
                        <li>
                            <b>2.1.</b> Заборонено змінювати матеріали Сайтів, розповсюджувати їх 
                            для громадських чи комерційних цілей. Будь-яке використання інформації 
                            на інших сайтах чи в комп'ютерних мережах заборонено.
                        </li>

                        <li>
                            <b>2.2.</b> Під час реєстрації на Сайтах Користувач згоден надавати достовірну 
                            та точну інформацію про себе та свої контактні дані.
                        </li>

                        <li>
                            <b>2.3.</b> Під час реєстрації на Сайтах Користувач отримує логін і пароль, 
                            за безпеку яких він несе особисту відповідальність.
                        </li>

                        <li>
                            <b>2.4.</b> Користувач може звертатися до Адміністрації Сайтів з питаннями, претензіями, 
                            побажаннями щодо покращення роботи або з будь-якою іншою інформацією. При 
                            цьому Користувач несе відповідальність за те, що це звернення не є незаконним, 
                            загрожує авторським правам, дискримінації за будь-якими ознаками, містить 
                            образливість чи інакше порушує діюче законодавство України.
                        </li>
                    </ul>
                    <h2>3. Персональна інформація Користувача</h2>
                    <ul>
                        <li>
                            <b>3.1.</b> Адміністрація Сайтів з повагою та відповідальністю ставиться до конфіденційної інформації будь-якої особи, яка стала відвідувачем цих Сайтів. Приймаючи цю Угоду, Користувач надає згоду на збір, обробку та використання певної інформації про Користувача відповідно до положень Закону України "Про захист персональних даних" та Правил Адміністрації Сайтів щодо захисту персональних даних. Крім того, Користувач надає згоду на те, що Адміністрація Сайтів може збирати, використовувати, передавати, обробляти та підтримувати інформацію, пов'язану з обліковим записом Користувача, з метою надання відповідних послуг.
                        </li>

                        <li>
                            <b>3.2.</b> Адміністрація Сайтів зобов'язується збирати лише ту персональну інформацію, яку Користувач надає добровільно у випадку, коли ця інформація потрібна для надання (покращення) послуг Користувачу.
                        </li>

                        <li>
                            <b>3.3.</b> Адміністрація Сайтів збирає як основні персональні дані, такі як ім'я, прізвище, по батькові, адреса та електронна адреса, назва юридичної особи, код ЄДРПОУ, так і вторинні (технічні) дані - файли cookies, інформацію про з'єднання та системну інформацію.
                        </li>

                        <li>
                            <b>3.4.</b> Користувач згоден з тим, що конфіденційність переданих через Інтернет даних не гарантується у випадку, якщо доступ до цих даних був наданий третім особам поза зоною технічних засобів зв'язку, що перебувають під контролем Адміністрації Сайтів. Адміністрація Сайтів не несе відповідальності за збитки, завдані таким доступом.
                        </li>

                        <li>
                            <b>3.5</b> Адміністрація Сайтів може використовувати будь-яку зібрану через Сайти інформацію з метою покращення вмісту інтернет-сайтів, їх вдосконалення, надання інформації Користувачу (за запитами), для маркетингових або дослідницьких цілей, а також для інших цілей, які не суперечать положенням чинного законодавства України.
                        </li>
                    </ul>
                    <h2>4. На Сайтах заборонено:</h2>
                    <ul>
                        <li>
                            <b>4.1.</b>  Заклики до насильницької зміни або повалення конституційного ладу або захоплення державної влади; заклики до зміни адміністративних кордонів чи державного кордону України, порушення порядку, встановленого Конституцією України; заклики до розгромів, підпалів, знищення майна, захоплення будівель або споруд, насильницької виштовхування громадян; заклики до агресії або розпочатку воєнного конфлікту.
                        </li>

                        <li>
                            <b>4.2.</b> Прямі та непрямі образи кого-небудь, зокрема політиків, чиновників, журналістів, користувачів ресурсу, включаючи національну, етнічну, расову або релігійну приналежність, а також шовіністичні висловлювання.
                        </li>

                        <li>
                            <b>4.3.</b> Нецензурні висловлювання, матеріали порнографічного, еротичного або сексуального характеру.
                        </li>

                        <li>
                            <b>4.4.</b> Будь-яку образливу поведінку щодо учасників ресурсу.
                        </li>

                        <li>
                            <b>4.5.</b> Висловлювання, метою яких є намір провокувати гостру реакцію інших учасників ресурсу.
                        </li>

                        <li>
                            <b>4.6.</b> Реклама, комерційні повідомлення, а також повідомлення, які не мають інформаційного навантаження і не стосуються тематики ресурсу, якщо на таку рекламу чи повідомлення не отримано спеціального дозволу від Адміністрації Сайтів.
                        </li>

                        <li>
                            <b>4.7.</b> Будь-які повідомлення та інші дії, заборонені законодавством України.
                        </li>

                        <li>
                            <b>4.8.</b> Видача себе за іншу людину або представника організації і/або спільноти без достатніх на це прав, включаючи співробітників і власників Сайтів, а також введення в оману щодо властивостей та характеристик будь-яких суб'єктів чи об'єктів.
                        </li>

                        <li>
                            <b>4.9.</b> Розміщення матеріалів, доступ до яких Користувач не має права забезпечити за законом або відповідно до будь-яких контрактних відносин, а також матеріалів, які порушують права на патент, торговельну марку, комерційну таємницю, авторські права або інші права власності та/або авторські та суміжні з ними права третьої сторони.
                        </li>

                        <li>
                            <b>4.10.</b> Розміщення несанкціонованої спеціальним чином рекламної інформації, спаму, схем "пірамід", "листів щастя"; матеріалів, що містять комп'ютерні коди, призначені для порушення, знищення або обмеження функціональності будь-якого комп'ютерного або телекомунікаційного обладнання або програм для незаконного доступу, а також серійних номерів до комерційних програмних продуктів, логінів, паролів і інших засобів для незаконного доступу до платних ресурсів в Інтернеті.
                        </li>

                        <li>
                            <b>4.11.</b> Навмисне чи випадкове порушення будь-яких відповідних місцевих, державних або міжнародних нормативно-правових актів.
                        </li>
                    </ul>
                    <h2>5. Обмеження відповідальності Адміністрації Сайтів</h2>
                    <ul>
                        <li>
                            <b>5.1.</b> Адміністрація Сайтів не несе жодної відповідальності за будь-які помилки, друкарські помилки та неточності, які можуть бути виявлені в матеріалах, що містяться на цих Сайтах. Адміністрація Сайтів робить всі необхідні зусилля для забезпечення точності і достовірності надаваної на Сайтах інформації. Вся інформація і матеріали надаються на умовах "як є", без будь-яких гарантій, як явних, так і підсумованих.
                        </li>

                        <li>
                            <b>5.2.</b> Інформація на Сайтах постійно оновлюється і в будь-який момент може стати застарілою. Адміністрація Сайтів не несе відповідальності за отримання застарілої інформації з Сайтів, а також за неможливість Користувача отримати оновлення інформації, що зберігається на Сайтах.
                        </li>

                        <li>
                            <b>5.3.</b> Адміністрація Сайтів не несе жодної відповідальності за висловлювання і думки відвідувачів Сайтів, залишені в якості коментарів чи оглядів. Думка Адміністрації Сайтів може не збігатися з думкою та позицією авторів оглядів і коментарів. У той же час Адміністрація Сайтів вживає всіх можливих заходів, щоб не допустити публікацію повідомлень, які порушують чинне законодавство або норми моралі.
                        </li>

                        <li>
                            <b>5.4.</b> Адміністрація Сайтів не несе відповідальності за можливі незаконні дії Користувача щодо третіх осіб або дій третіх осіб щодо Користувача.
                        </li>

                        <li>
                            <b>5.5.</b> Адміністрація Сайтів не несе відповідальності за висловлювання Користувача, зроблені або опубліковані на Сайті.
                        </li>

                        <li>
                            <b>5.6.</b> Адміністрація Сайтів не несе відповідальності за збитки, втрати або витрати (реальні або потенційні), виниклі в зв'язку з цими Сайтами, їх використанням або неможливістю використовувати їх.
                        </li>

                        <li>
                            <b>5.7.</b> Адміністрація Сайтів не несе відповідальності за втрату можливості доступу Користувача до свого облікового запису на Сайтах.
                        </li>

                        <li>
                            <b>5.8.</b> Адміністрація Сайтів не несе відповідальності за неповну, неточну, некоректну вказівку Користувачем своїх даних при створенні облікового запису Користувача.
                        </li>

                        <li>
                            <b>5.9.</b> У випадку виникнення проблем у використанні Сайтів, незгоди з конкретними розділами Угоди, отримання Користувачем недостовірної інформації від третіх осіб, образливої інформації, або іншої неприпустимої інформації, будь ласка, звертайтеся до Адміністрації Сайтів, щоб Адміністрація Сайтів могла проаналізувати та виправити відповідні недоліки, обмежити та запобігти надходження небажаної інформації на Сайти, а також, за необхідності, обмежити або припинити надання своїх послуг будь-якому Користувачу та клієнту, який свідомо порушує умови Угоди та роботу Сайтів.
                        </li>

                        <li>
                            <b>5.10.</b> З метою вищезазначеного Адміністрація Сайтів залишає за собою право видаляти розміщену на Сайтах інформацію та вживати технічні та юридичні заходи для припинення доступу до Сайтів Користувачів, які, за висновком Адміністрації Сайтів, створюють проблеми у використанні Сайтів іншими Користувачами або Користувачами, які порушують вимоги Угоди.
                        </li>
                    </ul>
                    <h2>6. Порядок дії Угоди</h2>
                    <ul>
                        <li>
                            <b>6.1.</b> Ця Угода є договором. Адміністрація Сайтів залишає за собою право як змінювати цю Угоду, так і вводити нову. Такі зміни набирають чинності з моменту їх розміщення на Сайтах. Використання Користувачем матеріалів Сайтів після зміни Угоди автоматично означає їх прийняття.
                        </li>

                        <li>
                            <b>6.2.</b> Ця Угода набуває чинності при першому відвідуванні Сайтів Користувачем і діє між Користувачем та Компанією протягом всього періоду використання Сайтів Користувачем.
                        </li>

                        <li>
                            <b>6.3.</b> Сайти є об'єктом права інтелектуальної власності Адміністрації Сайтів. Усі виключні майнові авторські права на Сайти належать Адміністрації Сайтів. Використання Сайтів Користувачами можливе виключно в межах Угоди та законодавства України про права інтелектуальної власності.
                        </li>

                        <li>
                            <b>6.4.</b> Всі товарні знаки і назви, на які наводяться посилання в матеріалах цих Сайтів, є власністю відповідних власників.
                        </li>

                        <li>
                            <b>6.5.</b> Користувач погоджується не відтворювати, не повторювати, не копіювати будь-які частини Сайтів, крім випадків, коли такий дозвіл дано Користувачу Адміністрацією Сайтів.
                        </li>

                        <li>
                            <b>6.6.</b> Ця Угода регулюється та тлумачиться відповідно до законодавства України. Питання, які не врегульовані Угодою, підлягають вирішенню відповідно до законодавства України.
                        </li>
                    </ul>
                    <h2>Захист персональних даних</h2>
                    <p>
                        Шановний Користувач, реєструючись на наших Сайтах або використовуючи різні
                        сервіси наших Сайтів, які потребують введення Ваших особистих даних, Ви 
                        надаєте згоду на обробку своїх персональних даних відповідно до Закону України 
                        "Про захист персональних даних", а також узгодженим і затвердженим Правилам. 
                        Адміністрація Сайтів залишає за собою право використовувати цю інформацію в 
                        маркетингових цілях в рамках надання послуг.
                    </p>
                    <h2>
                        Узгоджені і затверджені Правила відповідно до Закону України "Про захист персональних даних"
                    </h2>
                    <p>
                        Користувач, реєструючись на сайтах cb24.eu, carbook.ua, carbook.pro (далі - "Сайти"), в тому числі, створюючи свій обліковий запис та/або реєструючись як користувач якихось сервісів Сайтів, у випадку здійснення відповідних дій під своїм власним і дійсним (а не вигаданим) іменем, надає свою згоду власнику Сайтів на обробку персональних даних Користувача (ПІБ, стать, вік, місце проживання, дата та місце народження, контактні дані у випадку надання такої інформації і за умови, що така інформація є правдивою і дійсною).
                    </p>
                    <p>
                        Користувач надає свою згоду власнику Сайтів на здійснення дій в інформаційній (автоматизованій) системі і/або в картотеках персональних даних, які пов'язані зі збором, реєстрацією, накопиченням, зберіганням, адаптацією, зміною, оновленням, використанням і поширенням (реалізацією, передачею), обезособленням, утилізацією відповідних персональних даних та відомостей про Користувача – фізичну особу. Користувач також надає власнику Сайтів право визначати на свій розсуд порядок використання персональних даних Користувача (включаючи порядок використання персональних даних працівниками власника Сайтів відповідно до їх професійних або службових, або трудових обов'язків), порядок захисту, поширення, порядок доступу до баз даних та порядок передачі права обробки персональних даних іншим суб'єктам відносин, пов'язаних з персональними даними. Користувач надає вказані права власнику Сайтів з метою дотримання вимог чинного законодавства про захист персональних даних.
                    </p>
                </div>
            </Layout>
        );
    }
}
