/* eslint-disable no-template-curly-in-string */
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Popconfirm, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import './styles.less';

const EditableCell = ({
    updateRecord,
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    isDisabledCRUD,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Input
                    disabled={isDisabledCRUD}
                    onChange={e => {
                        const { value } = e.target;
                        updateRecord({ ...record, [dataIndex]: value });
                    }}
                    style={{
                        margin: 0
                    }}
                    value={record[dataIndex]}
                />
            ) : (
                children
            )}
        </td>
    );
};

export const PostsSettings = injectIntl(({ cb24data, fetchData, sessionId, intl, isDisabledCRUD, initialValues }) => {
    const [stations, setStations] = useState([]);

    const [editingKey, setEditingKey] = useState('');
    const [zeroDashboardsPost, setZeroDashboardsPost] = useState(false);

    const isEditing = record => record.id === editingKey;

    const edit = record => {
        setEditingKey(record.id);
    };

    const fetchStations = async () => {
        const res = await fetchAPI('GET', '/businesses/stations', null, null, { handleErrorInternally: true });
        setStations(res);
    };

    const save = async ({ id, name, ignoreDayLoad }) => {
        try {
            if (id && id !== -1) {
                await fetchAPI(
                    'PUT',
                    `/businesses/station/${id}`,
                    null,
                    {
                        name,
                        ignoreDayLoad
                    },
                    { handleErrorInternally: true }
                );
                setEditingKey('');
            } else {
                await fetchAPI(
                    'POST',
                    '/businesses/station',
                    null,
                    {
                        name,
                        ignoreDayLoad
                    },
                    { handleErrorInternally: true }
                );
                setEditingKey('');
            }
            fetchStations();
        } catch (e) {
            fetchStations();
        }
    };

    const updateRecord = record => {
        setStations(
            stations.map(station => {
                if (station.id === record.id) {
                    return record;
                }

                return station;
            })
        );
    };

    const getBusiness = async () => {
        const { zeroDashboardsPost } = await fetchAPI('GET', '/business');
        setZeroDashboardsPost(zeroDashboardsPost);
    };

    const handleCheckboxChange = async () => {
        await fetchAPI('PUT', '/businesses', null, { zeroDashboardsPost: !zeroDashboardsPost });
        getBusiness();
    };

    useEffect(() => {
        getBusiness();
        fetchStations();
    }, []);

    const columns = () => [
        {
            title: (
                <React.Fragment>
                    <Button
                        disabled={isDisabledCRUD}
                        icon={<PlusOutlined style={{ fontSize: 18 }} />}
                        onClick={async () => {
                            if (!stations.length || stations[stations.length - 1].id !== -1) {
                                await setStations([...stations, { id: -1 }]);
                                setEditingKey(-1);
                            }
                        }}
                    />
                </React.Fragment>
            ),

            dataIndex: 'operation',
            width: '5%',
            render: (_, record) => {
                const editable = isEditing(record);

                return (
                    <div className='centredFlex'>
                        {/* <UpSquareOutlined
                            data-qa='btn_up_square_post_settings'
                            disabled
                            style={{ fontSize: 22, marginLeft: 8 }}
                        />
                        <DownSquareOutlined
                            data-qa='btn_down_square_post_settings'
                            disabled
                            style={{ fontSize: 22, marginRight: 8 }}
                        /> */}
                        <Popconfirm
                            disabled={isDisabledCRUD}
                            onConfirm={async () => {
                                await fetchAPI(
                                    'DELETE',
                                    `/businesses/station/${record.id}`,
                                    null,
                                    {
                                        sessionId
                                    },
                                    { handleErrorInternally: true }
                                );
                                fetchStations();
                            }}
                            title={<FormattedMessage id='general_settings_delete_question' />}
                        >
                            <Button
                                disabled={isDisabledCRUD}
                                icon={<DeleteOutlined />}
                                onClick={() => record}
                                style={{ marginRight: 8 }}
                            />
                        </Popconfirm>
                        {editable ? (
                            <Button
                                disabled={isDisabledCRUD}
                                icon={<SaveOutlined />}
                                onClick={() => save(record)}
                                style={{
                                    marginRight: 8
                                }}
                                type='primary'
                            />
                        ) : (
                            <Button
                                disabled={editingKey !== '' || isDisabledCRUD}
                                icon={<EditOutlined />}
                                onClick={() => edit(record)}
                                style={{
                                    marginRight: 8
                                }}
                            />
                        )}
                    </div>
                );
            }
        },
        {
            title: <FormattedMessage id='general_settings.post_number' />,
            dataIndex: 'num',
            width: '20%',
            editable: false
        },
        {
            title: <FormattedMessage id='general_settings.post_name' />,
            dataIndex: 'name',
            width: '40%',
            editable: true
        },
        {
            title: <FormattedMessage id='general_settings.loading' />,
            editable: false,
            render: record => {
                return (
                    <Switch
                        checked={record.ignoreDayLoad}
                        disabled={!isEditing(record) || isDisabledCRUD}
                        onChange={ignoreDayLoad => {
                            updateRecord({ ...record, ignoreDayLoad });
                        }}
                    />
                );
            }
        }
    ];

    const mergedColumns = columns().map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                updateRecord
            })
        };
    });

    return (
        <React.Fragment>
            <Form.Item
                label={<FormattedMessage id='general_settings.post_post_zero' />}
                tooltip={{
                    title: intl.formatMessage({
                        id: 'profile-form.relogin_required'
                    }),
                    icon: <InfoCircleOutlined />
                }}
                valuePropName='checked'
            >
                <Checkbox checked={zeroDashboardsPost} disabled={isDisabledCRUD} onChange={handleCheckboxChange} />
            </Form.Item>
            <div>
                <Table
                    bordered
                    columns={mergedColumns}
                    components={{
                        body: {
                            cell: EditableCell
                        }
                    }}
                    dataSource={stations}
                    rowClassName='editable-row'
                    rowKey='id'
                />
            </div>
        </React.Fragment>
    );
});
