import { CheckCircleFilled, ClockCircleFilled } from '@ant-design/icons';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getCurrency } from 'utils';

/* eslint-disable complexity */
export function columnsConfig({ formatMessage, type }) {
    const documentNumber = {
        title: <FormattedMessage id='storage_document.document' />,
        dataIndex: 'documentNumber',
        key: 'documentNumber',
        width: 'auto'
    };

    const datetimeCol = {
        title: <FormattedMessage id='orders.creation_date' />,
        dataIndex: 'createdDatetime',
        key: 'createdDatetime',
        width: 'auto',
        sorter: (a, b) =>
            dayjs(a.createdDatetime).isAfter(b.createdDatetime)
                ? 1
                : dayjs(b.createdDatetime).isAfter(a.createdDatetime)
                ? -1
                : 0,
        render: (_, document) => (
            <div>
                {document.createdDatetime ? (
                    dayjs(document.createdDatetime).format('DD.MM.YYYY HH:mm')
                ) : (
                    <FormattedMessage id='long_dash' />
                )}
            </div>
        )
    };

    const counterpartyCol = {
        title: <FormattedMessage id='storage_document.counterparty' />,
        key: 'businessSupplier',
        width: 'auto',
        render: (_, document) => (
            <div>
                {document.counterpartBusinessSupplierName || <FormattedMessage id='long_dash' />}
            </div>
        )
    };

    const documentSumCol = {
        title: <FormattedMessage id='orders.sum' />,
        dataIndex: 'sum',
        key: 'sum',
        width: 'auto',
        sorter: (a, b) => Math.abs(a.sellingSum) - Math.abs(b.sellingSum),
        render: (_, document) => (
            <Numeral currency={getCurrency()} mask='0,0.00' nullText='0'>
                {Math.abs(document.sum)}
            </Numeral>
        )
    };

    const documentStatusCol = {
        title: <FormattedMessage id='storage_document.document_status' />,
        dataIndex: 'status',
        key: 'status',
        width: 'auto',
        render: (_, document) => (
            <div>
                {document.status == 'DONE' ? (
                    <React.Fragment>
                        <FormattedMessage id='storage_document.status_confirmed' />{' '}
                        <CheckCircleFilled style={{ color: 'var(--green)' }} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <FormattedMessage id='storage_document.status_created' />{' '}
                        <ClockCircleFilled style={{ color: 'var(--orange)' }} />
                    </React.Fragment>
                )}
            </div>
        )
    };

    return [documentNumber, datetimeCol, counterpartyCol, documentSumCol, documentStatusCol];
}
