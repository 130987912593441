import { MailFilled, PhoneFilled } from '@ant-design/icons';
import { Catcher, Spinner } from 'commons';
import { LoginForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { images, withErrorMessage } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    spinner: state.ui.authFetching
});

@connect(mapStateToProps)
@withErrorMessage()
export default class LoginPage extends Component {
    render() {
        return !this.props.spinner ? (
            <Catcher>
                <section className={Styles.loginPage}>
                    <img alt='logo' className={Styles.logo} src={images.carbookLogo2} />
                    <div className={Styles.loginWrap}>
                        <LoginForm />
                        <div className={Styles.info}>
                            <div>
                                <span className={Styles.title}>
                                    <FormattedMessage id='contacts' />
                                </span>
                                <div className={Styles.contactsWrap}>
                                    <div className={Styles.contacts}>
                                        <a className={Styles.link} href='tel:380504216648'>
                                            <PhoneFilled className={Styles.icon} />
                                            +380 (50) 421 6648
                                        </a>
                                        <a className={Styles.link} href='tel:380503567189'>
                                            <PhoneFilled className={Styles.icon} />
                                            +380 (50) 356 7189
                                        </a>
                                        <a className={Styles.link} href='tel:380953508289'>
                                            <PhoneFilled className={Styles.icon} />
                                            +380 (95) 350 8289
                                        </a>
                                        <a className={Styles.link} href='tel:380503786817'>
                                            <PhoneFilled className={Styles.icon} />
                                            +380 (50) 378 6817
                                        </a>
                                    </div>

                                    <a className={Styles.link} href='mailto:sales@carbook.ua'>
                                        <MailFilled className={Styles.icon} />
                                        sales@carbook.ua
                                    </a>
                                </div>
                            </div>
                            <div>
                                <span className={Styles.title}>
                                    <FormattedMessage id='social_networks' />
                                </span>
                                <div className={Styles.socials}>
                                    <a href='https://t.me/carbook_pro' rel='noreferrer' target='_blank'>
                                        <img alt='logo' className={Styles.social} src={images.telegram} />
                                    </a>
                                    <a href='https://www.instagram.com/carbook.pro' rel='noreferrer' target='_blank'>
                                        <img alt='logo' className={Styles.social} src={images.instagram} />
                                    </a>
                                    <a href='https://www.facebook.com/carbook.soft' rel='noreferrer' target='_blank'>
                                        <img alt='logo' className={Styles.social} src={images.facebook} />
                                    </a>
                                    <a
                                        href='https://www.youtube.com/@carbook-pro/featured'
                                        rel='noreferrer'
                                        target='_blank'
                                    >
                                        <img alt='logo' className={Styles.social} src={images.youtube} />
                                    </a>
                                </div>
                            </div>
                            <div className={Styles.helpWrap}>
                                <span className={Styles.title} style={{ padding: 0, marginRight: 6 }}>
                                    <FormattedMessage id='knowledge_base' /> -{' '}
                                </span>
                                <a
                                    className={Styles.help}
                                    href='https://help.carbook.pro/'
                                    rel='noreferrer'
                                    target='_blank'
                                >
                                    HELP.CARBOOK.PRO
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </Catcher>
        ) : (
            <Spinner spin={this.props.spinner} />
        );
    }
}
