import { Button, DatePicker, Form, Input, InputNumber } from 'antd';
import { onChangeBusinessPackageForm } from 'core/forms/businessPackageForm/duck';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapDispatchToProps = {
    onChangeBusinessPackageForm
};

@connect(null, mapDispatchToProps)
@injectIntl
export class BusinessPackageForm extends Component {
    _submit = values => {
        const { businessPackage, updateBusinessPackage } = this.props;
        const { id: businessPackageId } = businessPackage || {};
        updateBusinessPackage(businessPackageId, values);
    };

    render() {
        const { businessPackage } = this.props;
        const {
            activationDatetime,
            expirationDatetime,
            comment,
            amount,
            /* id: businessPackageId, */
            businessName,
            packageName
        } = businessPackage || {};
        const { formatMessage } = this.props.intl;

        return (
            { businessPackage } && (
                <Form
                    initialValues={{
                        activationDatetime: dayjs(activationDatetime),
                        expirationDatetime: dayjs(expirationDatetime),
                        comment,
                        amount
                    }}
                    layout='vertical'
                    name='businessPackageForm'
                    onFinish={this._submit}
                >
                    <FormattedMessage id='business-package-form.business' />: <b>{businessName}</b>
                    <br />
                    <FormattedMessage id='business-package-form.package' />: <b>{packageName}</b>
                    <br />
                    <Form.Item
                        label={<FormattedMessage id='business-package-form.activation_datetime' />}
                        name='activationDatetime'
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage({
                                    id: 'business-package-form.activation_datetime_error'
                                })
                            }
                        ]}
                    >
                        <DatePicker
                            format='YYYY-MM-DD HH:mm:ss'
                            getPopupContainer={trigger => trigger.parentNode}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='business-package-form.expiration_datetime' />}
                        name='expirationDatetime'
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage({
                                    id: 'business-package-form.expiration_datetime_error'
                                })
                            }
                        ]}
                    >
                        <DatePicker
                            format='YYYY-MM-DD HH:mm:ss'
                            getPopupContainer={trigger => trigger.parentNode}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='business-package-form.comment' />}
                        name='comment'
                        rules={[
                            {
                                max: 2000,
                                message: formatMessage({
                                    id: 'field_should_be_below_2000_chars'
                                })
                            }
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='business-package-form.amount' />}
                        name='amount'
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType='submit'
                            /* onClick={() =>
                                validateFields(
                                    (err, values) =>
                                        !err &&
                                        
                                )
                            } */
                            style={{ width: '100%' }}
                            type='primary'
                        >
                            <FormattedMessage id='business-package-form.edit' />
                        </Button>
                    </Form.Item>
                </Form>
            )
        );
    }
}
