/* eslint-disable no-template-curly-in-string */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, Upload } from 'antd';
import imageCompression from 'browser-image-compression';
import { PhoneInput } from 'components';
import _ from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI, getToken, toBase64 } from 'utils';
import './styles.less';

export const GeneralSettings = forwardRef(
    (
        {
            carbookValues,
            saveBussinessInfo,
            getProfileCarbook,
            sessionId,
            formatMessage,
            user,
            isDisabledCRUD,
            fetchData
        },
        ref
    ) => {
        const [generalSettingsForm] = Form.useForm();
        const [logo, setLogo] = useState([]);
        const [phones, setPhones] = useState([]);
        const [code, setPhoneCode] = useState();
        const [country, setCountry] = useState();
        const [phoneNumber, setPhoneNumber] = useState();

        useEffect(() => {
            generalSettingsForm.setFieldsValue({
                name: _.get(carbookValues, 'name'),
                ownerName: _.get(carbookValues, 'ownerName') || undefined,
                phones: String(_.get(carbookValues, 'phones[0].code') + _.get(carbookValues, 'phones[0].number')),
                email: _.get(carbookValues, 'email') || undefined
            });
            setLogo(_.get(carbookValues, 'logo'));
            setPhoneCode(String(_.get(carbookValues, 'phones[0].code')))
            setCountry(String(_.get(carbookValues, 'phones[0].country')))
            setPhoneNumber(String(_.get(carbookValues, 'phones[0].number')))
        }, [carbookValues, generalSettingsForm, user]);

        const findPhoneInfo = async (code, country, phoneNumber) => {
            setPhoneCode(code);
            setCountry(country);
            setPhoneNumber(phoneNumber);
        };

        const deleteLogo = async () => {
            await fetchAPI('DELETE', '/business/logo', null, null);

            await fetchData();

            setLogo(_.get(carbookValues, 'logo'));
        };

        const confirmLogo = async file => {
            const base64 = await toBase64(file);

            await fetchAPI('PUT', 'business/logo-base64', null, { base64 }, { handleErrorInternally: true });
        };

        const updateLogo = async file => {
            if (_.get(logo, 'original.path')) {
                deleteLogo();
            }
            const url = `${__API_URL__}/business/logo`;
            const formData = new FormData();
            const compressedFile = await imageCompression(file, {
                maxWidthOrHeight: 240,
                maxSizeMB: 2
            });
            formData.append('file', compressedFile);

            await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: getToken()
                },
                body: formData
            });

            confirmLogo(compressedFile);

            setTimeout(async () => {
                await fetchData();
                setLogo(_.get(carbookValues, 'logo'));
            }, 1000);
        };

        const onFinish = values => {
            const phones = {
                number: String(phoneNumber),
                code,
                country
            };

            saveBussinessInfo({
                ...values,
                ownerName: values.ownerName ? values.ownerName : null,
                email: values.email ? values.email : null,
                phones: [phones]
            });
        };

        const url = `${__API_URL__}/business/logo`;

        console.log(code, country, phoneNumber, 'phoneInfo');

        return (
            <div className='general-settings'>
                <Form
                    ref={ref}
                    className='general-settings-form'
                    disabled={isDisabledCRUD}
                    form={generalSettingsForm}
                    labelCol={{
                        span: 8
                    }}
                    name='general-settings'
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={<FormattedMessage id='general_settings.business_name' />}
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'general_settings.enter_your_name'
                                })
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='general_settings.manager' />} name='ownerName'>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='general_settings.phone_number' />}
                        name='phones'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'general_settings.enter_your_number'
                                })
                            }
                            // {
                            //     pattern: /(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/,
                            //     transform: value => String(value),
                            //     message: formatMessage({
                            //         id: 'add_client_form.invalid_phone_format'
                            //     })
                            // }
                        ]}
                    >
                        <PhoneInput
                            findPhoneInfo={findPhoneInfo}
                            onChange={(value, options) => {
                                console.log(options, 'options');
                                setPhones(value);
                            }}
                            placeholder={formatMessage({
                                id: 'nRegNew'
                            })}
                            style={{ width: '100%' }}
                            value={phones}
                            // formatter={value => phoneNumberFormatter(value, user.country)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id='general_settings.e_mail' />}
                        name='email'
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 14,
                            span: 12
                        }}
                    >
                        <Button htmlType='submit' type='primary'>
                            <FormattedMessage id='general_settings.submit' />
                        </Button>
                    </Form.Item>
                </Form>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Upload
                        action={updateLogo}
                        beforeUpload={file => {
                            const isPNG = file.type === 'image/png';
                            const isJPG = file.type === 'image/jpeg';
                            if (!isPNG && !isJPG) {
                                notification.error({
                                    message: `${file.name} ${formatMessage({
                                        id: 'photo_modal.is_not_an_image'
                                    })}!`
                                });
                            }

                            return isPNG || isJPG;
                        }}
                        className='avatar-uploader'
                        fileList={
                            _.get(logo, 'original.path')
                                ? [
                                      {
                                          uid: '-1',
                                          name: 'logo.png',
                                          status: 'done',
                                          url: `//s1.car-book.com${_.get(logo, 'original.path')}`
                                      }
                                  ]
                                : []
                        }
                        method={url}
                        onRemove={deleteLogo}
                        listType='picture-card'
                        // showUploadList={false}
                        name='avatar'
                    >
                        {!_.get(logo, 'original.path') && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>
                                    <FormattedMessage id='general_settings.upload_logo' />
                                </div>
                            </div>
                        )}
                    </Upload>
                    <span
                        style={{
                            opacity: 0.5,
                            fontSize: 16
                        }}
                    >
                        <FormattedMessage id='general_settings.recomendation_size' />
                    </span>
                </div>
            </div>
        );
    }
);
