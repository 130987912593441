import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Switch, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';

export function columnsConfig(props) {
    const { fetchCashboxes } = props;

    const numberCol = {
        title: '№',
        dataIndex: 'id',
        key: 'numberCol'
    };
    const nameCol = {
        title: <FormattedMessage id='cash-table.name' />,
        dataIndex: 'name',
        key: 'nameCol'
    };

    const typeCol = {
        title: <FormattedMessage id='cash-table.type' />,
        dataIndex: 'type',
        key: 'typeCol',
        render: type => <FormattedMessage id={`cash-creation-form.type-${type}`} />
    };

    const requisitesCol = {
        title: <FormattedMessage id='navigation.requisites' />,
        dataIndex: 'businessRequisiteName',
        key: 'businessRequisiteName'
    };

    const fiscalNumberCol = {
        title: <FormattedMessage id='cash-table.fiscalNumber' />,
        dataIndex: 'fiscalNumber',
        key: 'fiscalNumberCol'
    };

    const infoCol = {
        title: <FormattedMessage id='cash-table.description' />,
        dataIndex: 'description',
        key: 'infoCol'
    };

    const editCol = {
        key: 'editCol',
        render: row => (
            <Tooltip overlayStyle={{ zIndex: 110 }} placement='topLeft' title={<FormattedMessage id='edit' />}>
                <Button
                    disabled={props.isCRUDForbidden}
                    onClick={() => {
                        if (props.openEdit) {
                            props.openEdit(row);
                            props.setTableRow(row);
                        }
                    }}
                    style={{
                        cursor: 'pointer',
                        padding: '0px 8px',
                        fontSize: 18
                    }}
                >
                    <EditOutlined title={props.formatMessage({ id: 'edit' })} />
                </Button>
            </Tooltip>
        )
    };

    const switchCol = {
        key: 'switch',
        render: row => (
            <Switch
                checked={row.active}
                disabled={props.isCRUDForbidden}
                onChange={async () => {
                    if (row.active) {
                        Modal.confirm({
                            title: `${props.formatMessage({
                                id: 'cash-table.sure_to_turn_off'
                            })} ?`,
                            onOk: async () => {
                                await fetchAPI(
                                    'PUT',
                                    `cash_boxes/${row.id}`,
                                    undefined,
                                    {
                                        active: !row.active
                                    },
                                    {
                                        handleErrorInternally: true
                                    }
                                );
                                await setTimeout(() => fetchCashboxes(), 100);
                            },
                            okType: 'primary',
                            onCancel() {}
                        });
                    } else {
                        await fetchAPI(
                            'PUT',
                            `cash_boxes/${row.id}`,
                            undefined,
                            {
                                active: !row.active
                            },
                            {
                                handleErrorInternally: true
                            }
                        );
                        await setTimeout(() => fetchCashboxes(), 100);
                    }
                }}
            />
        )
    };

    const deleteCol = {
        dataIndex: 'delete',
        key: 'deleteCol',
        render: (key, { id, removable }) =>
            removable ? (
                <Popconfirm
                    disabled={props.isCRUDForbidden}
                    onConfirm={() => {
                        if (!props.isCRUDForbidden) {
                            props.deleteCashbox(id);
                        }
                    }}
                    title={`${props.formatMessage({ id: 'delete' })} ?`}
                >
                    <Button
                        disabled={props.isCRUDForbidden}
                        style={
                            props.isCRUDForbidden
                                ? {
                                      marginLeft: 4,
                                      cursor: 'pointer',
                                      padding: '0px 8px',
                                      fontSize: 18,
                                      pointerEvents: 'none'
                                  }
                                : {
                                      marginLeft: 4,
                                      cursor: 'pointer',
                                      padding: '0px 8px',
                                      fontSize: 18
                                  }
                        }
                    >
                        <DeleteOutlined title={props.formatMessage({ id: 'delete' })} />
                    </Button>
                </Popconfirm>
            ) : (
                <Tooltip
                    overlayStyle={{ zIndex: 110 }}
                    placement='topLeft'
                    title={<FormattedMessage id='cash-table_icon.unremovable' />}
                >
                    <QuestionCircleOutlined />
                </Tooltip>
            )
    };

    const mobileNameCol = {
        title: <FormattedMessage id='cash-table.name' />,
        key: 'nameCol',
        render: row => (
            <React.Fragment>
                <p style={{ color: 'var(--text)' }}>{row.name}</p>
                <p style={{ color: 'var(--text2)' }}>{row.description}</p>
            </React.Fragment>
        )
    };

    return props.isMobile
        ? [mobileNameCol, typeCol, fiscalNumberCol]
        : [numberCol, nameCol, typeCol, requisitesCol, fiscalNumberCol, infoCol, editCol, switchCol, deleteCol];
}
