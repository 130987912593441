import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { EditableContext } from './context';

const ActionsIcons = styled.div`
    display: flex;
    align-items: center;
`;

// export function columnsConfig(formatMessage, deletePriceGroup) {
export function columnsConfig(
    isCRUDForbidden,
    formatMessage,
    editingKey,
    getEditingState,
    startEditing,
    cancelEditing,
    save,
    handleDelete
) {
    const number = {
        title: <FormattedMessage id='storage.price_group' />,
        dataIndex: 'number',
        width: '25%'
    };

    const multiplier = {
        title: <FormattedMessage id='storage.markup' />,
        dataIndex: 'multiplier',
        width: '50%',
        editable: true,
        render: multiplier => <span>{multiplier}</span>
    };

    const operations = {
        title: '',
        dataIndex: 'operation',
        width: '25%',
        render: (text, record) => {
            const editable = getEditingState(record);

            return (
                !isCRUDForbidden && (
                    <ActionsIcons>
                        {editable ? (
                            <span>
                                <EditableContext.Consumer>
                                    {form => (
                                        <a
                                            href='javascript:;'
                                            onClick={() => save(form, record.number)}
                                            style={{ marginRight: 8 }}
                                        >
                                            {formatMessage({ id: 'save' })}
                                        </a>
                                    )}
                                </EditableContext.Consumer>
                                <Popconfirm
                                    onConfirm={() => cancelEditing(record.key)}
                                    title={`${formatMessage({ id: 'cancel' })} ?`}
                                >
                                    <a>{formatMessage({ id: 'cancel' })}</a>
                                </Popconfirm>
                            </span>
                        ) : (
                            <Button
                                disabled={editingKey !== ''}
                                onClick={() => startEditing(record.number)}
                                style={{
                                    cursor: 'pointer',
                                    padding: '0px 8px',
                                    fontSize: 18
                                }}
                            >
                                <EditOutlined title={formatMessage({ id: 'edit' })} />
                            </Button>
                        )}
                        <Popconfirm
                            onConfirm={() => handleDelete(record.number)}
                            title={`${formatMessage({ id: 'delete' })} ?`}
                        >
                            <Button
                                style={{
                                    marginLeft: 4,
                                    cursor: 'pointer',
                                    padding: '0px 8px',
                                    fontSize: 18
                                }}
                            >
                                <DeleteOutlined title={formatMessage({ id: 'delete' })} />
                            </Button>
                        </Popconfirm>
                    </ActionsIcons>
                )
            );
        }
    };

    return [number, multiplier, operations];
}
