// Core
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, ColorPicker, Input, Modal, Popconfirm, Select, Switch, Tooltip } from 'antd';
import { DraggableTable } from 'components';
import { _ } from 'core-js';
import { resetModal, setModal } from 'core/modals/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Panel } = Collapse;
const { Option } = Select;

const mapStateToProps = state => ({
    modal: state.modals.modal,
    user: state.auth
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class SubStatusesTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: undefined,
            openStatusModal: false,
            color: '#9F15B7'
        };

        this.columns = () => [
            {
                key: 'rowNumber',
                width: '1%',
                align: 'center',
                render: (data, row, index) => {
                    return <div>{index + 1}.</div>;
                }
            },
            {
                key: 'edit',
                width: '3%',
                align: 'center',
                render: (data, row) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Popconfirm
                                disabled={this.props.disabled}
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        '/crm/substatus',
                                        null,
                                        { id: Number(row.id) },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setTimeout(() => this.props.fetchData(), 100);
                                }}
                                title={`${props.intl.formatMessage({ id: 'delete' })}?`}
                            >
                                <Button 
                                disabled={this.props.disabled} icon={<DeleteOutlined />} />
                            </Popconfirm>
                            <Button
                            disabled={this.props.disabled}
                                icon={<EditOutlined />}
                                onClick={() =>
                                    this.setState({
                                        openStatusModal: true,
                                        edit: row,
                                        name: row.name,
                                        type: row.type,
                                        shortCut: row.shortCut,
                                        color: row.color,
                                        enabled: row.enabled,
                                        parentId: row.parentId
                                    })
                                }
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='barcode.name' />,
                key: 'name',
                dataIndex: 'name',
                width: '30%'
            },
            {
                title: <FormattedMessage id='task_page.short_name' />,
                key: 'shortCut',
                dataIndex: 'shortCut',
                width: '10%'
            },
            {
                title: <FormattedMessage id='cash-table.type' />,
                key: 'type',
                dataIndex: 'type',
                width: '10%'
            },
            {
                title: <FormattedMessage id='vehicle_page.color' />,
                key: 'color',
                width: '10%',
                render: row => {
                    return (
                        <div
                            style={{
                                width: '100%',
                                height: 30,
                                backgroundColor: row.color
                            }}
                        >
                            {' '}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='interactions.on_off' />,
                key: 'enabled',
                width: '10%',
                render: row => {
                    return (
                        <div>
                            <Tooltip title={<FormattedMessage id='edit_vehicle.display' />}>
                                <Switch
                            disabled={this.props.disabled}
                                    checked={row.enabled}
                                    onChange={async () => {
                                        await fetchAPI('PUT', '/crm/substatus', undefined, {
                                            id: row.id,
                                            enabled: !row.enabled
                                        });
                                        await setTimeout(() => this.props.fetchData(), 300);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
    }

    handleOK = async () => {
        const { edit } = this.state;
        if (!edit) {
            this.addStatus();
        } else {
            this.editStatus();
        }
    };

    addStatus = async () => {
        const { name, type, shortCut, color, parentId } = this.state;
        await fetchAPI(
            'POST',
            '/crm/substatus',
            null,
            {
                name,
                type,
                shortCut,
                color,
                parentId,
                enabled: true
            },
            {
                handleErrorInternally: true
            }
        );

        await this.handleClose();

        await setTimeout(() => this.props.fetchData(), 200);
    };

    editStatus = async () => {
        const { name, type, shortCut, color, edit, enabled, parentId } = this.state;
        await fetchAPI(
            'PUT',
            '/crm/substatus',
            null,
            {
                id: _.get(edit, 'id', undefined),
                name,
                type,
                shortCut,
                color,
                parentId,
                enabled
            },
            {
                handleErrorInternally: true
            }
        );

        await this.handleClose();

        await setTimeout(() => this.props.fetchData(), 200);
    };

    handleClose = () => {
        this.setState({
            openStatusModal: false,
            edit: undefined,
            name: '',
            shortCut: '',
            type: '',
            color: '#9F15B7',
            parentId: undefined,
            enabled: true
        });
    };

    render() {
        const { user, data, allStatuses, disabled } = this.props;
        const { openStatusModal, name, type, shortCut, color, edit, parentId } = this.state;

        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginBottom: 8
                    }}
                >
                    <div>
                        <div>
                            <Button
                                disabled={disabled}
                                icon={<PlusOutlined />}
                                onClick={async () =>
                                    this.setState({
                                        openStatusModal: true
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>

                <DraggableTable
                    bordered
                    columns={this.columns()}
                    dataSource={data}
                    onDragEnd={async (fromIndex, toIndex) => {
                        await fetchAPI(
                            'PUT',
                            '/crm/swap_substatus',
                            undefined,
                            {
                                order1: Number(data[fromIndex].order),
                                order2: Number(data[toIndex].order)
                            },
                            { handleErrorInternally: true }
                        );
                        await this.props.fetchData();
                    }}
                    pagination={false}
                    size='small'
                />
                <Modal
                    onCancel={() => this.handleClose()}
                    onOk={() => {
                        this.handleOK();
                    }}
                    open={openStatusModal}
                    title={
                        !edit ? (
                            <FormattedMessage id='interactions.add_status' />
                        ) : (
                            <FormattedMessage id='interactions.edit_status' />
                        )
                    }
                    width='35%'
                >
                    <div className={Styles.statusesModal}>
                        <div className={Styles.statusesDivModal}>
                            <div>
                                <div>
                                    <FormattedMessage id='description' />
                                </div>
                                <Input
                                    onChange={event => {
                                        this.setState({
                                            name: event.target.value
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'interactions.enter_status'
                                    })}
                                    value={name}
                                />
                            </div>
                            <div>
                                <div>
                                    <FormattedMessage id='task_page.short_name' />
                                </div>
                                <Input
                                    onChange={event => {
                                        this.setState({
                                            shortCut: event.target.value
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'interactions.enter_shortcut'
                                    })}
                                    value={shortCut}
                                />
                            </div>
                        </div>
                        <div className={Styles.statusesDivModal}>
                            <div>
                                <div>
                                    <FormattedMessage id='locations.type' />
                                </div>
                                <Input
                                    onChange={event => {
                                        this.setState({
                                            type: event.target.value
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'interactions.enter_type_name'
                                    })}
                                    value={type}
                                />
                            </div>
                            <div>
                                <div>
                                    <FormattedMessage id='vehicle_page.color' />
                                </div>
                                <ColorPicker
                                    onChange={color => {
                                        this.setState({
                                            color: color.toHexString()
                                        });
                                    }}
                                    showText
                                    value={color}
                                />
                            </div>
                        </div>
                        <div className={Styles.statusesDivModal}>
                            <div>
                                <FormattedMessage id='interactions.parent' />
                            </div>
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    this.setState({ parentId: value });
                                }}
                                optionFilterProp='children'
                                placeholder={this.props.intl.formatMessage({
                                    id: 'search'
                                })}
                                showSearch
                                style={{
                                    width: '100%'
                                }}
                                value={parentId}
                            >
                                {allStatuses.map(({ id, name }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
