/**
 * Types of operations that debts zeroing modal has to perform.
 */
const OPERATION_TYPES = Object.freeze({
    RECEIVABLES: 'RECEIVABLES',
    PAYABLES: 'PAYABLES'
});

/** Order statuses which can be supplied on backend */
const ORDER_TYPES = {
    REDUNDANT: 'redundant',
    APPROVED: 'approve',
    CANCELED: 'cancel',
    CREATED: 'not_complete',
    IN_PROGRESS: 'progress',
    RESERVED: 'reserve',
    REQUIRED: 'required',
    SUCCESS: 'success',
    INVITE: 'invite'
};

/**
 * Storage document types(can be used to filter documents)
 */
const STORAGE_DOCUMENT_TYPES = {
    SUPPLIER: 'SUPPLIER',
    CLIENT: 'CLIENT',
    INVENTORY: 'INVENTORY',
    OWN_CONSUMPTION: 'OWN_CONSUMPTION',
    TRANSFER: 'TRANSFER',
    SERVICE: 'SERVICE'
};

/**
 * Storage document context is a place where documents were taken.
 * \
 * Developer says: context = environment = середовище документа
 */
const STORAGE_DOCUMENT_CONTEXTS = {
    STOCK: 'STOCK',
    ORDER: 'ORDER'
};

/**
 * Each storage document has its operation code(SRV, ORD, INC, OUT etc.)
 * Those constants can be used to filter store docs.
 */
const STORAGE_DOCUMENT_OPERATION_CODES = {
    INC: 'INC',
    CRT: 'CRT',
    STP: 'STP',
    SRV: 'SRV',
    OUT: 'OUT',
    SRT: 'SRT',
    CST: 'CST',
    STM: 'STM',
    TSF: 'TSF',
    RES: 'RES',
    ORD: 'ORD',
    BOR: 'BOR',
    COM: 'COM',
    TOL: 'TOL',
    TOR: 'TOR',
    VRT: 'VRT',
    KPM: 'KPM',
    KPP: 'KPP',
    PRI: 'PRI',
    PRO: 'PRO',
    DSI: 'DSI',
    DSO: 'DSO'
};

const STORAGE_DOCUMENT_STATUSES = {
    NEW: 'NEW',
    DONE: 'DONE'
};

/** Used to represent date for a user */
const DEF_UI_DATE = 'DD.MM.YYYY';

/** Used to represent date and time for a user */
const DEF_UI_DATETIME = 'DD.MM.YYYY HH:mm';

/** Specific format for beck-end date, cast to this when request is sending */
const DEF_BACK_DATE = 'YYYY-MM-DD';

export {
    DEF_BACK_DATE,
    DEF_UI_DATE,
    DEF_UI_DATETIME,
    OPERATION_TYPES,
    ORDER_TYPES,
    STORAGE_DOCUMENT_CONTEXTS,
    STORAGE_DOCUMENT_OPERATION_CODES,
    STORAGE_DOCUMENT_STATUSES,
    STORAGE_DOCUMENT_TYPES
};
