import { Button, Popover } from 'antd';
import { Layout, Spinner } from 'commons';
import { ClientContainer } from 'containers';
import { createOrderForClient, fetchClient, selectClientFetching } from 'core/client/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { get } from 'lodash';
import { AddAggregateModal, VehicleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    clientEntity: state.client.clientEntity,
    clientFetching: selectClientFetching(state)
});

const mapDispatchToProps = {
    fetchClient,
    createOrderForClient,
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleAddAggregateModal: false,
            clientsAggregates: []
        };
    }

    componentDidMount() {
        this.props.fetchClient(this.props.match.params.id);
    }

    getClientsAggregates = async () => {
        const { clientEntity } = this.props;

        await fetchAPI('GET', '/client/aggregates', { clientId: clientEntity.clientId }, null, {
            handleErrorInternally: true
        });
    };

    /**
     * When we want to create a new order for this client
     */
    onCreateOrderForClient = () => {
        const { createOrderForClient, clientEntity, user } = this.props;

        createOrderForClient({
            clientId: clientEntity.clientId,
            managerId: user.id,
            businessRequisiteId: get(user, 'businessRequisitesId')
        });
    };

    /**
     * Open modal to add a new vehicle for current client
     */
    onAddVehicle = () => {
        const { clientEntity } = this.props;

        this.props.setModal(MODALS.VEHICLE, {
            mode: 'ADD',
            clientId: clientEntity.clientId,
            autoRedirect: false,
            onSubmit: () => window.location.reload()
        });
    };

    render() {
        const { clientFetching, clientEntity, match, location, fetchClient, user, isMobile } = this.props;

        const { visibleAddAggregateModal } = this.state;

        const specificTab = location && location.state ? location.state.specificTab : undefined;

        return clientFetching ? (
            <Spinner spin={clientFetching} />
        ) : (
            <Layout
                controls={
                    <div>
                        <span className={Styles.controlButton}>
                            <Popover content={<FormattedMessage id='client_page.hint_create_order_with_client' />}>
                                <Button
                                    disabled={
                                        clientFetching ||
                                        !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT, accesses.ROWO)
                                    }
                                    onClick={this.onCreateOrderForClient}
                                >
                                    <FormattedMessage id='client_page.create_order' />
                                </Button>
                            </Popover>
                        </span>
                        {!isMobile && (
                            <span className={Styles.controlButton}>
                                <Popover content={<FormattedMessage id='vehicle_page.hint_add_vehicle_modal' />}>
                                    <Button
                                        disabled={
                                            clientFetching ||
                                            !isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_CUSTOMERS_LIST_CARS,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={this.onAddVehicle}
                                        type='primary'
                                    >
                                        <FormattedMessage id='vehicle_page.hint_add_vehicle_modal' />
                                    </Button>
                                </Popover>
                            </span>
                        )}
                        <span className={Styles.controlButton}>
                            <Popover content={<FormattedMessage id='vehicle_page.hint_add_vehicle_modal' />}>
                                <Button
                                    disabled={
                                        clientFetching ||
                                        !isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_CUSTOMERS_LIST_AGGREGATES,
                                            accesses.ROWO
                                        )
                                    }
                                    onClick={() => {
                                        this.setState({
                                            visibleAddAggregateModal: true
                                        });
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='clients-page.add_aggregate' />
                                </Button>
                            </Popover>
                        </span>
                    </div>
                }
                title={<FormattedMessage id='client_page.title' />}
            >
                <ClientContainer
                    clientEntity={clientEntity}
                    clientId={match.params.id}
                    fetchClient={fetchClient}
                    onAddVehicle={this.onAddVehicle}
                    specificTab={specificTab}
                />

                <AddAggregateModal
                    clientId={clientEntity.clientId}
                    getClientsAggregates={this.getClientsAggregates}
                    hideModal={() => {
                        this.setState({
                            visibleAddAggregateModal: false
                        });
                    }}
                    visible={visibleAddAggregateModal}
                />
                <VehicleModal />
            </Layout>
        );
    }
}
