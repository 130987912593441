import { CloseOutlined, DollarOutlined, FilterFilled, PercentageOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, Select, Table, Tooltip } from 'antd';
import { Numeral } from 'commons';
import { DateRangePicker } from 'components';
import { CalcSalaryModal, PayOutSalaryModal } from 'containers/StorageFundsContainer/components';
import { _ } from 'core-js';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import styled from 'styled-components';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;

const { Panel } = Collapse;

@injectIntl
export default class StorageFundsEmployeeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            dateRange: [],
            allEmployees: [],
            page: 1,
            totalCount: 0,
            pageSize: 25,
            onlyForCurrentEmployee: true
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='employee_salary_rules.rule_name' />,
                key: 'rule',
                width: '15%',
                render: (data, row) => {
                    return <div>{row.ruleName}</div>;
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '20%',
                render: (data, row) => (
                    <div>{_.get(row, 'labors') ? _.get(row, 'labors').map(({ name }) => `${name}; `) : '-'} </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.labor_class' />,
                key: 'class',
                width: '25%',
                render: (data, row) => <div>{row.class}</div>
            },
            {
                title: <FormattedMessage id='employee_salary_rules.basis_of_calculation' />,
                key: 'mark',
                dataIndex: 'mark',
                width: '15%',
                render: (data, row) => (
                    <div>
                        <FormattedMessage id={`employee_salary_rules.${row.mark}`} />
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.sum_%' />,
                key: 'percent',
                width: '15%',
                render: (data, row) => <div>{`${row.percent}%`}</div>
            },
            {
                title: <FormattedMessage id='order_form_table.discount' />,
                key: 'discount',
                width: '15%',
                render: (data, row) => (
                    <div>{row.discount ? <FormattedMessage id='yes' /> : <FormattedMessage id='no' />}</div>
                )
            },
            {
                title: <FormattedMessage id='employee_salary_rules.salary_table' />,
                key: 'salary',
                dataIndex: 'percentFrom',
                width: '15%',
                render: (data, row) => (
                    <Numeral currency='₴' mask='0,0.00'>
                        {Number(row.salary)}
                    </Numeral>
                )
            }
        ];
    }

    componentDidMount() {
        this.getSalariesInfo();
        this.getAllEmployees();
    }

    getSalariesInfo = async () => {
        const { employee } = this.props;
        const { page, pageSize, orderId, startDate, endDate } = this.state;
        const data = await fetchAPI(
            'GET',
            '/employee/salary/data',
            {
                employeeId: _.get(employee, '[0]'),
                page,
                pageSize,
                orderId,
                startDate,
                endDate
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: data.salaryData,
            count: data.count,
            total: data.total
        });
    };

    getAllEmployees = async () => {
        const employees = await fetchAPI('GET', 'employees', null, null, { handleErrorInternally: true });
        this.setState({
            allEmployees: employees
        });
    };

    calcSalary = async (id, sDate, eDate) => {
        const calculatedSalary = await fetchAPI(
            'POST',
            '/recalculate/employee/salary',
            undefined,
            {
                employeeIds: id,
                startDate: sDate,
                endDate: eDate,
                status: 'ACCRUED'
            },
            { handleErrorInternally: true }
        );
        this.setState({
            calculatedSalary
        });
        this.getSalariesInfo();
    };

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'dateRangeDelivery' || dataIndex === 'dateRangeClosing') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={this.state[dataIndex]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                [dataIndex]: dateRange
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.getSalariesInfo();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        // if (dataIndex === 'filterDate') {
        //     filterComponent = (confirm, clearFilters) => (
        //         <DatePicker
        //             allowClear
        //             format='YYYY-MM-DD '
        //             onChange={date => {
        //                 this.setState({
        //                     [dataIndex]: date
        //                 });
        //             }}
        //             placeholder={this.props.intl.formatMessage({
        //                 id: 'select_date'
        //             })}
        //             style={{
        //                 width: '100%'
        //             }}
        //             value={this.state.dataIndex}
        //         />
        //     );
        // }

        // if (dataIndex === 'filterDocumentType') {
        //     filterComponent = (confirm, clearFilters) => (
        //         <Select
        //             allowClear
        //             getPopupContainer={trigger => trigger.parentNode}
        //             onChange={value => {
        //                 this.setState({
        //                     filterDocumentType: value
        //                 });
        //             }}
        //             // mode='multiple'
        //             placeholder={this.props.intl.formatMessage({
        //                 id: 'storage_journal.choose_type'
        //             })}
        //             style={{ marginBottom: 8, display: 'block', width: 180 }}
        //             value={this.state.filterDocumentType}
        //         >
        //             <Option value='INCOME'>
        //                 <FormattedMessage id='storage.INCOME' />
        //             </Option>
        //             <Option value='EXPENSE'>
        //                 <FormattedMessage id='storage.EXPENSE' />
        //             </Option>
        //         </Select>
        //     );
        // }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.getSalariesInfo();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1 });
        this.getSalariesInfo();
    };

    render() {
        const { employee } = this.props;
        const {
            dataSource,
            page,
            pageSize,
            count,
            orderId,
            total,
            startDate,
            endDate,
            visiblePayOutSalaryModal,
            visibleCalcSalaryModal,
            calculatedSalary,
            allEmployees,
            onlyForCurrentEmployee
        } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(count / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.getSalariesInfo();
            }
        };

        return (
            <div>
                <DataRow>
                    <DataWrapper>
                        <span
                            style={{
                                marginRight: 4
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.total_accrued' />:
                        </span>
                        <Numeral currency='₴' mask='0,0.00'>
                            {Number(_.get(total, 'accrued', 0))}
                        </Numeral>
                    </DataWrapper>
                    <DataWrapper>
                        <span
                            style={{
                                marginRight: 4
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.total_paid' />:
                        </span>
                        <Numeral currency='₴' mask='0,0.00'>
                            {Number(_.get(total, 'closed', 0))}
                        </Numeral>
                    </DataWrapper>
                </DataRow>

                <div
                    style={{
                        marginBottom: 8,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <div className={Styles.searchEmployeeTitle}>
                            <FormattedMessage id='salary-report-form_search_by_order' />
                        </div>
                        <Input
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            onChange={event => {
                                this.setState({
                                    orderId: event.target.value
                                });
                            }}
                            // mode='multiple'
                            style={{
                            maxWidth: '60%'
                        }}
                            value={orderId}
                        />
                        <Button
                            icon={<SearchOutlined />}
                            onClick={async () => {
                                const filteredData = await fetchAPI(
                                    'GET',
                                    '/employee/salary/data',
                                    {
                                        employeeId: _.get(employee, '[0]'),
                                        page,
                                        pageSize,
                                        orderId
                                    },
                                    null,
                                    { handleErrorInternally: true }
                                );
                                this.setState({
                                    dataSource: filteredData.salaryData
                                });
                            }}
                        />
                        {/* <Button
                            icon={<UndoOutlined />}
                            onClick={async () => {
                                await this.getSalariesInfo();
                                this.setState({
                                    orderId: undefined
                                });
                            }}
                        /> */}
                    </div>
                    <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'end' }}>
                        <Tooltip title={<FormattedMessage id='setting-salary.to_pay_salary' />}>
                            <Button
                                icon={<DollarOutlined />}
                                onClick={() => {
                                    this.setState({
                                        visiblePayOutSalaryModal: true
                                    });
                                }}
                                style={{
                                    marginRight: 8
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id='setting-salary.to_calc_salary' />}>
                            <Button
                                icon={<PercentageOutlined />}
                                onClick={() => {
                                    this.setState({
                                        visibleCalcSalaryModal: true
                                    });
                                }}
                                style={{
                                    marginRight: 8
                                }}
                            />
                        </Tooltip>
                        <DateRangePicker
                            allowClear
                            dateRange={[startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined]}
                            format={dateFormat}
                            minimize
                            onDateChange={async dateRange => {
                                await this.setState({
                                    startDate: dayjs(dateRange[0]).format(dateFormat),
                                    endDate: dayjs(dateRange[1]).format(dateFormat)
                                });
                                await this.getSalariesInfo();
                            }}
                            popupStyle={{
                                maxHeight: 250,
                                overflow: 'auto',
                                zIndex: '9999',
                                maxWidth: 150
                            }}
                            type='default'
                        />
                        {startDate || endDate || orderId ? (
                            <Tooltip title={<FormattedMessage id='storage.reset_all_filters' />}>
                                <Button
                                    icon={<CloseOutlined />}
                                    onClick={async () => {
                                        const data = await fetchAPI(
                                            'GET',
                                            '/employee/salary/data',
                                            {
                                                employeeId: _.get(employee, '[0]'),
                                                page,
                                                pageSize,
                                                orderId: undefined,
                                                startDate: undefined,
                                                endDate: undefined
                                            },
                                            null,
                                            { handleErrorInternally: true }
                                        );
                                        this.setState({
                                            dataSource: data.salaryData,
                                            count: data.count,
                                            total: data.total,
                                            startDate: undefined,
                                            endDate: undefined,
                                            orderId: undefined
                                        });
                                    }}
                                />
                            </Tooltip>
                        ) : undefined}
                    </div>
                </div>

                {dataSource.map(({ order, orderId, vehicle, salaryByOrder, rules, doneDateTime }, index) => (
                    <Collapse>
                        <Panel
                            key={orderId}
                            header={
                                <div className={Styles.collapseContainer}>
                                    <div className={Styles.collapseContainerParts}>
                                        <span
                                            style={{
                                                fontWeight: 700
                                            }}
                                        >
                                            {index + 1}.{' '}
                                            <Link className={Styles.orderLink} to={`${book.order}/${orderId}`}>
                                                {order}
                                            </Link>
                                        </span>
                                        <div>
                                            <FormattedMessage id='orders.vehicle' />:{' '}
                                            <span
                                                style={{
                                                    fontWeight: 700
                                                }}
                                            >{`${vehicle}`}</span>
                                            ;
                                        </div>
                                    </div>
                                    <div className={Styles.collapseContainerParts}>
                                        <div>
                                            <FormattedMessage id='employee_salary_rules.salary_table' />:{' '}
                                            <span
                                                style={{
                                                    fontWeight: 700
                                                }}
                                            >
                                                <Numeral mask='0,0.00'>{Number(salaryByOrder)}</Numeral>{' '}
                                            </span>
                                            <FormattedMessage id='cur' />
                                        </div>{' '}
                                        <div>
                                            <FormattedMessage id='storage_journal.closing_date' />:{' '}
                                            <span
                                                style={{
                                                    fontWeight: 700
                                                }}
                                            >
                                                {doneDateTime ? (
                                                    dayjs(doneDateTime).format('DD.MM.YYYY')
                                                ) : (
                                                    <FormattedMessage id='long_dash' />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            <Table
                                columns={this.columns()}
                                dataSource={rules}
                                pagination={{
                                    pageSize,
                                    total: Math.ceil(_.get(rules, 'length') / pageSize) * pageSize,
                                    hideOnSinglePage: true,
                                    current: page,
                                    onChange: async (page, pageSize) => {
                                        await this.setState({ page, pageSize });
                                        this.getSalariesInfo();
                                    }
                                }}
                                rowKey='employeeId'
                                scroll={{
                                    x: 800,
                                    y: '60vh'
                                }}
                            />
                        </Panel>
                    </Collapse>
                ))}
                <CalcSalaryModal
                    allEmployees={allEmployees}
                    calcSalary={this.calcSalary}
                    empId={_.get(employee, '[0]')}
                    hideModal={() => {
                        this.setState({
                            visibleCalcSalaryModal: false
                        });
                    }}
                    onlyForCurrentEmployee
                    visible={visibleCalcSalaryModal}
                />
                <PayOutSalaryModal
                    allEmployees={allEmployees}
                    calculatedSalary={calculatedSalary}
                    empId={_.get(employee, '[0]')}
                    getSalariesInfo={this.getSalariesInfo}
                    hideModal={() => {
                        this.setState({
                            visiblePayOutSalaryModal: false
                        });
                    }}
                    onlyForCurrentEmployee
                    visible={visiblePayOutSalaryModal}
                />
            </div>
        );
    }
}

const DataRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 14px;
    padding: 14px 0;

    border-bottom: 1px solid;
    border-top: 1px solid;
`;

const DataWrapper = styled.div`
    display: flex;
    font-size: 16px;
`;
